
import InfiniteScroll from 'react-infinite-scroll-component';
import { styled } from '@mui/material/styles';
import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Skeleton,
} from '@mui/material';
import { useTable } from 'react-table';
import EmptyTableState from './EmptyTableState';

const Table = styled(MuiTable)(({ theme }) => `
  .MuiTableCell-head,
  .MuiTableCell-body {
    border: 0;
    padding: 0;

    > div {
      padding-left: 8px;
      padding-right: 8px;
    }
  }

  .MuiTableCell-head {
    font-weight: 600;
    font-size: 12px;

    > div {
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }

  .MuiTableCell-body {
    font-size: 14px;
    cursor: pointer;

    > div {
      position: relative;
      border-top: 1px solid ${theme.palette.grey[400]};
      border-bottom: 1px solid ${theme.palette.grey[400]};
      margin-bottom: 10px;
      height: 48px;
      display: flex;
      align-items: center;

      &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto 0;
        width: 1px;
        height: calc(100% - 6px);
        background: ${theme.palette.grey[400]};
      }
    }

    &:first-of-type {
      > div {
        border-left: 1px solid ${theme.palette.grey[400]};
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
    }

    &:last-child {
      color: #D31163;
      font-weight: 500;
      > div {
        border-right: 1px solid ${theme.palette.grey[400]};
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;

        &:after {
          display: none;
        }
      }
    }
  }
`);

const TableComponent = ({
  onRowClick,
  data,
  columns,
  isLoading,
  emptyStateProps,
  hasNextPage,
  height = 400,
  onNextPage,
}) => {
  const tableInstance = useTable({ columns, data });
  const noResults = !isLoading && !Boolean(data?.length);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  return (
    <InfiniteScroll
      height={height}
      dataLength={data.length}
      next={onNextPage}
      hasMore={hasNextPage}
      loader={<h4>Loading...</h4>}
    >
      <Box sx={{
        mt: 2,
        // maxHeight: '100%',
        // overflowY: 'auto',
        position: 'relative'
      }}>
        {!noResults ? (
          <Table {...getTableProps()}>
            <TableHead>
              {// Loop over the header rows
                headerGroups?.map(headerGroup => (
                  // Apply the header row props
                  <TableRow {...headerGroup.getHeaderGroupProps()}>
                    {// Loop over the headers in each row
                      headerGroup?.headers?.map(column => (
                        // Apply the header cell props
                        <TableCell {...column.getHeaderProps()}>
                          <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                            {// Render the header
                              column.render('Header')}
                          </div>
                        </TableCell>
                      ))}
                  </TableRow>
                ))}
            </TableHead>
            {/* Apply the table body props */}
            {!isLoading ? (
              <TableBody {...getTableBodyProps()}>
                {// Loop over the table rows
                  rows?.map(row => {
                    // Prepare the row for display
                    prepareRow(row);
                    return (
                      // Apply the row props
                      <TableRow {...row.getRowProps()} onClick={onRowClick ? () => onRowClick(row) : undefined}>
                        {// Loop over the rows cells
                          row.cells?.map(cell => (
                            <TableCell {...cell.getCellProps()}>
                              <div>
                                {// Render the cell contents
                                  cell.render('Cell')}
                              </div>
                            </TableCell>
                          ))}
                      </TableRow>
                    );
                  })}
              </TableBody>
            ) : (
              <TableBody>
                {[...Array(15)].map((_, index) => (
                  <TableRow key={`table-row-loader-${index}`}>
                    {columns?.map((_, index) => (
                      <TableCell key={`column-loader-${index}`}>
                        <div>
                          <Skeleton width='100%' />
                        </div>
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
        ) : (
          <EmptyTableState {...emptyStateProps} />
        )}
      </Box>
    </InfiniteScroll>
  );
};

export default TableComponent;