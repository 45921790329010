import { createSlice } from '@reduxjs/toolkit';
import queryString from 'query-string';
import httpClient from '../api/httpClient';

const initialState = {
  citiesList: [],
  searchCityIsLoading: false,
};

export const AddressSlice = createSlice({
  name: 'Address',
  initialState,
  reducers: {
    searchCityRequesting: (state) => {
      state.citiesList = [];
      state.searchCityIsLoading = true;
    },
    searchCitySuccess: (state, { payload }) => {
      state.searchCityIsLoading = false;
      state.citiesList = payload ?? [];
    },
    searchCityError: (state) => {
      state.searchCityIsLoading = false;
    },
  }
});

export const {
  searchCityRequesting,
  searchCitySuccess,
  searchCityError,
  searchAddressRequesting,
  searchAddressSuccess,
  searchAddressError,
} = AddressSlice.actions;

export const searchCity = (keyword, setToState = true, onSuccess) => async (dispatch) => {
  if (setToState) {
    dispatch(searchCityRequesting());
  }
  httpClient.post('/proxy/speedy', { keyword }).then(
    (response) => {
      if (setToState) {
        dispatch(searchCitySuccess(response?.data?.sites));
      }
      if (onSuccess) {
        onSuccess(response);
      }
    },
    (error) => {
      console.log('error', error);
    },
  );
};

export const searchAddress = (city, address) => async (dispatch) => {
  const params = {
    address: `${city}, ${address}`,
    key: process.env.REACT_APP_GOOGLE_API_KEY,
    language: 'bg',
    components: 'country:bg'
  };

  httpClient.get(`/geocode/json?&${queryString.stringify(params)}`, {
    baseURL: 'https://maps.googleapis.com/maps/api'
  }).then(
    (response) => {
      const latLng = response?.data?.results[0]?.geometry?.location;

      const addressParams = {
        input: address,
        key: process.env.REACT_APP_GOOGLE_API_KEY,
        language: 'bg',
        types: 'geocode',
        components: 'country:bg',
        location: latLng,
        radius: 15000,
        strictbounds: true
      };

      httpClient.get(`/place/autocomplete/json?&${queryString.stringify(addressParams)}`, {
        baseURL: 'https://maps.googleapis.com/maps/api'
      });
    },
    (error) => {
      console.log('error', error);
    },
  );
};

export default AddressSlice.reducer;
