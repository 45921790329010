import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RentForm } from 'components/forms';
import { getSingleRent } from 'stores/RentSlice';

const PropertiesRentEdit = () => {
  const { rentId } = useParams();
  const dispatch = useDispatch();

  const { singleRent, getSingleRentIsLoading } = useSelector(({ rentState }) => rentState);

  useEffect(() => {
    if (rentId) {
      dispatch(getSingleRent(rentId));
    }
  }, []);

  const defaultValues = {
    amountPaid: singleRent?.amountPaid,
    paymentDate: singleRent?.paymentDate,
    attachments: singleRent?.attachments,
    note: singleRent?.note
  };

  return (
    <RentForm
      isEdit
      defaultValues={defaultValues}
      isLoading={getSingleRentIsLoading}
    />
  );
};
export default PropertiesRentEdit;