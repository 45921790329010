import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Typography,
  Button,
  ButtonBase,
  Box,
  styled
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Dialog } from 'components';
import { createRequisition, clearBanks, getBanks } from 'stores/FinancesSlice';

const BankItems = styled('div')(({ theme }) => `
   overflow-x: auto;
   display: flex;
   align-items: center;
   padding: 20px 5px;
`);

const BankItem = styled(ButtonBase)(({ theme, isActive }) => `
  box-shadow: 0 0 12px 0 rgb(0 0 0 / 15%);
  min-width: 150px;
  max-width: 150px;
  height: 60px;
  padding: 10px;
  margin-right: 20px;
  border-radius: 6px;
  border: ${isActive ? `1px solid ${theme.palette.primary.main}` : '1px solid transparent'};

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`);

const SelectBankAccount = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedBankId, setSelectedBankId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [banksListIsOpen, setBanksListIsOpen] = useState(false);
  const { financesState: { banks } } = useSelector(({ financesState }) => ({ financesState }));

  useEffect(() => {
    if (isOpen) {
      setIsLoading(true);
      dispatch(getBanks(() => {
        setIsLoading(false);
        setBanksListIsOpen(true);
      }));
    } else {
      setBanksListIsOpen(false);
    }
  }, [isOpen]);

  return (
    <>
      <Dialog
        open={isLoading}
        title={t('connection')}
        dialogWidth='380px'
      />

      <Dialog
        open={banksListIsOpen}
        onClose={() => {
          dispatch(clearBanks());
          onClose();
        }}
        title={t('bankIntegration')}
        dialogWidth='490px'
      >
        <Typography variant='h6'>
          {t('chooseBankAndVerify')}
        </Typography>
        <Typography mt={2} variant='subtitle2'>
          {`${t('bank')}*`}
        </Typography>
        <BankItems>
          {banks?.map((bank) => (
            <BankItem
              key={bank?._id}
              onClick={() => setSelectedBankId(bank?._id)}
              isActive={selectedBankId === bank?._id}
            >
              <img
                alt='bank-img'
                src={bank?.logo}
                style={{ width: '100%', display: 'block' }}
              />
            </BankItem>
          ))}
        </BankItems>
        <Typography variant='caption'>
          {t('ifYouClickYouWillBeRedirected')}
        </Typography>
        <Box mt={1}>
          <Button
            variant='contained'
            color='primary'
            fullWidth
            disabled={!selectedBankId}
            onClick={() => {
              dispatch(createRequisition({
                bankId: selectedBankId,
                redirectUrl: window?.location?.origin,
              }, (response) => {
                const link = response?.data?.requisition?.link;
                window.open(link);
              }));
            }}
          >
            {t('choose')}
          </Button>
        </Box>
      </Dialog>
    </>
  );
};

export default SelectBankAccount;