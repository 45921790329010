
const CancelIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20.414'
    height='20.414'
    viewBox='0 0 20.414 20.414'
    {...props}
  >
    <path
      fill='#2f3191'
      d='M10.207 0A10.237 10.237 0 1017.8 3.4l.077-.077-.787-.785-.077.077A10.153 10.153 0 0010.207 0zm0 1.276a8.888 8.888 0 015.9 2.23L14.481 5.1H4.986a1.17 1.17 0 00-1.158 1.162v6.626a1.17 1.17 0 001.159 1.159h.406L3.409 16a8.93 8.93 0 016.8-14.722zM16.9 4.3A8.925 8.925 0 014.231 16.836l.925-.81.137-.117 1.762-1.762 4.834 3.013v-3.113h3.539a1.17 1.17 0 001.159-1.159V6.262a1.16 1.16 0 00-.635-1.014zM5.1 6.379h8.081l-6.5 6.392H5.1zm9.716 0h.491v6.392h-4.7v2.091L7.979 13.22z'
    ></path>
  </svg>
);

export default CancelIcon;
