import { Container } from '@mui/material';
import { useMeasure } from 'react-use';

const headerHeight = 60;
const layoutGutter = 20;

const FullScreenLayout = ({ children }) => {
  const [ref, { height }] = useMeasure();

  return (
    <div ref={ref} style={{
      height: `calc(100vh - ${layoutGutter}px)`,
      paddingTop: headerHeight + layoutGutter,
    }}>
      <Container>
        <div>
          {children({ layoutHeight: height })}
        </div>
      </Container>
    </div>
  );
};

export default FullScreenLayout;