import { createSlice } from '@reduxjs/toolkit';
import httpClient from '../api/httpClient';

const localStorageToken = localStorage.getItem('token') ?? '';

const initialState = {
  isAuthenticated: Boolean(localStorageToken),
  token: localStorageToken,
  getCodeIsLoading: false,
  confirmCodeIsLoading: false,
  logoutIsLoading: false,
  organizationUsers: [],
  updateUserIsLoading: false,
};

export const AuthenticationSlice = createSlice({
  name: 'Authentication',
  initialState,
  reducers: {
    getCodeRequesting: (state) => {
      state.getCodeIsLoading = true;
    },
    getCodeSuccess: (state) => {
      state.getCodeIsLoading = false;
    },
    getCodeError: (state) => {
      state.loginIsLoading = false;
    },
    confirmCodeRequesting: (state) => {
      state.confirmCodeIsLoading = true;
    },
    confirmCodeSuccess: (state, { payload }) => {
      state.confirmCodeIsLoading = false;
      const token = payload;
      if (token) {
        state.isAuthenticated = true;
        state.token = token;
        localStorage.setItem('token', token);
      }
    },
    confirmCodeError: (state, { payload }) => {
      state.confirmCodeIsLoading = false;
    },
    logoutSuccess: (state) => {
      state.isAuthenticated = false;
      state.token = '';
      localStorage.removeItem('token');
    },
  }
});

export const {
  getCodeRequesting,
  getCodeSuccess,
  getCodeError,
  confirmCodeRequesting,
  confirmCodeSuccess,
  confirmCodeError,
  logoutSuccess,
} = AuthenticationSlice.actions;

export const getSMSCode = (data, navigate) => async (dispatch) => {
  dispatch(getCodeRequesting());
  httpClient.post('/codes/v2?org=true', data, { headers: { Authorization: `Basic ${btoa('fidweb99#:1mm0t3ChBuSin3$$!')}` } }).then(
    (response) => {
      dispatch(getCodeSuccess(response?.data));
      if (navigate) {
        navigate(`/login/${data?.phoneNumber}`);
      }
    },
    (error) => dispatch(getCodeError(error)),
  );
};

export const confirmCode = (data, navigate) => async (dispatch) => {
  dispatch(confirmCodeRequesting());
  httpClient.post('/codes/use', data).then(
    (response) => {
      dispatch(confirmCodeSuccess(response?.data?.token));
      if (navigate) {
        navigate('/');
      }
    },
    (error) => dispatch(confirmCodeError(error)),
  );
};

export const logout = () => (dispatch) => {
  dispatch(logoutSuccess());
};


export default AuthenticationSlice.reducer;
