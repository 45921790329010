import { useState } from 'react';
import {
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Typography,
  Stack
} from '@mui/material';

const MenuList = ({ items, trigger }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      {trigger({ handleOpen })}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {items?.map(({ icon: Icon, onClick, label, description }, index) => {
          const isLastItem = index + 1 === items?.length;
          return (
            <div key={`menu-list-item${label}-${index}`}>
              <MenuItem
                onClick={() => {
                  handleClose();
                  if (onClick) {
                    onClick();
                  }
                }}
              >
                <div>
                  <Stack direction='row'>
                    {Icon && (
                      <ListItemIcon>
                        <Icon />
                      </ListItemIcon>
                    )}
                    <ListItemText>
                      {label}
                    </ListItemText>
                  </Stack>
                  {description && (
                    <Typography variant='caption' textTransform='none'>{description}</Typography>
                  )}
                </div>
              </MenuItem>
              {!isLastItem && (
                <Divider />
              )}
            </div>
          );
        })}
      </Menu>
    </div>
  );
};

export default MenuList;