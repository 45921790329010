
const ReloadIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='21.533'
    height='17'
    viewBox='0 0 21.533 17'
    {...props}
  >
    <path
      fill='#2f3191'
      d='M7.852 19.406A7.072 7.072 0 0012.633 21a8.5 8.5 0 008.434-7.438h1.268a1.062 1.062 0 00.884-1.652l-2.265-3.4a1.063 1.063 0 00-1.768 0l-2.265 3.4a1.062 1.062 0 00.884 1.652h1.115a6.378 6.378 0 01-9.476 4.459zm7.97-12.428a6.378 6.378 0 00-9.476 4.459h1.115a1.063 1.063 0 01.884 1.652l-2.265 3.4a1.062 1.062 0 01-1.768 0l-2.265-3.4a1.063 1.063 0 01.884-1.652H4.2A8.5 8.5 0 0112.633 4a7.072 7.072 0 014.781 1.594z'
      transform='translate(-1.867 -4)'
    ></path>
  </svg>
);

export default ReloadIcon;
