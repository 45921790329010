import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
} from '@mui/material';
import { PhoneNumberInput } from 'components';
import { AuthLayout } from 'layouts';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getSMSCode } from 'stores/AuthenticationSlice';
import { useTranslation } from 'react-i18next';

const validations = Yup.object().shape({
  phoneNumber: Yup.string().required().label('Phone number')
});

const Login = () => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    watch,
    setValue
  } = useForm({
    resolver: yupResolver(validations)
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const canSubmit = Boolean(watch('phoneNumber'));

  return (
    <AuthLayout
      title={t('logInSystem')}
      subtitle={t('fillNumber')}
    >
      <Box p={3}>
        <form onSubmit={handleSubmit(({ phoneNumber }) => dispatch(getSMSCode({
          phoneNumber: `+${phoneNumber}`,
          isBusinessLogin: true
        }, navigate)))}>
          <Box mt={5}>
            <PhoneNumberInput
              onChange={(value) => setValue('phoneNumber', value)}
            />
          </Box>
          <Box mt={4}>
            <Button
              variant='contained'
              color='primary'
              fullWidth
              disabled={!canSubmit}
              type='submit'
            >
              {t('continue')}
            </Button>
          </Box>
        </form>
      </Box>
    </AuthLayout>
  );
};

export default Login;