import { useRef, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Paper,
  Typography,
  IconButton,
  Stack,
  Button,
  Box,
  Grid,
  TextField,
  InputAdornment,
  Chip,
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  styled
} from '@mui/material';
import { toast } from 'react-toastify';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import NumberFormat from 'react-number-format';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { DefaultLayout } from 'layouts';
import { ArrowBackIcon } from 'icons';
import { s3Upload } from 'stores/FilesSlice';
import { markAsPaidLandlord, editSemiPaidRent, getSingleRent } from 'stores/RentSlice';

const FilesContainer = styled('div')`
   display: flex;
   flex-wrap: nowrap; 
   overflow: auto;
   width: '100%';
   padding: 10px;
   > * {
     margin-right: 10px;
     margin-bottom: 2px; 
   }
`;

const RentForm = ({ isEdit, isSemiPaid, defaultValues, isLoading }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fileInputRef = useRef();
  const { propertyId, rentId } = useParams();
  const [deleteFilesIds, setDeleteFilesIds] = useState([]);
  const [amountType, setAmountType] = useState('');
  const { singleRent } = useSelector(({ rentState }) => rentState);
  const rentAmount = singleRent?.amount - singleRent?.amountPaid;

  const validations = isSemiPaid ? (
    Yup.object().shape({
      amount: Yup.number().label('Amount').required(t('amountRequired')).typeError(t('amountRequired')),
      paymentDate: Yup.date().required(t('payDateRequired')).typeError(t('enterValidDate')),
      rent: Yup.object().shape({
        attachments: Yup.array().of(
          Yup.string()
        ),
        note: Yup.string()
      })
    })
  ) : (
    Yup.object().shape({
      amountPaid: Yup.number().label('Amount').required(t('amountRequired')).typeError(t('amountRequired')),
      note: Yup.string().label('Note'),
      attachments: Yup.array().of(
        Yup.object().shape({
          name: Yup.string(),
          url: Yup.string()
        })
      ),
      paymentDate: Yup.date().required(t('payDateRequired')).typeError(t('enterValidDate')),
    })
  );

  useEffect(() => {
    if (rentId) {
      dispatch(getSingleRent(rentId));
    }
  }, [rentId]);

  const {
    watch,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({
    resolver: yupResolver(validations),
    defaultValues: {
      attachments: []
    }
  });

  useEffect(() => {
    if (isEdit || isSemiPaid) {
      reset(defaultValues);
    }
  }, [isEdit, isLoading, isSemiPaid, defaultValues]);

  const filesValue = watch('attachments');

  const handleDeleteFile = (index, id) => {
    setValue('attachments', filesValue?.filter((_, currentIndex) => currentIndex !== index));
    if (id) {
      setDeleteFilesIds((currentIds) => [...currentIds, id]);
    }
  };

  const onChooseFile = (ev) => {
    const attachments = [...ev.currentTarget.files];
    attachments.map((item) => {
      dispatch(s3Upload(item, (data) => {
        const newFile = {
          name: item?.name,
          url: data?.location
        };
        setValue('attachments', [
          ...watch('attachments'),
          newFile
        ]);
        fileInputRef.current.value = '';
      }));

      return item;
    });
  };

  return (
    <DefaultLayout>
      <form onSubmit={handleSubmit((data) => {
        if (isSemiPaid) {
          const submitData = {
            amount: data?.amount,
            paymentDate: data?.paymentDate,
            attachments: data?.attachments,
            note: data?.note
          };
          dispatch(editSemiPaidRent(defaultValues?._id, submitData, () => {
            navigate(`/properties/${propertyId}`);
            toast.success(t('editSemiPaidSuccess'));
          }));
        } else {
          if (isEdit) {
            dispatch(markAsPaidLandlord({
              ...data,
              _id: rentId,
              paymentDate: format(data?.paymentDate, 'dd.MM.yyyy'),
              isEdit,
              documents: data?.documents?.map((document) => document?.url)
            }, () => {
              navigate(`/properties/${propertyId}`);
              toast.success(t('editPaidRent'));
            }, propertyId));
          } else {
            dispatch(markAsPaidLandlord({
              ...data,
              _id: rentId,
              documents: data?.documents?.map((document) => document?.url)
            }, () => {
              navigate(`/properties/${propertyId}`);
              toast.success(t('rentMarkedAsPaidSuccess'));
            }, propertyId));
          }
        }
      })}>
        <Paper elevation={4}>
          <Stack direction='row' alignItems='center' justifyContent='space-between'>
            <Stack direction='row' spacing={1}>
              <IconButton onClick={() => navigate(`/properties/${propertyId}`)}>
                <ArrowBackIcon
                  style={{ width: 16, height: 16 }}
                />
              </IconButton>
              <Typography variant='h4'>{isEdit ? t('editOnPayment') : t('tickAsPaid')}</Typography>
            </Stack>
            <Stack direction='row' spacing={1}>
              <Button
                variant='contained'
                color='primary'
                type='submit'
              >
                {t('save')}
              </Button>
            </Stack>
          </Stack>
        </Paper>
        <Box mt={3}>
          <Paper elevation={4}>
            <Typography variant='subtitle1'>{t('details')}</Typography>
            <Typography variant='caption' textTransform='none'>
              {t('tickpaymentText')}
            </Typography>
            <Grid container mt={3}>
              {!isSemiPaid && (
                <Grid item md={4}>
                  <FormControl>
                    <FormLabel disabled>{`${t('paidAmount')}*`}</FormLabel>
                    <RadioGroup
                      row
                    >
                      <FormControlLabel
                        value='фиксирана'
                        control={<Radio
                          checked={amountType === 'fixed'}
                          onChange={() => {
                            setAmountType('fixed');
                            setValue('amountPaid', rentAmount);
                          }}
                        />}
                        label={t('fixed')}
                      />
                      <FormControlLabel
                        value='различна'
                        control={<Radio
                          checked={amountType === 'different'}
                          onChange={() => {
                            setAmountType('different');
                            setValue('amountPaid', '');
                          }}
                        />}
                        label={t('different')}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              )}
              <Grid item md={3}>
                {isSemiPaid ? (
                  <>
                    <NumberFormat
                      customInput={TextField}
                      thousandSeparator
                      InputLabelProps={{ shrink: true }}
                      label={`${t('paidAmount')}*`}
                      InputProps={{
                        endAdornment: <InputAdornment position='start'>
                          {t('lv')}
                        </InputAdornment>,
                      }}
                      size='small'
                      fullWidth
                      value={watch('amount')}
                      onValueChange={({ floatValue }) => {
                        setValue('amount', floatValue, { shouldValidate: true });
                      }}
                      error={Boolean(errors?.amount?.message)}
                      helperText={errors?.amount?.message}
                    />
                  </>
                ) : (
                  <>
                    <NumberFormat
                      customInput={TextField}
                      thousandSeparator
                      InputLabelProps={{ shrink: true }}
                      label={`${t('paidAmount')}*`}
                      InputProps={{
                        endAdornment: <InputAdornment position='start'>
                          {t('lv')}
                        </InputAdornment>,
                      }}
                      size='small'
                      fullWidth
                      value={watch('amountPaid')}
                      onValueChange={({ floatValue }) => {
                        setValue('amountPaid', floatValue, { shouldValidate: true });
                      }}
                      disabled={amountType === 'fixed'}
                      error={Boolean(errors?.amountPaid?.message)}
                      helperText={errors?.amountPaid?.message}
                    />
                  </>
                )}
              </Grid>
              <Grid item md={3}>
                <DesktopDatePicker
                  label={t('endDatePayment')}
                  value={watch('paymentDate') ? watch('paymentDate') : null}
                  onChange={(date) => setValue('paymentDate', date, { shouldValidate: true })}
                  renderInput={(params) => <TextField
                    {...params}
                    size='small'
                    InputLabelProps={{ shrink: true }}
                    error={Boolean(errors?.paymentDate?.message)}
                    helperText={errors?.paymentDate?.message}
                  />
                  }
                />
              </Grid>
            </Grid>
            <Grid container mt={3} alignItems='center'>
              <Grid item md={2}>
                <>
                  <input
                    ref={fileInputRef}
                    type='file'
                    onChange={onChooseFile}
                    multiple
                    style={{ display: 'none' }}
                  />
                  <Button
                    variant='outlined'
                    color='primary'
                    onClick={() => fileInputRef?.current?.click()}
                  >
                    {t('addFiles')}
                  </Button>
                </>
              </Grid>
              <Grid item md={10}>
                <FilesContainer>
                  {filesValue?.map((file, index) => (
                    <Box key={file?.url} mt={1} mb={1}>
                      <Chip
                        label={file?.name}
                        variant='outlined'
                        onDelete={() => handleDeleteFile(index)}
                      />
                    </Box>
                  ))}
                </FilesContainer>
              </Grid>
            </Grid>
            <Grid container mt={3}>
              <Grid item md={8} mt={3}>
                <TextField
                  label={t('note')}
                  size='small'
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  {...register('note')}
                  error={Boolean(errors?.note?.message)}
                  helperText={errors?.note?.message}
                />
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </form>
    </DefaultLayout >
  );
};
export default RentForm;