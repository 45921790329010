
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Stack,
  Typography,
  Box,
  Button,
  TextField
} from '@mui/material';
import { Dialog, OverflowContent } from 'components';
import { RentInfo } from 'components/Properties';
import { addNote, editNote, deleteNote } from 'stores/RentSlice';

const RentList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { propertyId } = useParams();
  const { rents } = useSelector(({ rentState }) => rentState);
  const copiedRentsList = [...rents];
  const reversedRentsList = copiedRentsList?.reverse();
  const [selectedRentItem, setSelectedRentItem] = useState(undefined);
  const [noteModalIsOpen, setNoteModalIsOpen] = useState(false);
  const [noteValue, setNoteValue] = useState('');

  useEffect(() => {
    setNoteValue(selectedRentItem?.note ?? '');
  }, [selectedRentItem]);

  return (
    <>
      <OverflowContent head={(
        <Stack direction='row' justifyContent='space-between'>
          <Typography variant='subtitle1'>{t('history')}</Typography>
        </Stack>
      )}>
        {reversedRentsList?.map((rentItem) => {
          const { id, amountPaid, rent, amount, paymentDate, dueDate, currency, month, paid, paymentMethod, note, attachments, invoice, paymentHistory } = rentItem;
          const amountPaidIsLowerThanDue = amount > amountPaid;
          const hasInvoice = Boolean(invoice);
          const invoiceId = invoice?._id;
          return (
            <Box
              key={`rent-item-${id}`}
              mt={2}
            >
              <RentInfo
                month={month}
                paymentDate={paymentDate}
                dueDate={dueDate}
                amount={amount}
                amountPaid={amountPaid}
                rent={rent}
                button
                paid={paid}
                note={note}
                paymentMethod={paymentMethod}
                currency={currency}
                amountPaidIsLowerThanDue={amountPaidIsLowerThanDue}
                attachments={attachments}
                invoice={invoice}
                hasInvoice={hasInvoice}
                invoiceId={invoiceId}
                paymentHistory={paymentHistory}
                {...{ propertyId }}
                {...{ id }}
                onNoteClick={() => {
                  setSelectedRentItem(rentItem);
                  setNoteModalIsOpen(true);
                }}
              />
            </Box>
          );
        })}
      </OverflowContent>
      <Dialog
        open={noteModalIsOpen}
        onClose={() => setNoteModalIsOpen(false)}
        title={t('rentNote')}
      >
        <Typography variant='h6' mb={3}>{t('addOrEditNote')}</Typography>
        <TextField
          label={t('note')}
          required
          multiline
          rows={6}
          fullWidth
          value={noteValue}
          InputLabelProps={{ shrink: true }}
          onChange={({ currentTarget: { value } }) => {
            setNoteValue(value);
          }}
        />
        {selectedRentItem?.note !== '' ? (
          <Stack mt={3} direction='row' alignItems='center' spacing={3}>
            <Button
              variant='contained'
              color='error'
              fullWidth
              onClick={() => {
                dispatch(deleteNote({
                  rentId: selectedRentItem?.id,
                  note: ''
                }));
                setNoteModalIsOpen(false);
              }}
            >
              {t('deleteNote')}
            </Button>
            <Button
              variant='contained'
              color='primary'
              fullWidth
              onClick={() => {
                dispatch(editNote({
                  rentId: selectedRentItem?.id,
                  note: noteValue
                }));
                setNoteModalIsOpen(false);
              }}
            >
              {t('saveChanges')}
            </Button>
          </Stack>
        ) : (
          <Box mt={3}>
            <Button
              fullWidth
              variant='contained'
              color='primary'
              onClick={() => {
                dispatch(addNote({
                  rentId: selectedRentItem?.id,
                  note: noteValue
                }));
                setNoteModalIsOpen(false);
              }}
            >
              {t('add')}
            </Button>
          </Box>
        )}
      </Dialog>
    </>
  );
};

export default RentList;

