import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Paper,
  Button,
  Stack,
  Box,
  IconButton,
  Typography,
  Grid,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  FormHelperText,
  Radio,
  InputAdornment,
  TextField,
  FormGroup,
  Switch,
  useTheme,
  Fade,
  Chip,
  styled
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import NumberFormat from 'react-number-format';
import { format, isBefore, parseISO } from 'date-fns';
import { DefaultLayout } from 'layouts';
import { ArrowBackIcon } from 'icons';
import { Select, CardDialog, Dialog } from 'components';
import { s3Upload } from 'stores/FilesSlice';
import { createArrivalOrExpense, deleteArrivalOrExpense, editArrivalOrExpense } from 'stores/FinancesSlice';
import { getCards, addCard } from 'stores/PaymentSlice';
import { getPropertiesList } from 'stores/PropertiesSlice';

const FilesContainer = styled('div')`
   display: flex;
   flex-wrap: nowrap; 
   overflow: auto;
   width: '100%';
   padding: 10px;
   > * {
     margin-right: 10px;
     margin-bottom: 2px; 
   }
`;

const CardsContainer = styled('div')`
   display: flex;
   flex-wrap: nowrap; 
   overflow: auto;
   width: '100%';
   padding: 10px;
   > * {
     margin-right: 10px;
     margin-bottom: 2px; 
   }
`;

const FinancesArrivalExpensesForm = ({ isEdit, defaultValues, isLoading }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fileInputRef = useRef();
  const [deleteFilesIds, setDeleteFilesIds] = useState([]);
  const [addCardModalIsOpen, setAddCardModalIsOpen] = useState(false);
  const [confirmDeleteModalIsOpen, setConfirmDeleteModalIsOpen] = useState(false);

  const {
    propertiesState: { propertiesList },
    paymentState: { cards, addCardIsLoading },
    financesState: { singleArrivalOrExpense }
  } = useSelector(({ propertiesState, paymentState, financesState }) => ({ propertiesState, paymentState, financesState }));

  useEffect(() => {
    dispatch(getPropertiesList({ isOrg: true }));
    dispatch(getCards());
  }, []);

  const validations = Yup.object().shape({
    type: Yup.string().required().label('Type'),
    payDate: Yup.date().required(t('payDateRequired')).typeError(t('enterValidDate')),
    amount: Yup.number().required(t('amountRequired')).typeError(t('amountRequired')),
    subtitle: Yup.string().required(t('subtitleRequired')),
    property: Yup.string().required(t('propertyRequired')),
    holder: Yup.string().when('isAutomatic', {
      is: true,
      then: Yup.string().required(t('holderRequired'))
    }),
    iban: Yup.string().when('isAutomatic', {
      is: true,
      then: Yup.string().required(t('ibanRequired'))
    }),
    card: Yup.string().when('isAutomatic', {
      is: true,
      then: Yup.string().required(t('cardRequired'))
    })
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
    control
  } = useForm({
    resolver: yupResolver(validations),
    defaultValues: {
      attachments: [],
      isAutomatic: false,
      isRegular: false
    }
  });

  useEffect(() => {
    if (isEdit) {
      reset({
        ...defaultValues,
        property: defaultValues?.property,
        type: defaultValues?.type,
        isRegular: defaultValues?.isRegular,
        isAutomatic: defaultValues?.isAutomatic,
        attachments: defaultValues?.attachments?.map((url) => ({
          url,
          name: url
        }))
      });
    }
  }, [isEdit]);

  useEffect(() => {
    if (isEdit) {
      setValue('type', defaultValues?.type);
    } else {
      setValue('type', 'income');
    }
  }, []);

  const handleDeleteFile = (index, id) => {
    setValue('attachments', attachmentsValue?.filter((_, currentIndex) => currentIndex !== index));
    if (id) {
      setDeleteFilesIds((currentIds) => [...currentIds, id]);
    }
  };

  const onChooseFile = (ev) => {
    const attachments = [...ev.currentTarget.files];
    attachments.map((item) => {
      dispatch(s3Upload(item, (data) => {
        const newFile = {
          name: item?.name,
          url: data?.location
        };
        setValue('attachments', [
          ...watch('attachments'),
          newFile
        ]);
        fileInputRef.current.value = '';
      }));

      return item;
    });
  };

  const typeValue = watch('type');
  const attachmentsValue = watch('attachments');
  const isAutomaticValue = watch('isAutomatic');
  const isRegularValue = watch('isRegular');
  const payDateValue = watch('payDate');
  const isOutcome = typeValue === 'outcome';

  const isYesterdayPayment = isBefore(parseISO(payDateValue), parseISO(format(new Date(), 'yyyy-MM-dd')));

  return (
    <DefaultLayout>
      <form onSubmit={handleSubmit((data) => {
        const isIncome = data?.type === 'income';
        if (isEdit) {
          dispatch(editArrivalOrExpense({
            ...data,
            _id: singleArrivalOrExpense?._id,
            property: data?.property,
            attachments: data?.attachments?.map(({ url }) => url),
            payDate: format(data?.payDate, 'dd.MM.yyyy'),
            title: 'Други',
            currency: 'BGN',
            ...(isIncome && {
              isRegular: false,
              isAutomatic: false,
            }),
          }, () => {
            navigate('/finances/other');
          }));
        } else {
          dispatch(createArrivalOrExpense({
            ...data,
            attachments: data?.attachments?.map(({ url }) => url),
            title: 'Други',
            currency: 'BGN',
            ...(isIncome && {
              isRegular: false,
              isAutomatic: false,
            })
          }, () => {
            navigate('/finances/other');
          }));
        }
      })}>
        <Paper elevation={4}>
          <Stack direction='row' alignItems='center' justifyContent='space-between'>
            <Stack direction='row' alignItems='center' spacing={1}>
              <IconButton onClick={() => navigate('/finances/other')}>
                <ArrowBackIcon style={{ width: 16, height: 16 }} />
              </IconButton>
              <Typography variant='h5'>{isEdit ? t('editOutComeIncome') : t('addIncomeOutcome')}</Typography>
            </Stack>
            {isEdit ? (
              <Stack direction='row' spacing={1}>
                <Button
                  variant='contained'
                  color='error'
                  onClick={() => setConfirmDeleteModalIsOpen(true)}
                >
                  {t('delete')}
                </Button>
                <Button variant='contained' color='primary' disabled={isLoading} type='submit'>
                  {t('edit')}
                </Button>
              </Stack>
            ) : (
              <Button variant='contained' color='primary' type='submit'>{t('add')}</Button>
            )}
          </Stack>
        </Paper>
        <Box mt={2}>
          <Paper elevation={4}>
            <Typography variant='subtitle1' textTransform='uppercase'>{t('forPayment')}</Typography>
            <Grid container mt={2}>
              <Grid item md={3}>
                <FormControl error={Boolean(errors?.payForMonth?.message)}>
                  <FormLabel disabled>{t('addOn')}*</FormLabel>
                  <RadioGroup row>
                    <FormControlLabel
                      value='приход'
                      control={
                        <Radio
                          checked={watch('type') === 'income'}
                          onChange={() => setValue('type', 'income', { shouldValidate: true })}
                        />
                      }
                      label={t('incomes')}
                    />
                    <FormControlLabel
                      value='разход'
                      control={
                        <Radio
                          checked={watch('type') === 'outcome'}
                          onChange={() => setValue('type', 'outcome', { shouldValidate: true })}
                        />
                      }
                      label={t('outcomes')}
                    />
                  </RadioGroup>
                  <FormHelperText error>
                    {errors?.type?.message}
                  </FormHelperText >
                </FormControl>
              </Grid>
              <Grid item md={3}>
                <DesktopDatePicker
                  label={`${t('payDate')}*`}
                  value={watch('payDate') ? watch('payDate') : null}
                  onChange={(date) => setValue('payDate', date, { shouldValidate: true })}
                  TransitionComponent={Fade}
                  renderInput={(params) => <TextField
                    {...params}
                    size='small'
                    InputLabelProps={{ shrink: true }}
                    error={Boolean(errors?.payDate?.message)}
                    helperText={errors?.payDate?.message}
                  />}
                />
              </Grid>
              <Grid item md={3}>
                <NumberFormat
                  customInput={TextField}
                  thousandSeparator
                  InputLabelProps={{ shrink: true }}
                  label={`${t('amount')}*`}
                  InputProps={{
                    endAdornment: <InputAdornment position='start'>
                      {t('lv')}
                    </InputAdornment>,
                  }}
                  size='small'
                  fullWidth
                  value={watch('amount')}
                  onValueChange={({ floatValue }) => {
                    setValue('amount', floatValue, { shouldValidate: true });
                  }}
                  error={Boolean(errors?.amount?.message)}
                  helperText={errors?.amount?.message}
                />
              </Grid>
              <Grid item md={3}>
                <Controller
                  control={control}
                  name='property'
                  render={({
                    field: { onChange, value },
                  }) => (
                    <Select
                      key={value}
                      label={t('property')}
                      size='small'
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      onChange={onChange}
                      value={value}
                      error={Boolean(errors?.property?.message)}
                      helperText={errors?.property?.message}
                      options={propertiesList?.map((property) => ({
                        label: property?.nicknames?.landlord ?? property?.nicknames?.owner,
                        value: property?._id,
                      }))}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container mt={2} alignItems='center'>
              <Grid item md={3}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  label={`${t('basis')}*`}
                  size='small'
                  fullWidth
                  {...register('subtitle')}
                  error={Boolean(errors?.subtitle?.message)}
                  helperText={errors?.subtitle?.message}
                />
              </Grid>
              <Grid item md={3}>
                <input
                  type='file'
                  onChange={onChooseFile}
                  ref={fileInputRef}
                  multiple
                  style={{ display: 'none' }}
                />
                <Button
                  variant='outlined'
                  color='primary'
                  onClick={() => fileInputRef.current.click()}
                >
                  {t('addFiles')}
                </Button>
              </Grid>
              <Grid item md={6}>
                <FilesContainer >
                  {attachmentsValue?.map((file, index) => (
                    <Box key={`file-${index}-${file?.name}`} mt={1} mb={1}>
                      <Chip
                        label={file?.name}
                        variant='outlined'
                        onDelete={() => handleDeleteFile(index)}
                      />
                    </Box>
                  ))}
                </FilesContainer>
              </Grid>
            </Grid>
            {isOutcome && (
              <>
                <Box p={1} mt={5} sx={{ backgroundColor: theme.palette.info.light }}>
                  <Stack direction='row' spacing={1} alignItems='center'>
                    <Typography variant='caption' textTransform='uppercase'>{t('repeatEveryMonth')}</Typography>
                    <FormGroup>
                      <FormControlLabel
                        control={<Switch
                          checked={isRegularValue}
                          onChange={() => setValue('isRegular', !isRegularValue)}
                        />}
                        label={isRegularValue ? t('yes') : t('no')}
                      />
                    </FormGroup>
                  </Stack>
                  <Typography variant='caption' textTransform='none'>{t('repeatEveryMonthClicked')}</Typography>
                </Box>
                <Box p={1} mt={2} sx={{ backgroundColor: theme.palette.info.light }}>
                  <Stack direction='row' spacing={1} alignItems='center'>
                    <Typography variant='caption' textTransform='uppercase'>{t('autoPayment')}</Typography>
                    <FormGroup>
                      <FormControlLabel
                        control={<Switch
                          disabled={isYesterdayPayment}
                          checked={isAutomaticValue}
                          onChange={() => setValue('isAutomatic', !isAutomaticValue)}
                        />}
                        label={isAutomaticValue ? t('yes') : t('no')}
                      />
                    </FormGroup>
                  </Stack>
                  <Typography variant='caption' textTransform='none'>{t('repeatEveryMonthClicked')}</Typography>
                </Box>
              </>
            )}
          </Paper>
        </Box>
        {(isOutcome && isAutomaticValue) && (
          <Box mt={2}>
            <Paper elevation={4}>
              <Typography variant='subtitle1' textTransform='uppercase' > {t('settingsAutoPayment')}</Typography>
              <Typography variant='caption' textTransform='none'> {t('addInfo')}
              </Typography>
              <Grid container mt={2}>
                <Grid item md={3}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    label={t('holder')}
                    {...register('holder')}
                    size='small'
                    fullWidth
                    error={Boolean(errors?.holder?.message)}
                    helperText={errors?.holder?.message}
                  />
                </Grid>
                <Grid item md={3}>
                  <TextField
                    label='IBAN*'
                    InputLabelProps={{ shrink: true }}
                    {...register('iban')}
                    error={Boolean(errors?.iban?.message)}
                    helperText={errors?.iban?.message}
                    size='small'
                    fullWidth
                  />
                </Grid>
                <Grid item md={3}>
                  <Typography variant='caption' textTransform='none'>
                    {t('cardForPayment')} *
                  </Typography>
                  <CardsContainer>
                    {cards?.map(({ _id, token, cardData }) => (
                      <div>
                        <Button
                          key={token}
                          variant={watch('card') === _id ? 'contained' : 'outlined'}
                          onClick={() => setValue('card', _id, { shouldValidate: true })}
                        >
                          ****{cardData?.card?.last4}
                        </Button>
                      </div>
                    ))}
                    {Boolean(errors?.card?.message) && (
                      <FormHelperText error={errors?.card?.message}>
                        {errors?.card?.message}
                      </FormHelperText>
                    )}
                  </CardsContainer>
                </Grid>
                <Grid item md={3}>
                  <Button
                    variant='outlined'
                    color='primary'
                    onClick={() => setAddCardModalIsOpen(true)}
                  >
                    {t('addCard')}
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Box>
        )}
      </form>
      <CardDialog
        open={addCardModalIsOpen}
        onClose={() => setAddCardModalIsOpen(false)}
        onSubmit={(data) => dispatch(addCard(data, () => setAddCardModalIsOpen(false)))}
        isLoading={addCardIsLoading}
      />
      <Dialog
        open={confirmDeleteModalIsOpen}
        onClose={() => setConfirmDeleteModalIsOpen(false)}
        title={t('deleteArrivalOrExpense')}
      >
        <Typography variant='h6' mb={3}>{t('deleteIncomeOutcomeConfirmation')}</Typography>
        <Button
          variant='contained'
          color='error'
          fullWidth
          onClick={() => {
            dispatch(deleteArrivalOrExpense({
              _id: singleArrivalOrExpense?._id
            }, () => {
              navigate('/finances/other');
              setConfirmDeleteModalIsOpen(false);
            }));
          }}
        >
          {t('delete')}
        </Button>
      </Dialog>
    </DefaultLayout>
  );
};
export default FinancesArrivalExpensesForm;