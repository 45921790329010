import { useEffect } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import cardValid from 'card-validator';
import { Dialog } from 'components';
import { Typography, Button, TextField, Grid, Box, CircularProgress } from '@mui/material';

const CardDialog = ({ open, onClose, onSubmit, isLoading }) => {
  const { t } = useTranslation();

  const validations = Yup.object().shape({
    number: Yup.string()
      .test('test-number',
        t('cardNumberIsInvalid'),
        (value) => cardValid.number(value).isValid)
      .required(t('cardNumberRequired')),
    expMonth: Yup.string()
      .required(t('expMonthRequired'))
      .matches(/^(0?[1-9]|1[012])$/, t('invalidMonth')),
    expYear: Yup.number()
      .typeError(t('expYearRequired'))
      .min(parseInt(`${new Date().getFullYear()}`.slice(-2), 10), t('cardExpired'))
      .required(t('expYearRequired')),
    cvc: Yup.string()
      .required(t('cvcRequired'))
      .min(3)
      .matches(/^[0-9]{3,4}$/gm, t('invalidCVV')),
    name: Yup.string()
      .required(t('nameOnCardRequired'))
      .trim()
      .matches(/^[a-zA-Z]+(\s[a-zA-Z]+)+$/gm, { message: t('nameOnCardMustContainTwoWords') })
  });

  useEffect(() => {
    if (!open) {
      reset({
        number: '',
        expMonth: '',
        expYear: '',
        cvc: '',
        name: '',
      });
    }
  }, [open]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({
    resolver: yupResolver(validations),
  });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={t('addCard')}
      isLoading={isLoading}
      dialogWidth='700px'
    >
      <Typography mb={2} variant='h6'>{t('guaranteeSavetyOfData')}</Typography>
      <form onSubmit={handleSubmit((data) => onSubmit(data))}>
        {isLoading ? (
          <div style={{ width: '100%', textAlign: 'center' }}>
            <CircularProgress size={80} />
          </div>
        ) : (
          <Box>
            <Grid container mt={3}>
              <Grid item md={8}>
                <TextField
                  label={t('cardNumber')}
                  size='small'
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ maxLength: 16 }}
                  {...register('number')}
                  error={Boolean(errors?.number?.message)}
                  helperText={errors?.number?.message}
                />
              </Grid>
              <Grid item md={2}>
                <TextField
                  label={t('MM')}
                  size='small'
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ maxLength: 2 }}
                  {...register('expMonth')}
                  error={Boolean(errors?.expMonth?.message)}
                  helperText={errors?.expMonth?.message}
                />
              </Grid>
              <Grid item md={2}>
                <TextField
                  label={t('YY')}
                  size='small'
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ maxLength: 2 }}
                  {...register('expYear')}
                  error={Boolean(errors?.expYear?.message)}
                  helperText={errors?.expYear?.message}
                />
              </Grid>
            </Grid>
            <Grid container mt={3}>
              <Grid item md={4}>
                <TextField
                  label='CVC'
                  size='small'
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ maxLength: 4 }}
                  {...register('cvc')}
                  error={Boolean(errors?.cvc?.message)}
                  helperText={errors?.cvc?.message}
                />
              </Grid>
              <Grid item md={8}>
                <TextField
                  label={t('cardHolder')}
                  size='small'
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  {...register('name')}
                  error={Boolean(errors?.name?.message)}
                  helperText={errors?.name?.message}
                />
              </Grid>
            </Grid>
            <Box mt={3}>
              <Button
                variant='contained'
                color='primary'
                fullWidth
                type='submit'
              >
                {t('add')}
              </Button>
            </Box>
          </Box>
        )}
      </form>
    </Dialog>
  );
};

export default CardDialog;