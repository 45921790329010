import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import httpClient from '../api/httpClient';

const initialState = {
  propertiesList: [],
  userTypes: [],
  utilityBillsTypes: [],
  utilityBillsProviders: [],
  propertyTypes: [],
  singleProperty: {},
  getPropertiesListIsLoading: false,
  getUserTypesIsLoading: false,
  getPropertyTypesIsLoading: false,
  createPropertyIsLoading: false,
  editPropertyIsLoading: false,
  getSinglePropertyIsLoading: false,
  createTenantIsLoading: false,
  getUtilityBillsTypes: false,
  getUtilityBillsProviders: false,
  createUtilityBillIsLoading: false,
  removeTenantIsLoading: false,
  tenantHistory: [],
  getTenantHistoryIsLoading: false
};

export const PropertiesSlice = createSlice({
  name: 'Properties',
  initialState,
  reducers: {
    getPropertyTypesRequesting: (state) => {
      state.getPropertyTypesIsLoading = true;
    },
    getPropertyTypesSuccess: (state, { payload }) => {
      state.getPropertyTypesIsLoading = false;
      state.propertyTypes = payload;
    },
    getPropertyTypesError: (state) => {
      state.getPropertyTypesIsLoading = false;
    },
    getUtilityBillsTypesRequesting: (state) => {
      state.getUtilityBillsTypes = true;
    },
    getUtilityBillsTypesSuccess: (state, { payload }) => {
      state.getUtilityBillsTypes = false;
      state.utilityBillsTypes = payload;
    },
    getUtilityBillsTypesError: (state) => {
      state.getUtilityBillsTypes = false;
    },
    getUtilityBillsProvidersRequesting: (state) => {
      state.getUtilityBillsProviders = true;
    },
    getUtilityBillsProvidersSuccess: (state, { payload }) => {
      state.getUtilityBillsProviders = false;
      state.utilityBillsProviders = payload;
    },
    getUtilityBillsProvidersError: (state) => {
      state.getUtilityBillsProviders = false;
    },
    getPropertiesListRequesting: (state) => {
      state.getPropertiesListIsLoading = true;
    },
    getPropertiesListSuccess: (state, { payload }) => {
      state.getPropertiesListIsLoading = false;
      state.propertiesList = payload;
    },
    getPropertiesListError: (state) => {
      state.getPropertiesListIsLoading = false;
    },
    getUserTypesRequesting: (state) => {
      state.getUserTypesIsLoading = true;
    },
    getUserTypesSuccess: (state, { payload }) => {
      state.getUserTypesIsLoading = false;
      state.userTypes = payload;
    },
    getUserTypesError: (state) => {
      state.getUserTypesIsLoading = false;
    },
    createPropertyRequesting: (state) => {
      state.createPropertyIsLoading = true;
    },
    createPropertySuccess: (state) => {
      state.createPropertyIsLoading = false;
    },
    createPropertyError: (state) => {
      state.createPropertyIsLoading = false;
    },
    editPropertyRequesting: (state) => {
      state.editPropertyIsLoading = true;
    },
    editPropertySuccess: (state, { payload }) => {
      state.editPropertyIsLoading = false;
      state.singleProperty = payload;
    },
    editPropertyError: (state) => {
      state.editPropertyIsLoading = false;
    },
    getSinglePropertyRequesting: (state) => {
      state.getSinglePropertyIsLoading = true;
    },
    getSinglePropertySuccess: (state, { payload }) => {
      state.getSinglePropertyIsLoading = false;
      state.singleProperty = payload;
    },
    getSinglePropertyError: (state) => {
      state.getSinglePropertyIsLoading = false;
    },
    resetSingleProperty: (state) => {
      state.singleProperty = {};
    },
    createTenantRequesting: (state) => {
      state.createTenantIsLoading = true;
    },
    createTenantSuccess: (state) => {
      state.createTenantIsLoading = false;
    },
    createTenantError: (state) => {
      state.createTenantIsLoading = false;
    },
    createUtilityBillRequesting: (state) => {
      state.createUtilityBillIsLoading = true;
    },
    createUtilityBillsSuccess: (state) => {
      state.createUtilityBillIsLoading = false;
    },
    createUtilityBillError: (state) => {
      state.createUtilityBillIsLoading = false;
    },
    removeTenantRequesting: (state) => {
      state.removeTenantIsLoading = true;
    },
    removeTenantSuccess: (state, { payload }) => {
      state.removeTenantIsLoading = false;
      state.singleProperty = {
        ...payload,
        openCasesCount: payload?.cases?.length,
        openRoomsCount: payload?.rooms?.length,
      };
    },
    removeTenantError: (state) => {
      state.removeTenantIsLoading = false;
    },
    getTenantHistoryRequesting: (state) => {
      state.getTenantHistoryIsLoading = true;
    },
    getTenantHistorySuccess: (state, { payload }) => {
      state.getTenantHistoryIsLoading = false;
      state.tenantHistory = payload;
    },
    getTenantHistoryError: (state) => {
      state.getTenantHistoryIsLoading = false;
    },
  }
});

export const {
  getPropertyTypesRequesting,
  getPropertyTypesSuccess,
  getPropertyTypesError,
  getPropertiesListRequesting,
  getPropertiesListSuccess,
  getPropertiesListError,
  getUserTypesRequesting,
  getUserTypesSuccess,
  getUserTypesError,
  createPropertyRequesting,
  createPropertySuccess,
  createPropertyError,
  editPropertyRequesting,
  editPropertySuccess,
  editPropertyError,
  getSinglePropertyRequesting,
  getSinglePropertySuccess,
  getSinglePropertyError,
  resetSingleProperty,
  createTenantRequesting,
  createTenantSuccess,
  createTenantError,
  getUtilityBillsTypesRequesting,
  getUtilityBillsTypesSuccess,
  getUtilityBillsTypesError,
  getUtilityBillsProvidersRequesting,
  getUtilityBillsProvidersSuccess,
  getUtilityBillsProvidersError,
  createUtilityBillRequesting,
  createUtilityBillsSuccess,
  createUtilityBillError,
  removeTenantRequesting,
  removeTenantSuccess,
  removeTenantError,
  getTenantHistoryRequesting,
  getTenantHistorySuccess,
  getTenantHistoryError
} = PropertiesSlice.actions;

export const getPropertyTypes = () => async (dispatch) => {
  dispatch(getPropertyTypesRequesting());
  httpClient.get('/properties/types').then(
    (response) => {
      dispatch(getPropertyTypesSuccess(response?.data?.types));
    },
    (error) => dispatch(getPropertyTypesError(error)),
  );
};

export const getUtilityBillsTypes = () => async (dispatch) => {
  dispatch(getUtilityBillsTypesRequesting());
  httpClient.get('/utility-bills/types').then(
    (response) => {
      dispatch(getUtilityBillsTypesSuccess(response?.data?.types));
    },
    (error) => dispatch(getUtilityBillsTypesError(error)),
  );
};

export const getUtilityBillsProviders = () => async (dispatch) => {
  dispatch(getUtilityBillsProvidersRequesting());
  httpClient.get('/utility-bills/providers').then(
    (response) => {
      dispatch(getUtilityBillsProvidersSuccess(response?.data?.types));
    },
    (error) => dispatch(getUtilityBillsProvidersError(error)),
  );
};

export const getPropertiesList = () => async (dispatch) => {
  dispatch(getPropertiesListRequesting());
  httpClient.get('/properties/org').then(
    (response) => {
      dispatch(getPropertiesListSuccess(response?.data?.properties));
    },
    (error) => dispatch(getPropertiesListError(error)),
  );
};

export const getUserTypes = () => async (dispatch) => {
  dispatch(getUserTypesRequesting());
  httpClient.get('/user-types').then(
    (response) => {
      dispatch(getUserTypesSuccess(response?.data?.types));
    },
    (error) => dispatch(getUserTypesError(error)),
  );
};

export const createProperty = (data, onSuccess) => async (dispatch) => {
  dispatch(createPropertyRequesting());
  httpClient.post('/properties?org=true', data).then(
    (response) => {
      dispatch(createPropertySuccess(response?.data));
      if (onSuccess) {
        onSuccess(response);
      }
    },
    (error) => dispatch(createPropertyError(error)),
  );
};

export const editProperty = (data, id, onSuccess) => async (dispatch) => {
  dispatch(editPropertyRequesting());
  httpClient.put('/properties', {
    ...data,
    _id: id,
  }).then(
    (response) => {
      dispatch(editPropertySuccess(response?.data?.property));
      if (onSuccess) {
        onSuccess(response);
      }
    },
    (error) => dispatch(editPropertyError(error)),
  );
};

export const getSingleProperty = (id) => async (dispatch) => {
  dispatch(getSinglePropertyRequesting());
  httpClient.get(`/properties/${id}`).then(
    (response) => {
      dispatch(getSinglePropertySuccess(response?.data?.property));
    },
    (error) => dispatch(getSinglePropertyError(error)),
  );
};

export const createTenant = (data, onSuccess) => async (dispatch) => {
  dispatch(createTenantRequesting());
  httpClient.post('/properties/org/add-tenant', data).then(
    (response) => {
      dispatch(createTenantSuccess(response?.data));
      if (onSuccess) {
        onSuccess(response);
      }
    },
    (error) => dispatch(createTenantError(error)),
  );
};

export const createUtilityBills = (data, onSuccess) => async (dispatch) => {
  dispatch(createUtilityBillRequesting());
  httpClient.post('/utility-bills?org=true', data).then((response) => {
    dispatch(createUtilityBillsSuccess());
    toast.success(window.i18n.t('batchAddSuccess'));
    if (onSuccess) {
      onSuccess(response);
    }
  }, (error) => dispatch(createUtilityBillError(error)));
};

export const removeTenant = (data, onSuccess) => async (dispatch) => {
  dispatch(removeTenantRequesting());
  httpClient.post('/properties/end-contract2?org=true', data).then((response) => {
    dispatch(removeTenantSuccess(response?.data?.property));
    if (onSuccess) {
      onSuccess();
    }
  }, (error) => dispatch(removeTenantError(error)));
};

export const getTenantHistory = (id) => async (dispatch) => {
  dispatch(getTenantHistoryRequesting());
  httpClient.get(`/properties/org/tenant-history/${id}`).then(
    (response) => {
      dispatch(getTenantHistorySuccess(response?.data?.history));
    },
    (error) => dispatch(getTenantHistoryError(error)),
  );
};

export default PropertiesSlice.reducer;
