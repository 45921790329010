
const FileIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='22.606'
    height='26.374'
    viewBox='0 0 22.606 26.374'
    {...props}
  >
    <path
      fill='#c5d7f2'
      d='M149.605 5.593a3.424 3.424 0 01.706 1.119 3.4 3.4 0 01.294 1.3v16.949a1.407 1.407 0 01-1.413 1.413h-19.78A1.407 1.407 0 01128 24.961V1.413a1.363 1.363 0 01.412-1 1.362 1.362 0 011-.412H142.6a3.4 3.4 0 011.3.294 3.424 3.424 0 011.113.705zM143.071 2v5.535h5.529a1.608 1.608 0 00-.324-.6l-4.607-4.607a1.608 1.608 0 00-.598-.328zm5.652 22.488V9.419H142.6a1.407 1.407 0 01-1.413-1.413V1.884h-11.3V24.49z'
      transform='translate(-128)'
    ></path>
  </svg>
);

export default FileIcon;