

const EmptySettingIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='95.875'
    height='75.781'
    viewBox='0 0 95.875 75.781'
    {...props}
  >
    <g data-name='Group 33725' transform='translate(22930.999 19524.022)'>
      <path
        fill='#f5f9fc'
        d='M5.2 99.659a8.153 8.153 0 011.617-1.446l-.229-2 3.14-1.1 1.068 1.7a8.141 8.141 0 012.166.12l1.25-1.575 3 1.443-.45 1.959a8.144 8.144 0 011.446 1.618l2-.229 1.1 3.14-1.7 1.068a8.157 8.157 0 01-.12 2.166l1.575 1.25-1.442 3-1.96-.45a8.154 8.154 0 01-1.617 1.446l.229 2-3.14 1.1-1.068-1.7a8.166 8.166 0 01-2.166-.12l-1.25 1.575-3-1.443.45-1.959a8.143 8.143 0 01-1.455-1.622l-2 .229-1.1-3.14 1.7-1.068a8.159 8.159 0 01.12-2.166l-1.575-1.25 1.442-3zm2.182 3.389a4.489 4.489 0 105.991-2.1 4.489 4.489 0 00-5.991 2.1'
        data-name='Path 59218'
        transform='translate(-22932.217 -19611.246)'
      ></path>
      <path
        fill='#f5f9fc'
        d='M363.2 245.963a8.04 8.04 0 011.594-1.425l-.226-1.97 3.1-1.085 1.053 1.68a8.022 8.022 0 012.135.118l1.232-1.552 2.956 1.422-.443 1.931a8.03 8.03 0 011.425 1.594l1.969-.226 1.085 3.1-1.68 1.053a8.038 8.038 0 01-.118 2.135l1.552 1.232-1.421 2.956-1.932-.443a8.04 8.04 0 01-1.594 1.425l.226 1.97-3.1 1.085-1.053-1.68a8.038 8.038 0 01-2.135-.118l-1.232 1.552-2.956-1.422.443-1.931a8.029 8.029 0 01-1.425-1.594l-1.97.226-1.085-3.1 1.68-1.053a8.048 8.048 0 01.118-2.135l-1.552-1.232 1.421-2.956zm2.151 3.34a4.424 4.424 0 105.9-2.07 4.424 4.424 0 00-5.9 2.07'
        data-name='Path 59219'
        transform='translate(-23214.197 -19726.518)'
      ></path>
      <path
        fill='#c5d7f2'
        d='M358.15 109.945a6.208 6.208 0 011.233-1.1l-.175-1.523 2.394-.839.814 1.3a6.212 6.212 0 011.651.091l.953-1.2 2.286 1.1-.343 1.494a6.213 6.213 0 011.1 1.233l1.523-.175.839 2.394-1.3.814a6.211 6.211 0 01-.091 1.651l1.2.953-1.1 2.286-1.494-.343a6.211 6.211 0 01-1.233 1.1l.175 1.523-2.394.839-.815-1.3a6.221 6.221 0 01-1.651-.092l-.953 1.2-2.286-1.1.343-1.494a6.206 6.206 0 01-1.1-1.233l-1.523.175-.839-2.394 1.3-.814a6.212 6.212 0 01.091-1.651l-1.2-.953 1.1-2.286zm1.663 2.583a3.422 3.422 0 104.567-1.6 3.422 3.422 0 00-4.567 1.6'
        data-name='Path 59220'
        transform='translate(-23210.863 -19620.197)'
      ></path>
      <path
        fill='#f5f9fc'
        d='M333.107 62.07a4.175 4.175 0 01.829-.741l-.118-1.024 1.609-.564.547.873a4.173 4.173 0 011.11.061l.641-.807 1.536.739-.23 1a4.177 4.177 0 01.741.829l1.024-.118.564 1.609-.873.547a4.19 4.19 0 01-.061 1.109l.807.641-.739 1.536-1-.23a4.174 4.174 0 01-.829.741l.118 1.024-1.609.564-.547-.873a4.191 4.191 0 01-1.11-.061l-.64.807-1.543-.732.23-1a4.185 4.185 0 01-.741-.829l-1.024.118-.564-1.609.873-.547a4.18 4.18 0 01.061-1.11l-.807-.64.739-1.536zm1.118 1.736a2.3 2.3 0 103.069-1.076 2.3 2.3 0 00-3.069 1.076'
        data-name='Path 59221'
        transform='translate(-23191.859 -19583.389)'
      ></path>
      <path
        fill='#c5d7f2'
        d='M69.78 324.482a4.175 4.175 0 01.829-.741l-.118-1.024 1.609-.564.547.873a4.17 4.17 0 011.11.061l.641-.807 1.536.739-.23 1a4.175 4.175 0 01.741.829l1.024-.117.564 1.609-.873.547A4.189 4.189 0 0177.1 328l.807.641-.739 1.536-1-.23a4.176 4.176 0 01-.829.741l.118 1.024-1.609.564-.547-.873a4.191 4.191 0 01-1.11-.061l-.64.807-1.536-.739.23-1a4.182 4.182 0 01-.741-.829l-1.024.118-.564-1.609.873-.547a4.182 4.182 0 01.061-1.11l-.807-.64.739-1.536zm1.118 1.736a2.3 2.3 0 103.069-1.076 2.3 2.3 0 00-3.069 1.076'
        data-name='Path 59222'
        transform='translate(-22984.48 -19790.049)'
      ></path>
      <path
        fill='#f5f9fc'
        d='M123.59 346.421a6.282 6.282 0 011.247-1.115l-.177-1.541 2.422-.849.824 1.315a6.291 6.291 0 011.671.092l.964-1.215 2.313 1.113-.347 1.511a6.283 6.283 0 011.115 1.248l1.541-.177.849 2.422-1.314.824a6.288 6.288 0 01-.092 1.671l1.214.965-1.112 2.315-1.511-.347a6.288 6.288 0 01-1.248 1.115l.177 1.541-2.422.849-.824-1.315a6.288 6.288 0 01-1.671-.092l-.964 1.215-2.313-1.113.347-1.511a6.29 6.29 0 01-1.115-1.248l-1.541.177-.849-2.422 1.315-.824a6.3 6.3 0 01.092-1.671l-1.214-.964 1.112-2.313zm1.683 2.614a3.462 3.462 0 104.621-1.62 3.463 3.463 0 00-4.621 1.62'
        data-name='Path 59223'
        transform='translate(-23026.113 -19806.396)'
      ></path>
      <path
        fill='#eff5ff'
        d='M13.28 182.6a.839.839 0 01-.15.048c-.1.025-.245.072-.436.111a10.987 10.987 0 01-1.639.24 11.724 11.724 0 01-9.761-4.04A10.99 10.99 0 01.3 177.63c-.108-.163-.178-.3-.231-.387A.82.82 0 010 177.1a.86.86 0 01.091.128c.059.085.134.216.248.375a12.394 12.394 0 001.013 1.3 11.922 11.922 0 009.7 4.014 12.4 12.4 0 001.635-.2c.192-.033.339-.072.44-.09a.843.843 0 01.155-.026'
        data-name='Path 59224'
        transform='translate(-22931 -19675.814)'
      ></path>
      <path
        fill='#eff5ff'
        d='M22.607 203.662a.9.9 0 01-.228.007 6.4 6.4 0 01-.621-.044 10.022 10.022 0 01-3.88-1.268 7.117 7.117 0 01-.526-.331.9.9 0 01-.18-.14c.01-.015.283.159.744.4a11.739 11.739 0 003.852 1.259c.516.075.84.1.839.114'
        data-name='Path 59225'
        transform='translate(-22944.523 -19695.332)'
      ></path>
      <path
        fill='#eff5ff'
        d='M90.685 67.489a26.8 26.8 0 016.542-2.843l1.348-6.476 10.941-.192 1.575 6.424a26.8 26.8 0 016.639 2.612l5.53-3.625 7.872 7.6-3.432 5.654a26.793 26.793 0 012.843 6.544l6.475 1.347.192 10.941-6.424 1.575a26.816 26.816 0 01-2.612 6.638l3.624 5.53-7.6 7.873-5.653-3.428a26.8 26.8 0 01-6.543 2.843l-1.348 6.476-10.941.192-1.575-6.424a26.82 26.82 0 01-6.638-2.612l-5.53 3.625-7.873-7.6 3.428-5.653a26.788 26.788 0 01-2.844-6.543l-6.475-1.348-.192-10.941 6.429-1.578a26.836 26.836 0 012.612-6.638l-3.624-5.53 7.6-7.873zM94 80.323a14.762 14.762 0 1020.873-.367A14.762 14.762 0 0094 80.323'
        data-name='Path 59226'
        transform='translate(-22987.717 -19582)'
      ></path>
    </g>
  </svg>
);

export default EmptySettingIcon;
