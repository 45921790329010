import { useNavigate, useParams } from 'react-router-dom';
import { useCallback, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Paper,
  Stack,
  Typography,
  IconButton,
  Box,
  Divider,
  CircularProgress,
  InputBase,
  Button,
  useTheme
} from '@mui/material';
import { isSameDay, format } from 'date-fns';
import { bg } from 'date-fns/locale';
import io from 'socket.io-client';
import InfiniteScroll from 'react-infinite-scroll-component';
import { DefaultLayout } from 'layouts';
import { MenuList, Dialog } from 'components';
import { formatDate } from 'utils';
import { ArrowBackIcon, ActionsIcon, CancelIcon, SendIcon } from 'icons';
import { useTranslation } from 'react-i18next';

const SOCKET_LISTENER_MESSAGE = 'socket-message';
const SOCKET_EMITTER_MESSAGE = 'client-message';

const loadMessages = ({ page, roomId, socket }) => {
  socket?.emit(SOCKET_EMITTER_MESSAGE, {
    action: 'loadMoreMessages',
    payload: {
      room: roomId,
      page,
    },
  });
};

const seeMessage = ({ roomId, socket, lastMessageId }) => {
  socket?.emit(SOCKET_EMITTER_MESSAGE, {
    action: 'seeMessages',
    payload: {
      room: roomId,
      lastMessage: lastMessageId,
    },
  });
};


const PropertiesCommunicatorRoom = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const [socket, setSocket] = useState(null);
  const [currentRoom, setCurrentRoom] = useState(undefined);
  const [messages, setMessages] = useState([]);
  const [messageText, setMessageText] = useState('');
  const [hasNextPage, setHasNextPage] = useState(true);
  let [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
  const { propertyId, roomId } = useParams();
  const {
    authenticationState: { token },
    organizationState: { myUserData }
  } = useSelector(({ authenticationState, organizationState }) => ({ authenticationState, organizationState }));

  const currentUserId = myUserData?.organization;

  const socketInit = useCallback(() => {
    const newSocket = io(process.env.REACT_APP_API_URL, {
      query: `token=${token}`,
      transports: ['websocket'],
    });
    if (newSocket && propertyId) {
      newSocket.on(SOCKET_LISTENER_MESSAGE, ({ action, payload }) => {
        if (action === 'socket/INIT') {
          setCurrentRoom(payload?.rooms?.find((item) => item?.id === roomId));
          setIsLoading(false);
        }
        if (action === 'socket/LOAD_MORE_MESSAGES') {
          setMessages((currentMessages) => ([...currentMessages, ...payload]));
          if (!payload?.length) {
            setHasNextPage(false);
          }
        }
        if (action === 'socket/NEW_MESSAGE') {
          setMessages((currentMessage) => [payload, ...currentMessage]);
        }
        if (action === 'socket/CHANGE_ROOM_STATUS') {
          setCurrentRoom(payload);
          setConfirmModalIsOpen(false);
        }
      });

      setSocket(() => newSocket);
    }
  }, [token, propertyId]);

  useEffect(() => {
    if (socket) {
      loadMessages({ page: currentPage, roomId, socket });
    }
  }, [socket, roomId, currentPage]);

  useEffect(() => {
    const lastMessage = messages[messages?.length - 1];

    if (!lastMessage?.seenBy?.includes(currentUserId)) {
      seeMessage({
        roomId,
        lastMessageId: lastMessage?.id,
        socket
      });
    }
  }, [socket, roomId, messages, currentUserId]);

  useEffect(() => {
    if (!socket) {
      setIsLoading(true);
      socketInit();
    };

    //cleanup
    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  }, [socket, socketInit]);


  const onSendMessage = () => {
    if (messageText) {
      socket.emit(SOCKET_EMITTER_MESSAGE, {
        action: 'sendMessage',
        payload: {
          text: messageText,
          type: 'text',
          room: roomId,
        },
      });
      setMessageText('');
    }
  };

  const onCloseRoom = () => {
    socket.emit(SOCKET_EMITTER_MESSAGE, {
      action: 'changeRoomStatus',
      payload: {
        status: 'closed',
        room: roomId,
        isOrg: true
      },
    });
  };

  return (
    <DefaultLayout>
      <Paper elevation={4}>
        <Box p={1} backgroundColor={theme.palette.info.main} borderRadius={2}>
          <Stack direction='row' alignItems='center' justifyContent='space-between'>
            <Stack direction='row' alignItems='center' spacing={1}>
              <IconButton onClick={() => navigate(`/properties/${propertyId}?activeTab=communicator`)}>
                <ArrowBackIcon style={{ width: 16, height: 16 }} />
              </IconButton>
              <Typography variant='h5'>
                {currentRoom?.title}
              </Typography>
            </Stack>
            {(!isLoading && currentRoom?.status === 'open') && (
              <MenuList
                trigger={({ handleOpen }) => (
                  <IconButton onClick={handleOpen}>
                    <ActionsIcon
                      style={{ width: 16, height: 16 }}
                    />
                  </IconButton>
                )}
                items={[
                  //TODO: The backend doesn't support this functionality.
                  // {
                  //   label: t('search'),
                  //   description: t('searchKeyword'),
                  //   icon: SearchIcon,
                  // },
                  {
                    label: t('closeCase'),
                    description: t('closeCaseText'),
                    icon: CancelIcon,
                    onClick: () => setConfirmModalIsOpen(true),
                  }
                ]}
              />
            )}
          </Stack>
        </Box>
        <InfiniteScroll
          inverse
          style={{
            display: 'flex',
            flexDirection: 'column-reverse',
            padding: '20px 20px 40px 20px',
            overflowX: 'hidden',
          }}
          height={430}
          dataLength={messages?.length}
          next={() => {
            setCurrentPage(currentPage = currentPage + 1);
          }}
          hasMore={hasNextPage}
          loader={(
            <Box textAlign='center'>
              <CircularProgress size={60} />
            </Box>
          )}
        >
          {messages?.map(({ id, text, from, createdAt, type }, index) => {
            const isOwnMessage = from?.id === currentUserId;
            const isMediaMessage = type === 'media';
            const nextMessage = messages[index + 1];

            const isShownDate = (!isSameDay(new Date(nextMessage?.createdAt), new Date(createdAt)));

            return (
              <Box mb={2} key={id}>
                {isShownDate && (
                  <Box mt={2}>
                    <Divider>{format(new Date(createdAt), ' dd LLLL yyyy', { locale: bg })}</Divider>
                  </Box>
                )}
                <Stack direction='row' justifyContent={isOwnMessage ? 'flex-end' : 'flex-start'}>
                  <Typography mb={1} variant='subtitle2'>{from?.fullName}</Typography>
                </Stack>
                <Stack direction='row' alignItems='center' justifyContent={isOwnMessage ? 'flex-end' : 'flex-start'} spacing={1}>
                  <Box p={1} sx={{
                    backgroundColor: '#EFF5FF',
                    borderRadius: 2,
                    textTransform: 'none',
                    order: isOwnMessage ? 2 : 1
                  }}
                  >
                    {isMediaMessage ? (
                      <Box width={300} p={1}>
                        <img style={{ display: 'block', width: '100%' }} src={text} />
                      </Box>
                    ) : (
                      <Typography variant='caption' textTransform='none'>
                        {text}
                      </Typography>
                    )}
                  </Box>
                  <Typography sx={{ order: isOwnMessage ? 1 : 2, pr: isOwnMessage ? 1 : 0 }} variant='caption'>{createdAt ? formatDate(createdAt, 'HH:mm') : ''}</Typography>
                </Stack>
              </Box>
            );
          })}
        </InfiniteScroll>
        {!isLoading && (
          <>
            {currentRoom?.status === 'open' ? (
              <Box
                p={1}
                mt={1}
                sx={{ backgroundColor: theme.palette.info.main, borderRadius: 2 }}
              >
                <InputBase
                  sx={{ backgroundColor: '#fff' }}
                  fullWidth
                  multiline
                  endAdornment={(
                    <div>
                      <IconButton
                        disabled={!messageText}
                        onClick={onSendMessage}
                      >
                        <SendIcon style={{ width: 20, height: 20 }} />
                      </IconButton>
                    </div>
                  )}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter' && !event.shiftKey) {
                      event.preventDefault();
                      onSendMessage();
                    }
                  }}
                  value={messageText}
                  onChange={({ currentTarget: { value } }) => setMessageText(value)}
                />
              </Box>
            ) : (
              <Box p={1} textAlign='center' backgroundColor='#EFF5FF' borderRadius={2}>
                <Stack direction='row' alignItems='center' spacing={6}>
                  <Stack>
                    <Typography variant='caption'>{t('solvedAt')}</Typography>
                    <Typography variant='subtitle2'>
                      {currentRoom?.updatedAt ? formatDate(currentRoom?.updatedAt, 'dd.MM.yyyy | HH:mm') : ''}
                    </Typography>
                  </Stack>
                  <Stack>
                    <Typography variant='caption'>{t('solvedFrom')}</Typography>
                    <Typography variant='subtitle2'>{currentRoom?.updatedBy?.fullName}</Typography>
                  </Stack>
                </Stack>
              </Box>
            )}
          </>
        )}
      </Paper>
      <Dialog
        open={confirmModalIsOpen}
        onClose={() => setConfirmModalIsOpen(false)}
        title={t('closeThisCase')}
      >
        <Box mt={4}>
          <Stack direction='row' alignItems='center' spacing={2} justifyContent='center'>
            <Button
              variant='contained'
              color='warning'
              size='large'
              fullWidth
              onClick={() => setConfirmModalIsOpen(false)}
            >
              {t('back')}
            </Button>
            <Button
              variant='contained'
              color='primary'
              type='submit'
              size='large'
              fullWidth
              onClick={() => onCloseRoom()}
            >
              {t('confirm')}
            </Button>
          </Stack>
        </Box>
      </Dialog>
    </DefaultLayout >
  );
};

export default PropertiesCommunicatorRoom;