import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { styled, FormControl, FormLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';

const StyledInput = styled(PhoneInput)`
  & .flag-dropdown {
    background: transparent;
  }
`;

const PhoneNumberInput = ({ value, onChange }) => {
  const { t } = useTranslation();
  return (
    <FormControl sx={{ width: '100%' }}>
      <FormLabel sx={{ mb: 2, color: '#000' }}>
        {t('phone')}
      </FormLabel>
      <StyledInput
        country={'bg'}
        value={value}
        placeholder=''
        onChange={onChange}
        inputStyle={{ width: '100%', height: '45px' }}
        dropdownStyle={{ position: 'absolute', bottom: 0, left: 0 }}
      />
    </FormControl>
  );
};

export default PhoneNumberInput;