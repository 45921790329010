/* eslint-disable indent */
import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import httpClient from '../api/httpClient';

const initialState = {
  allCases: {
    docs: []
  },
  casesList: [],
  singleCase: {},
  getCasesListIsLoading: true,
  createCaseIsLoading: false,
  getSingleCaseIsLoading: false,
  updateCasesFileIsLoading: false,
  deleteCasesFileIsLoading: false,
  updateCaseIsLoading: false,
  updateCaseStatusIsLoading: false,
  deleteCaseIsLoading: false,
  addNoteIsLoading: false,
  getAllCasesIsLoading: false
};

export const CasesSlice = createSlice({
  name: 'Cases',
  initialState,
  reducers: {
    getCasesListRequesting: (state) => {
      state.getCasesListIsLoading = true;
    },
    getCasesListSuccess: (state, { payload }) => {
      state.getCasesListIsLoading = false;
      state.casesList = payload;
    },
    getCasesListError: (state) => {
      state.getCasesListIsLoading = false;
    },
    createCaseRequesting: (state) => {
      state.createCaseIsLoading = true;
    },
    createCaseSuccess: (state) => {
      state.createCaseIsLoading = false;
    },
    createCaseError: (state) => {
      state.createCaseIsLoading = false;
    },
    getSingleCaseRequesting: (state) => {
      state.getSingleCaseIsLoading = true;
    },
    getSingleCaseSuccess: (state, { payload }) => {
      state.getSingleCaseIsLoading = false;
      state.singleCase = payload;
    },
    getSingleCaseError: (state) => {
      state.getSingleCaseIsLoading = false;
    },
    updateSingleCaseFiles: (state, { payload }) => {
      state.singleCase = {
        ...state.singleCase,
        files: payload,
      };
    },
    updateCasesFileRequesting: (state) => {
      state.updateCasesFileIsLoading = true;
    },
    updateCasesFileSuccess: (state, { payload }) => {
      state.updateCasesFileIsLoading = false;
    },
    updateCasesFileError: (state) => {
      state.updateCasesFileIsLoading = false;
    },
    deleteCasesFileRequesting: (state) => {
      state.deleteCasesFileIsLoading = true;
    },
    deleteCasesFileSuccess: (state, { payload }) => {
      state.deleteCasesFileIsLoading = false;
    },
    deleteCasesFileError: (state) => {
      state.deleteCasesFileIsLoading = false;
    },
    updateCaseRequesting: (state) => {
      state.updateCaseIsLoading = true;
    },
    updateCaseSuccess: (state, { payload }) => {
      state.updateCaseIsLoading = false;
      if (payload?.data?.data) {
        state.singleCase = payload?.data?.data;
      }
    },
    updateCaseError: (state) => {
      state.updateCaseIsLoading = false;
    },
    updateCaseStatusRequesting: (state) => {
      state.updateCaseStatusIsLoading = true;
    },
    updateCaseStatusSuccess: (state, { payload }) => {
      state.updateCaseStatusIsLoading = false;
      state.singleCase = payload?.data?.data;
    },
    updateCaseStatusError: (state) => {
      state.updateCaseStatusIsLoading = false;
    },
    deleteCaseRequesting: (state) => {
      state.updateCaseStatusIsLoading = true;
    },
    deleteCaseSuccess: (state, { payload }) => {
      state.updateCaseStatusIsLoading = false;
    },
    deleteCaseError: (state) => {
      state.updateCaseStatusIsLoading = false;
    },
    addNoteRequesting: (state) => {
      state.addNoteIsLoading = true;
    },
    addNoteSuccess: (state, { payload }) => {
      state.addNoteIsLoading = false;
      state.singleCase = {
        ...state.singleCase,
        notes: [
          ...state.singleCase?.notes,
          payload,
        ],
      };
    },
    addNoteError: (state) => {
      state.addNoteIsLoading = false;
    },
    getAllCasesRequesting: (state) => {
      state.getAllCasesIsLoading = true;
    },
    getAllCasesSuccess: (state, { payload }) => {
      state.getAllCasesIsLoading = false;
      if (payload?.loadMore) {
        state.allCases = {
          ...payload,
          docs: [
            ...state.allCases?.docs,
            ...payload?.docs,
          ]
        };
      } else {
        state.allCases = payload;
      }
    },
    getAllCasesError: (state) => {
      state.getAllCasesIsLoading = false;
    },
  }
});

export const {
  getCasesListRequesting,
  getCasesListSuccess,
  getCasesListError,
  createCaseRequesting,
  createCaseSuccess,
  createCaseError,
  getSingleCaseRequesting,
  getSingleCaseSuccess,
  getSingleCaseError,
  updateSingleCaseFiles,
  updateCasesFileRequesting,
  updateCasesFileSuccess,
  updateCasesFileError,
  deleteCasesFileRequesting,
  deleteCasesFileSuccess,
  deleteCasesFileError,
  updateCaseRequesting,
  updateCaseSuccess,
  updateCaseError,
  updateCaseStatusRequesting,
  updateCaseStatusSuccess,
  updateCaseStatusError,
  deleteCaseRequesting,
  deleteCaseSuccess,
  deleteCaseError,
  addNoteRequesting,
  addNoteSuccess,
  addNoteError,
  getAllCasesRequesting,
  getAllCasesSuccess,
  getAllCasesError
} = CasesSlice.actions;

export const getCasesList = (propertyId) => async (dispatch) => {
  dispatch(getCasesListRequesting());
  httpClient.get(`/cases?propertyId=${propertyId}`).then(
    (response) => {
      dispatch(getCasesListSuccess(response?.data?.data));
    },
    (error) => dispatch(getCasesListError(error)),
  );
};

export const createCase = (data, propertyId, navigate) => async (dispatch) => {
  dispatch(createCaseRequesting());
  httpClient.post('/cases', data).then(
    (response) => {
      dispatch(createCaseSuccess(response?.data));
      navigate(`/properties/${propertyId}`);
      toast.success(window.i18n.t('caseCreateSuccess'));
    },
    (error) => dispatch(createCaseError(error)),
  );
};

export const getSingleCase = (id) => async (dispatch) => {
  dispatch(getSingleCaseRequesting());
  httpClient.get(`/cases/${id}`).then(
    (response) => {
      dispatch(getSingleCaseSuccess(response?.data?.data));
    },
    (error) => dispatch(getSingleCaseError(error)),
  );
};

export const updateCasesFile = (data, onSuccess) => async (dispatch) => {
  dispatch(updateCasesFileRequesting());
  httpClient.post('/cases/files', data).then(
    (response) => {
      dispatch(updateCasesFileSuccess(response));
      if (onSuccess) {
        onSuccess(response);
      }
    },
    (error) => dispatch(updateCasesFileError(error)),
  );
};

export const deleteCasesFile = (id, onSuccess) => async (dispatch) => {
  dispatch(deleteCasesFileRequesting());
  httpClient.delete(`/cases/files/${id}`).then(
    (response) => {
      dispatch(deleteCasesFileSuccess(response));
      if (onSuccess) {
        onSuccess(response);
      }
    },
    (error) => dispatch(deleteCasesFileError(error)),
  );
};

export const updateCase = (data, id, propertyId, navigate) => async (dispatch) => {
  dispatch(updateCaseRequesting());
  httpClient.put(`/cases/${id}`, data).then(
    (response) => {
      dispatch(updateCaseSuccess(response));
      navigate(`/properties/${propertyId}`);
      toast.success(window.i18n.t('caseEditSuccess'));
    },
    (error) => dispatch(updateCaseError(error)),
  );
};

export const updateCaseStatus = (id) => async (dispatch) => {
  dispatch(updateCaseStatusRequesting());
  httpClient.post(`/cases/close/${id}`).then(
    (response) => {
      dispatch(updateCaseStatusSuccess(response));
    },
    (error) => dispatch(updateCaseStatusError(error)),
  );
};

export const deleteCase = (id, propertyId, navigate) => async (dispatch) => {
  dispatch(deleteCaseRequesting());
  httpClient.delete(`/cases/${id}`).then(
    (response) => {
      dispatch(deleteCaseSuccess(response));
      toast.success(window.i18n.t('caseDeleteSuccess'));
      navigate(`/properties/${propertyId}`);
    },
    (error) => dispatch(deleteCaseError(error)),
  );
};

export const addNote = (data, onSuccess) => async (dispatch) => {
  dispatch(addNoteRequesting());
  httpClient.post('/cases/notes', data).then(
    (response) => {
      dispatch(addNoteSuccess(response?.data?.data));
      if (onSuccess) {
        onSuccess();
      }
    },
    (error) => dispatch(addNoteError(error)),
  );
};

export const getCases = ({ page, limit = 5, loadMore }) => async (dispatch) => {
  dispatch(getAllCasesRequesting());
  httpClient.get('/dashboard/cases',
    {
      params:
      {
        page,
        limit,
        loadMore
      }
    }).then(
      (response) => {
        dispatch(getAllCasesSuccess({
          ...response?.data?.cases,
          loadMore
        }));
      },
      (error) => dispatch(getAllCasesError(error)),
    );
};

export default CasesSlice.reducer;
