import { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Paper,
  Typography,
  Box,
  Stack,
  IconButton,
  Button,
  Tabs,
  Tab,
} from '@mui/material';
import { DefaultLayout } from 'layouts';
import { ArrowBackIcon } from 'icons';
import { handleBeforeReload } from 'utils';

const TenantFormLayout = ({ children, canContinue }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { propertyId } = useParams();
  const location = useLocation();
  const currentPath = location?.pathname;

  const TABS = [
    {
      route: `/properties/${propertyId}/add-tenant/rent-contract`,
      label: t('rentContract'),
    },
    {
      route: `/properties/${propertyId}/add-tenant/data-tenant`,
      label: t('dataTenant'),
    },
  ];

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeReload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeReload);
    };
  }, []);

  // useEffect(() => {
  //   if (!isCreate) {
  //     if (currentPath === `/properties/${propertyId}/tenant`) {
  //       navigate('/properties');
  //     }
  //     if (currentPath === `/properties/${propertyId}/payment-bills`) {
  //       navigate('/properties');
  //     }
  //   }
  // }, [
  //   currentPath,
  // ]);

  return (
    <DefaultLayout>
      <Box mb={3}>
        <Paper elevation={4}>
          <Stack direction='row' justifyContent='space-between' alignItems='center'>
            <Stack direction='row' alignItems='center' spacing={1}>
              <IconButton onClick={() => navigate(`/properties/${propertyId}`)}>
                <ArrowBackIcon style={{ width: 16, height: 16 }} />
              </IconButton>
              <Typography variant='h5'>
                {t('addingTenant')}
              </Typography>
            </Stack>
            <Button
              variant='contained'
              color='primary'
              disabled={currentPath === `/properties/${propertyId}/add-tenant/rent-contract` || !canContinue}
              onClick={() => navigate(`/properties/${propertyId}`)}
            >
              {t('save')}
            </Button>
          </Stack>
        </Paper>
      </Box>
      <Box mt={3}>
        <Tabs
          value={currentPath}
          variant='fullWidth'
        >
          {TABS.map(({ route, label }) => (
            <Tab
              key={`${route}-tab`}
              value={route}
              disabled={currentPath !== route}
              {...{ label }}
            />
          ))}
        </Tabs>
      </Box>
      <Box mt={2}>
        {children}
      </Box>
    </DefaultLayout>
  );
};

export default TenantFormLayout;