import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Paper,
  Typography,
  Button,
  Box,
  Stack,
  IconButton,
  Tabs,
  Tab,
  Switch,
  Card,
  Grid,
  TextField,
  InputAdornment,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Radio
} from '@mui/material';
import { DefaultLayout } from 'layouts';
import { Dialog } from 'components';
import { ArrowBackIcon } from 'icons';
import { getOrganization, updateOrganizationSettings } from 'stores/OrganizationSlice';
import { updatePrimaryBank } from 'stores/FinancesSlice';

const GlobalSettings = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [automaticInvoiceDialogIsOpen, setAutomaticInvoiceDialogIsOpen] = useState(false);
  const [editPrimaryBank, setEditPrimaryBank] = useState(false);
  const [selectedBankId, setSelectedBankId] = useState('');
  const dispatch = useDispatch();
  const {
    organizationState: { myUserData, organization }
  } = useSelector(({ organizationState }) => ({ organizationState }));

  const currentUserId = myUserData?.organization;

  const validations = Yup.object().shape({
    sendInvoicesAutomatically: Yup.boolean(),
    rentOverdueReminder: Yup.boolean(),
    rentOverdueReminderDays: Yup.number().required(t('rentOverdueReminderDaysRequired')).typeError(t('rentOverdueReminderDaysRequired'))
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
    watch
  } = useForm({
    resolver: yupResolver(validations),
  });

  useEffect(() => {
    if (currentUserId) {
      dispatch(getOrganization(currentUserId, (organization) => reset({
        sendInvoicesAutomatically: organization?.globalOrgSettings?.['automatic-invoice-sending'],
        rentOverdueReminder: organization?.globalOrgSettings?.['automatic-rent-overdue-reminder'],
        rentOverdueReminderDays: organization?.globalOrgSettings?.['send-overdue-reminder-after-x-days'],
      })));
    }
  }, [currentUserId]);

  const sendInvoicesAutomaticallyValue = watch('sendInvoicesAutomatically');
  const rentOverdueReminderValue = watch('rentOverdueReminder');

  const Invoices = () => {
    const { t } = useTranslation();

    return (
      <>
        <Box mb={3}>
          <Paper elevation={4}>
            <Box p={1}>
              <Stack>
                <Stack direction='row' spacing={3} alignItems='center'>
                  <Typography variant='caption'>
                    {`${t('automateSending')}`}
                  </Typography>
                  <Switch
                    checked={sendInvoicesAutomaticallyValue}
                    onChange={() => {
                      if (sendInvoicesAutomaticallyValue) {
                        setAutomaticInvoiceDialogIsOpen(true);
                      } else {
                        setValue('sendInvoicesAutomatically', !sendInvoicesAutomaticallyValue);
                      }
                    }}
                  />
                </Stack>
                <Typography variant='caption' textTransform='none'>
                  {t('automateSendingMessage')}
                </Typography>
              </Stack>
            </Box>
          </Paper>
        </Box>
        <Box mb={3}>
          <Paper elevation={4}>
            <Box p={1} mt={2}>
              <Stack direction='row' justifyContent='space-between'>
                <Stack>
                  <Typography variant='caption'>
                    {t('bankAcc')}
                  </Typography>
                  <Typography mt={1} variant='caption' textTransform='none'>
                    {t('bankAccForInvoice')}
                  </Typography>
                </Stack>
                {editPrimaryBank && (
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={() => {
                      dispatch(updatePrimaryBank(selectedBankId, { isPrimary: true }, () => {
                        setEditPrimaryBank(false);
                        toast.success(t('editBankAccAsPrimary'));
                      }));
                    }}
                  >
                    {t('select')}
                  </Button>
                )}
              </Stack>
            </Box>
            {organization?.bankAccounts?.map(({ bank, iban, holder, _id }, index) => (
              <Box key={`${_id}-item-${index}`} mt={3}>
                <Grid container alignItems='center'>
                  <Grid item md={7}>
                    <Card variant='outlined'>
                      <Grid container>
                        <Grid item md={4}>
                          <Typography variant='caption'>
                            {t('bank')}
                          </Typography>
                          <Typography variant='subtitle2'>
                            {bank}
                          </Typography>
                        </Grid>
                        <Grid item md={4}>
                          <Typography variant='caption'>
                            {t('iban')}
                          </Typography>
                          <Typography variant='subtitle2'>
                            {iban}
                          </Typography>
                        </Grid>
                        <Grid item md={4}>
                          <Typography variant='caption'>
                            {t('holder')}
                          </Typography>
                          <Typography variant='subtitle2'>
                            {holder}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                  <Grid item md={3}>
                    {editPrimaryBank ? (
                      <Radio
                        checked={_id === selectedBankId}
                        onClick={() => setSelectedBankId(_id)} />
                    ) : (
                      <Button
                        variant='contained'
                        color='info'
                        onClick={() => setEditPrimaryBank(true)}
                      >
                        {t('edit')}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Box>
            ))}
          </Paper>
        </Box>
      </>
    );
  };

  const AutomaticSettings = () => {
    const { t } = useTranslation();
    return (
      <Box mb={3}>
        <Paper elevation={4}>
          <Box p={1}>
            <Stack>
              <Stack direction='row' spacing={3} alignItems='center'>
                <Typography variant='caption'>
                  {t('automaticPaymentDueRent')}
                </Typography>
                <Switch
                  checked={rentOverdueReminderValue}
                  onChange={() => setValue('rentOverdueReminder', !rentOverdueReminderValue)}
                />
              </Stack>
              <Typography variant='caption' textTransform='none'>
                {t('automaticSystemMail')}
              </Typography>
            </Stack>
            <Grid container mt={3}>
              <Grid item md={3}>
                <TextField
                  label={`${t('send')}*`}
                  size='small'
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    endAdornment: <InputAdornment position='start'>
                      {t('daysAfterDueDаte')}
                    </InputAdornment>,
                  }}
                  {...register('rentOverdueReminderDays')}
                  error={Boolean(errors?.rentOverdueReminderDays?.message)}
                  helperText={errors?.rentOverdueReminderDays?.message}
                />
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Box>
    );
  };

  const TABS = [
    {
      id: 'invoices',
      label: t('invoices'),
      component: Invoices
    },
    {
      id: 'automaticSettings',
      label: t('automaticSettings'),
      component: AutomaticSettings
    },
  ];

  const [activeTabId, setActiveTabId] = useState(TABS[0].id);

  return (
    <form onSubmit={handleSubmit((data) => {
      dispatch(updateOrganizationSettings(data, () => toast.success(t('globalSettingsEditSuccess'))));
    })}>
      <DefaultLayout>
        <Paper elevation={4}>
          <Stack direction='row' justifyContent='space-between' alignItems='center'>
            <Stack direction='row' alignItems='center' spacing={1}>
              <IconButton onClick={() => navigate('/')}>
                <ArrowBackIcon style={{ width: 16, height: 16 }} />
              </IconButton>
              <Typography variant='h5'>{t('globalSettings')}</Typography>
            </Stack>
            <Button
              variant='contained'
              color='primary'
              type='submit'
            >
              {t('save')}
            </Button>
          </Stack>
        </Paper>
        <Box mt={3}>
          <Tabs
            value={activeTabId}
            onChange={(_, tabValue) => setActiveTabId(tabValue)}
            variant='fullWidth'
          >
            {TABS.map(({ id, label }) => (
              <Tab
                key={`${id}-tab`}
                value={id}
                {...{ label }}
              />
            ))}
          </Tabs>
        </Box>
        <Box mt={2}>
          {TABS.map(({ id, component: Component }) => {
            const isActive = id === activeTabId;
            return (
              <div
                key={`${id}-tab-component`}
                style={{ display: isActive ? 'block' : 'none' }}>
                <Component />
              </div>
            );
          })}
        </Box>
      </DefaultLayout>
      <Dialog
        open={automaticInvoiceDialogIsOpen}
        onClose={() => setAutomaticInvoiceDialogIsOpen(false)}
        title={t('turnOffAutomaticMessage')}
      >
        <Typography variant='h6' mb={3}>
          {t('chooseToTurnOfAutomaticMessage')}
        </Typography>
        <Stack direction='row' alignItems='center' justifyContent='space-between'>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox defaultChecked color='secondary' />}
              label={t('dontShowThisMessage')} />
          </FormGroup>
        </Stack>
        <Button
          variant='contained'
          color='error'
          fullWidth
          onClick={() => {
            setAutomaticInvoiceDialogIsOpen(false);
            setValue('sendInvoicesAutomatically', !sendInvoicesAutomaticallyValue);
          }}
        >
          {t('turnOff')}
        </Button>
        <Box mt={3}>
          <Button
            variant='contained'
            color='warning'
            fullWidth
            onClick={() => setAutomaticInvoiceDialogIsOpen(false)}
          >
            {t('decline')}
          </Button>
        </Box>
      </Dialog>
    </form>
  );
};

export default GlobalSettings;