import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  IconButton,
  Stack,
  Typography,
  Box,
  Paper,
  Grid,
  Button,
  TextField,
  Switch
} from '@mui/material';
import * as Yup from 'yup';
import { useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ArrowBackIcon, CrossIcon } from 'icons';
import { DefaultLayout } from 'layouts';
import { Dialog } from 'components';
import {
  getOrganization,
  deleteOrganizationBank,
  updateBank
} from 'stores/OrganizationSlice';
import { useTranslation } from 'react-i18next';

const arrayFieldName = 'banks';

const EditOrganizationBank = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { organizationId } = useParams();
  const [confirmDeleteModalIsOpen, setConfirmDeleteModalIsOpen] = useState();
  const [bankId, setBankId] = useState('');

  const { organization } = useSelector(({ organizationState }) => organizationState);

  const validations = Yup.object().shape({
    banks: Yup.array().of(
      Yup.object().shape({
        bank: Yup.string().required(t('bankRequired')),
        holder: Yup.string().required(t('holderRequired')),
        primary: Yup.boolean().label('Primary'),
        nickname: Yup.string().label('Nickname')
      })
    )
  });

  useEffect(() => {
    if (organizationId) {
      dispatch(getOrganization(organizationId));
    }
  }, [organizationId]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(validations),
    defaultValues: {
      [arrayFieldName]: []
    }
  });

  const { fields } = useFieldArray({
    control,
    name: arrayFieldName,
  });

  useEffect(() => {
    if (organization?.bankAccounts?.length) {
      reset({
        [arrayFieldName]: organization?.bankAccounts?.map(({ id, holder, bank, primary, ng_requisitionId, nickname }) => (
          {
            holder,
            id,
            bank,
            primary,
            ng_requisitionId,
            nickname
          }
        )),
      });
    }
  }, [organization]);

  return (
    <DefaultLayout>
      <form onSubmit={handleSubmit((data) => {
        dispatch(updateBank(data?.[arrayFieldName], () => navigate('/personal-settings')));
      })}>
        <Paper elevation={4}>
          <Stack direction='row' alignItems='center' justifyContent='space-between'>
            <Stack direction='row' alignItems='center' spacing={1}>
              <IconButton onClick={() => navigate('/personal-settings')}>
                <ArrowBackIcon style={{ width: 16, height: 16 }} />
              </IconButton>
              <Typography variant='h5'>{t('editBankAccounts')}</Typography>
            </Stack>
            <Button
              variant='contained'
              color='primary'
              type='submit'
            >
              {t('save')}
            </Button>
          </Stack>
        </Paper>
        {fields?.map(({ iban, ng_requisitionId, bank, holder, nickname }, index) => {
          const fieldPrefix = `${arrayFieldName}.${index}`;
          const fieldError = errors?.[arrayFieldName]?.[index];
          const primaryFieldName = `${fieldPrefix}.primary`;
          const primaryValue = watch(primaryFieldName);
          return (
            <Box key={`${iban}-${ng_requisitionId}-${index}`} mt={3}>
              <Paper elevation={4}>
                <Stack direction='row' alignItems='center' justifyContent='space-between'>
                  <Typography variant='subtitle1'>
                    {`${t('bankAcc')} ${index + 1}`}
                  </Typography>
                  <IconButton
                    onClick={() => {
                      setBankId(ng_requisitionId);
                      setConfirmDeleteModalIsOpen(true);
                    }}
                  >
                    <CrossIcon
                      fill='#d31163'
                      style={{ width: 16, height: 16 }}
                    />
                  </IconButton>
                </Stack>
                <Grid container mt={3}>
                  <Grid item md={3}>
                    <TextField
                      label={`${t('bank')}*`}
                      size='small'
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      {...register(`${fieldPrefix}.bank`)}
                      error={Boolean(fieldError?.bank?.message)}
                      helperText={fieldError?.bank?.message}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <TextField
                      label={`${t('holder')}*`}
                      size='small'
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      {...register(`${fieldPrefix}.holder`)}
                      error={Boolean(fieldError?.holder?.message)}
                      helperText={fieldError?.holder?.message}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <TextField
                      label={`${t('nickname')}*`}
                      size='small'
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      {...register(`${fieldPrefix}.nickname`)}
                      error={Boolean(fieldError?.nickname?.message)}
                      helperText={fieldError?.nickname?.message}
                    />
                  </Grid>
                </Grid>
                <Box p={1} mt={3} sx={{ backgroundColor: '#F5F9FC' }}>
                  <Stack direction='row' alignItems='center' spacing={1}>
                    <Typography variant='caption'>
                      {t('addToFinance')}
                    </Typography>
                    <Switch
                      checked={primaryValue}
                      onChange={() => setValue(primaryFieldName, !primaryValue)}
                      label={t('yes')}
                    />
                  </Stack>
                  <Typography variant='caption' textTransform='none'>
                    {t('markToAppearInFinance')}
                  </Typography>
                </Box>
              </Paper>
            </Box>
          );
        })}
      </form>
      <Dialog
        open={confirmDeleteModalIsOpen}
        onClose={() => setConfirmDeleteModalIsOpen(false)}
        title={t('deleteBankAccount')}
      >
        <Typography variant='h6' mb={3}>{t('deleteBankConfirmation')}</Typography>
        <Button
          variant='contained'
          color='error'
          fullWidth
          onClick={() => {
            dispatch(deleteOrganizationBank(bankId, () => {
              setConfirmDeleteModalIsOpen(false);
              navigate('/personal-settings');
            }));
          }}
        >
          {t('delete')}
        </Button>
        <Box mt={3}>
          <Button
            variant='contained'
            color='warning'
            fullWidth
            onClick={() => setConfirmDeleteModalIsOpen(false)}
          >
            {t('decline')}
          </Button>
        </Box>
      </Dialog>
    </DefaultLayout >
  );
};

export default EditOrganizationBank;