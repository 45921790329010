import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import {
  Paper,
  Typography,
  Box,
  Stack,
  Grid,
  IconButton,
  TextField,
  Button,
  InputAdornment,
  FormHelperText,
  FormControl,
  Radio,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Fade,
  FormGroup,
  Checkbox
} from '@mui/material';
import NumberFormat from 'react-number-format';
import { format, isValid } from 'date-fns';
import { toast } from 'react-toastify';
import { ArrowBackIcon } from 'icons';
import { Dialog } from 'components';
import { DefaultLayout } from 'layouts';
import { getSingleProperty, editProperty } from 'stores/PropertiesSlice';
import { useTranslation } from 'react-i18next';

const EditRent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { propertyId } = useParams();
  const [hasEndDate, setHasEndDate] = useState(false);
  const [changeContractDialogIsOpen, setChangeContractDialogIsOpen] = useState(false);
  const [submitData, setSubmitData] = useState(undefined);
  const [hasChangeConfirm, setHasChangeConfirm] = useState(true);

  const { singleProperty, getSinglePropertyIsLoading } = useSelector(({ propertiesState }) => propertiesState);

  const validations = Yup.object().shape({
    rent: Yup.string().required(t('rentRequired')),
    payBefore: Yup.string().required(t('payBeforeRequired')),
    payForMonth: Yup.string().required(t('payForMonthRequired')),
    deposit: Yup.number(),
    starDate: Yup.date().typeError(t('enterValidDate')),
    ...(hasEndDate && {
      endDate: Yup.date().required(t('endDateRequired')).typeError(t('enterValidDate')),
    })
  });

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(validations)
  });

  useEffect(() => {
    dispatch(getSingleProperty(propertyId));
  }, [propertyId]);

  useEffect(() => {
    if (singleProperty) {
      const defaultValues = {
        rent: singleProperty?.rent,
        payBefore: singleProperty?.payBefore,
        payForMonth: singleProperty?.payForMonth,
        deposit: singleProperty?.deposit ?? 0,
        startDate: singleProperty?.startDate,
        endDate: singleProperty?.endDate,
      };
      setHasEndDate(Boolean(singleProperty?.endDate));
      reset(defaultValues);
    }
  }, [getSinglePropertyIsLoading]);

  return (
    <DefaultLayout>
      <form onSubmit={handleSubmit((data) => {
        setSubmitData({
          rent: data?.rent,
          payBefore: data?.payBefore,
          payForMonth: data?.payForMonth,
          deposit: data?.deposit ?? 0,
          startDate: format(data?.startDate, 'dd.MM.yyyy'),
          endDate: hasEndDate ? format(data?.endDate, 'dd.MM.yyyy') : '',
        });
        if (hasChangeConfirm) {
          setChangeContractDialogIsOpen(true);
        } else {
          dispatch(editProperty(submitData, propertyId, () => {
            setChangeContractDialogIsOpen(false);
            toast.success(t('editRentSuccess'));
          }));
        }
      })}>
        <Box mb={3}>
          <Paper elevation={4}>
            <Stack direction='row' justifyContent='space-between' alignItems='center'>
              <Stack direction='row' alignItems='center' spacing={1}>
                <IconButton onClick={() => navigate(`/properties/${propertyId}`)}>
                  <ArrowBackIcon style={{ width: 16, height: 16 }} />
                </IconButton>
                <Typography variant='h5'>
                  {t('editRent')}
                </Typography>
              </Stack>
              <Button
                variant='contained'
                color='primary'
                type='submit'
              >
                {t('save')}
              </Button>
            </Stack>
          </Paper>
        </Box>
        <Box>
          <Paper elevation={4}>
            <Typography variant='subtitle1'>{t('rentAndContract')}</Typography>
            <Grid container mt={1}>
              <Grid item md={3}>
                <NumberFormat
                  customInput={TextField}
                  thousandSeparator
                  InputLabelProps={{ shrink: true }}
                  label={`${t('monthlyRentAmount')}*`}
                  InputProps={{
                    endAdornment: <InputAdornment position='start'>
                      {t('lv')}
                    </InputAdornment>,
                  }}
                  size='small'
                  fullWidth
                  value={watch('rent')}
                  onValueChange={({ floatValue }) => {
                    setValue('rent', floatValue, { shouldValidate: true });
                  }}
                  error={Boolean(errors?.rent?.message)}
                  helperText={errors?.rent?.message}
                />
              </Grid>
              <Grid item md={3}>
                <TextField
                  label={`${t('dueDate')}*`}
                  type='number'
                  InputProps={{
                    endAdornment: <InputAdornment position='start'>{t('numberMonth')}</InputAdornment>,
                  }}
                  size='small'
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  {...register('payBefore')}
                  error={Boolean(errors?.payBefore?.message)}
                  helperText={errors?.payBefore?.message}
                />
              </Grid>
              <Grid item md={3}>
                <FormControl error={Boolean(errors?.payForMonth?.message)}>
                  <FormLabel disabled>{t('payOn')}*</FormLabel>
                  <RadioGroup row>
                    <FormControlLabel
                      value='предходен месец'
                      control={
                        <Radio
                          checked={watch('payForMonth') === 'previous'}
                          onChange={() => setValue('payForMonth', 'previous', { shouldValidate: true })}
                        />
                      }
                      label={t('previousMonth')}
                    />
                    <FormControlLabel
                      value='текущ месец'
                      control={
                        <Radio
                          checked={watch('payForMonth') === 'current'}
                          onChange={() => setValue('payForMonth', 'current', { shouldValidate: true })}
                        />
                      }
                      label={t('nextMonth')}
                    />
                  </RadioGroup>
                  <FormHelperText error>
                    {errors?.payForMonth?.message}
                  </FormHelperText >
                </FormControl>
              </Grid>
              <Grid item md={3}>
                <NumberFormat
                  customInput={TextField}
                  thousandSeparator
                  InputLabelProps={{ shrink: true }}
                  label={t('deposit')}
                  InputProps={{
                    endAdornment: <InputAdornment position='start'>
                      {t('lv')}
                    </InputAdornment>,
                  }}
                  size='small'
                  fullWidth
                  value={watch('deposit')}
                  onValueChange={({ floatValue }) => {
                    setValue('deposit', floatValue, { shouldValidate: true });
                  }}
                  error={Boolean(errors?.deposit?.message)}
                  helperText={errors?.deposit?.message}
                />
              </Grid>
            </Grid>
            <Grid container mt={1}>
              <Grid item md={3}>
                <DesktopDatePicker
                  label={`${t('startContract')} *`}
                  value={watch('startDate') ? watch('startDate') : null}
                  onChange={(date) => setValue('starDate', date, { shouldValidate: true })}
                  TransitionComponent={Fade}
                  disabled
                  renderInput={(params) => <TextField
                    {...params}
                    size='small'
                    error={Boolean(errors?.starDate?.message)}
                    helperText={errors?.starDate?.message}
                    InputLabelProps={{ shrink: true }}
                  />}
                />
              </Grid>
              <Grid item md={3}>
                <FormControl>
                  <FormLabel disabled>{t('contractType')}</FormLabel>
                  <RadioGroup row>
                    <FormControlLabel
                      control={
                        <Radio
                          onChange={() => setHasEndDate(true)}
                          checked={hasEndDate}
                        />
                      }
                      label={t('limited')}
                    />
                    <FormControlLabel
                      control={
                        <Radio
                          onChange={() => setHasEndDate(false)}
                          checked={!hasEndDate}
                        />
                      }
                      label={t('unlimited')}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {hasEndDate && (
                <Grid item md={3}>
                  <DesktopDatePicker
                    label={`${t('endContract')} *`}
                    value={watch('startDate') ? watch('endDate') : null}
                    onChange={(date) => setValue('endDate', date, { shouldValidate: true })}
                    TransitionComponent={Fade}
                    minDate={watch('startDate') ? watch('startDate') : undefined}
                    renderInput={(params) => <TextField
                      {...params}
                      size='small'
                      error={Boolean(errors?.endDate?.message)}
                      helperText={errors?.endDate?.message}
                      InputLabelProps={{ shrink: true }}
                    />
                    }
                  />
                </Grid>
              )}
            </Grid>
          </Paper>
        </Box>
      </form>
      <Dialog
        open={changeContractDialogIsOpen}
        onClose={() => setChangeContractDialogIsOpen(false)}
        title={t('editContractAndRent')}
      >
        <Typography variant='h6' mb={3}>
          {t('confirmEditContractAndRent')}
        </Typography>
        <FormGroup>
          <FormControlLabel control={
            <Checkbox
              color='secondary'
              onChange={() => setHasChangeConfirm(!hasChangeConfirm)}
            />} label={<Typography sx={{ fontWeight: 400 }}>{t('doNotShowMore')}</Typography>} />
        </FormGroup>
        <Box mb={3} mt={3}>
          <Button
            variant='contained'
            color='primary'
            fullWidth
            onClick={() => {
              dispatch(editProperty(submitData, propertyId, () => {
                setChangeContractDialogIsOpen(false);
                toast.success(t('editRentSuccess'));
              }));
            }}
          >
            {t('continue')}
          </Button>
        </Box>
        <Button
          variant='contained'
          color='warning'
          fullWidth
        >
          {t('decline')}
        </Button>
      </Dialog>
    </DefaultLayout>
  );
};

export default EditRent;