
const FolderIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='36.397'
    height='30.936'
    viewBox='0 0 36.397 30.936'
    {...props}
  >
    <g data-name='Group 33485' transform='translate(3992.958 12847.958)'>
      <path
        fill='#2f3191'
        d='M33.667 30.936H2.729A2.732 2.732 0 010 28.208V2.729A2.732 2.732 0 012.729 0h9.783a2.744 2.744 0 012.456 1.538l1.019 2.1h17.68a2.733 2.733 0 012.73 2.73v21.84a2.733 2.733 0 01-2.73 2.728zM2.729 1.819a.911.911 0 00-.91.91v25.479a.911.911 0 00.91.91h30.938a.911.911 0 00.91-.91V6.369a.911.911 0 00-.91-.91h-16.8l.637 1.31a.913.913 0 00.814.509h13.528a.91.91 0 110 1.821H18.325a2.746 2.746 0 01-2.456-1.538L13.33 2.328a.9.9 0 00-.818-.509z'
        data-name='Subtraction 9'
        transform='translate(-3992.958 -12847.958)'
      ></path>
    </g>
  </svg>
);

export default FolderIcon;