import { createTheme } from '@mui/material/styles';
import { Fade, Collapse } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      main: '#00D297',
    },
    secondary: {
      main: '#2F3191',
    },
    info: {
      main: '#C5D7F2',
      dark: '#C5CAF2',
    },
    background: {
      default: '#FAFAFA',
    },
    // error: {
    //   main: '#F5CCDB'
    // },
    warning: {
      main: '#C7C7C7'
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1200,
      lg: 1600,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: [
      'CorsaGrotesk',
      '-apple-system',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  components: {
    MuiGrid: {
      defaultProps: {
        spacing: 2,
      }
    },
    MuiPaper: {
      defaultProps: {
        sx: {
          p: 1
        },
      },
      styleOverrides: {
        root: {
          border: '1px solid #C7F8E9',
        },
        elevation3: {
          boxShadow: '0 -1px 5px -2px rgb(222 36 36 / 30%)',
        },
        elevation4: {
          boxShadow: '0 0 10px -2px rgb(222 36 36 / 30%)',
        },
        elevation16: {
          width: '370px'
        },
        elevation10: {
          border: '1px solid #FFF8FA'
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
          fontSize: 16,
          height: 63,
          boxShadow: '0px 3px 6px rgba(14, 24, 51, 16%)',
        },
      }
    },
    MuiToolBar: {
      styleOverrides: {
        root: {
          fontSize: '14px',
        },
      },
    },
    MuiContainer: {
      defaultProps: {
        maxWidth: 'xl',
      },
    },
    MuiTypography: {
      styleOverrides: {
        h4: {
          fontSize: 20,
          fontWeight: 400,
          textTransform: 'uppercase'
        },
        h5: {
          fontSize: 18,
          fontWeight: 500,
          textTransform: 'uppercase'
        },
        h6: {
          fontSize: 16,
          fontWeight: 500
        },
        body1: {
          fontSize: 12,
          fontWeight: 600,
        },
        body2: {
          fontSize: 16,
          fontWeight: 600
        },
        subtitle1: {
          fontSize: 18,
          fontWeight: 700,
          textTransform: 'uppercase'
        },
        caption: {
          textTransform: 'uppercase',
          fontSize: 12
        },
        subtitle2: {
          fontSize: 16,
          fontWeight: 800
        },
        overline: {
          fontSize: 22,
          fontWeight: 500,
          textTransform: 'uppercase'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: 6,
        },
        containedPrimary: {
          'color': '#fff',
          '&:disabled': {
            backgroundColor: '#00D297',
            opacity: 0.5,
            color: '#fff',
          }
        },
      },
      defaultProps: {
        disableElevation: true,
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          margin: '0 -10px',
        },
        indicator: {
          display: 'none',
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          'border': '1px solid #C5D7F2',
          'margin': '0 10px',
          'borderRadius': '6px',
          'backgroundColor': '#fff',
          'fontSize': 16,
          'fontWeight': 600,
          'textTransform': 'none',
          'color': '#000',
          'boxShadow': '0 0 4px -2px rgb(222 36 36 / 30%)',
          'minHeight': 50,
          '&.Mui-selected': {
            'borderColor': '#2F3191',
            'backgroundColor': '#2F3191',
            'color': '#fff',
            '& .MuiTab-iconWrapper': {
              color: '#fff',
            }
          },
          '& .MuiTab-iconWrapper': {
            position: 'absolute',
            top: 10,
            right: 10,
          }
        },
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-inputAdornedEnd': {
            borderRight: '1px solid rgba(0, 0, 0, 0.23)',
          },
          '& .MuiInputBase-adornedEnd': {
            '& .MuiInputAdornment-positionStart': {
              margin: 0,
              paddingLeft: 10,
            },
          },
        }
      }
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          'fontSize': 16,
          'textTransform': 'uppercase',
          '& .MuiTypography-root': {
            fontSize: 18,
            fontWeight: 600,
            color: 'rgba(0, 0, 0, 0.87)',
          }
        }
      }
    },
    MuiPopover: {
      defaultProps: {
        TransitionComponent: Collapse,
      }
    },
    MuiMenu: {
      defaultProps: {
        TransitionComponent: Fade,
      },
      styleOverrides: {
        paper: {
          padding: '0 !important',
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          '& .MuiBackdrop-root': {
            backgroundColor: 'rgb(14 24 51 / 70%)',
          }
        }
      }
    }
  },
});

export default theme;
