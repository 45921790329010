import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import NumberFormat from 'react-number-format';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import {
  Paper,
  Typography,
  Button,
  Box,
  Stack,
  TextField,
  Grid,
  MenuItem,
  FormControlLabel,
  IconButton,
  FormGroup,
  Checkbox,
  InputAdornment,
  styled
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { ArrowBackIcon, CrossIcon } from 'icons';
import { Dialog, CardDialog } from 'components';
import { DefaultLayout } from 'layouts';
import {
  getUtilityBillsTypes,
  getUtilityBillsProviders,
  createUtilityBills,
  getSingleProperty
} from 'stores/PropertiesSlice';
import { deleteUtilityBill, editUtilityBill } from 'stores/UtilityBillsSlice';
import { getCards, addCard } from 'stores/PaymentSlice';

const CardsContainer = styled('div')`
   display: flex;
   flex-wrap: nowrap; 
   overflow: auto;
   width: '100%';
   padding: 10px;
   > * {
     margin-right: 10px;
     margin-bottom: 2px; 
   }
`;

const billsDefaultValues = {
  id: '',
  utilityType: '',
  provider: '',
  clientNumber: ''
};

const SingleBill = ({ billData, index, isEdit }) => {
  const { t } = useTranslation();
  const { propertyId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [deleteId, setDeleteId] = useState(undefined);
  const [previewOnly, setPreviewOnly] = useState(false);
  const [isCustomBill, setIsCustomBill] = useState(false);
  const [confirmDeleteModalIsOpen, setConfirmDeleteModalIsOpen] = useState(false);
  const [addCardModalIsOpen, setAddCardModalIsOpen] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const billId = billData?._id;
  const otherProvider = 'other';

  const {
    propertiesState: {
      utilityBillsTypes,
      utilityBillsProviders,
      singleProperty
    },
    paymentState: {
      cards, addCardIsLoading
    },
  } = useSelector(({ propertiesState, paymentState, }) => ({ propertiesState, paymentState }));

  const validations = Yup.object().shape({
    utilityType: Yup.string().required(t('utilityTypeRequired')),
    ...(isCustomBill ? {
      iban: Yup.string().required(t('ibanRequired')),
      holder: Yup.string().required(t('holderRequired')),
      amount: Yup.number().required(t('amountRequired')),
      payDate: Yup.date().required(t('payDateRequired')).typeError(t('enterValidDate')),
    } : {
      provider: Yup.string().required(t('providerRequired')),
      clientNumber: Yup.string().required(t('clientNumberRequired')),
    }),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    reset,
    setValue
  } = useForm({
    resolver: yupResolver(validations),
  });

  const utilityTypeValue = watch('utilityType');
  const providerValue = watch('provider');

  useEffect(() => {
    dispatch(getSingleProperty(propertyId));
  }, []);

  useEffect(() => {
    setIsCustomBill(providerValue === otherProvider);
  }, [providerValue]);

  useEffect(() => {
    if (billId) {
      setPreviewOnly(true);
    }
  }, [billId]);

  useEffect(() => {
    if (isCustomBill) {
      dispatch(getCards());
    }
  }, [isCustomBill]);

  useEffect(() => {
    const defaultCardId = cards?.find((card) => card?.isDefault === true)?._id;
    if (defaultCardId) {
      setValue('card', defaultCardId);
    }
  }, [cards]);

  const resetForm = () => {
    if (billData?._id) {
      reset({
        ...billData,
        utilityType: billData?.utilityType?._id,
        provider: billData?.isCustom ? otherProvider : billData?.provider?._id,
      });

      if (billData?.isCustom) {
        setIsCustomBill(true);
      }

      setPreviewOnly(true);
    }
  };

  useEffect(() => {
    resetForm();
  }, [billData]);

  return (
    <>
      <form onSubmit={handleSubmit(({
        provider, utilityType, clientNumber, user, iban, holder, amount, payDate, card
      }) => {
        const data = {
          provider,
          utilityType,
          clientNumber,
          payDate,
          user,
          _id: billData?._id ? billData?._id : propertyId,
        };

        const customData = {
          utilityType,
          clientNumber,
          card,
          payDate,
          isCustom: true,
          iban,
          holder,
          amount,
          label: 'Test',
          user,
          _id: billData?._id ? billData?._id : propertyId,
        };

        const action = billData?._id ? editUtilityBill : createUtilityBills;

        dispatch(action((billData?.isCustom || isCustomBill) ? customData : data, () => setPreviewOnly(true)));

      })}>
        <Box mt={3}>
          <Paper elevation={4}>
            <Stack direction='row' justifyContent='space-between'>
              <Typography variant='subtitle1'>{t('batch')} {index += 1}</Typography>
              {canDelete && (
                <IconButton onClick={() => {
                  setDeleteId(billData?._id);
                  setConfirmDeleteModalIsOpen(true);
                }}>
                  <CrossIcon fill='#d31163' />
                </IconButton>
              )}
            </Stack>
            <Grid container mt={3}>
              <Grid item md={3}>
                <Controller
                  control={control}
                  name='utilityType'
                  render={({
                    field: { onChange, value },
                  }) => (
                    <TextField
                      key={value}
                      select
                      label={t('batchType')}
                      size='small'
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      onChange={onChange}
                      value={value}
                      error={Boolean(errors?.utilityType?.message)}
                      helperText={errors?.utilityType?.message}
                      disabled={previewOnly}
                    >
                      {utilityBillsTypes?.map((option, index) => (
                        <MenuItem key={`${option._id}-utility-${index}`} value={option._id}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
              {isCustomBill ? (
                <>
                  <Grid item md={3}>
                    <Controller
                      control={control}
                      name='provider'
                      render={({
                        field: { onChange, value },
                      }) => (
                        <TextField
                          key={value}
                          select
                          label={t('provider')}
                          size='small'
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          onChange={onChange}
                          value={value}
                          disabled={!utilityTypeValue || previewOnly || isEdit}
                          error={Boolean(errors?.provider?.message)}
                          helperText={errors?.provider?.message}
                        >
                          {utilityBillsProviders?.filter(({ utilityBillType }, index) => utilityBillType === utilityTypeValue)?.map((option) => (
                            <MenuItem key={`${option._id}-utility-type-${index}`} value={option._id}>
                              {option.label}
                            </MenuItem>
                          ))}
                          <MenuItem value={otherProvider}>
                            {t('other')}
                          </MenuItem>
                        </TextField>
                      )}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <TextField
                      label={t('holder')}
                      size='small'
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      {...register('holder')}
                      error={Boolean(errors?.holder?.message)}
                      helperText={errors?.holder?.message}
                      disabled={previewOnly}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <TextField
                      label={t('IBAN')}
                      size='small'
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      {...register('iban')}
                      error={Boolean(errors?.iban?.message)}
                      helperText={errors?.iban?.message}
                      disabled={previewOnly}
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item md={3}>
                    <Controller
                      control={control}
                      name='provider'
                      render={({
                        field: { onChange, value },
                      }) => (
                        <TextField
                          key={value}
                          select
                          label={t('provider')}
                          size='small'
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          onChange={onChange}
                          value={value}
                          disabled={!utilityTypeValue || previewOnly}
                          error={Boolean(errors?.provider?.message)}
                          helperText={errors?.provider?.message}
                        >
                          {utilityBillsProviders?.filter(({ utilityBillType }, index) => utilityBillType === utilityTypeValue)?.map((option) => (
                            <MenuItem key={`${option._id}-utility-type-${index}`} value={option._id}>
                              {option.label}
                            </MenuItem>
                          ))}
                          {!isEdit && (
                            <MenuItem value={otherProvider}>
                              {t('other')}
                            </MenuItem>
                          )}
                        </TextField>
                      )}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <TextField
                      label={t('clientNumber')}
                      size='small'
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      {...register('clientNumber')}
                      error={Boolean(errors?.clientNumber?.message)}
                      helperText={errors?.clientNumber?.message}
                      disabled={previewOnly}
                    />
                  </Grid>
                </>
              )}
            </Grid>
            {isCustomBill && (
              <>
                <Grid container mt={3} >
                  <Grid item md={3}>
                    <NumberFormat
                      customInput={TextField}
                      thousandSeparator
                      InputLabelProps={{ shrink: true }}
                      label={`${t('dueAmount')}*`}
                      InputProps={{
                        endAdornment: <InputAdornment position='start'>
                          {t('lv')}
                        </InputAdornment>,
                      }}
                      size='small'
                      fullWidth
                      value={watch('amount')}
                      onValueChange={({ floatValue }) => {
                        setValue('amount', floatValue, { shouldValidate: true });
                      }}
                      error={Boolean(errors?.amount?.message)}
                      helperText={errors?.amount?.message}
                      disabled={previewOnly}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <DesktopDatePicker
                      label={t('endDatePayment')}
                      value={watch('payDate') ? watch('payDate') : null}
                      onChange={(date) => setValue('payDate', date, { shouldValidate: true })}
                      renderInput={(params) => <TextField
                        {...params} size='small'
                        error={Boolean(errors?.payDate?.message)}
                        helperText={errors?.payDate?.message}
                        InputLabelProps={{ shrink: true }}
                        disabled={previewOnly}
                      />
                      }
                    />
                  </Grid>
                  <Grid item md={4}>
                    <CardsContainer>
                      {cards?.map(({ _id, token, cardData }) => (
                        <div
                          key={`${token}-${_id}`}
                          style={{ marginTop: 5 }}
                        >
                          <Button
                            key={token}
                            variant={watch('card') === _id ? 'contained' : 'outlined'}
                            onClick={() => setValue('card', _id)}
                            disabled={previewOnly}
                          >
                            ****{cardData?.card?.last4}
                          </Button>
                        </div>
                      ))}
                    </CardsContainer>
                  </Grid>
                  <Grid item md={2}>
                    <Button
                      variant='outlined'
                      color='primary'
                      onClick={() => setAddCardModalIsOpen(true)}
                      disabled={previewOnly}
                    >
                      {t('addCard')}
                    </Button>
                  </Grid>
                </Grid>
                <Box p={1} mt={3} sx={{ backgroundColor: '#F5F9FC' }}>
                  <Stack direction='row' alignItems='center' spacing={1}>
                    <PriorityHighIcon />
                    <Typography variant='caption' textTransform='none'>
                      {t('automaticPayment')}
                    </Typography>
                  </Stack>
                </Box>
              </>
            )}

            <Stack direction='row' justifyContent='flex-end' mt={1} spacing={1}>
              {!billId ? (
                <Button
                  variant='contained'
                  color='primary'
                  type='submit'
                  disabled={previewOnly}
                >
                  {t('add')}
                </Button>
              ) : (
                <>
                  {previewOnly ? (
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={() => {
                        setPreviewOnly(false);
                        setCanDelete(true);
                      }}
                    >
                      {t('edit')}
                    </Button>
                  ) : (
                    <>
                      <Button
                        variant='contained'
                        color='secondary'
                        onClick={() => {
                          setPreviewOnly(true);
                          resetForm();
                          setCanDelete(false);
                        }}
                      >
                        {t('decline')}
                      </Button>
                      <Button
                        type='submit'
                        variant='contained'
                        color='primary'
                      >
                        {t('save')}
                      </Button>
                    </>
                  )}

                </>
              )}
            </Stack>
          </Paper>
        </Box>
        <Dialog
          open={confirmDeleteModalIsOpen}
          onClose={() => setConfirmDeleteModalIsOpen(false)}
          title={t('deleteBatches')}
          dialogWidth='480px'
        >
          <Typography variant='h6' mb={3}>{t('deleteBatchesText')}</Typography>
          <Typography variant='h6' mb={3}>{t('confirmDeleteBatches')}</Typography>
          <FormGroup>
            <FormControlLabel control={<Checkbox defaultChecked color='secondary' />}
              label={<Typography sx={{ fontWeight: 400 }}>{t('doNotShowMore')}</Typography>} />
          </FormGroup>
          <Box mt={3} mb={3}>
            <Button variant='contained' color='error' fullWidth onClick={() => {
              dispatch(deleteUtilityBill({
                _id: deleteId,
                propertyId
              }, () => {
                setConfirmDeleteModalIsOpen(false);
                toast.success(t('successUtilityBillDelete'));
              }));
            }}>
              {t('deleteElement')}
            </Button>
          </Box>
          <Button
            variant='contained'
            color='warning'
            fullWidth
            onClick={() => setConfirmDeleteModalIsOpen(false)}
          >
            {t('cancel')}
          </Button>
        </Dialog>
      </form>
      <CardDialog
        open={addCardModalIsOpen}
        onClose={() => setAddCardModalIsOpen(false)}
        onSubmit={(data) => dispatch(addCard(data, () => setAddCardModalIsOpen(false)))}
        isLoading={addCardIsLoading}
      />
    </>
  );
};

const PaymentBillsForm = ({ isEdit, defaultValues }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { propertyId } = useParams();
  const [billsItems, setBillsItems] = useState([billsDefaultValues]);

  useEffect(() => {
    dispatch(getUtilityBillsTypes());
    dispatch(getUtilityBillsProviders());
  }, []);

  useEffect(() => {
    if (isEdit) {
      setBillsItems(defaultValues);
    }
  }, [defaultValues]);

  return (
    <DefaultLayout>
      <Paper elevation={4}>
        <Stack direction='row' alignItems='center' justifyContent='space-between'>
          <Stack direction='row' spacing={1}>
            <IconButton onClick={() => navigate(`/properties/${propertyId}?activeTab=paymentBills`)}>
              <ArrowBackIcon style={{ width: 16, height: 16 }} />
            </IconButton>
            <Typography variant='h5'>{isEdit ? t('editBatches') : t('addBatches')}</Typography>
          </Stack>
          {/* <Button variant='contained' color='primary'>{isEdit ? t('save') : t('add')}</Button> */}
        </Stack>
      </Paper>
      {billsItems?.map((item, index) => (
        <SingleBill
          key={item?.id}
          index={index}
          billData={item}
          isEdit={isEdit}
        />
      ))}
      {!isEdit && (
        <Stack mt={3} mb={3} direction='row' justifyContent='flex-start'>
          <Button
            variant='outlined'
            color='primary'
            onClick={() => {
              setBillsItems([
                ...billsItems,
                billsDefaultValues,
              ]);
            }}
          >
            {t('addNew')}
          </Button>
        </Stack>
      )}
    </DefaultLayout>
  );
};

export default PaymentBillsForm;