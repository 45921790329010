import { format } from 'date-fns';
import { bg, enGB } from 'date-fns/locale';

const formatDate = (dateString, dateFormat = 'yyyy-MM-dd', lang = 'bg') => {
  const langs = {
    bg,
    en: enGB,
  };

  return (
    format(new Date(dateString), dateFormat, { locale: langs[lang] })
  );
};

export default formatDate;
