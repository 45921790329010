import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { DefaultLayout } from 'layouts';
import {
  Paper,
  Typography,
  Checkbox,
  Button,
  Box,
  Stack,
  IconButton,
  Grid,
  ButtonBase,
  useTheme,
  styled,
} from '@mui/material';
import { toast } from 'react-toastify';
import { useStripe } from '@stripe/react-stripe-js';
import AddIcon from '@mui/icons-material/Add';
import { ArrowBackIcon, InfoIcon, VisaIcon, MasterCardIcon } from 'icons';
import { CardDialog } from 'components';
import { formatAmount } from 'utils';
import { getSingleProperty } from 'stores/PropertiesSlice';
import { getUtilityBills } from 'stores/UtilityBillsSlice';
import { getCards, stripePayment, addCard } from 'stores/PaymentSlice';
import { useTranslation } from 'react-i18next';

const CardItem = styled(ButtonBase)(({ theme, isActive }) => `
  border-radius: 4px;
  color: ${isActive ? '#fff' : theme.palette.primary.main};
  border: 1px solid ${theme.palette.primary.main};
  background-color: ${isActive ? theme.palette.primary.main : '#fff'};
`);

const Icon = styled('div')(({ theme, isActive }) => `
  & svg {
    width: 56px;
    height: 34px;
  }
`);

const ConfirmPayments = () => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { propertyId } = useParams();
  const stripe = useStripe();
  const [addCardModalIsOpen, setAddCardModalIsOpen] = useState(false);
  const [selectedBills, setSelectedBills] = useState([]);
  const [selectedCardId, setSelectedCardId] = useState('');

  const {
    utilityBillsState: { utilityBillsList },
    paymentState: { cards, addCardIsLoading },
    propertiesState: { singleProperty },
    organizationState: { myUserData },
  } = useSelector(({
    utilityBillsState,
    paymentState,
    propertiesState,
    organizationState,
  }) => ({ utilityBillsState, paymentState, propertiesState, organizationState }));

  useEffect(() => {
    if (propertyId) {
      dispatch(getUtilityBills(propertyId));
      dispatch(getSingleProperty(propertyId));
    }
  }, [propertyId]);

  useEffect(() => {
    if (propertyId) {
      dispatch(getCards());
    }
  }, [propertyId]);

  useEffect(() => {
    const defaultCardId = cards?.find(({ isDefault }) => isDefault === true)?.cardData?.id;

    if (defaultCardId) {
      setSelectedCardId(defaultCardId);
    }
  }, [cards]);

  const unpaidAmounts = selectedBills?.map(({ unpaidAmount }) => unpaidAmount);
  const totalAmount = unpaidAmounts?.reduce((a, b) => a + b, 0);

  return (
    <DefaultLayout>
      <Paper elevation={4} sx={{ width: 850, margin: '0 auto' }}>
        <Box p={2}>
          <Stack direction='row' spacing={3} alignItems='center'>
            <IconButton onClick={() => navigate(`/properties/${propertyId}?activeTab=paymentBills`)}>
              <ArrowBackIcon style={{ width: 16, height: 16 }} />
            </IconButton>
            <Typography variant='h5'>{t('payingUtilityBills')}</Typography>
          </Stack>
          <Typography variant='caption' textTransform='none'>{t('payingUtilityBillsText')}</Typography>
          <Typography variant='subtitle1' mt={3} textTransform='uppercase'>{t('batches')}</Typography>

          <Grid container mt={1}>
            {utilityBillsList?.map((billItem) => {
              const isChecked = selectedBills?.map(({ _id }) => _id)?.includes(billItem?._id);
              return (
                <>
                  <Grid item md={11}>
                    <Box
                      p={1}
                      sx={{ backgroundColor: theme.palette.info.light, border: '1px solid #C5D7F2', borderRadius: 2 }}
                    >
                      <Stack direction='row' alignItems='center' justifyContent='space-between'>
                        <Typography variant='subtitle1' textTransform='uppercase'>
                          {billItem?.provider?.label}
                        </Typography>
                        <Typography variant='subtitle1'>
                          {formatAmount(billItem?.unpaidAmount, i18n?.language)}
                        </Typography>
                      </Stack>
                    </Box>
                  </Grid>
                  <Grid item md={1}>
                    <Checkbox
                      color='secondary'
                      checked={isChecked}
                      onChange={() => {
                        if (isChecked) {
                          setSelectedBills(selectedBills?.filter(({ _id }) => _id !== billItem?._id));
                        } else {
                          setSelectedBills([
                            billItem,
                            ...selectedBills,
                          ]);
                        }
                      }}
                    />
                  </Grid>
                </>
              );
            })}
          </Grid>
          <Stack mt={15} direction='row' alignItems='center' spacing={1}>
            <Typography variant='subtitle1'>{t('bankCard')}</Typography>
            <IconButton>
              <InfoIcon style={{ width: 16, height: 16 }} />
            </IconButton>
          </Stack>
          <Stack direction='row' spacing={1} sx={{ overflowY: 'auto' }}>
            {cards?.map(({ cardData }, index) => (
              <CardItem
                key={`card-data-${index}`}
                isActive={selectedCardId === cardData?.id}
                onClick={() => setSelectedCardId(cardData?.id)}
              >
                <Stack
                  p={1}
                  direction='row'
                  alignItems='center'
                  justifyContent='space-between'
                  spacing={4}
                  sx={{ msOverflowY: 'auto' }}
                >
                  <Stack>
                    <Typography variant='body1'>{t('number')}</Typography>
                    <Typography variant='body1'>****{cardData?.card?.last4}</Typography>
                  </Stack>
                  <Stack>
                    <Typography variant='body1'>{t('validity')}</Typography>
                    <Typography variant='body1'>
                      {`${cardData?.card?.exp_month}/${cardData?.card?.exp_year}`}
                    </Typography>
                  </Stack>
                  <Stack>
                    <Icon>
                      {cardData?.card?.brand === 'visa' ? (
                        <VisaIcon style={{ fill: 'currentColor' }} />
                      ) : (
                        <MasterCardIcon style={{ fill: 'currentColor' }} />
                      )}
                    </Icon>
                  </Stack>
                </Stack>
              </CardItem>
            ))}
            <Button
              variant='outlined'
              color='primary'
              onClick={() => setAddCardModalIsOpen(true)}
            >
              <AddIcon style={{ width: 25, height: 40 }} />
            </Button>
          </Stack>
          <Box p={1} mt={1} sx={{ backgroundColor: '#EFF5FF', border: '1px solid #C5D7F2', borderRadius: 2 }}>
            <Stack direction='row' alignItems='center' justifyContent='space-between'>
              <Typography variant='subtitle1'>{t('totalAmount')}</Typography>
              <Stack direction='row' spacing={1} alignItems='center'>
                {Boolean(totalAmount) && (
                  <Typography variant='overline'>{formatAmount(totalAmount, i18n?.language)}</Typography>
                )}
                <Button
                  variant='contained'
                  color='primary'
                  sx={{ textTransform: 'uppercase' }}
                  disabled={!selectedBills?.length}
                  onClick={() => {
                    const payments = [];
                    selectedBills.forEach((billItem) => {
                      billItem?.payments?.forEach(({ _id }) => {
                        payments.push(_id);
                      });
                    });
                    dispatch(stripePayment({
                      propertyId,
                      type: 'utilityBills',
                      currency: 'bgn',
                      payments: payments,
                      total: totalAmount,
                      paidBy: singleProperty?.landlord?._id === myUserData?.id ? 'landlord' : 'tenant',
                    }, (response) => {

                      stripe
                        .confirmCardPayment(response?.data?.client_secret, {
                          payment_method: selectedCardId,
                        })
                        .then(function (result) {
                          if (result?.paymentIntent?.status === 'succeeded') {
                            toast.success(t('payBillSuccess'));
                            dispatch(getUtilityBills(propertyId));
                            navigate(`/properties/${propertyId}`);
                          }
                          if (result?.error) {
                            toast.error(result?.error?.decline_code);
                          }
                        });
                    }));
                  }}
                >
                  {t('payBills')}
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Box>
        <CardDialog
          open={addCardModalIsOpen}
          onClose={() => setAddCardModalIsOpen(false)}
          onSubmit={(data) => dispatch(addCard(data, () => setAddCardModalIsOpen(false)))}
          isLoading={addCardIsLoading}
        />
      </Paper>
    </DefaultLayout >
  );
};

export default ConfirmPayments;