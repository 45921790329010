import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import httpClient from '../api/httpClient';

const initialState = {
  organization: {},
  getOrganizationDataIsLoading: false,
  myUserData: undefined,
  getMyUserDataIsLoading: false,
  updateUserIsLoading: false,
  addUserToOrganizationIsLoading: false,
  singleUser: {},
  bankDetails: [],
  getSingleUserIsLoading: false,
  deleteSingleUserIsLoading: false,
  updateSingleUserIsLoading: false,
  updateOrganizationIsLoading: false,
  deleteOrganizationBankIsLoading: false,
  updateOrganizationBankIsLoading: false,
  getBankDetailsIsLoading: false,
  updateOrganizationSettingsIsLoading: false
};

export const OrganizationSlice = createSlice({
  name: 'Organization',
  initialState,
  reducers: {
    getOrganizationDataRequesting: (state) => {
      state.getOrganizationDataIsLoading = true;
    },
    getOrganizationDataSuccess: (state, { payload }) => {
      state.getOrganizationDataIsLoading = false;
      state.organization = payload;
    },
    getOrganizationDataError: (state) => {
      state.getOrganizationDataIsLoading = false;
    },
    getMyUserDataRequesting: (state) => {
      state.getMyUserDataIsLoading = true;
    },
    getMyUserDataSuccess: (state, { payload }) => {
      state.getMyUserDataIsLoading = false;
      state.myUserData = payload;
    },
    getMyUserDataError: (state) => {
      state.getMyUserDataIsLoading = false;
    },
    updateUserRequesting: (state) => {
      state.updateUserIsLoading = true;
    },
    updateUserSuccess: (state, { payload }) => {
      state.updateUserIsLoading = false;
      state.singleUser = payload;
    },
    updateUserError: (state) => {
      state.updateUserIsLoading = false;
    },
    addUserRequesting: (state) => {
      state.addUserToOrganizationIsLoading = true;
    },
    addUserSuccess: (state) => {
      state.addUserToOrganizationIsLoading = false;
    },
    addUserError: (state) => {
      state.addUserToOrganizationIsLoading = false;
    },
    getSingleUserRequesting: (state) => {
      state.getSingleUserIsLoading = true;
    },
    getSingleUserSuccess: (state, { payload }) => {
      state.getSingleUserIsLoading = false;
      state.singleUser = payload;
    },
    getSingleUserError: (state) => {
      state.getSingleUserIsLoading = false;
    },
    deleteSingleUserRequesting: (state) => {
      state.deleteSingleUserIsLoading = true;
    },
    deleteSingleUserSuccess: (state) => {
      state.deleteSingleUserIsLoading = false;
    },
    deleteSingleUserError: (state) => {
      state.deleteSingleUserIsLoading = false;
    },
    updateSingleUserRequesting: (state) => {
      state.updateSingleUserIsLoading = true;
    },
    updateSingleUserSuccess: (state, { payload }) => {
      state.updateSingleUserIsLoading = false;
      if (payload?.data?.success) {
        state.singleUser = payload?.data?.user;
      }
    },
    updateSingleUserError: (state) => {
      state.updateSingleUserIsLoading = false;
    },
    updateOrganizationRequesting: (state) => {
      state.updateOrganizationIsLoading = true;
    },
    updateOrganizationSuccess: (state, { payload }) => {
      state.updateOrganizationIsLoading = false;
      state.organization = payload;
    },
    updateOrganizationError: (state) => {
      state.updateOrganizationIsLoading = false;
    },
    deleteOrganizationBankRequesting: (state) => {
      state.deleteOrganizationBankIsLoading = true;
    },
    deleteOrganizationBankSuccess: (state) => {
      state.deleteOrganizationBankIsLoading = false;
    },
    deleteOrganizationBankError: (state) => {
      state.deleteOrganizationBankIsLoading = false;
    },
    updateOrganizationBankRequesting: (state) => {
      state.updateOrganizationBankIsLoading = true;
    },
    updateOrganizationBankSuccess: (state) => {
      state.updateOrganizationBankIsLoading = false;
    },
    updateOrganizationBankError: (state) => {
      state.updateOrganizationBankIsLoading = false;
    },
    getBankDetailsRequesting: (state) => {
      state.getBankDetailsIsLoading = true;
    },
    getBankDetailsSuccess: (state, { payload }) => {
      state.getBankDetailsIsLoading = false;
      state.bankDetails = payload;
    },
    getBankDetailsError: (state) => {
      state.getBankDetailsIsLoading = false;
    },
    updateOrganizationSettingsRequesting: (state) => {
      state.updateOrganizationSettingsIsLoading = true;
    },
    updateOrganizationSettingsSuccess: (state) => {
      state.updateOrganizationSettingsIsLoading = false;
    },
    updateOrganizationSettingsError: (state) => {
      state.updateOrganizationSettingsIsLoading = false;
    },
  }
});

export const {
  getOrganizationDataRequesting,
  getOrganizationDataSuccess,
  getOrganizationDataError,
  getMyUserDataRequesting,
  getMyUserDataSuccess,
  getMyUserDataError,
  updateUserRequesting,
  updateUserSuccess,
  updateUserError,
  addUserRequesting,
  addUserSuccess,
  addUserError,
  getSingleUserRequesting,
  getSingleUserSuccess,
  getSingleUserError,
  deleteSingleUserRequesting,
  deleteSingleUserSuccess,
  deleteSingleUserError,
  updateSingleUserRequesting,
  updateSingleUserSuccess,
  updateSingleUserError,
  updateOrganizationRequesting,
  updateOrganizationSuccess,
  updateOrganizationError,
  deleteOrganizationBankRequesting,
  deleteOrganizationBankSuccess,
  deleteOrganizationBankError,
  updateOrganizationBankRequesting,
  updateOrganizationBankSuccess,
  updateOrganizationBankError,
  getBankDetailsRequesting,
  getBankDetailsSuccess,
  getBankDetailsError,
  updateOrganizationSettingsRequesting,
  updateOrganizationSettingsSuccess,
  updateOrganizationSettingsError
} = OrganizationSlice.actions;

export const getOrganization = (id, onSuccess) => async (dispatch) => {
  dispatch(getOrganizationDataRequesting());
  httpClient.get(`/organization/${id}`).then(
    (response) => {
      dispatch(getOrganizationDataSuccess(response?.data?.organization));
      if (onSuccess) {
        onSuccess(response?.data?.organization);
      }
    },
    (error) => dispatch(getOrganizationDataError(error)),
  );
};

export const getMyUserData = () => async (dispatch) => {
  dispatch(getMyUserDataRequesting());
  httpClient.get('/user').then(
    (response) => {
      dispatch(getMyUserDataSuccess(response?.data?.user));
    },
    (error) => dispatch(getMyUserDataError(error)),
  );
};

export const updateUser = (data, onSuccess) => async (dispatch) => {
  dispatch(updateUserRequesting());
  httpClient.put('/user', data).then(
    (response) => {
      dispatch(updateUserSuccess(response?.data?.user));
      toast.success(window.i18n.t('userUpdateSuccess'));
      if (onSuccess) {
        onSuccess();
      }
    },
    (error) => dispatch(updateUserError(error)),
  );
};

export const addUser = (users, onSuccess) => async (dispatch) => {
  dispatch(addUserRequesting());
  Promise.all(users?.map(async (data) => httpClient.post('/organization/add-user', data)))
    .then((response) => {
      dispatch(addUserSuccess(response));
      if (onSuccess) {
        onSuccess();
      }
      toast.success(window.i18n.t('userAddSuccess'));
    }, (error) => dispatch(addUserError(error)));
};

export const updateBank = (data, onSuccess) => async (dispatch) => {
  dispatch(updateOrganizationBankRequesting());
  Promise.all(data?.map(async ({ id, ...bankData }) => httpClient.put(`/bank-details/${id}`, bankData)))
    .then((response) => {
      dispatch(updateOrganizationBankSuccess(response));
      if (onSuccess) {
        onSuccess();
      }
      toast.success(window.i18n.t('bankAccountEditSuccess'));
    }, (error) => dispatch(updateOrganizationBankError(error)));
};

export const getSingleUser = (id) => async (dispatch) => {
  dispatch(getSingleUserRequesting());
  httpClient.get(`/user/${id}`).then(
    (response) => {
      dispatch(getSingleUserSuccess(response?.data?.user));
    },
    (error) => dispatch(getSingleUserError(error)),
  );
};

export const deleteSingleUser = (id, onSuccess) => async (dispatch) => {
  dispatch(deleteSingleUserRequesting());
  httpClient.delete(`/organization/user/${id}`).then(
    (response) => {
      dispatch(deleteSingleUserSuccess(response));
      if (onSuccess) {
        onSuccess();
      }
      toast.success(window.i18n.t('userSuccessDelete'));
    },
    (error) => dispatch(deleteSingleUserError(error)),
  );
};

export const updateSingleUser = (data, onSuccess) => async (dispatch) => {
  dispatch(updateSingleUserRequesting());
  httpClient.put('/user', data).then(
    (response) => {
      dispatch(updateSingleUserSuccess(response));
      if (onSuccess) {
        onSuccess();
      }
      toast.success(window.i18n.t('userUpdateSuccess'));
    },
    (error) => dispatch(updateSingleUserError(error)),
  );
};

export const updateOrganization = (id, data, onSuccess) => async (dispatch) => {
  dispatch(updateOrganizationRequesting());
  httpClient.put(`/organization/${id}`, data).then(
    (response) => {
      dispatch(updateOrganizationSuccess(response?.data?.organization));
      if (onSuccess) {
        onSuccess();
      }
      toast.success(window.i18n.t('organizationEdit'));
    },
    (error) => dispatch(updateOrganizationError(error)),
  );
};

export const deleteOrganizationBank = (id, onSuccess) => async (dispatch) => {
  dispatch(deleteOrganizationBankRequesting());
  httpClient.delete(`/nordigen/requisitions/${id}?org=true`).then(
    (response) => {
      dispatch(deleteOrganizationBankSuccess(response?.data));
      if (onSuccess) {
        onSuccess();
      }
      toast.success(window.i18n.t('bankAccountDeleteSuccess'));
    },
    (error) => dispatch(deleteOrganizationBankError(error)),
  );
};

export const getBankDetails = (onSuccess) => async (dispatch) => {
  dispatch(getBankDetailsRequesting());
  httpClient.get('/bank-details?org=true').then(
    (response) => {
      dispatch(getBankDetailsSuccess(response?.data?.bankDetails));
      if (onSuccess) {
        onSuccess();
      }
    },
    (error) => dispatch(getBankDetailsError(error)),
  );
};

export const updateOrganizationSettings = (data, onSuccess) => async (dispatch) => {
  dispatch(updateOrganizationSettingsRequesting());
  httpClient.put('/organization/settings', data).then(
    (response) => {
      dispatch(updateOrganizationSettingsSuccess(response?.data));
      if (onSuccess) {
        onSuccess();
      }
    },
    (error) => dispatch(updateOrganizationSettingsError(error)),
  );
};

export default OrganizationSlice.reducer;
