
const EditRentIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20.01'
    height='20.01'
    viewBox='0 0 20.01 20.01'
    {...props}
  >
    <g
      fill='#2f3191'
      data-name='Group 33865'
      transform='translate(15478 4447)'
    >
      <path
        d='M13.336 11.672a5.336 5.336 0 115.336-5.336 5.342 5.342 0 01-5.336 5.336zm0-9.338a4 4 0 104 4 4.006 4.006 0 00-4-4z'
        data-name='Path 59467'
        transform='translate(-15481.331 -4448)'
      ></path>
      <path
        d='M12.339 26.671H1.667A.667.667 0 011 26v-6a.665.665 0 01.2-.472A5.169 5.169 0 014.335 18a8.055 8.055 0 013.532 1.334H11a2 2 0 012 2 2.043 2.043 0 01-.03.346l5.263-2.193a2 2 0 012.771 1.847.666.666 0 01-.331.576l-8 4.669a.669.669 0 01-.334.092zm-10-1.334h9.824L19.588 21a.673.673 0 00-.209-.223.656.656 0 00-.626-.061L12.6 23.285a.672.672 0 01-.257.051H7A.667.667 0 017 22h4a.667.667 0 000-1.334H7.67a.675.675 0 01-.37-.111 7.317 7.317 0 00-2.965-1.223 3.634 3.634 0 00-2 .96z'
        data-name='Path 59469'
        transform='translate(-15479 -4453.661)'
      ></path>
      <path
        d='M10.855 6.711a2.855 2.855 0 112.855-2.855 2.859 2.859 0 01-2.855 2.855zm0-5A2.142 2.142 0 1013 3.855a2.144 2.144 0 00-2.145-2.141z'
        data-name='Path 59471'
        transform='translate(-15478.851 -4445.52)'
      ></path>
    </g>
  </svg>
);

export default EditRentIcon;
