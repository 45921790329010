import { useDispatch, useSelector } from 'react-redux';
import { Support } from '@fidweb/customer-support';
import { useTranslation } from 'react-i18next';
import { updateUser } from 'stores/OrganizationSlice';


const PersonalSettingsHelp = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const { myUserData } = useSelector(({ organizationState }) => organizationState);
  const currentLangCode = i18n?.language;

  return (
    <div style={{ height: '100vh', width: '100vw', padding: '90px 50px 20px', boxSizing: 'border-box' }}>
      {myUserData && <Support
        language={currentLangCode}
        URL={process.env.REACT_APP_API_CUSTOMER_URL}
        currentUser={myUserData}
        platform='immotech'
        handleNewUser={(supportId) => dispatch(updateUser({
          _id: myUserData._id,
          supportId
        }))}
        // logo={require('../../assets/images/logo-small.png')}
        colors={{
          '--main-color': '#00D297',
          '--secondary-color': '#2F3191',
          '--light-color': '#C5D7F2'
        }}
      />}
    </div>
  );
};

export default PersonalSettingsHelp;