import { configureStore } from '@reduxjs/toolkit';
import AuthenticationReducer from './AuthenticationSlice';
import PropertiesReducer from './PropertiesSlice';
import FilesReducer from './FilesSlice';
import AddressReducer from './AddressSlice';
import CasesReducer from './CasesSlice';
import RentReducer from './RentSlice';
import UtilityBillsReducer from './UtilityBillsSlice';
import PaymentReducer from './PaymentSlice';
import FinancesReducer from './FinancesSlice';
import OrganizationReducer from './OrganizationSlice';
import DashboardReducer from './DashboardSlice';
import InvoiceReducer from './InvoiceSlice';

export const store = configureStore({
  reducer: {
    authenticationState: AuthenticationReducer,
    propertiesState: PropertiesReducer,
    filesState: FilesReducer,
    addressState: AddressReducer,
    casesState: CasesReducer,
    rentState: RentReducer,
    utilityBillsState: UtilityBillsReducer,
    paymentState: PaymentReducer,
    financesState: FinancesReducer,
    organizationState: OrganizationReducer,
    dashboardState: DashboardReducer,
    invoiceState: InvoiceReducer
  },
});