
const ArrowIcon = (props) => (
  <>
    <svg
      xmlns='http://www.w3.org/2000/svg'
      x='0'
      y='0'
      enableBackground='new 0 0 19.6 19.6'
      version='1.1'
      viewBox='0 0 19.6 19.6'
      xmlSpace='preserve'
      width={19.609}
      height={13.072}
      {...props}
    >
      <path
        d='M19.1 8.9l-5.3-5.3c-.4-.4-1.1-.4-1.5 0-.2.2-.3.5-.3.8 0 .3.1.6.3.8l3.6 3.6H1.1 1c-.6 0-1 .5-1 1.1v.1c0 .6.5 1 1.1 1h14.8l-3.6 3.6c-.2.1-.3.4-.3.7 0 .3.1.6.3.8.4.4 1.1.4 1.5 0l5.3-5.3c.3-.2.5-.5.5-.9v-.1c0-.5-.2-.7-.5-.9z'
        className='st0'
      ></path>
    </svg>
  </>
);

export default ArrowIcon;
