import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Paper,
  Typography,
  Box,
  Stack,
  Grid,
  IconButton,
  TextField,
  Button,
  MenuItem,
  InputAdornment,
  FormHelperText,
  Autocomplete,
  styled
} from '@mui/material';
import GoogleAutoComplete from 'react-google-autocomplete';
import { useTranslation } from 'react-i18next';
import { ArrowBackIcon } from 'icons';
import { DefaultLayout } from 'layouts';
import { Select, Dialog } from 'components';
import {
  getPropertyTypes,
  getSingleProperty,
  editProperty
} from 'stores/PropertiesSlice';
import { searchCity } from 'stores/AddressSlice';

const StyledAddress = styled('div')(({ error }) => `
  input {
    width: 100%;
    height: 40px;
    border: 1px solid  ${error ? '#d32f2f' : 'rgb(196 196 196)'};
    font-family: CorsaGrotesk;
    padding: 0 14px;
    border-radius: 3px;
    font-size: 16px;
  }
`);

const EditProperty = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { propertyId } = useParams();
  const [latLng, setLatLng] = useState(undefined);
  const [successDialogIsOpen, setSuccessDialogIsOpen] = useState(false);

  const {
    propertiesState: {
      propertyTypes, singleProperty, getSinglePropertyIsLoading,
    },
    addressState: { citiesList },
  } = useSelector(({ propertiesState, addressState }) => ({ propertiesState, addressState }));

  const validations = Yup.object().shape({
    name: Yup.string().required(t('nicknameRequired')),
    city: Yup.string().required(t('cityRequired')),
    address: Yup.string().required(t('addressRequired')),
    propertyType: Yup.string().required(t('propertyTypeRequired')),
    area: Yup.number().required(t('areaRequired')).typeError(t('areaRequired'))
  });

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(validations)
  });

  useEffect(() => {
    dispatch(getPropertyTypes());
  }, []);

  useEffect(() => {
    dispatch(getSingleProperty(propertyId));
  }, [propertyId]);

  useEffect(() => {
    if (singleProperty) {
      let cityName = singleProperty?.city;
      if (cityName?.includes('гр. ')) {
        cityName = cityName?.replace('гр. ', '');
      } else {
        cityName = cityName?.replace('с. ', '');
      }
      dispatch(searchCity(cityName, false, (response) => {
        const cities = response?.data?.sites;
        const selectedCity = cities?.find(({ name }) => name === cityName);

        const defaultValues = {
          name: singleProperty?.nicknames?.landlord,
          city: singleProperty?.city,
          address: singleProperty?.address,
          propertyType: singleProperty?.propertyType?._id,
          area: singleProperty?.area
        };
        reset(defaultValues);

        if (selectedCity) {
          setValue('city', singleProperty?.city, { shouldValidate: true });
          setValue('address', singleProperty?.address);
          setValue('municipality', selectedCity?.municipality);
          setValue('region', selectedCity?.region);
          setValue('postCode', selectedCity?.postCode);
          setLatLng({
            lat: selectedCity?.y,
            lng: selectedCity?.x,
          });
        }
      }));
    }
  }, [getSinglePropertyIsLoading]);

  return (
    <DefaultLayout>
      <form onSubmit={handleSubmit((data) => {
        dispatch(editProperty({
          nicknames: {
            landlord: data?.name,
            owner: data?.name,
            tenant: singleProperty?.nicknames?.tenant
          },
          city: data?.city,
          address: data?.address,
          area: data?.area,
          propertyType: data?.propertyType
        }, propertyId, () => setSuccessDialogIsOpen(true)));
      })}>
        <Box mb={3}>
          <Paper elevation={4}>
            <Stack direction='row' justifyContent='space-between' alignItems='center'>
              <Stack direction='row' alignItems='center' spacing={1}>
                <IconButton onClick={() => navigate(`/properties/${propertyId}`)}>
                  <ArrowBackIcon style={{ width: 16, height: 16 }} />
                </IconButton>
                <Typography variant='h5'>
                  {t('editPropertyData')}
                </Typography>
              </Stack>
              <Button variant='contained' color='primary' type='submit'>
                {t('save')}
              </Button>
            </Stack>
          </Paper>
        </Box>
        <Box>
          <Paper elevation={4}>
            <Typography variant='subtitle1'>{t('aboutProperty')}</Typography>
            <Grid container mt={1}>
              <Grid item md={4}>
                <TextField
                  label={`${t('propertyName')}*`}
                  size='small'
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  {...register('name')}
                  error={Boolean(errors?.name?.message)}
                  helperText={errors?.name?.message}
                />
              </Grid>
              <Grid item md={4}>
                {singleProperty?.city && (
                  <Autocomplete
                    options={citiesList?.map((city) => ({
                      label: `${city?.type} ${city?.name} (обл. ${city?.region})`,
                      value: city,
                    }))}
                    defaultValue={{
                      value: singleProperty?.city,
                      label: singleProperty?.city,
                    }}
                    onChange={(_, { value }) => {
                      setValue('city', value?.name, { shouldValidate: true });
                      setValue('municipality', value?.municipality);
                      setValue('region', value?.region);
                      setValue('postCode', value?.postCode);
                      setValue('address', '');
                      setLatLng({
                        lat: value?.y,
                        lng: value?.x,
                      });
                    }}
                    filterOptions={(options) => options}
                    onInputChange={(_, value) => dispatch(searchCity(value))}
                    renderInput={(params) => <TextField {...params} label={`${t('city')}*`} size='small' InputLabelProps={{ shrink: true }} {...register('city')} error={Boolean(errors?.city?.message)} />}
                    noOptionsText={t('addCity')}
                  />
                )}
                {Boolean(errors?.city?.message) && (
                  <FormHelperText error>
                    {errors?.city?.message}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item md={4}>
                {Boolean(latLng) && (
                  <StyledAddress error={Boolean(errors?.address?.message)}>
                    <GoogleAutoComplete
                      key={`${latLng.lat}${latLng.lng}`}
                      apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                      onPlaceSelected={(place) => {
                        setValue('address', place?.formatted_address, { shouldValidate: true });
                      }}
                      onChange={(ev) => {
                        if (!ev?.target?.value) {
                          setValue('address', '', { shouldValidate: true });
                        }
                      }}
                      defaultValue={watch('address') ?? ''}
                      language='bg'
                      placeholder={t('enterAddress')}
                      options={{
                        bounds: {
                          north: latLng.lat + 0.1,
                          south: latLng.lat - 0.1,
                          east: latLng.lng + 0.1,
                          west: latLng.lng - 0.1,
                        },
                        strictBounds: true,
                        types: [],
                        language: 'bulgarian',
                        componentRestrictions: { country: 'bg' }
                      }}
                    />
                    {Boolean(errors?.address?.message) && (
                      <FormHelperText error>
                        {errors?.address?.message}
                      </FormHelperText>
                    )}
                  </StyledAddress>
                )}
              </Grid>
            </Grid>
            <Grid container mt={1}>
              <Grid item md={4}>
                <Controller
                  control={control}
                  name='propertyType'
                  render={({
                    field: { onChange, value },
                  }) => (
                    <>
                      <Select
                        key={value}
                        label={`${t('propertyType')}*`}
                        size='small'
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        onChange={onChange}
                        value={value}
                        error={Boolean(errors?.propertyType?.message)}
                        helperText={errors?.propertyType?.message}
                        options={propertyTypes?.map(({ _id, name }) => ({
                          label: name,
                          value: _id,
                        }))}
                      >
                        {propertyTypes?.map(({ _id, name }) => (
                          <MenuItem key={_id} value={_id}>
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </>
                  )}
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  label={`${t('size')}*`}
                  type='number'
                  size='small'
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    endAdornment: <InputAdornment position='start'>{t('squareMeters')}</InputAdornment>,
                  }}
                  {...register('area')}
                  error={Boolean(errors?.area?.message)}
                  helperText={errors?.area?.message}
                />
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </form>
      <Dialog
        open={successDialogIsOpen}
        onClose={() => setSuccessDialogIsOpen(false)}
        title={t('editRentSuccessMessage')}
      >
        <Typography variant='h6'>
          {t('successfullyEditedProperty')}
        </Typography>
        <Box mt={3}>
          <Button
            variant='contained'
            color='primary'
            fullWidth
            onClick={() => {
              navigate(`/properties/${propertyId}`);
              setSuccessDialogIsOpen(false);
            }}
          >
            {t('manageProperty')}
          </Button>
        </Box>
      </Dialog>
    </DefaultLayout >
  );
};

export default EditProperty;