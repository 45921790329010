import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  styled,
  Paper,
  Typography,
  Button,
  Box,
  Stack,
  TextField,
  Grid,
  InputAdornment,
  Switch,
  FormGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  FormHelperText,
  Autocomplete,
  Fade,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import GoogleAutoComplete from 'react-google-autocomplete';
import NumberFormat from 'react-number-format';
import { useTranslation } from 'react-i18next';
import { PropertyFormLayout } from 'layouts';
import { Dialog, Select } from 'components';
import {
  getPropertyTypes,
  getUserTypes,
  createProperty,
} from 'stores/PropertiesSlice';
import { searchCity } from 'stores/AddressSlice';

const StyledAddress = styled('div')(({ error }) => `
  input {
    width: 100%;
    height: 40px;
    border: 1px solid  ${error ? '#d32f2f' : 'rgb(196 196 196)'};
    font-family: CorsaGrotesk;
    padding: 0 14px;
    border-radius: 3px;
    font-size: 16px;
  }
`);

const PropertyBaseParams = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { propertyId } = useParams();
  const [hasLandlord, setHasLandlord] = useState(true);
  const [hasEndDate, setHasEndDate] = useState(true);
  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
  const [submitData, setSubmitData] = useState();
  const [latLng, setLatLng] = useState(undefined);
  const { myUserData } = useSelector(({ organizationState }) => organizationState);
  const {
    propertiesState: {
      propertyTypes, userTypes, singleProperty
    },
    addressState: { citiesList },
  } = useSelector(({ propertiesState, addressState }) => ({ propertiesState, addressState }));
  const isPreview = Boolean(propertyId);

  useEffect(() => {
    dispatch(getPropertyTypes());
    dispatch(getUserTypes());
  }, []);

  useEffect(() => {
    const userTypeKey = hasLandlord ? 'landlord' : 'owner';
    const userTypeId = userTypes?.find(({ key }) => key === userTypeKey)?._id;
    setValue('userType', userTypeId);
  }, [hasLandlord, userTypes]);

  const validations = Yup.object().shape({
    nickname: Yup.string().required(t('nicknameRequired')),
    city: Yup.string().required(t('cityRequired')),
    address: Yup.string().required(t('addressRequired')),
    propertyType: Yup.string().required(t('propertyTypeRequired')),
    ...(hasLandlord && {
      area: Yup.number().required(t('areaRequired')).typeError(t('areaRequired')),
      rent: Yup.number().min(1).required(t('rentRequired')).typeError(t('rentRequired')),
      payBefore: Yup.number().min(1).max(31).required('payBeforeRequired').typeError(t('payBeforeRequired')),
      payForMonth: Yup.string().required(t('payForMonthRequired')),
      deposit: Yup.number().label('Deposit').typeError(t('depositRequired')),
      startDate: Yup.date().required(t('startDateRequired')).typeError('enterValidDate'),
      ...(hasEndDate && {
        endDate: Yup.date().required(t('endDateRequired')).typeError(t('enterValidDate')),
      })
    })
  });

  // const schema = useMemo(() => validations(), [i18n.language]);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
    reset,
    // setError,
  } = useForm({
    resolver: yupResolver(validations)
  });

  // useEffect(() => {
  //   setError('nickname', {
  //     type: 'required',
  //     message: t('nicknameRequired'),
  //   });
  //   setError('city', {
  //     type: 'required',
  //     message: t('cityRequired'),
  //   });
  //   setError('address', {
  //     type: 'required',
  //     message: t('addressRequired'),
  //   });
  //   setError('propertyType', {
  //     type: 'required',
  //     message: t('propertyTypeRequired'),
  //   });
  //   if (hasLandlord) {
  //     setError('area', {
  //       type: 'required',
  //       message: t('areaRequired'),
  //     });
  //     setError('rent', {
  //       type: 'required',
  //       message: t('rentRequired'),
  //     });
  //     setError('payBefore', {
  //       type: 'required',
  //       message: t('payBeforeRequired'),
  //     });
  //     setError('payForMonth', {
  //       type: 'required',
  //       message: t('payForMonthRequired'),
  //     });
  //     setError('deposit', {
  //       type: 'required',
  //       message: t('depositRequired'),
  //     });
  //     setError('startDate', {
  //       type: 'required',
  //       message: t('startDateRequired'),
  //     });
  //     if (hasEndDate) {
  //       setError('endDate', {
  //         type: 'required',
  //         message: t('endDateRequired'),
  //       });
  //     }
  //   }
  // }, [i18n.language]);


  useEffect(() => {
    const defaultValues = {
      city: singleProperty?.city ?? '',
      area: singleProperty?.area ?? '',
      address: singleProperty?.address ?? '',
      municipality: singleProperty?.municipality ?? '',
      payBefore: singleProperty?.payBefore ?? '',
      payForMonth: singleProperty?.payForMonth ?? 'previous',
      postCode: singleProperty?.postCode ?? '',
      region: singleProperty?.region ?? '',
      rent: singleProperty?.rent ?? '',
      deposit: singleProperty?.deposit ?? '',
      nickname: singleProperty?.nicknames?.landlord ?? '',
      propertyType: singleProperty?.propertyType?._id ?? '',
      startDate: singleProperty?.startDate ?? '',
      endDate: singleProperty?.endDate ?? ''
    };

    if (isPreview) {
      reset(defaultValues);
      setHasEndDate(Boolean(defaultValues?.endDate));
    }
  }, [singleProperty, isPreview]);

  return (
    <PropertyFormLayout {... { hasLandlord }}>
      {({ hasBaseParamsData }) => {
        const fieldsProps = {
          disabled: isPreview && hasBaseParamsData,
        };
        const getNextStepRoute = (id) => `/properties/${id}${hasLandlord ? '/tenant' : '/payment-bills'}`;

        return (
          <>
            <form onSubmit={handleSubmit((data) => {
              setSubmitData({
                gallery: [],
                ...data,
                startDate: data?.startDate,
                endDate: hasEndDate ? data?.endDate : null,
              });
              setConfirmModalIsOpen(true);
            }
            )}>
              <Box mb={3}>
                <Paper elevation={4}>
                  <Typography variant='subtitle1' mb={3}>{t('aboutProperty')}</Typography>
                  <Grid container>
                    <Grid item md={4}>
                      <TextField
                        label={`${t('propertyName')}*`}
                        size='small'
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        {...register('nickname')}
                        error={Boolean(errors?.nickname?.message)}
                        helperText={errors?.nickname?.message}
                        {...fieldsProps}
                      />
                    </Grid>
                    <Grid item md={4}>
                      <Autocomplete
                        options={citiesList?.map((city, cityIndex) => ({
                          label: `${city?.type} ${city?.name} (обл. ${city?.region})`,
                          value: city,
                        }))}
                        onChange={(_, { value }) => {
                          setValue('city', value?.name, { shouldValidate: true });
                          setValue('municipality', value?.municipality);
                          setValue('region', value?.region);
                          setValue('postCode', value?.postCode);
                          setLatLng({
                            lat: value?.y,
                            lng: value?.x,
                          });
                        }}
                        filterOptions={(options) => options}
                        onInputChange={(_, value) => dispatch(searchCity(value))}
                        renderInput={(params) => <TextField {...params} label={`${t('city')}*`} size='small' InputLabelProps={{ shrink: true }} error={Boolean(errors?.city?.message)} />}
                        noOptionsText={t('addCity')}
                      />
                      {Boolean(errors?.city?.message) && (
                        <FormHelperText error>
                          {errors?.city?.message}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item md={4}>
                      {Boolean(latLng) && (
                        <StyledAddress error={Boolean(errors?.address?.message)}>
                          <GoogleAutoComplete
                            key={`${latLng.lat}${latLng.lng}`}
                            apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                            onPlaceSelected={(place) => {
                              setValue('address', place?.formatted_address, { shouldValidate: true });
                            }}
                            language='bg'
                            placeholder={t('enterAddress')}
                            options={{
                              bounds: {
                                north: latLng.lat + 0.1,
                                south: latLng.lat - 0.1,
                                east: latLng.lng + 0.1,
                                west: latLng.lng - 0.1,
                              },
                              strictBounds: true,
                              types: [],
                              language: 'bulgarian',
                              componentRestrictions: { country: 'bg' }
                            }}
                          />
                          {Boolean(errors?.address?.message) && (
                            <FormHelperText error>
                              {errors?.address?.message}
                            </FormHelperText>
                          )}
                        </StyledAddress>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container mt={3}>
                    <Grid item md={4}>
                      <Controller
                        control={control}
                        name='propertyType'
                        render={({
                          field: { onChange, value },
                        }) => (
                          <>
                            <Select
                              key={value}
                              label={`${t('propertyType')}*`}
                              size='small'
                              fullWidth
                              InputLabelProps={{ shrink: true }}
                              onChange={onChange}
                              value={value}
                              error={Boolean(errors?.propertyType?.message)}
                              helperText={errors?.propertyType?.message}
                              options={propertyTypes?.map(({ _id, name }) => ({
                                label: t(name),
                                value: _id,
                              }))}
                              {...fieldsProps}
                            />
                          </>
                        )}
                      />
                    </Grid>
                    <Grid item md={4}>
                      <TextField
                        type='number'
                        label={t('size')}
                        InputProps={{
                          endAdornment: <InputAdornment position='start'>{t('squareMeters')}</InputAdornment>,
                        }}
                        size='small'
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        {...register('area')}
                        error={Boolean(errors?.area?.message)}
                        helperText={errors?.area?.message}
                        {...fieldsProps}
                      />
                    </Grid>
                  </Grid>
                  <Box sx={{ backgroundColor: '#F5F9FC' }} mt={3}>
                    <Stack p={1} direction='row' alignItems='center' spacing={2}>
                      <Typography variant='caption'>{t('propertyWithLandlord')}</Typography>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={hasLandlord}
                              onChange={({ target: { checked } }) => setHasLandlord(checked)}
                              {...fieldsProps}
                            />}
                          label={hasLandlord ? t('yes') : t('no')}
                        />
                      </FormGroup>
                    </Stack>
                    <Typography p={1} variant='caption' textTransform='none'>{t('propertyWithLandlordText')}</Typography>
                  </Box>
                </Paper>
              </Box>
              {hasLandlord && (
                <Box mb={3}>
                  <Paper elevation={4}>
                    <Typography variant='subtitle1' mb={3}>{t('rentContract')}</Typography>
                    <Grid container>
                      <Grid item md={3}>
                        <NumberFormat
                          customInput={TextField}
                          thousandSeparator
                          InputLabelProps={{ shrink: true }}
                          label={`${t('monthlyRentAmount')}*`}
                          InputProps={{
                            endAdornment: <InputAdornment position='start'>
                              {t('lv')}
                            </InputAdornment>,
                          }}
                          size='small'
                          fullWidth
                          value={watch('rent')}
                          onValueChange={({ floatValue }) => {
                            setValue('rent', floatValue, { shouldValidate: true });
                          }}
                          error={Boolean(errors?.rent?.message)}
                          helperText={errors?.rent?.message}
                          {...fieldsProps}
                        />
                      </Grid>
                      <Grid item md={3}>
                        <TextField
                          label={`${t('dueDate')}*`}
                          type='number'
                          InputProps={{
                            endAdornment: <InputAdornment position='start'>
                              {t('numberMonth')}
                            </InputAdornment>,
                          }}
                          size='small'
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          {...register('payBefore')}
                          error={Boolean(errors?.payBefore?.message)}
                          helperText={errors?.payBefore?.message}
                          {...fieldsProps}
                        />
                      </Grid>
                      <Grid item md={3}>
                        <FormControl error={Boolean(errors?.payForMonth?.message)} sx={{ marginTop: '-15px', marginLeft: '15px' }}>
                          <FormLabel disabled>{t('payOn')}*</FormLabel>
                          <RadioGroup row>
                            <FormControlLabel
                              value='предходен месец'
                              control={
                                <Radio
                                  checked={watch('payForMonth') === 'previous'}
                                  onChange={() => setValue('payForMonth', 'previous', { shouldValidate: true })}
                                  {...fieldsProps}
                                />
                              }
                              label={t('previousMonth')}
                            />
                            <FormControlLabel
                              value='текущ месец'
                              control={
                                <Radio
                                  checked={watch('payForMonth') === 'current'}
                                  onChange={() => setValue('payForMonth', 'current', { shouldValidate: true })}
                                  {...fieldsProps}
                                />
                              }
                              label={t('nextMonth')}
                            />
                          </RadioGroup>
                          <FormHelperText error>
                            {errors?.payForMonth?.message}
                          </FormHelperText >
                        </FormControl>
                      </Grid>
                      <Grid item md={3}>
                        <NumberFormat
                          customInput={TextField}
                          thousandSeparator
                          InputLabelProps={{ shrink: true }}
                          label={t('deposit')}
                          InputProps={{
                            endAdornment: <InputAdornment position='start'>
                              {t('lv')}
                            </InputAdornment>,
                          }}
                          size='small'
                          fullWidth
                          value={watch('deposit')}
                          onValueChange={({ floatValue }) => {
                            setValue('deposit', floatValue, { shouldValidate: true });
                          }}
                          error={Boolean(errors?.deposit?.message)}
                          helperText={errors?.deposit?.message}
                          {...fieldsProps}
                        />
                      </Grid>
                    </Grid>
                    <Grid container mt={3}>
                      <Grid item md={4}>
                        <FormControl>
                          <FormLabel disabled>{t('contractType')}</FormLabel>
                          <RadioGroup row>
                            <FormControlLabel
                              control={
                                <Radio
                                  onChange={() => setHasEndDate(true)}
                                  checked={hasEndDate}
                                  {...fieldsProps}
                                />
                              }
                              label={t('limited')}
                            />
                            <FormControlLabel
                              control={
                                <Radio
                                  onChange={() => setHasEndDate(false)}
                                  checked={!hasEndDate}
                                  {...fieldsProps}
                                />
                              }
                              label={t('unlimited')}
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid item md={3}>
                        <DesktopDatePicker
                          label={`${t('startContract')} *`}
                          value={watch('startDate') ? watch('startDate') : null}
                          onChange={(date) => setValue('startDate', date, { shouldValidate: true })}
                          maxDate={watch('endDate') ? watch('endDate') : undefined}
                          TransitionComponent={Fade}
                          renderInput={(params) => <TextField
                            {...params}
                            size='small'
                            error={Boolean(errors?.startDate?.message)}
                            helperText={errors?.startDate?.message}
                            InputLabelProps={{ shrink: true }}
                          />}
                          {...fieldsProps}
                        />
                      </Grid>
                      {hasEndDate && (
                        <Grid item md={3}>
                          <DesktopDatePicker
                            label={`${t('endContract')}`}
                            value={watch('endDate') ? watch('endDate') : null}
                            onChange={(date) => setValue('endDate', date, { shouldValidate: true })}
                            minDate={watch('startDate') ? watch('startDate') : undefined}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size='small'
                                InputLabelProps={{ shrink: true }}
                                error={Boolean(errors?.endDate?.message)}
                                helperText={errors?.endDate?.message}
                              />
                            )}
                            TransitionComponent={Fade}
                            {...fieldsProps}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Paper>
                </Box>
              )}
              <Stack direction='row' justifyContent='flex-end' mb={3}>
                <Button
                  variant='contained'
                  color='secondary'
                  {...(isPreview && hasBaseParamsData ? {
                    onClick: () => navigate(getNextStepRoute(propertyId))
                  } : {
                    type: 'submit',
                  })}
                >
                  {t('continue')}
                </Button>
              </Stack>
            </form>
            <Dialog
              open={confirmModalIsOpen}
              onClose={() => setConfirmModalIsOpen(false)}
              title={t('confirmInfo')}
            >
              <Typography variant='h6'>
                {t('propertyError')}
              </Typography>
              <Box mt={4}>
                <Stack direction='row' alignItems='center' spacing={2} justifyContent='center'>
                  <Button
                    variant='contained'
                    color='warning'
                    size='large'
                    fullWidth
                    onClick={() => setConfirmModalIsOpen(false)}
                  >
                    {t('back')}
                  </Button>
                  <Button
                    variant='contained'
                    color='primary'
                    type='submit'
                    size='large'
                    fullWidth
                    onClick={() => {
                      dispatch(createProperty({
                        ...submitData,
                        ...(Boolean(myUserData?.organization) && { isOrgProp: true }),
                      }, ({ data }) => {
                        navigate(getNextStepRoute(data?.property?.id));
                        setConfirmModalIsOpen(false);
                      }));
                    }}
                  >
                    {t('confirm')}
                  </Button>
                </Stack>
              </Box>
            </Dialog>
          </>
        );
      }}
    </PropertyFormLayout>
  );
};

export default PropertyBaseParams;
