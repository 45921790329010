import { createSlice } from '@reduxjs/toolkit';
import httpClient from '../api/httpClient';

const initialState = {
  rents: [],
  getRentsListIsLoading: false,
  prepareFirstRentIsLoading: false,
  markAsPaidLandlordIsLoading: false,
  addNoteIsLoading: false,
  editNoteIsLoading: false,
  deleteNoteIsLoading: false,
  sendInvoiceManuallyIsLoading: false,
  singleRent: {},
  getSingleRentIsLoading: false,
  getSemiPaidRentIsLoading: false,
  semiPaidRent: {},
  editSemiPaidRentIsLoading: false
};

export const RentSlice = createSlice({
  name: 'Rents',
  initialState,
  reducers: {
    prepareFirstRentRequesting: (state) => {
      state.prepareFirstRentIsLoading = true;
    },
    prepareFirstRentSuccess: (state) => {
      state.prepareFirstRentIsLoading = false;
    },
    prepareFirstRentError: (state) => {
      state.prepareFirstRentIsLoading = false;
    },
    markAsPaidLandlordRequesting: (state) => {
      state.markAsPaidLandlordIsLoading = true;
    },
    markAsPaidLandlordSuccess: (state) => {
      state.markAsPaidLandlordIsLoading = false;
    },
    markAsPaidLandlordError: (state) => {
      state.markAsPaidLandlordIsLoading = false;
    },
    getRentListRequesting: (state) => {
      state.getRentsListIsLoading = true;
    },
    getRentListSuccess: (state, { payload }) => {
      state.getRentsListIsLoading = false;
      state.rents = payload;
    },
    getRentListError: (state) => {
      state.getRentsListIsLoading = false;
    },
    addNoteRequesting: (state) => {
      state.addNoteIsLoading = true;
    },
    addNoteSuccess: (state, { payload }) => {
      state.addNoteIsLoading = false;
      if (payload?.data?.success) {
        const updatedItem = payload?.data?.rent;
        state.rents = state.rents.map((item) => {
          if (item.id === updatedItem?.id) {
            return updatedItem;
          }
          return item;
        });
      }
    },
    addNoteError: (state) => {
      state.addNoteIsLoading = false;
    },
    editNoteRequesting: (state) => {
      state.editNoteIsLoading = true;
    },
    editNoteSuccess: (state, { payload }) => {
      state.editNoteIsLoading = false;
      const updatedItem = payload;
      state.rents = state.rents.map((item) => {
        if (item.id === updatedItem?.id) {
          return updatedItem;
        }
        return item;
      });
    },
    editNoteError: (state) => {
      state.editNoteIsLoading = false;
    },
    deleteNoteRequesting: (state) => {
      state.editNoteIsLoading = true;
    },
    deleteNoteSuccess: (state, { payload }) => {
      state.editNoteIsLoading = false;
      const updatedItem = payload;
      state.rents = state.rents.map((item) => {
        if (item.id === updatedItem?.id) {
          return updatedItem;
        }
        return item;
      });
    },
    deleteNoteError: (state) => {
      state.editNoteIsLoading = false;
    },
    sendInvoiceManuallyRequesting: (state) => {
      state.sendInvoiceManuallyIsLoading = true;
    },
    sendInvoiceManuallySuccess: (state, { payload }) => {
      state.sendInvoiceManuallyIsLoading = false;
      const updatedItem = payload;
      state.rents = state.rents.map((item) => {
        if (item?.invoice?.id === updatedItem?.id) {
          return { ...item, invoice: updatedItem };
        }
        return item;
      });
    },
    sendInvoiceManuallyError: (state) => {
      state.sendInvoiceManuallyIsLoading = false;
    },
    getSingleRentRequesting: (state) => {
      state.getSingleRentIsLoading = true;
    },
    getSingleRentSuccess: (state, { payload }) => {
      state.getSingleRentIsLoading = false;
      state.singleRent = payload;
    },
    getSingleRentError: (state) => {
      state.getSingleRentIsLoading = false;
    },
    getSemiPaidRentRequesting: (state) => {
      state.getSemiPaidRentIsLoading = true;
    },
    getSemiPaidRentSuccess: (state, { payload }) => {
      state.getSemiPaidRentIsLoading = false;
      state.semiPaidRent = payload;
    },
    getSemiPaidRentError: (state) => {
      state.getSemiPaidRentIsLoading = false;
    },
    editSemiPaidRentRequesting: (state) => {
      state.editSemiPaidRentIsLoading = true;
    },
    editSemiPaidRentSuccess: (state, { payload }) => {
      state.editSemiPaidRentIsLoading = false;
    },
    editSemiPaidRentError: (state) => {
      state.editSemiPaidRentIsLoading = false;
    },
  }
});

export const {
  prepareFirstRentRequesting,
  prepareFirstRentSuccess,
  prepareFirstRentError,
  markAsPaidLandlordRequesting,
  markAsPaidLandlordSuccess,
  markAsPaidLandlordError,
  getRentListRequesting,
  getRentListSuccess,
  getRentListError,
  addNoteRequesting,
  addNoteSuccess,
  addNoteError,
  editNoteRequesting,
  editNoteSuccess,
  editNoteError,
  deleteNoteRequesting,
  deleteNoteSuccess,
  deleteNoteError,
  sendInvoiceManuallyRequesting,
  sendInvoiceManuallySuccess,
  sendInvoiceManuallyError,
  getSingleRentRequesting,
  getSingleRentSuccess,
  getSingleRentError,
  getSemiPaidRentRequesting,
  getSemiPaidRentSuccess,
  getSemiPaidRentError,
  editSemiPaidRentRequesting,
  editSemiPaidRentSuccess,
  editSemiPaidRentError
} = RentSlice.actions;

export const prepareFirstRent = (data, onSuccess) => async (dispatch) => {
  dispatch(prepareFirstRentRequesting());
  httpClient.post('/properties/prepare-rent', data).then(
    (response) => {
      dispatch(prepareFirstRentSuccess(response?.data));
      if (onSuccess) {
        onSuccess(response);
      }
    },
    (error) => dispatch(prepareFirstRentError(error)),
  );
};

export const markAsPaidLandlord = (data, onSuccess, propertyId) => async (dispatch) => {
  dispatch(markAsPaidLandlordRequesting());
  httpClient.post('/properties/rents/pay/landlord', data).then(
    (response) => {
      dispatch(markAsPaidLandlordSuccess(response?.data?.rent));
      if (onSuccess) {
        onSuccess();
      }
    },
    (error) => dispatch(markAsPaidLandlordError(error)),
  );
};

export const getRentList = (id) => async (dispatch) => {
  dispatch(getRentListRequesting());
  httpClient.get(`/properties/rents/${id}?org=true`).then(
    (response) => {
      dispatch(getRentListSuccess(response?.data?.rents));
    },
    (error) => dispatch(getRentListError(error)),
  );
};

export const addNote = (data, id) => async (dispatch) => {
  dispatch(addNoteRequesting());
  httpClient.put('/properties/rents/note', data).then(
    (response) => {
      dispatch(addNoteSuccess(response));
    },
    (error) => dispatch(addNoteError(error)),
  );
};

export const editNote = (data) => async (dispatch) => {
  dispatch(editNoteRequesting());
  httpClient.put('/properties/rents/note', data).then(
    (response) => {
      dispatch(editNoteSuccess(response?.data?.rent));
    },
    (error) => dispatch(editNoteError(error)),
  );
};

export const deleteNote = (data) => async (dispatch) => {
  dispatch(deleteNoteRequesting());
  httpClient.put('/properties/rents/note', data).then(
    (response) => {
      dispatch(deleteNoteSuccess(response?.data?.rent));
    },
    (error) => dispatch(deleteNoteError(error)),
  );
};

export const sendInvoiceManually = (invoiceId, onSuccess) => async (dispatch) => {
  dispatch(sendInvoiceManuallyRequesting());
  httpClient.post(`/properties/rents/send-invoice/${invoiceId}`).then(
    (response) => {
      dispatch(sendInvoiceManuallySuccess(response?.data?.invoice));
      if (onSuccess) {
        onSuccess(response?.data);
      }
    },
    (error) => dispatch(sendInvoiceManuallyError(error)),
  );
};

export const getSingleRent = (rentId) => async (dispatch) => {
  dispatch(getSingleRentRequesting());
  httpClient.get(`/properties/rents/details/${rentId}`).then(
    (response) => {
      dispatch(getSingleRentSuccess(response?.data?.rent));
    },
    (error) => dispatch(getSingleRentError(error)),
  );
};

export const getSemiPaidRent = (paymentId) => async (dispatch) => {
  dispatch(getSemiPaidRentRequesting());
  httpClient.get(`/properties/rents/payment-history/${paymentId}`).then(
    (response) => {
      dispatch(getSemiPaidRentSuccess(response?.data?.payment));
    },
    (error) => dispatch(getSemiPaidRentError(error)),
  );
};

export const editSemiPaidRent = (paymentId, data, onSuccess) => async (dispatch) => {
  dispatch(editSemiPaidRentRequesting());
  httpClient.put(`/properties/rents/payment-history/${paymentId}`, data).then(
    (response) => {
      dispatch(editSemiPaidRentSuccess(response?.data));
      if (onSuccess) {
        onSuccess();
      }
    },
    (error) => dispatch(editSemiPaidRentError(error)),
  );
};


export default RentSlice.reducer;
