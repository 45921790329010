
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Stack,
  Typography,
  IconButton,
  Button,
  Box,
  Grid
} from '@mui/material';
import io from 'socket.io-client';
import moment from 'moment';
import { useTheme } from '@mui/material/styles';
import { formatDate, formatAmount, formatPayDate } from 'utils';
import {
  ActionsIcon,
  AddFolderIcon,
  EditIcon,
  ReloadIcon,
  EmptyStateIcon
} from 'icons';
import { Table, ContentBox, MenuList, OverflowContent, Dialog, Loader } from 'components';
import { getSingleProperty } from 'stores/PropertiesSlice';
import { getUtilityBills, checkUtilityBillsForPayment } from 'stores/UtilityBillsSlice';
import { useTranslation } from 'react-i18next';

const getPaidBills = (utilityBills) => {
  const paymentsHistory = [];

  utilityBills?.forEach(bill => {
    bill?.payments?.forEach((payment) => {
      if (payment.status === 'paid' || payment.status === 'processing' || payment.isPaid) {
        paymentsHistory.push({
          ...payment,
          currency: 'bgn',
          type: bill?.utilityType?.type,
          label: bill?.utilityType?.label,
          provider: bill?.provider?.label,
          clientNumber: bill?.clientNumber,
        });
      }
    });
  });

  return paymentsHistory;
};

const BillsList = () => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { propertyId } = useParams();
  const [billsAreLoading, setBillsAreLoading] = useState(false);
  const [socket, setSocket] = useState(null);

  const columns = [
    {
      Header: t('batch'),
      accessor: 'utilityType.label',
    },
    {
      Header: t('аccumulatedAmount'),
      accessor: 'amount',
      Cell: ({ value, row }) => {
        const isCustom = Boolean(row?.original?.isCustom);
        const billIsPaid = Boolean(row?.original?.automatic?.isPaid);
        const priceToBePaid = row?.original?.payments?.filter(({ status }) => status === 'unpaid');
        const actualPrice = priceToBePaid[priceToBePaid.length - 1]?.price;
        if (value) {
          if (billIsPaid) {
            return (<div>{formatAmount(0, i18n?.language)}</div>);
          } else {
            return <div>{formatAmount(value, i18n?.language)}</div>;
          }
        } else {
          if (!isCustom && actualPrice) {
            return <div>{formatAmount(actualPrice, i18n?.language)}</div>;
          } else {
            return <div>{formatAmount(0, i18n?.language)}</div>;
          }
        }
      },
    },
    {
      Header: t('cameOutOn'),
      accessor: 'payDate',
      Cell: ({ value, row }) => {
        if (value) {
          return <div>{`${t('automaticPaymentAt')}${formatDate(value, 'dd.MM.yyyy')}`}</div>;
        } else {
          if (row?.original?.checkedByDate) {
            return <div>{formatDate(row?.original?.checkedByDate, 'dd.MM.yyyy')}</div>;
          } else {
            return <div>--</div>;
          }
        }
      },
    },
    {
      Header: t('provider'),
      accessor: 'automatic',
      Cell: ({ row }) => {
        const isCustomHolder = Boolean(row?.original?.automatic?.holder);
        if (isCustomHolder) {
          return <div>{row?.original?.automatic?.holder}</div>;
        } else {
          return <div>{row?.original?.provider?.label}</div>;
        }
      }
    },
  ];

  const {
    utilityBillsState: { utilityBillsList, getUtilityBillsIsLoading, totalUnpaidAmount },
    propertiesState: { singleProperty }
  } = useSelector(({ utilityBillsState, authenticationState, propertiesState }) => ({ utilityBillsState, authenticationState, propertiesState }));

  const hasUtilityBills = Boolean(utilityBillsList?.length);
  const paymentsHistory = getPaidBills(singleProperty?.utilityBills ?? []);
  const reversedPaymentHistory = [...paymentsHistory].reverse();

  const socketInit = ({ room, shouldRedirect }) => {
    const newSocket = io(process.env.REACT_APP_RPA, {
      query: `room=${room}&token=fd3242_012DDdDaAdW1212123!111MEXU5____2020-cOR0n444`,
      transports: ['websocket'],
    });
    if (newSocket && room) {
      newSocket.on('socket-message', ({ action }) => {
        if (action === 'bots/FINISHED') {
          dispatch(getUtilityBills(propertyId, () => {
            setBillsAreLoading(false);
            dispatch(getSingleProperty(propertyId));
            dispatch(getUtilityBills(propertyId, () => {
              if (shouldRedirect) {
                navigate(`/properties/${propertyId}/confirm-payments`);
              }
            }));
          }));
          newSocket?.disconnect();
        }
      });

      setSocket(() => newSocket);
    }
  };

  const checkUtilityBills = ({ shouldRedirect }) => {
    setBillsAreLoading(true);
    dispatch(checkUtilityBillsForPayment({
      bills: utilityBillsList?.map((item) => item._id?.toString()).filter(x => x),
    }, (response) => {
      socketInit({
        room: response?.data?.room,
        shouldRedirect,
      });
    }));
  };

  const getLastCheck = () => {
    let lastCheck = null;
    // TODO: Here before was singleProperty?.utilityBills
    utilityBillsList.forEach(item => {
      if (item?.checkedByDate && lastCheck) {
        lastCheck = moment(item?.checkedByDate).isAfter(moment(lastCheck)) ? item?.checkedByDate : lastCheck;
        return;
      }
      if (item.checkedByDate) {
        lastCheck = item.checkedByDate;
      }
    });
    if (lastCheck && moment(lastCheck).isAfter(moment())) {
      lastCheck = moment().subtract(30, 'seconds');
    }
    return lastCheck;
  };

  const lastCheck = getLastCheck();
  const formattedDate = moment(lastCheck).format('DD.MM.YYYY | HH:mm');
  const invalidDate = Boolean(formattedDate === 'Invalid date');
  const hasUnpaidAmount = Boolean(totalUnpaidAmount);

  return (
    <OverflowContent>
      <Stack direction='row' alignItems='center' justifyContent='space-between' mb={1}>
        <Typography variant='subtitle1'>{t('addedBatches')}</Typography>
        <Stack direction='row' spacing={2} alignItems='center'>
          <Typography>
            {!invalidDate ? `${t('lastActualization')}: ${formattedDate}` : '--'}
          </Typography>
          <IconButton
            onClick={() => checkUtilityBills({ shouldRedirect: false })}>
            <ReloadIcon />
          </IconButton>
          <MenuList
            trigger={({ handleOpen }) => (
              <IconButton onClick={handleOpen}>
                <ActionsIcon
                  style={{ width: 16, height: 16 }}
                />
              </IconButton>
            )}
            items={[
              {
                label: t('addBatch'),
                description: t('addedBatchesText'),
                icon: AddFolderIcon,
                onClick: () => navigate(`/properties/${propertyId}/add-payment-bills`)
              },
              {
                label: t('editAdded'),
                description: t('editAddedText'),
                icon: EditIcon,
                onClick: () => navigate(`/properties/${propertyId}/edit-payment-bills`)
              }
            ]}
          />
        </Stack>
      </Stack>
      {getUtilityBillsIsLoading && (
        <OverflowContent.Loader />
      )}

      {(!getUtilityBillsIsLoading && !hasUtilityBills) ? (
        <Box mt={10} textAlign='center'>
          <EmptyStateIcon />
          <Typography variant='h6'>{t('welcomeToUtilityBills')}</Typography>
          <Typography variant='h6' mt={2}>{t('trackBills')}</Typography>
          <Typography variant='h6'>{t('startAddingBatches')}</Typography>
          <Box mt={3}>
            <Button
              variant='contained'
              color='primary'
              size='large'
              onClick={() => navigate(`/properties/${propertyId}/add-payment-bills`)}
            >
              {t('addNew')}
            </Button>
          </Box>
        </Box>
      ) : (
        // TODO: Maybe here for the table will be used singleProperty?.utilityBills.
        <>
          <Box>
            <ContentBox contentHeight='250px' scrollable>
              <Table
                height='auto'
                data={utilityBillsList ?? []}
                columns={columns}
              />
            </ContentBox>
          </Box>
          <Box p={1} sx={{ backgroundColor: theme.palette.info.main, borderRadius: 2 }}>
            <Stack direction='row' alignItems='center' justifyContent='space-between'>
              <Typography variant='subtitle2' textTransform='uppercase'>{t('totalAmount')}</Typography>
              <Stack direction='row' alignItems='center' spacing={1}>
                <Typography variant='subtitle2'>
                  {hasUnpaidAmount ? formatAmount(totalUnpaidAmount, i18n?.language) : '--'}
                </Typography>
                <Button
                  variant='contained'
                  color='primary'
                  disabled={billsAreLoading || !Boolean(totalUnpaidAmount)}
                  onClick={() => {
                    const date1 = new Date();
                    const date2 = new Date(lastCheck);
                    const dispatchBot = date1 - date2 > 5 * 60 * 1000 ? true : false;

                    if (dispatchBot) {
                      checkUtilityBills({ shouldRedirect: true });
                    } else {
                      navigate(`/properties/${propertyId}/confirm-payments`);
                    }
                  }}
                >
                  {t('payment')}
                </Button>
              </Stack>
            </Stack>
          </Box>
          <Box mt={2}>
            <Stack direction='row' alignItems='center' justifyContent='space-between'>
              <Typography variant='subtitle1'>{t('history')}</Typography>
            </Stack>
          </Box>
          {reversedPaymentHistory?.map(({ label, price, updatedAt }, index) => (
            <Box key={`${index}-${price}`} mt={3} p={1} sx={{
              border: `1px solid ${theme.palette.info.main}`,
              borderRadius: 1,
            }}>
              <Stack direction='row' justifyContent='flex-start'>
                <Typography variant='subtitle1'>{label}</Typography>
              </Stack>
              <Grid container mt={1}>
                <Grid item md={3}>
                  <Typography variant='caption'>{t('paidOn')}</Typography>
                  <Typography variant='subtitle2'>{formatDate(updatedAt, 'dd.MM.yyyy')}</Typography>
                </Grid>
                <Grid item md={3}>
                  <Typography variant='caption'>{t('paidAmount')}</Typography>
                  <Typography variant='subtitle2'>{formatAmount(price, i18n?.language)}</Typography>
                </Grid>
                <Grid item md={3}>
                  <Typography variant='caption'>{t('paymentWay')}</Typography>
                  <Typography variant='subtitle2'>--</Typography>
                </Grid>
                <Grid item md={3}>
                  <Typography variant='caption'>{t('uploadFiles')}</Typography>
                  <Typography variant='subtitle2'>--</Typography>
                </Grid>
              </Grid>
            </Box>
          ))}
        </>
      )
      }
      <Dialog title={t('actualizeData')} open={billsAreLoading}>
        <Loader />
        <Typography mt={4} mb={4} variant='h6'>
          {t('pleaseWait')}
        </Typography>
        <Button
          fullWidth
          variant='contained'
          color='warning'
          onClick={() => {
            setBillsAreLoading(false);
            socket?.disconnect();
          }}
        >
          {t('stop')}
        </Button>
      </Dialog>
    </OverflowContent>
  );
};

export default BillsList;