import { useState, useEffect, useMemo } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';
import {
  Paper,
  Typography,
  Box,
  Stack,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  useTheme,
  Button
} from '@mui/material';
import { toast } from 'react-toastify';
import { useMeasure } from 'react-use';
import InfoIcon from '@mui/icons-material/Info';
import isAfter from 'date-fns/isAfter';
import {
  formatAmount,
  displayDays,
  formatPayDate,
  formatDate,
  getDaysBetweenDates
} from 'utils';
import { DefaultLayout } from 'layouts';
import {
  ArrowBackIcon,
  ActionsIcon,
  ArrowIcon,
  EditIcon,
  TenantIcon,
  EditRentIcon,
  RemoveTenantIcon,
  RentHistoryIcon
} from 'icons';
import { MenuList, Dialog } from 'components';
import {
  PropertiesBillsList,
  PropertiesRentList,
  PropertiesCasesList,
  PropertiesCommunicatorList,
  PropertiesFilesList,
  PropertiesActivitiesList
} from 'components/Properties';
import { getSingleProperty, removeTenant, getTenantHistory } from 'stores/PropertiesSlice';
import { getUtilityBills } from 'stores/UtilityBillsSlice';
import { getRentList } from 'stores/RentSlice';
import { getPropertyActivity } from 'stores/DashboardSlice';
import { useTranslation } from 'react-i18next';

const ItemButton = styled(ListItem)(({ theme }) => `
  border: 1px solid ${theme.palette.info.main};
  border-radius: 5px;
  margin: 15px 0;
`);

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const PropertiesDetailView = () => {
  const { t, i18n } = useTranslation();
  let { propertyId } = useParams();
  let query = useQuery();
  const activeTabQuery = query.get('activeTab');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [leftColRef, { height: leftColHeight }] = useMeasure();
  const [removeTenantDialogIsOpen, setRemoveTenantDialogIsOpen] = useState(false);

  const printPropertyValue = (type) => {
    if (type === 'office') {
      return <div>{t('office')}</div>;
    } else if (type === 'warehouse') {
      return <div>{t('warehouse')}</div>;
    } else if (type === 'house') {
      return <div>{t('house')}</div>;
    } else if (type === 'apartment') {
      return <div>{t('apartment')}</div>;
    } else if (type === 'room') {
      return <div>{t('room')}</div>;
    } else {
      return <div>{t('houseFloor')}</div>;
    }
  };

  const {
    propertiesState: { singleProperty, tenantHistory },
    utilityBillsState: { totalUnpaidAmount },
    rentState: { rents },
    dashboardState: { singlePropertyActivity }
  } = useSelector(({ propertiesState, utilityBillsState, rentState, dashboardState }) => ({ propertiesState, utilityBillsState, rentState, dashboardState }));
  const hasOneCase = singleProperty?.openCasesCount === 1;
  const hasDeposit = Boolean(singleProperty?.deposit >= 0);
  const hasOneOpenedRoom = singleProperty?.openRoomsCount?.length === 1;
  const rentPaymentDueDate = rents?.find(({ paid }) => paid === false)?.dueDate;
  const hasUnpaidRent = Boolean(rentPaymentDueDate);
  const isLatePayment = Boolean(isAfter(new Date(), new Date(rentPaymentDueDate)));
  const dueDateDifference = getDaysBetweenDates(new Date(), new Date(rentPaymentDueDate));
  const dueDateIsToday = Boolean(dueDateDifference === 1);

  useEffect(() => {
    if (propertyId) {
      dispatch(getSingleProperty(propertyId));
      dispatch(getUtilityBills(propertyId));
      dispatch(getRentList(propertyId));
      dispatch(getPropertyActivity(propertyId));
      dispatch(getTenantHistory(propertyId));
    }
  }, [propertyId]);

  const TABS = [
    {
      id: 'monthlyRent',
      name: t('monthlyRent'),
      status: dueDateIsToday ? t('paymentHasToBeToday') : hasUnpaidRent ? `${isLatePayment ? t('dueWith') : t('paymentAfter')} ${displayDays(dueDateDifference)}` : t('doesntHaveBills'),
      component: PropertiesRentList,
      isDangerous: isLatePayment
    },
    {
      id: 'paymentBills',
      name: t('utilityBills'),
      status: `${totalUnpaidAmount ? t('hasBills') : t('doesntHaveBills')}`,
      component: PropertiesBillsList,
      isDangerous: totalUnpaidAmount
    },
    {
      id: 'cases',
      name: t('cases'),
      status: hasOneCase ? t('oneOpenCase') : `${singleProperty?.openCasesCount} ${t('openedCases')}`,
      component: PropertiesCasesList
    },
    {
      id: 'communicator',
      name: t('communicator'),
      status: hasOneOpenedRoom ? t('oneOpenTheme') : `${singleProperty?.openRoomsCount} ${t('openedThemes')}`,
      component: PropertiesCommunicatorList
    },
    {
      id: 'files',
      name: t('files'),
      status: singleProperty?.files?.length === 1 ? `1 ${t('file')}` : `${singleProperty?.files?.length} ${t('files')}`,
      component: PropertiesFilesList
    },
    {
      id: 'activity',
      name: t('activity'),
      status: singlePropertyActivity?.totalDocs ? `${singlePropertyActivity?.totalDocs} ${t('activities')}` : t('noActivity'),
      component: PropertiesActivitiesList
    },
  ];

  const [activeTabId, setActiveTabId] = useState(TABS[0].id);
  const ActiveTabComponent = TABS.find(({ id }) => id === activeTabId).component;
  const [leftSideDisabled, setLeftSideDisabled] = useState(false);

  useEffect(() => {
    const activeTabQueryId = TABS.find(({ id }) => id === activeTabQuery)?.id;
    if (activeTabQueryId) {
      setActiveTabId(activeTabQueryId);
    }
  }, [activeTabQuery]);

  const hasTenantData = Boolean(singleProperty?.tenant);
  const noTenantHistory = Boolean(!hasTenantData && !tenantHistory?.length);

  return (
    <DefaultLayout>
      <Grid container mb={5}>
        <Grid item md={5}>
          <div ref={leftColRef} style={{ pointerEvents: leftSideDisabled ? 'none' : '', opacity: leftSideDisabled ? '0.4' : '' }}>
            <Paper elevation={4}>
              <Box p={1}>
                <Stack direction='row' alignItems='center' justifyContent='space-between'>
                  <Stack direction='row' spacing={1}>
                    <IconButton onClick={() => navigate('/properties')}>
                      <ArrowBackIcon
                        style={{ width: 16, height: 16 }}
                      />
                    </IconButton>
                    <Typography variant='h5'>{singleProperty?.nicknames?.landlord}</Typography>
                  </Stack>
                  {hasTenantData ? (
                    <MenuList
                      trigger={({ handleOpen }) => (
                        <IconButton onClick={handleOpen}>
                          <ActionsIcon
                            style={{ width: 16, height: 16 }}
                          />
                        </IconButton>
                      )}
                      items={[{
                        label: t('edit'),
                        description: t('editPropertyText'),
                        icon: EditIcon,
                        onClick: () => navigate(`/properties/${propertyId}/edit`)
                      },
                      {
                        label: t('editRentData'),
                        description: t('editRentDataDescription'),
                        icon: EditRentIcon,
                        onClick: () => navigate(`/properties/${propertyId}/edit-rent`)
                      },
                      {
                        label: t('editTenantData'),
                        description: t('editTenantDataDescription'),
                        icon: TenantIcon,
                        onClick: () => navigate(`/properties/${propertyId}/edit-tenant`)
                      },
                      {
                        label: t('removeTenant'),
                        description: t('removeTenantDescription'),
                        icon: RemoveTenantIcon,
                        onClick: () => setRemoveTenantDialogIsOpen(true)
                      },
                      {
                        label: t('rentHistory'),
                        description: t('rentHistoryDescription'),
                        icon: RentHistoryIcon,
                        onClick: () => navigate(`/properties/${propertyId}/tenant-history`)
                      },
                      ]}
                    />
                  ) : (
                    <MenuList
                      trigger={({ handleOpen }) => (
                        <IconButton onClick={handleOpen}>
                          <ActionsIcon
                            style={{ width: 16, height: 16 }}
                          />
                        </IconButton>
                      )}
                      items={[{
                        label: t('edit'),
                        description: t('editPropertyText'),
                        icon: EditIcon,
                        onClick: () => navigate(`/properties/${propertyId}/edit`)
                      },
                      {
                        label: t('addTenant'),
                        description: t('addTenantToProperty'),
                        icon: TenantIcon,
                        onClick: () => navigate(`/properties/${propertyId}/add-tenant/rent-contract`)
                      },
                      {
                        value: 'rentHistory',
                        label: t('rentHistory'),
                        description: t('rentHistoryDescription'),
                        icon: RentHistoryIcon,
                        onClick: () => navigate(`/properties/${propertyId}/tenant-history`)
                      }
                      ].filter(({ value }) => value !== 'rentHistory' || !noTenantHistory)}
                    />
                  )}
                </Stack>
                <Box mt={2}>
                  {hasTenantData ? (
                    <Paper variant='outlined'>
                      <Grid container>
                        <Grid item md={3}>
                          <Typography variant='caption'>{t('rent')}</Typography>
                          <Typography variant='subtitle2'>{formatAmount(singleProperty?.rent, i18n?.language)}</Typography>
                        </Grid>
                        <Grid item md={3}>
                          <Typography variant='caption'>{t('paymentUntill')}</Typography>
                          <Typography variant='subtitle2'>{formatPayDate(singleProperty?.payBefore)}</Typography>
                        </Grid>
                        <Grid item md={6}>
                          <Typography variant='caption'>{t('tenant')}</Typography>
                          <Stack direction='row' alignItems='center'>
                            <Typography variant='subtitle2'>{singleProperty?.tenant?.fullName}</Typography>
                            <IconButton onClick={() => navigate(`/properties/${propertyId}/tenant-data`)} >
                              <InfoIcon style={{ color: '#fff', fill: theme.palette.primary.main }} />
                            </IconButton>
                          </Stack>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item md={3}>
                          <Typography variant='caption'>{t('deposit')}</Typography>
                          <Typography variant='subtitle2'>
                            {hasDeposit ? formatAmount(singleProperty?.deposit, i18n?.language) : formatAmount(0, i18n?.language)}
                          </Typography>
                        </Grid>
                        <Grid item md={3}>
                          <Typography variant='caption'>{t('contract')}</Typography>
                          <Typography variant='subtitle2'>{singleProperty?.startDate ? formatDate(singleProperty?.startDate, 'dd.MM.yyyy') : '--'}</Typography>
                        </Grid>
                        <Grid item md={6}>
                          <Typography variant='caption'>{t('endContract')}</Typography>
                          <Typography variant='subtitle2'>{singleProperty?.endDate ? formatDate(singleProperty?.endDate, 'dd.MM.yyyy') : '--'}</Typography>
                        </Grid>
                      </Grid>
                    </Paper>
                  ) : (
                    <Paper variant='outlined'>
                      <Typography variant='caption' textTransform='none'>
                        {t('noActiveTenant')}
                      </Typography>
                      <Box mt={2}>
                        <Typography variant='caption' textTransform='none'>
                          {t('noActiveTenantDescription')}
                        </Typography>
                      </Box>
                    </Paper>
                  )}
                </Box>
                <Box mt={2}>
                  <Paper variant='outlined'>
                    <Grid container>
                      <Grid item md={6}>
                        <Typography variant='caption'>{t('city')}</Typography>
                        <Typography variant='subtitle2'>{singleProperty?.city}</Typography>
                      </Grid>
                      <Grid item md={6}>
                        <Typography variant='caption'>{t('address')}</Typography>
                        <Stack direction='row' alignItems='center'>
                          <Typography variant='subtitle2'>{singleProperty?.address}</Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item md={6}>
                        <Typography variant='caption'>{t('propertyType')}</Typography>
                        <Typography variant='subtitle2'>{printPropertyValue(singleProperty?.propertyType?.type)}</Typography>
                      </Grid>
                      <Grid item md={6}>
                        <Typography variant='caption'>{t('size')}</Typography>
                        <Typography variant='subtitle2'>{`${singleProperty?.area} ${t('squareMeters')}`}</Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Box>
                <Box mt={2}>
                  <List>
                    {TABS.map(({ id, name, status, isDangerous = false }) => {
                      const isActive = id === activeTabId;

                      return (
                        <ItemButton
                          key={`item-${id}-${name}`}
                          disablePadding
                          id={id}
                          name={name}
                          status={status}
                          sx={{
                            backgroundColor: isActive ? theme.palette.secondary.main : 'transparent',
                            color: isActive ? '#fff' : '#000',
                          }}
                          onClick={() => {
                            setActiveTabId(id);
                            window.scrollTo({ top: 0, behavior: 'smooth' });
                          }}
                        >
                          <ListItemButton>
                            <Grid container spacing={2} alignItems='center'>
                              <Grid item xs={4}>
                                <Typography variant='subtitle1' textTransform='none'>{name}</Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography variant='caption'>{t('status')}</Typography>
                                <Typography {...isDangerous && { color: 'error' }} variant='subtitle1' textTransform='none'>{status}</Typography>
                              </Grid>
                              <Grid item xs={2}>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                  <ArrowIcon
                                    style={{
                                      width: 16,
                                      height: 16,
                                      fill: isActive ? '#fff' : '#000'
                                    }}
                                  />
                                </Box>
                              </Grid>
                            </Grid>
                          </ListItemButton>
                        </ItemButton>
                      );
                    })}
                  </List>
                </Box>
              </Box>
            </Paper>
          </div>
        </Grid>
        <Grid item md={7}>
          <Paper style={{ height: leftColHeight }} elevation={4}>
            <ActiveTabComponent {...{ propertyId, leftColHeight, setLeftSideDisabled, leftSideDisabled }} />
          </Paper>
        </Grid>
      </Grid>
      <Dialog
        open={removeTenantDialogIsOpen}
        onClose={() => setRemoveTenantDialogIsOpen(false)}
        title={t('removingTenant')}
      >
        <Typography variant='h6'>
          {t('confirmRemovingTenant')}
        </Typography>
        <Typography mt={1} variant='h6'>
          {t('confirmDeleteBatches')}
        </Typography>
        <Box mt={3}>
          <Button
            variant='contained'
            color='error'
            fullWidth
            onClick={() => {
              dispatch(removeTenant({
                propertyId
              }, () => {
                setRemoveTenantDialogIsOpen(false);
                toast.success(t('removeTenantSuccess'));
              }));
            }}
          >
            {t('removeTenant')}
          </Button>
          <Box mt={3}>
            <Button
              variant='contained'
              color='warning'
              fullWidth
              onClick={() => setRemoveTenantDialogIsOpen(false)}
            >
              {t('decline')}
            </Button>
          </Box>
        </Box>
      </Dialog>
    </DefaultLayout>
  );
};

export default PropertiesDetailView;
