
import { styled } from '@mui/material/styles';
import {
  Paper,
  Typography,
  Stack,
} from '@mui/material';

const Content = styled('div', { shouldForwardProp: false })(({ centeredContent, scrollable }) => `
  display: ${centeredContent ? 'flex' : 'block'};
  align-items: center;
  justify-content: center;
  overflow-y: ${scrollable ? 'auto' : 'hidden'};
  overflow-x: hidden;
}
`);

export const contentBoxBottomGutter = 15;
export const contentBoxHeaderHeight = 30;

const ContentBox = ({
  title,
  caption,
  children,
  iconButton,
  contentHeight,
  scrollable,
  centeredContent,
}) => (
  <Paper style={{ marginBottom: contentBoxBottomGutter }} variant='outlined'>
    {title !== undefined && (
      <Stack mb={1} height={contentBoxHeaderHeight} direction='row' justifyContent='space-between' alignItems='center'>
        <Typography variant='body2'>
          {title}
        </Typography>
        <Stack direction='row' spacing={1} alignItems='center'>
          <Typography variant='caption'>
            {caption}
          </Typography>
          {iconButton}
        </Stack>
      </Stack>
    )}
    <Content style={{ height: contentHeight }} centeredContent={centeredContent} scrollable={scrollable}>
      {children}
    </Content>
  </Paper>
);

export default ContentBox;