
const AddFilesIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20.063'
    height='23.603'
    viewBox='0 0 20.063 23.603'
    {...props}
  >
    <path
      fill='#00d297'
      d='M26.555 10.172l-5.512-5.641A1.77 1.77 0 0019.781 4H11.13a1.77 1.77 0 00-1.77 1.77v.59h-.59A1.77 1.77 0 007 8.13v2.95a.59.59 0 001.18 0V8.13a.59.59 0 01.59-.59h.59v15.932a1.77 1.77 0 001.77 1.77h12.392v.59a.59.59 0 01-.59.59H8.77a.59.59 0 01-.59-.59V13.441a.59.59 0 10-1.18 0v12.391A1.77 1.77 0 008.77 27.6h14.162a1.77 1.77 0 001.77-1.77v-.59h.59a1.77 1.77 0 001.77-1.77V11.405a1.729 1.729 0 00-.507-1.233zM21.161 6.36l4.06 4.13h-3.47a.59.59 0 01-.59-.59zm4.72 17.112a.59.59 0 01-.59.59H11.13a.59.59 0 01-.59-.59V5.77a.59.59 0 01.59-.59h8.65a.59.59 0 01.2.041V9.9a1.77 1.77 0 001.77 1.77h4.13zm-4.13-7.081a.59.59 0 010 1.18H18.8v2.95a.59.59 0 01-1.18 0v-2.95h-2.95a.59.59 0 110-1.18h2.95v-2.95a.59.59 0 111.18 0v2.95z'
      data-name='46 Add Files'
      transform='translate(-7 -4)'
    ></path>
  </svg>
);

export default AddFilesIcon;
