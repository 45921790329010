import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Stack,
  Box,
  IconButton,
  Typography,
  Paper,
  Grid,
  TextField,
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
} from '@mui/material';
import * as Yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { Select } from 'components';
import { ArrowBackIcon } from 'icons';
import { DefaultLayout } from 'layouts';
import { getPropertiesList } from 'stores/PropertiesSlice';
import { getPaymentLocalizationDetails, createPaymentLocalization } from 'stores/FinancesSlice';

const PaymentLocalizationForm = ({ isEdit }) => {
  const { t } = useTranslation();
  const { financeId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isRentLocalization, setRentLocalization] = useState(true);
  const {
    propertiesState: { propertiesList },
    financesState: { paymentLocalizationDetails }
  } = useSelector(({ propertiesState, financesState }) => ({ propertiesState, financesState }));

  const validations = Yup.object().shape({
    property: Yup.string().required(t('propertyRequired')),
    title: Yup.string().required(t('titleRequired')),
    ...(isRentLocalization && {
      invoice: Yup.string().required(t('invoiceRequired'))
    })
  });

  useEffect(() => {
    dispatch(getPropertiesList({ isOrg: true }));
    dispatch(getPaymentLocalizationDetails());
  }, []);

  const {
    formState: { errors },
    control,
    register,
    handleSubmit,
    watch
  } = useForm({
    resolver: yupResolver(validations),
  });

  return (
    <DefaultLayout>
      <form onSubmit={handleSubmit((data) => {
        const submitData = {
          propertyId: data?.property,
          title: data?.title,
          ...(isRentLocalization && {
            rentId: data?.invoice
          })
        };
        dispatch(createPaymentLocalization(financeId, submitData, () => {
          navigate('/finances/bank');
          toast.success(t('successfulLocalization'));
        }));
      })}>
        <Paper elevation={4}>
          <Stack direction='row' alignItems='center' justifyContent='space-between'>
            <Stack direction='row' alignItems='center' spacing={2}>
              <IconButton onClick={() => navigate('/finances/bank')}>
                <ArrowBackIcon style={{ width: 16, height: 16 }} />
              </IconButton>
              <Typography variant='h5'>{isEdit ? t('edinOnPayment') : t('locatePayment')}</Typography>
            </Stack>
            {isEdit ? (
              <Stack direction='row' alignItems='center' spacing={2}>
                <Button variant='contained' color='error'>{t('delete')}</Button>
                <Button variant='contained' color='primary'>{t('save')}</Button>
              </Stack>
            ) : (
              <Button variant='contained' color='primary' type='submit'>{t('save')}</Button>
            )}
          </Stack>
        </Paper>
        <Box mt={3}>
          <Paper elevation={4}>
            <Typography variant='subtitle1'>{t('forPayment')}</Typography>
            <Typography variant='caption' textTransform='none'>
              {t('forPaymentText')}
            </Typography>
            <Grid container mt={2}>
              <Grid item md={2}>
                <FormControl sx={{ marginTop: '-15px' }}>
                  <FormLabel disabled>{t('hasRent')}</FormLabel>
                  <RadioGroup
                    row
                  >
                    <FormControlLabel
                      label={t('rent')}
                      value='наем'
                      control={
                        <Radio
                          checked={isRentLocalization}
                          onChange={() => setRentLocalization(true)}
                        />
                      }
                    />
                    <FormControlLabel
                      label={t('other')}
                      value='друго'
                      control={
                        <Radio
                          checked={!isRentLocalization}
                          onChange={() => setRentLocalization(false)}
                        />
                      }
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item md={3}>
                <Controller
                  control={control}
                  name='property'
                  render={({
                    field: { onChange, value },
                  }) => (
                    <Select
                      label={`${t('property')}*`}
                      size='small'
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      onChange={onChange}
                      value={value}
                      error={Boolean(errors?.property?.message)}
                      helperText={errors?.property?.message}
                      options={propertiesList?.map((property) => ({
                        label: property?.nicknames?.landlord ?? property?.nicknames?.owner,
                        value: property?._id,
                      }))}
                    />
                  )}
                />
              </Grid>
              <Grid item md={3}>
                <TextField
                  label={`${t('hasRent')}*`}
                  size='small'
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  {...register('title')}
                  error={Boolean(errors?.title?.message)}
                  helperText={errors?.title?.message}
                />
              </Grid>
              {isRentLocalization && (
                <Grid item md={3}>
                  <Controller
                    control={control}
                    name='invoice'
                    render={({
                      field: { onChange, value },
                    }) => (
                      <Select
                        key={value}
                        label={`${t('invoice')}*`}
                        size='small'
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        onChange={onChange}
                        value={value}
                        error={Boolean(errors?.invoice?.message)}
                        helperText={errors?.invoice?.message}
                        options={paymentLocalizationDetails?.filter((item) => item?._id === watch('property'))[0]?.rentPayments?.map((rent) => ({
                          label: `${t('invoice')} ${rent?.invoice?.invoiceNumber} - ${t('m')}${t(rent?.month)}`,
                          value: rent?._id,
                        }))}
                      />
                    )}
                  />
                </Grid>
              )}
            </Grid>
          </Paper>
        </Box>
      </form>
    </DefaultLayout>
  );
};
export default PaymentLocalizationForm;