
const ArrowUpIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16.027'
    height='9.35'
    viewBox='0 0 16.027 9.35'
    {...props}
  >
    <path
      fill='#0e1833'
      d='M8.014 9.35a1.212 1.212 0 01-.935-.4L.4 2.271A1.291 1.291 0 01.4.4a1.291 1.291 0 011.87 0l6.679 6.679a1.291 1.291 0 010 1.87 1.212 1.212 0 01-.935.4z'
      data-name='Path 55392'
      transform='translate(6.678)'
    ></path>
    <path
      fill='#0e1833'
      d='M1.336 9.35a1.212 1.212 0 01-.935-.4 1.291 1.291 0 010-1.87L7.079.4a1.291 1.291 0 011.87 0 1.291 1.291 0 010 1.87L2.271 8.949a1.212 1.212 0 01-.935.4z'
      data-name='Path 55393'
    ></path>
  </svg>
);

export default ArrowUpIcon;
