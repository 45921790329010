import React from 'react';

const ListIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='22.763'
    height='20.031'
    viewBox='0 0 22.763 20.031'
    {...props}
  >
    <path
      fill='#00d297'
      d='M3 7.732A2.732 2.732 0 105.732 5 2.732 2.732 0 003 7.732m8.195-1.821h14.568v3.642H11.195zM3 15.016a2.732 2.732 0 102.732-2.732A2.732 2.732 0 003 15.016m8.195-1.821h14.568v3.642H11.195zM3 22.3a2.732 2.732 0 102.732-2.732A2.732 2.732 0 003 22.3m8.195-1.821h14.568v3.642H11.195z'
      transform='translate(-3 -5)'
    ></path>
  </svg>
);

export default ListIcon;
