const printMonth = (month) => {
  if (month === 'January') {
    return window?.i18n?.t('January');
  } else if (month === 'February') {
    return window?.i18n?.t('February');
  } else if (month === 'March') {
    return window?.i18n?.t('March');
  } else if (month === 'April') {
    return window?.i18n?.t('April');
  } else if (month === 'May') {
    return window?.i18n?.t('May');
  } else if (month === 'June') {
    return window?.i18n?.t('June');
  } else if (month === 'July') {
    return window?.i18n?.t('July');
  } else if (month === 'August') {
    return window?.i18n?.t('August');
  } if (month === 'September') {
    return window?.i18n?.t('September');
  } else if (month === 'October') {
    return window?.i18n?.t('October');
  } else if (month === 'November') {
    return window?.i18n?.t('November');
  } else if (month === 'December') {
    return window?.i18n?.t('December');
  }
};

export default printMonth;