
const SmallEllipseIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='33'
    height='33'
    viewBox='0 0 33 33'
    {...props}
  >
    <defs>
      <filter
        id='Ellipse_8830'
        width='33'
        height='33'
        x='0'
        y='0'
        filterUnits='userSpaceOnUse'
      >
        <feOffset dy='3'></feOffset>
        <feGaussianBlur result='blur' stdDeviation='3'></feGaussianBlur>
        <feFlood floodColor='#d31163' floodOpacity='0.161'></feFlood>
        <feComposite in2='blur' operator='in'></feComposite>
        <feComposite in='SourceGraphic'></feComposite>
      </filter>
    </defs>
    <g filter='url(#Ellipse_8830)'>
      <circle
        cx='7.5'
        cy='7.5'
        r='7.5'
        fill='#d31163'
        data-name='Ellipse 8830'
        transform='translate(9 6)'
      ></circle>
    </g>
  </svg>
);

export default SmallEllipseIcon;
