import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Stack,
  IconButton,
  Radio,
  Typography,
  CircularProgress,
  Button,
} from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { FolderIcon } from 'icons';
import { getFiles } from 'stores/FilesSlice';
import { useTranslation } from 'react-i18next';

const filterFolders = (items) => items.filter(({ type }) => type === 'folder');

const FolderItem = ({ id, name, selectedFolderId, setSelectedFolderId, propertyId, depth = 0, disabledFoldersIds, typeOfItem }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [subFolders, setSubFolders] = useState([]);
  const [hasSubFolders, setHasSubFolders] = useState(true);
  const isDisabled = disabledFoldersIds?.includes(id);
  const moveItemIsFolder = typeOfItem === 'folder';
  const disableExpand = isDisabled && moveItemIsFolder;

  useEffect(() => {
    if (propertyId && isExpanded && id) {
      setIsLoading(true);
      dispatch(getFiles({
        property: propertyId,
        dir: id,
      }, (response) => {
        setIsLoading(false);
        const items = filterFolders(response?.data?.data);
        if (items?.length) {
          setSubFolders(items);
        } else {
          setHasSubFolders(false);
        }
      }, false));
    }
  }, [propertyId, isExpanded, id]);

  return (
    <>
      <TableRow>
        <TableCell style={{ width: '90%' }}>
          <Stack direction='row' spacing={2} alignItems='center' pl={depth * 2}>
            <FolderIcon style={{ width: 28, height: 28 }} />
            <div>
              {name}
            </div>
          </Stack>
        </TableCell>
        <TableCell>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <>
              {hasSubFolders && (
                <IconButton disabled={disableExpand} onClick={() => setIsExpanded(!isExpanded)}>
                  {isExpanded ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                </IconButton>
              )}
            </>
          )}
        </TableCell>
        <TableCell align='right'>
          <Radio
            checked={selectedFolderId === id}
            onChange={() => setSelectedFolderId(id)}
            disabled={isDisabled}
          />
        </TableCell>
      </TableRow>
      {isExpanded && (
        <>
          {subFolders?.map(({ id, name, parentDir }, index) => (
            <FolderItem
              key={id}
              id={id}
              parentDir={parentDir}
              name={name}
              propertyId={propertyId}
              depth={index + 1}
              selectedFolderId={selectedFolderId}
              setSelectedFolderId={setSelectedFolderId}
              disabledFoldersIds={disabledFoldersIds}
              typeOfItem={typeOfItem}
            />
          ))}
        </>
      )}
    </>
  );
};

const FoldersList = ({ propertyId, disabledFoldersIds, onChooseFolder, onCancel, typeOfItem }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [folders, setFolders] = useState([]);
  const [selectedFolderId, setSelectedFolderId] = useState('');

  useEffect(() => {
    if (propertyId) {
      dispatch(getFiles({ property: propertyId }, (response) => {
        setFolders(filterFolders(response?.data?.data));
      }, false));
    }
  }, [propertyId]);

  return (
    <>
      <Stack direction='row' justifyContent='flex-end' spacing={1}>
        <Button
          variant='contained'
          color='warning'
          onClick={() => {
            setSelectedFolderId('');
            onCancel();
          }}
        >
          {t('cancel')}
        </Button>
        <Button
          variant='contained'
          color='primary'
          onClick={() => onChooseFolder(selectedFolderId)}
          disabled={!Boolean(selectedFolderId)}
        >
          {t('approve')}
        </Button>
      </Stack>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant='body1'>{t('name')}</Typography>
            </TableCell>
            <TableCell justify='flex-end'></TableCell>
            <TableCell justify='flex-end'></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {folders?.map(({ id, name, parentDir }) => (
            <FolderItem
              key={id}
              id={id}
              parentDir={parentDir}
              name={name}
              propertyId={propertyId}
              selectedFolderId={selectedFolderId}
              setSelectedFolderId={setSelectedFolderId}
              disabledFoldersIds={disabledFoldersIds}
              typeOfItem={typeOfItem}
            />
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default FoldersList;