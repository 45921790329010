import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Stack,
  IconButton,
  Drawer,
  Typography,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Box,
  Divider,
  Button,
  FormControl,
  RadioGroup,
  Radio
} from '@mui/material';
import { FinancesLayout } from 'layouts';
import { formatAmount, formatDate } from 'utils';
import {
  ActionsIcon,
  EditIcon,
  CrossIcon,
  ArrowUpIcon,
  DownloadFileIcon,
  CompletePaymentIcon
} from 'icons';
import { Table, MenuList, Select, SearchField, Dialog } from 'components';
import {
  setSingleArrivalOrExpense,
  setSelectedPropertyId,
  getArrivalsAndExpenses
} from 'stores/FinancesSlice';
import { getPropertiesList } from 'stores/PropertiesSlice';

const PER_PAGE = 20;

const FinancesOther = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const [propertyContentIsOpen, setPropertyContentIsOpen] = useState(true);
  const [tenantContentIsOpen, setTenantContentIsOpen] = useState(true);
  const [attachmentsDialogIsOpen, setAttachmentsDialogIsOpen] = useState(false);

  const {
    financesState: {
      arrivalsAndExpensesList,
      getArrivalAndExpensesListIsLoading,
      selectedPropertyId,
      singleArrivalOrExpense
    },
    propertiesState: { propertiesList }
  } = useSelector(({ financesState, propertiesState }) => ({ financesState, propertiesState }));

  useEffect(() => {
    dispatch(getPropertiesList({ isOrg: true }));
  }, []);

  useEffect(() => {
    dispatch(getArrivalsAndExpenses({ page: 1 }));
  }, []);

  useEffect(() => {
    if (!selectedPropertyId && propertiesList?.length) {
      dispatch(setSelectedPropertyId(propertiesList?.[0]?._id));
    }
  }, [selectedPropertyId, propertiesList]);

  const arrivalsAndExpensesColumns = [
    {
      Header: 'Вид',
      accessor: 'type',
      Cell: ({ value }) => {
        if (value === 'income') {
          return <div>{t('income')}</div>;
        } else {
          return <div>{t('outcome')}</div>;
        };
      }
    },
    {
      Header: t('name'),
      accessor: 'property.nicknames.landlord'
    },
    {
      Header: t('amount'),
      accessor: 'amount',
      Cell: ({ value }) => (
        <div>{formatAmount(value, i18n?.language)}</div>
      )
    },
    {
      Header: t('date'),
      accessor: 'payDate',
      Cell: ({ value }) => (
        <div>{formatDate(value, 'dd.MM.yyyy')}</div>
      )
    },
    {
      Header: t('basis'),
      accessor: 'title'
    },
    {
      Header: t('files'),
      accessor: 'attachments',
      Cell: ({ value, row }) => {
        const isYetToBePaid = Boolean(row?.original?.automatic && row?.original?.automatic?.isPaid === false);
        const alreadyPaid = Boolean(row?.original?.automatic && row?.original?.automatic?.isPaid === true);
        const selectedFinanceItem = row?.original;
        const attachmentHasOneFile = Boolean(value?.length === 1);
        if (value?.length === 0) {
          return <Stack sx={{ width: '100%', color: '#000' }} direction='row' justifyContent='space-between' alignItems='center'>
            <div>--</div>
            <Stack direction='row' spacing={1} alignItems='center'>
              {isYetToBePaid && (
                <Typography fontWeight='bold'>{t('isYetToBeAutoPaid')}</Typography>
              )}
              {alreadyPaid && (
                <CompletePaymentIcon />
              )}
              <MenuList
                trigger={({ handleOpen }) => (
                  <>
                    <Stack direction='row' alignItems='center' justifyContent='space-between'>
                      <IconButton onClick={handleOpen}>
                        <ActionsIcon style={{ width: 16, height: 16 }} />
                      </IconButton>
                    </Stack>
                  </>
                )}
                items={[
                  {
                    label: t('edit'),
                    description: t('editOrIgnore'),
                    icon: EditIcon,
                    onClick: () => {
                      dispatch(setSingleArrivalOrExpense(selectedFinanceItem));
                      navigate('/finances/other/edit-arrival-and-expenses');
                    },
                  },
                ]}
              />
            </Stack>
          </Stack>;
        } else {
          return <Stack sx={{ width: '100%', color: '#2F3191', fontWeight: 'bold' }} direction='row' justifyContent='space-between' alignItems='center'>
            <div
              style={{ textDecoration: 'underline' }}
              onClick={() => {
                dispatch(setSingleArrivalOrExpense(selectedFinanceItem));
                setAttachmentsDialogIsOpen(true);
              }}
            >
              {attachmentHasOneFile ? `1 ${t('attachedFile')}` : `${value?.length} ${t('attachedFiles')}`}
            </div>
            <Stack direction='row' spacing={1} alignItems='center'>
              {isYetToBePaid && (
                <Typography fontWeight='bold'>{t('isYetToBeAutoPaid')}</Typography>
              )}
              {alreadyPaid && (
                <CompletePaymentIcon />
              )}
              <MenuList
                trigger={({ handleOpen }) => (
                  <>
                    <Stack direction='row' alignItems='center' justifyContent='space-between'>
                      <IconButton onClick={handleOpen}>
                        <ActionsIcon style={{ width: 16, height: 16 }} />
                      </IconButton>
                    </Stack>
                  </>
                )}
                items={[
                  {
                    label: t('edit'),
                    description: t('editOrIgnore'),
                    icon: EditIcon,
                    onClick: () => {
                      dispatch(setSingleArrivalOrExpense(selectedFinanceItem));
                      navigate('/finances/other/edit-arrival-and-expenses');
                    },
                  },
                ]}
              />
            </Stack>
          </Stack>;
        }
      }
    },
  ];

  return (
    <FinancesLayout
      leftAdornment={
        <>
          <Select
            label={t('property')}
            size='large'
            fullWidth
            InputLabelProps={{ shrink: true }}
            options={[
              {
                value: 'all',
                label: t('all'),
              },
              ...propertiesList?.map((property) => (
                {
                  label: property?.nicknames?.landlord ?? property?.nicknames?.owner,
                  value: property?._id,
                }
              ))
            ]}
            value={selectedPropertyId}
            onChange={(ev) => {
              const propertyId = ev?.target?.value;
              dispatch(setSelectedPropertyId(propertyId));
              dispatch(getArrivalsAndExpenses(
                {
                  page: 1,
                  limit: PER_PAGE,
                  ...((propertyId !== 'all') && {
                    filters: {
                      properties: [propertyId]
                    }
                  }),
                  loadMore: false,
                }
              ));
            }}
            sx={{ minWidth: 160, height: 44 }}
          />
        </>
      }
      rightAdornment={
        <>
          <Button
            variant='contained'
            color='primary'
            onClick={() => navigate('/finances/other/add-arrival-and-expenses')}
          >
            {t('add')}
          </Button>
        </>
      }
    >
      {({ layoutHeight }) => (
        <>
          {Boolean(layoutHeight) && (
            <Table
              height={layoutHeight - 20}
              data={arrivalsAndExpensesList?.docs ?? []}
              isLoading={getArrivalAndExpensesListIsLoading}
              nextPage={arrivalsAndExpensesList?.nextPage}
              hasNextPage={arrivalsAndExpensesList?.hasNextPage}
              columns={arrivalsAndExpensesColumns}
              onNextPage={() => {
                dispatch(getArrivalsAndExpenses({
                  page: arrivalsAndExpensesList?.nextPage,
                  limit: PER_PAGE,
                  loadMore: true
                }));
              }}
              emptyStateProps={{
                title: t('welcomeToFinances'),
                subtitle: t('financesModule')
              }}
            />
          )}
          <Drawer
            open={drawerIsOpen}
            onClose={() => setDrawerIsOpen(false)}
            anchor='right'
          >
            <Stack direction='row' alignItems='center' justifyContent='space-between'>
              <Typography variant='subtitle2' fontSize='20px'>{t('filters')}</Typography>
              <IconButton onClick={() => setDrawerIsOpen(false)}>
                <CrossIcon
                  fill='#0E1833'
                  style={{ width: 16, height: 16 }}
                />
              </IconButton>
            </Stack>
            <Box p={2}>
              <Typography variant='subtitle2'>{t('type')}</Typography>
              <FormGroup>
                <FormControlLabel control={<Checkbox defaultChecked color='secondary' />} label={t('incomes')} />
                <FormControlLabel control={<Checkbox defaultChecked color='secondary' />} label={t('outcomes')} />
              </FormGroup>
              <Box mt={1} mb={1}>
                <Divider />
              </Box>
              <Typography variant='subtitle2'>{t('date')}</Typography>
              <FormControl>
                <RadioGroup>
                  <FormControlLabel value='текущ месец' control={<Radio />} label={t('currentMonth')} />
                  <FormControlLabel value='дата/период' control={<Radio />} label={t('period')} />
                </RadioGroup>
              </FormControl>
              <Box mt={1} mb={1}>
                <Divider />
              </Box>
              <Stack direction='row' alignItems='center' justifyContent='space-between'>
                <Typography variant='subtitle2'>{t('properties')}</Typography>
                <IconButton onClick={() => setPropertyContentIsOpen(!propertyContentIsOpen)}>
                  <ArrowUpIcon style={{ width: 12, height: 12, transform: propertyContentIsOpen ? '' : 'rotate(180deg)' }} />
                </IconButton>
              </Stack>
              <SearchField />
              <div style={{ display: propertyContentIsOpen ? 'block' : 'none' }}>
                <FormGroup>
                  <FormControlLabel control={<Checkbox defaultChecked color='secondary' />} label='Всички' />
                  <FormControlLabel control={<Checkbox defaultChecked color='secondary' />} label='Апартамент Варна Център' />
                  <FormControlLabel control={<Checkbox defaultChecked color='secondary' />} label='Ресторант “Къщите”' />
                  <FormControlLabel control={<Checkbox defaultChecked color='secondary' />} label='Апартамент Редута 2' />
                  <FormControlLabel control={<Checkbox defaultChecked color='secondary' />} label='Магазин “Зоя” - Център' />
                  <FormControlLabel control={<Checkbox defaultChecked color='secondary' />} label='Сладкарница “Неделя”' />
                  <FormControlLabel control={<Checkbox defaultChecked color='secondary' />} label='Апартамент Красно Село бл.12' />
                </FormGroup>
                <Box mt={1} mb={1}>
                  <Divider />
                </Box>
              </div>
              <Stack direction='row' alignItems='center' justifyContent='space-between'>
                <Typography variant='subtitle2'>{t('tenant')}</Typography>
                <IconButton onClick={() => setTenantContentIsOpen(!tenantContentIsOpen)}>
                  <ArrowUpIcon style={{ width: 12, height: 12, transform: tenantContentIsOpen ? '' : 'rotate(180deg)' }} />
                </IconButton>
              </Stack>
              <SearchField />
              <div style={{ display: tenantContentIsOpen ? 'block' : 'none' }}>
                <FormGroup>
                  <FormControlLabel control={<Checkbox defaultChecked color='secondary' />} label='Всички' />
                  <FormControlLabel control={<Checkbox defaultChecked color='secondary' />} label='Александър Димитров' />
                </FormGroup>
              </div>
              <Box mt={1}>
                <Stack direction='row' justifyContent='space-between'>
                  <Button variant='contained' color='info'>{t('clear')}</Button>
                  <Button variant='contained' color='primary'>{t('apply')}</Button>
                </Stack>
              </Box>
            </Box>
          </Drawer>
          <Dialog
            open={attachmentsDialogIsOpen}
            onClose={() => setAttachmentsDialogIsOpen(false)}
            title={t('attachedFiles')}
            dialogWidth='650px'
          >
            <Typography variant='h6' mb={3}>
              {t('viewDownloadFile')}
            </Typography>
            {singleArrivalOrExpense?.attachments?.map((attachment, index) => (
              <Box key={`${attachment}-attachment-${index}`} mt={3}>
                <Stack>
                  <Typography variant='caption'>
                    {t('file')}
                  </Typography>
                  <Stack direction='row' alignItems='center' spacing={1}>
                    <Typography variant='subtitle2'>
                      {attachment}
                    </Typography>
                    <a href={attachment} download target='_blank' rel='noreferrer noopener' style={{ color: '#000' }}>
                      <IconButton
                        onClick={() => attachment}
                      >
                        <DownloadFileIcon />
                      </IconButton>
                    </a>
                  </Stack>
                </Stack>
              </Box>
            ))}
          </Dialog>
        </>
      )}
    </FinancesLayout>
  );
};

export default FinancesOther;