import { createSlice } from '@reduxjs/toolkit';
import httpClient from '../api/httpClient';

const initialState = {
  cards: [],
  getCardsIsLoading: false,
  addCardIsLoading: false,
  singleCard: {},
  stripePaymentIsLoading: false
};

export const PaymentSlice = createSlice({
  name: 'Payment',
  initialState,
  reducers: {
    getCardsRequesting: (state) => {
      state.getCardsIsLoading = true;
    },
    getCardsSuccess: (state, { payload }) => {
      state.getCardsIsLoading = false;
      state.cards = payload;
    },
    getCardsError: (state) => {
      state.getCardsIsLoading = false;
    },
    addCardRequesting: (state) => {
      state.addCardIsLoading = true;
    },
    addCardSuccess: (state, { payload }) => {
      state.addCardIsLoading = false;
      if (payload?.data?.success) {
        state.cards = [
          ...state.cards,
          payload?.data?.card
        ];
      }
    },
    addCardError: (state) => {
      state.addCardIsLoading = false;
    },
    stripePaymentRequesting: (state) => {
      state.stripePaymentIsLoading = true;
    },
    stripePaymentSuccess: (state, { payload }) => {
      state.stripePaymentIsLoading = false;
    },
    stripePaymentError: (state) => {
      state.stripePaymentIsLoading = false;
    },
  }
});

export const {
  getCardsRequesting,
  getCardsSuccess,
  getCardsError,
  addCardRequesting,
  addCardSuccess,
  addCardError,
  stripePaymentRequesting,
  stripePaymentSuccess,
  stripePaymentError
} = PaymentSlice.actions;

export const getCards = () => async (dispatch) => {
  dispatch(getCardsRequesting());
  httpClient.get('/stripe/cards?org=true').then(
    (response) => {
      dispatch(getCardsSuccess(response?.data?.cards));
    },
    (error) => dispatch(getCardsError(error)),
  );
};

export const addCard = (data, onSuccess) => async (dispatch) => {
  dispatch(addCardRequesting());
  httpClient.post('/stripe/save-card?org=true', data).then(
    (response) => {
      dispatch(addCardSuccess(response));
      if (onSuccess) {
        onSuccess();
      }
    },
    (error) => dispatch(addCardError(error)),
  );
};

export const stripePayment = (data, onSuccess) => async (dispatch) => {
  dispatch(stripePaymentRequesting());
  httpClient.post('/stripe/payment', data).then(
    (response) => {
      dispatch(stripePaymentSuccess(response));
      if (onSuccess) {
        onSuccess(response);
      }
    },
    (error) => dispatch(stripePaymentError(error)),
  );
};


export default PaymentSlice.reducer;
