import { useState, useRef } from 'react';
import { format } from 'date-fns';
import { Button, Menu, Grid, styled, Typography, Box, Divider, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMeasure, useClickAway } from 'react-use';
import { MonthPicker } from 'components';

const Head = styled('div')(({ theme }) => `
  position: relative;
  background: ${theme.palette.secondary.main};
  text-align: center;
  color: #ffffff;
  padding: 10px;
`);

const MonthRangePicker = ({ trigger, onSelectDate }) => {
  const { t } = useTranslation();
  const [date, setDate] = useState(undefined);
  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = Boolean(anchorEl);

  const onDateChange = (newDate) => {
    setDate((currentDate) => ({
      ...currentDate,
      ...newDate,
    }));
  };

  const onClear = () => {
    setDate(undefined);
    onSelectDate(undefined);
  };

  const onConfirm = () => {
    onSelectDate(date);
  };

  return (
    <>
      <span onClick={(event) => setAnchorEl(event.currentTarget)}>
        {trigger()}
      </span>
      <Menu
        anchorEl={anchorEl}
        open={isOpen}
        onClose={() => setAnchorEl(null)}
      >
        <Box sx={{
          width: '340px',
          padding: 0,
        }}>
          <Head>
            {Boolean(date) ? (
              <Typography variant='body1'>
                {Boolean(date?.from) && (
                  <>
                    {`${t('from')} ${format(date?.from, 'MM-yyyy')}`}
                  </>
                )}
                {' '}
                {Boolean(date?.to) && (
                  <>
                    {`${t('to')} ${format(date?.from, 'MM-yyyy')}`}
                  </>
                )}
              </Typography>
            ) : (
              <Typography variant='body1'>
                {t('choosePeriod')}
              </Typography>
            )}
          </Head>
          <Grid container justifyContent='center' spacing={1}>
            <Grid item xs={5}>
              <Box pt={2} pb={2}>
                <MonthPicker
                  date={date?.from}
                  onChange={(date) => onDateChange({ from: date })}
                  maxDate={date?.to ? date?.to : null}
                />
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                height: '100%',
              }}>
                <Divider orientation='vertical' />
              </Box>
            </Grid>
            <Grid item xs={5}>
              <Box pt={2} pb={2}>
                <MonthPicker
                  date={date?.to}
                  onChange={(date) => onDateChange({ to: date })}
                  minDate={date?.from ? date?.from : null}
                  disabled={!Boolean(date?.from)}
                />
              </Box>
            </Grid>
          </Grid>
          <Stack justifyContent='center' direction='row'>
            <Button
              sx={{ borderRadius: 0 }}
              color='warning'
              fullWidth
              variant='contained'
              onClick={onClear}
              disabled={!date}
            >
              {t('clear')}
            </Button>
            <Button
              sx={{ borderRadius: 0 }}
              color='primary'
              fullWidth
              variant='contained'
              onClick={onConfirm}
              disabled={!(Boolean(date?.from) && Boolean(date?.to))}
            >
              {t('set')}
            </Button>
          </Stack>
        </Box>
      </Menu>
    </>
  );
};

export default MonthRangePicker;