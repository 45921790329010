
const SettingsIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    x='0'
    y='0'
    enableBackground='new 0 0 11.6 11.6'
    version='1.1'
    viewBox='0 0 11.6 11.6'
    xmlSpace='preserve'
    {...props}
  >
    <path
      d='M5.8 0c-.6 0-1.1.3-1.4.9l-.3.5c-.1 0-.2.1-.3.1l-.5-.2c-.8-.3-1.7.2-2 1-.1.3-.1.7 0 1l.2.5c0 .2-.1.2-.1.3l-.5.3C.1 4.8-.2 5.7.2 6.5c.2.3.4.6.7.7l.5.3c0 .1.1.2.1.3l-.2.5c-.3.8.2 1.7 1 2 .3.1.7.1 1 0l.5-.2c.1 0 .2.1.3.1l.2.5c.4.8 1.3 1.1 2.1.7.3-.2.6-.4.7-.7l.2-.5c.1 0 .2-.1.3-.1l.5.2c.8.3 1.7-.2 2-1 .1-.3.1-.7 0-1l-.2-.5c0-.1.1-.2.1-.3l.5-.2c.8-.4 1.1-1.3.7-2.1-.2-.3-.4-.6-.7-.7l-.5-.3c0-.1-.1-.2-.1-.3l.2-.5c.3-.8-.2-1.7-1-2-.3-.1-.7-.1-1 0l-.3.1c-.1 0-.2-.1-.3-.1L7.2.9C7 .3 6.4 0 5.8 0zm0 1.1c.2 0 .4.1.5.3l.3.6.3.3c.2.1.4.1.6.2.1.1.3.1.4 0l.7-.2c.3-.1.6.1.7.3v.3l-.2.8v.4c.1.2.2.4.2.6 0 .1.1.3.3.3l.7.3c.3.1.4.4.2.7-.1.1-.1.2-.2.2l-.7.4-.3.3c0 .2-.1.4-.2.6-.1.1-.1.3 0 .4l.2.7c.1.3-.1.6-.3.7h-.3l-.8-.2h-.4c-.2.1-.4.2-.6.2-.1 0-.3.1-.3.3l-.3.7c-.1.3-.4.4-.7.2-.1-.1-.2-.1-.2-.2L5 9.6l-.3-.3c-.2 0-.4-.1-.6-.2-.1-.1-.3-.1-.4 0l-.7.2c-.3.1-.6-.1-.7-.3v-.3l.2-.7v-.4c-.1-.3-.1-.5-.2-.7 0-.1-.1-.2-.3-.3l-.6-.3c-.3-.1-.4-.5-.3-.7.1-.1.1-.2.2-.2L2 5l.3-.3c.1-.2.1-.4.2-.6.1-.1.1-.3 0-.4L2.3 3c-.1-.3.1-.6.4-.7H3l.7.2h.4c.2-.1.4-.2.6-.2.1 0 .2-.1.3-.3l.3-.7c.1-.1.3-.2.5-.2zm0 2.1c-1.5 0-2.6 1.2-2.6 2.6s1.2 2.6 2.6 2.6c1.5 0 2.6-1.2 2.6-2.6 0-1.4-1.1-2.6-2.6-2.6zm0 1c.9 0 1.6.7 1.6 1.6s-.7 1.6-1.6 1.6c-.9 0-1.6-.7-1.6-1.6 0-.9.7-1.6 1.6-1.6z'
      className='st0'
    ></path>
  </svg>
);

export default SettingsIcon;
