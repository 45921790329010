import { useState, useRef, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Paper,
  Stack,
  Typography,
  IconButton,
  Button,
  Box,
  Grid,
  TextField,
  Chip,
  styled
} from '@mui/material';
import { DefaultLayout } from 'layouts';
import { ArrowBackIcon } from 'icons';
import { Dialog } from 'components';
import { s3Upload } from 'stores/FilesSlice';
import { createCase, updateCase, deleteCase, updateCasesFile, deleteCasesFile } from 'stores/CasesSlice';

const TITLE_MAX_LENGTH = 120;

const FilesContainer = styled('div')`
   display: flex;
   flex-wrap: nowrap; 
   overflow: auto;
   width: '100%';
   padding: 10px;
   > * {
     margin-right: 10px;
     margin-bottom: 2px; 
   }
`;


const CaseForm = ({ isEdit, isLoading, defaultValues }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fileInputRef = useRef();
  const { propertyId } = useParams();
  const { caseId } = useParams();
  const [confirmDeleteModalIsOpen, setConfirmDeleteModalIsOpen] = useState(false);
  const [deleteFilesIds, setDeleteFilesIds] = useState([]);

  const validations = Yup.object().shape({
    title: Yup.string().required(t('titleRequired')),
    description: Yup.string().required(t('descriptionRequired'))
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(validations),
    defaultValues: {
      files: []
    }
  });

  useEffect(() => {
    if (isEdit) {
      reset(defaultValues);
    }
  }, [isLoading, isEdit]);

  const filesValue = watch('files');

  const handleDeleteFile = (index, id) => {
    setValue('files', filesValue?.filter((_, currentIndex) => currentIndex !== index));
    if (id) {
      setDeleteFilesIds((currentIds) => [...currentIds, id]);
    }
  };

  const onChooseFile = (ev) => {
    const files = [...ev.currentTarget.files];
    files.map((item) => {
      dispatch(s3Upload(item, (data) => {
        const newFile = {
          name: item?.name,
          url: data?.location
        };
        setValue('files', [
          ...watch('files'),
          newFile
        ]);
        fileInputRef.current.value = '';
      }));

      return item;
    });
  };

  const titleLength = Boolean(watch('title')) ? watch('title')?.length : 0;

  return (
    <DefaultLayout>
      <form onSubmit={handleSubmit((data) => {
        if (isEdit) {
          dispatch(updateCase({
            title: data?.title,
            description: data?.description
          }, caseId, propertyId, navigate));

          const newFiles = data?.files?.filter((file) => !file?._id);

          newFiles?.map((file) => {
            dispatch(updateCasesFile({
              ...file,
              caseId,
            }));
            return file;
          });

          deleteFilesIds?.map((id) => {
            dispatch(deleteCasesFile(id, () => setDeleteFilesIds((currentIds) => currentIds?.filter((fileId) => fileId !== id))));
            return id;
          });

        } else {
          dispatch(createCase({
            ...data,
            property: propertyId
          }, propertyId, navigate));
        }
      })}>
        <Box mb={3}>
          <Paper elevation={4}>
            <Stack direction='row' justifyContent='space-between' alignItems='center'>
              <Stack direction='row' alignItems='center' spacing={1}>
                <IconButton onClick={() => navigate(`/properties/${propertyId}?activeTab=cases`)}>
                  <ArrowBackIcon style={{ width: 16, height: 16 }} />
                </IconButton>
                <Typography variant='h5'>{isEdit ? t('editCase') : t('createCase')}</Typography>
              </Stack>
              {isEdit ? (
                <Stack direction='row' spacing={1}>
                  <Button
                    variant='contained'
                    color='error'
                    onClick={() => setConfirmDeleteModalIsOpen(true)}
                  >
                    {t('delete')}
                  </Button>
                  <Button
                    variant='contained'
                    color='primary'
                    type='submit'
                  >
                    {t('edit')}
                  </Button>
                </Stack>
              ) : (
                <Button
                  variant='contained'
                  color='primary'
                  type='submit'
                >
                  {t('create')}
                </Button>
              )}
            </Stack>
          </Paper>
        </Box>
        <Box>
          <Paper elevation={4}>
            <Typography variant='subtitle1'>{t('basicInfo')}</Typography>
            <Grid container mt={1}>
              <Grid item md={4}>
                <TextField
                  label={`${t('title')}*`}
                  size='small'
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  {...register('title')}
                  error={Boolean(errors?.title?.message)}
                  helperText={errors?.title?.message}
                  inputProps={{ maxLength: TITLE_MAX_LENGTH }}
                />
                <Stack direction='row' justifyContent='flex-end'>
                  <Typography variant='body1' color='primary'>
                    {t('left')} {TITLE_MAX_LENGTH - titleLength} {t('symbols')}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item md={2}>
                <Button
                  variant='outlined'
                  color='primary'
                  onClick={() => fileInputRef?.current?.click()}
                >
                  {t('addFiles')}
                </Button>
                <input
                  ref={fileInputRef}
                  type='file'
                  onChange={onChooseFile}
                  multiple
                  style={{ display: 'none' }}
                />
              </Grid>
              <Grid item md={6}>
                <FilesContainer>
                  {filesValue?.map((file, index) => (
                    <div key={`${file?.url}-index`}>
                      <Chip
                        label={file?.name}
                        variant='outlined'
                        onDelete={() => handleDeleteFile(index, isEdit ? file?._id : '')}
                      />
                    </div>
                  ))}
                </FilesContainer>
              </Grid>
            </Grid>
            <Grid container mt={1}>
              <Grid item md={12}>
                <TextField
                  label={`${t('description')}*`}
                  multiline
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  rows={5}
                  {...register('description')}
                  error={Boolean(errors?.description?.message)}
                  helperText={errors?.description?.message}
                />
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </form>
      <Dialog
        open={confirmDeleteModalIsOpen}
        onClose={() => setConfirmDeleteModalIsOpen(false)}
        title={t('deleteCase')}
      >
        <Typography variant='h6' mb={3}>{t('deleteCaseConfirmation')}</Typography>
        <Button
          variant='contained'
          color='error'
          fullWidth
          onClick={() => {
            dispatch(deleteCase(caseId, propertyId, navigate));
            setConfirmDeleteModalIsOpen(false);
          }}
        >
          {t('delete')}
        </Button>
      </Dialog>
    </DefaultLayout >
  );
};

export default CaseForm;