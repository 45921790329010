import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Typography, Card, Stack, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  LoginLogoIcon as LoginLogoIconSVG,
  LoginLogoSubIcon as LoginLogoSubIconSVG,
  ChevronIcon
} from 'icons';
import authBackgroundPNG from '../assets/authBackground.png';

const Layout = styled('div')`
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  &::before,
  &::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
  }

  &::before {
    background-color: #0E1833;
    z-index: 2;
    opacity: 0.7;
  }

  &::after {
    background: rgb(57,175,178);
    background: linear-gradient(90deg, rgba(57,175,178,1) 0%, rgba(0,210,151,1) 100%);
    z-index: 3;
    opacity: 0.2;
  }
`;

const Background = styled('img')`
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`;

const Content = styled('div')`
  position: relative;
  width: 500px;
  margin: 0 auto;
  z-index: 4;
`;

const LoginLogoIcon = styled(LoginLogoIconSVG)`
  width: 80px;
  display: block;
`;

const LoginLogoSubIcon = styled(LoginLogoSubIconSVG)`
  width: 160px;
  display: block;
`;

const AuthLayout = ({ children, title, subtitle, isConfirmCode }) => {
  const navigate = useNavigate();
  const { isAuthenticated } = useSelector(({ authenticationState }) => authenticationState);

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return (
    <Layout>
      <Background src={authBackgroundPNG} alt={title} />
      <Content>
        <Card sx={{
          borderRadius: '20px'
        }}>
          {isConfirmCode && (
            <Stack direction='row' justifyContent='flex-start'>
              <IconButton>
                <ChevronIcon
                  style={{ width: 18, height: 18 }}
                  onClick={() => navigate('/login')}
                />
              </IconButton>
            </Stack>
          )}
          <Box p={1}>
            <Box textAlign='center'>
              <Box display='flex' justifyContent='center'>
                <LoginLogoIcon />
              </Box>
              <Box mt={1} display='flex' justifyContent='center'>
                <LoginLogoSubIcon />
              </Box>
              <Box mt={2} display='flex' justifyContent='center'>
                <Typography variant='overline'>{title}</Typography>
              </Box>
              <Typography mt={1} variant='h6' fontSize={14}>
                {subtitle}
              </Typography>
            </Box>
            {children}
          </Box>
        </Card>
      </Content >
    </Layout >
  );
};

export default AuthLayout;