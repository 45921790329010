
const LogoutIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='19.996'
    height='20.002'
    viewBox='0 0 19.996 20.002'
  >
    <path
      fill='#d31163'
      d='M12.59 13l-2.3 2.29a1 1 0 101.42 1.42l4-4a1.034 1.034 0 000-1.42l-4-4a1 1 0 00-1.42 1.42l2.3 2.29H3a1 1 0 000 2zM12 2a10 10 0 00-9 5.55 1.006 1.006 0 001.8.9 8.041 8.041 0 11.04 7.1 1.006 1.006 0 00-1.8.9A10 10 0 1012 2z'
      transform='translate(-2 -2)'
    ></path>
  </svg>
);

export default LogoutIcon;
