import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import NumberFormat from 'react-number-format';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Paper,
  Typography,
  Button,
  Box,
  Stack,
  TextField,
  Grid,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
  InputAdornment,
  styled
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { format, isValid } from 'date-fns';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { PropertyFormLayout } from 'layouts';
import { Dialog, CardDialog, Select } from 'components';
import {
  getUtilityBillsTypes,
  getUtilityBillsProviders,
  createUtilityBills,
} from 'stores/PropertiesSlice';
import { getCards, addCard } from 'stores/PaymentSlice';

const CardsContainer = styled('div')`
   display: flex;
   flex-wrap: nowrap; 
   overflow: auto;
   width: '100%';
   padding: 10px;
   > * {
     margin-right: 10px;
     margin-bottom: 2px; 
   }
`;

const billsDefaultValues = {
  id: '',
  utilityType: '',
  provider: '',
  clientNumber: ''
};

const BillForm = ({ index, hasTenantData }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { propertyId } = useParams();
  const [userType, setUserType] = useState('landlord');
  const [previewOnly, setPreviewOnly] = useState(false);
  const [isCustomBill, setIsCustomBill] = useState(false);
  const [addCardModalIsOpen, setAddCardModalIsOpen] = useState(false);

  const {
    propertiesState: {
      utilityBillsTypes,
      utilityBillsProviders,
      singleProperty
    },
    paymentState: {
      cards, addCardIsLoading
    }
  } = useSelector(({ propertiesState, paymentState }) => ({ propertiesState, paymentState }));

  const validations = Yup.object().shape({
    utilityType: Yup.string().required(t('utilityTypeRequired')),
    ...(isCustomBill ? {
      iban: Yup.string().required(t('ibanRequired')),
      holder: Yup.string().required(t('holderRequired')),
      amount: Yup.number().required(t('amountRequired')),
      payDate: Yup.date().required(t('payDateRequired')).typeError(t('enterValidDate')),
    } : {
      provider: Yup.string().required(t('providerRequired')),
      clientNumber: Yup.string().required(t('clientNumberRequired')),
    }),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(validations),
  });

  const utilityTypeValue = watch('utilityType');
  const providerValue = watch('provider');


  useEffect(() => {
    setIsCustomBill(providerValue === 'other');
  }, [providerValue]);

  useEffect(() => {
    const landlordId = singleProperty?.landlord?._id;
    const tenantId = singleProperty?.tenant?._id;
    const ownerId = singleProperty?.owner?._id;
    if (hasTenantData) {
      setValue('user', userType === 'landlord' ? landlordId : tenantId);
    } else {
      setValue('user', landlordId ? landlordId : ownerId);
    }
  }, [singleProperty, userType, hasTenantData]);

  ;

  useEffect(() => {
    if (isCustomBill) {
      dispatch(getCards());
    }
  }, [isCustomBill]);

  useEffect(() => {
    const defaultCardId = cards?.find((card) => card?.isDefault === true)?._id;
    if (defaultCardId) {
      setValue('card', defaultCardId);
    }
  }, [cards]);

  return (
    <>
      <form onSubmit={handleSubmit(({
        provider, utilityType, clientNumber, user, iban, holder, amount, payDate, card
      }) => {

        if (isCustomBill) {
          dispatch(createUtilityBills({
            utilityType,
            clientNumber,
            card,
            payDate: format(payDate, 'dd.MM.yyyy'),
            isCustom: true,
            iban,
            holder,
            amount,
            user,
            _id: propertyId
          }, () => setPreviewOnly(true)));
        } else {
          dispatch(createUtilityBills({
            provider,
            utilityType,
            clientNumber,
            user,
            _id: propertyId
          }, () => setPreviewOnly(true)));
        }
      })}>
        <Box mt={3}>
          <Paper elevation={4}>
            <Stack width='100%' direction='row' alignItems='center' justifyContent='space-between'>
              <Typography variant='subtitle1'>{t('batch')} {index + 1}</Typography>
            </Stack>
            <Grid container mt={1} mb={2}>
              <Grid item md={3}>
                <Controller
                  control={control}
                  name='utilityType'
                  render={({
                    field: { onChange, value },
                  }) => (
                    <Select
                      key={value}
                      label={t('batchType')}
                      size='small'
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      onChange={onChange}
                      value={value}
                      error={Boolean(errors?.utilityType?.message)}
                      helperText={errors?.utilityType?.message}
                      disabled={previewOnly}
                      options={utilityBillsTypes?.map(({ _id, label }) => ({
                        label: t(label),
                        value: _id,
                      }))}
                    />
                  )}
                />
              </Grid>
              <Grid item md={3}>
                <Controller
                  control={control}
                  name='provider'
                  render={({
                    field: { onChange, value },
                  }) => (
                    <Select
                      key={value}
                      label={t('provider')}
                      size='small'
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      onChange={onChange}
                      value={value}
                      error={Boolean(errors?.provider?.message)}
                      helperText={errors?.provider?.message}
                      disabled={previewOnly}
                      options={[
                        ...utilityBillsProviders?.filter(({ utilityBillType }) => utilityBillType === utilityTypeValue)?.map((option) => (
                          {
                            value: option?._id,
                            label: option?.label,
                          }
                        )),
                        {
                          value: 'other',
                          label: t('other'),
                        }
                      ]}
                    />
                  )}
                />
              </Grid>
              {isCustomBill ? (
                <>
                  <Grid item md={3}>
                    <TextField
                      label={t('holder')}
                      size='small'
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      disabled={previewOnly}
                      {...register('holder')}
                      error={Boolean(errors?.holder?.message)}
                      helperText={errors?.holder?.message}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <TextField
                      label={t('IBAN')}
                      size='small'
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      disabled={previewOnly}
                      {...register('iban')}
                      error={Boolean(errors?.iban?.message)}
                      helperText={errors?.iban?.message}
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item md={3}>
                    <TextField
                      label={t('clientNumber')}
                      size='small'
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      {...register('clientNumber')}
                      error={Boolean(errors?.clientNumber?.message)}
                      helperText={errors?.clientNumber?.message}
                      disabled={previewOnly}
                    />
                  </Grid>
                  {hasTenantData && (
                    <Grid item md={3}>
                      <FormControl disabled={previewOnly}>
                        <FormLabel disabled>{t('batchNameOn')}</FormLabel>
                        <RadioGroup
                          row
                        >
                          <FormControlLabel
                            control={
                              <Radio
                                checked={userType === 'landlord'}
                                onChange={() => setUserType('landlord')}
                              />
                            }
                            label={t('owner')}
                          />
                          <FormControlLabel
                            control={
                              <Radio
                                checked={userType === 'tenant'}
                                onChange={() => setUserType('tenant')}
                              />
                            }
                            label={t('tenant')}
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
            {isCustomBill && (
              <>
                <Grid container mt={3} >
                  <Grid item md={3}>
                    <NumberFormat
                      customInput={TextField}
                      thousandSeparator
                      InputLabelProps={{ shrink: true }}
                      label={`${t('dueAmount')}*`}
                      InputProps={{
                        endAdornment: <InputAdornment position='start'>
                          {t('lv')}
                        </InputAdornment>,
                      }}
                      size='small'
                      fullWidth
                      value={watch('amount')}
                      onValueChange={({ floatValue }) => {
                        setValue('amount', floatValue, { shouldValidate: true });
                      }}
                      disabled={previewOnly}
                      error={Boolean(errors?.amount?.message)}
                      helperText={errors?.amount?.message}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <DesktopDatePicker
                      label={t('endDatePayment')}
                      value={watch('payDate') ? watch('payDate') : null}
                      disabled={previewOnly}
                      onChange={(date) => setValue('payDate', date, { shouldValidate: true })}
                      renderInput={(params) => <TextField
                        {...params} size='small'
                        error={Boolean(errors?.payDate?.message)}
                        helperText={errors?.payDate?.message}
                        InputLabelProps={{ shrink: true }}
                      />}
                    />
                  </Grid>
                  {hasTenantData && (
                    <Grid item md={4}>
                      <FormControl disabled={previewOnly}>
                        <FormLabel disabled>{t('batchNameOn')}</FormLabel>
                        <RadioGroup
                          row
                        >
                          <FormControlLabel
                            control={
                              <Radio
                                checked={userType === 'landlord'}
                                onChange={() => setUserType('landlord')}
                              />
                            }
                            label={t('owner')}
                          />
                          <FormControlLabel
                            control={
                              <Radio
                                checked={userType === 'tenant'}
                                onChange={() => setUserType('tenant')}
                              />
                            }
                            label={t('tenant')}
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  )}
                  <Grid item md={4}>
                    <CardsContainer direction='row' spacing={1} alignItems='center'>
                      {cards?.map(({ _id, token, cardData }) => (
                        <div>
                          <Button
                            key={token}
                            variant={watch('card') === _id ? 'contained' : 'outlined'}
                            onClick={() => setValue('card', _id)}
                            disabled={previewOnly}
                          >
                            ****{cardData?.card?.last4}
                          </Button>
                        </div>
                      ))}
                    </CardsContainer>
                  </Grid>
                  <Grid item md={2}>
                    <Button
                      variant='outlined'
                      color='primary'
                      onClick={() => setAddCardModalIsOpen(true)}
                      disabled={previewOnly}
                    >
                      {t('addCard')}
                    </Button>
                  </Grid>
                </Grid>
                <Box p={1} mt={3} sx={{ backgroundColor: '#F5F9FC' }}>
                  <Stack direction='row' alignItems='center' spacing={1}>
                    <PriorityHighIcon />
                    <Typography variant='caption' textTransform='none'>
                      {t('payingAutoBills')}
                    </Typography>
                  </Stack>
                </Box>
              </>
            )}
            {!previewOnly && (
              <Stack direction='row' justifyContent='flex-end' mt={1}>
                <Button
                  variant='contained'
                  color='primary'
                  type='submit'
                >
                  {t('add')}
                </Button>
              </Stack>
            )}
          </Paper>
        </Box>
      </form>
      <CardDialog
        open={addCardModalIsOpen}
        onClose={() => setAddCardModalIsOpen(false)}
        onSubmit={(data) => dispatch(addCard(data, () => setAddCardModalIsOpen(false)))}
        isLoading={addCardIsLoading}
      />
    </>
  );
};

const PropertyPaymentBills = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
  const [billsItems, setBillsItems] = useState([billsDefaultValues]);

  const {
    singleProperty,
  } = useSelector(({ propertiesState }) => propertiesState);

  useEffect(() => {
    dispatch(getUtilityBillsTypes());
    dispatch(getUtilityBillsProviders());
  }, []);

  return (
    <PropertyFormLayout hasLandlord={Boolean(singleProperty?.tenant)}>
      {({ propertyId, hasTenantData }) => (
        <>
          {billsItems?.map((item, index) => (
            <BillForm
              key={item?.id}
              index={index}
              billData={item}
              hasTenantData={hasTenantData}
            />
          ))}

          <Stack mt={3} mb={3} direction='row' justifyContent='space-between'>
            <Button
              variant='outlined'
              color='primary'
              onClick={() => {
                setBillsItems([
                  ...billsItems,
                  billsDefaultValues,
                ]);
              }}
            >
              {t('addNew')}
            </Button>

            <Button
              variant='contained'
              color='secondary'
              onClick={() => setConfirmModalIsOpen(true)}
            >
              {t('continue')}
            </Button>
          </Stack>

          <Dialog
            open={confirmModalIsOpen}
            onClose={() => setConfirmModalIsOpen(false)}
            title={t('confirmInfo')}
          >
            <Typography variant='h6'>
              {t('propertyError')}
            </Typography>
            <Box mt={4}>
              <Stack direction='row' alignItems='center' spacing={2}>
                <Button
                  variant='contained'
                  color='warning'
                  size='large'
                  fullWidth
                  onClick={() => setConfirmModalIsOpen(false)}
                >
                  {t('back')}
                </Button>
                <Button
                  variant='contained'
                  color='primary'
                  size='large'
                  fullWidth
                  onClick={() => navigate(`/properties/${propertyId}/files`)}
                >
                  {t('confirm')}
                </Button>
              </Stack>
            </Box>
          </Dialog>
        </>
      )}
    </PropertyFormLayout>
  );
};

export default PropertyPaymentBills;