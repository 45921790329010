
const TrashIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='19.26'
    height='19.26'
    viewBox='0 0 19.26 19.26'
    {...props}
  >
    <g fill='#d31163' transform='translate(-2 -2)'>
      <path
        d='M16.283 22.178H8.125a2.3 2.3 0 01-2.3-2.082L4.5 6.847A.769.769 0 015.27 6h13.867a.77.77 0 01.767.847L18.583 20.1a2.3 2.3 0 01-2.3 2.082zM6.121 7.541l1.237 12.4a.769.769 0 00.767.694h8.158a.769.769 0 00.767-.694l1.237-12.4z'
        data-name='Path 55901'
        transform='translate(-.574 -.918)'
      ></path>
      <path
        d='M20.489 7.541H2.77A.77.77 0 112.77 6h17.719a.77.77 0 110 1.541zM11.63 19.1a.77.77 0 01-.77-.77V9.852a.77.77 0 111.541 0v8.474a.77.77 0 01-.771.774zm-2.7 0a.77.77 0 01-.767-.7L7.4 9.921a.77.77 0 111.53-.138l.77 8.474a.77.77 0 01-.768.84zm5.394 0a.77.77 0 01-.768-.84l.77-8.474a.77.77 0 111.535.139l-.77 8.474a.77.77 0 01-.764.701z'
        data-name='Path 55902'
        transform='translate(0 -.918)'
      ></path>
      <path
        d='M16.7 6.622H9.77a.77.77 0 01-.77-.77V2.77A.77.77 0 019.77 2h6.93a.77.77 0 01.77.77v3.082a.77.77 0 01-.77.77zm-6.159-1.54h5.393V3.541h-5.393z'
        data-name='Path 55903'
        transform='translate(-1.607)'
      ></path>
    </g>
  </svg>
);

export default TrashIcon;
