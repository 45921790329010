import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ArrivalsAndExpensesForm } from 'components/forms';
import { handleBeforeReload } from 'utils';
import { setSingleArrivalOrExpense } from 'stores/FinancesSlice';

const FinancesArrivalExpensesEdit = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    financesState: { singleArrivalOrExpense, editArrivalOrExpenseIsLoading, getArrivalAndExpensesListIsLoading }
  } = useSelector(({ financesState }) => ({ financesState }));

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeReload);
    return () => {
      dispatch(setSingleArrivalOrExpense(undefined));
      window.removeEventListener('beforeunload', handleBeforeReload);
    };
  }, []);

  useEffect(() => {
    if (!Boolean(singleArrivalOrExpense)) {
      navigate('/finances/other');
    }
  }, [singleArrivalOrExpense]);

  const defaultValues = {
    payDate: singleArrivalOrExpense?.payDate,
    amount: singleArrivalOrExpense?.amount,
    property: singleArrivalOrExpense?.property?._id,
    type: singleArrivalOrExpense?.type,
    subtitle: singleArrivalOrExpense?.subtitle,
    attachments: singleArrivalOrExpense?.attachments,
    isAutomatic: singleArrivalOrExpense?.isAutomatic,
    isRegular: singleArrivalOrExpense?.isRegular,
    iban: singleArrivalOrExpense?.automatic?.iban,
    holder: singleArrivalOrExpense?.automatic?.holder,
    card: singleArrivalOrExpense?.automatic?.card,
  };

  return (
    <ArrivalsAndExpensesForm
      defaultValues={defaultValues}
      isEdit
      isLoading={editArrivalOrExpenseIsLoading || getArrivalAndExpensesListIsLoading}
    />
  );
};

export default FinancesArrivalExpensesEdit;