
const ChangeFolderIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20.063'
    height='17.053'
    viewBox='0 0 20.063 17.053'
    {...props}
  >
    <path
      fill='#2f3191'
      d='M22.557 9.006h-9.745l-.562-1.159A1.5 1.5 0 0010.9 7H5.5A1.5 1.5 0 004 8.5v14.048a1.5 1.5 0 001.5 1.5h17.057a1.5 1.5 0 001.5-1.5V10.511a1.5 1.5 0 00-1.5-1.5zm.5 13.542a.5.5 0 01-.5.5H5.5a.5.5 0 01-.5-.5V8.5a.5.5 0 01.5-.5h5.4a.5.5 0 01.451.281l1.4 2.884a1.5 1.5 0 001.354.848h7.453a.5.5 0 100-1H14.1a.5.5 0 01-.451-.281l-.351-.722h9.259a.5.5 0 01.5.5zm-9.026-9.028a4.012 4.012 0 104.012 4.012 4.012 4.012 0 00-4.012-4.012zm0 7.022a3.009 3.009 0 113.009-3.009 3.009 3.009 0 01-3.009 3.009z'
      data-name='05 Add Folder'
      transform='translate(-4 -7)'
    ></path>
  </svg>
);

export default ChangeFolderIcon;
