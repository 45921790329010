
const ArrowBackIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={19.609}
    height={13.072}
    {...props}
  >
    <g fill='#0e1833'>
      <path
        data-name='Path 55392'
        d='M7.625 11.983a.989.989 0 0 0-.327-.763L1.851 5.774a1.053 1.053 0 0 0-1.525 0 1.053 1.053 0 0 0 0 1.525l5.447 5.448a1.053 1.053 0 0 0 1.525 0 .989.989 0 0 0 .327-.764Z'
      />
      <path
        data-name='Path 55393'
        d='M7.625 1.089a.989.989 0 0 0-.327-.762 1.053 1.053 0 0 0-1.525 0L.325 5.774A1.079 1.079 0 1 0 1.851 7.3l5.447-5.448a.989.989 0 0 0 .327-.763Z'
      />
      <path
        data-name='Path 55394'
        d='M19.609 6.536a1.029 1.029 0 0 0-1.089-1.089H1.089A1.029 1.029 0 0 0 0 6.536a1.029 1.029 0 0 0 1.089 1.09H18.52a1.029 1.029 0 0 0 1.089-1.09Z'
      />
    </g>
  </svg>
);

export default ArrowBackIcon;
