import { useNavigate } from 'react-router-dom';
import {
  IconButton,
  Stack,
  Typography,
  Box,
  Paper,
  Grid,
  Button,
  TextField,
  useTheme,
  Switch
} from '@mui/material';
import { ArrowBackIcon } from 'icons';
import { DefaultLayout } from 'layouts';
import { useTranslation } from 'react-i18next';

const AddOrganizationBank = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  return (
    <DefaultLayout>
      <Paper elevation={4}>
        <Stack direction='row' alignItems='center' justifyContent='space-between'>
          <Stack direction='row' alignItems='center' spacing={1}>
            <IconButton onClick={() => navigate('/personal-settings')}>
              <ArrowBackIcon style={{ width: 16, height: 16 }} />
            </IconButton>
            <Typography variant='h5'>{t('addBankAccounts')}</Typography>
          </Stack>
          <Button variant='contained' color='primary'>{t('add')}</Button>
        </Stack>
      </Paper>
      <Box mt={3}>
        <Paper elevation={4}>
          <Typography variant='subtitle1'>
            {t('bankAccount')}
          </Typography>
          <Grid container mt={3}>
            <Grid item md={3}>
              <TextField
                label={`${t('bank')}*`}
                size='small'
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item md={3}>
              <TextField
                label={`${t('iban')}*`}
                size='small'
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item md={3}>
              <TextField
                label={t('holder')}
                size='small'
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>
          <Box p={1} mt={5} sx={{ backgroundColor: theme.palette.info.light }}>
            <Stack direction='row' spacing={1} alignItems='center'>
              <Typography variant='caption' textTransform='uppercase'>
                {t('addToFinanceCheck')}
              </Typography>
              <Switch label={t('yes')} />
            </Stack>
            <Typography variant='caption' textTransform='none'>
              {t('checkWithYesIfYouWantToAddToFinance')}
            </Typography>
          </Box>
        </Paper>
      </Box>
    </DefaultLayout>
  );
};

export default AddOrganizationBank;