import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RentForm } from 'components/forms';
import { getSemiPaidRent } from 'stores/RentSlice';

const PropertiesSemiPaidRentEdit = () => {
  const { paymentId } = useParams();
  const dispatch = useDispatch();
  const { semiPaidRent } = useSelector(({ rentState }) => rentState);

  const defaultValues = {
    _id: semiPaidRent?._id,
    amount: semiPaidRent?.amount,
    paymentDate: semiPaidRent?.paymentDate,
    attachments: semiPaidRent?.rent?.attachments,
    note: semiPaidRent?.rent?.note,
  };

  useEffect(() => {
    if (paymentId) {
      dispatch(getSemiPaidRent(paymentId));
    }
  }, []);

  return (
    <RentForm
      isEdit
      isSemiPaid={Boolean(paymentId)}
      defaultValues={defaultValues}
    />
  );
};

export default PropertiesSemiPaidRentEdit;