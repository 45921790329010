import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Paper,
  Typography,
  Box,
  Stack,
  Grid,
  IconButton,
  Card
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DefaultLayout } from 'layouts';
import { ArrowBackIcon } from 'icons';
import { formatAmount, formatDate } from 'utils';
import { getTenantHistory } from 'stores/PropertiesSlice';

const TenantHistory = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { propertyId } = useParams();
  const navigate = useNavigate();

  const { tenantHistory, singleProperty } = useSelector(({ propertiesState }) => propertiesState);

  const copiedTenantHistoryList = [...tenantHistory];
  const reversedTenantList = copiedTenantHistoryList?.reverse();

  useEffect(() => {
    dispatch(getTenantHistory(propertyId));
  }, []);

  return (
    <DefaultLayout>
      <Paper elevation={4} sx={{ width: 850, margin: '0 auto' }}>
        <Box p={1}>
          <Stack direction='row' spacing={1} alignItems='center'>
            <IconButton onClick={() => navigate(`/properties/${propertyId}`)}>
              <ArrowBackIcon style={{ width: 16, height: 16 }} />
            </IconButton>
            <Typography variant='h5'>
              {t('tenantHistory')}
            </Typography>
          </Stack>
          <Typography variant='caption' textTransform='none'>
            {t('tenantHistoryImmotech')}
          </Typography>
          {reversedTenantList?.map(({ fullName, email, phoneNumber, rent, deposit, startDate, endDate, tenant }, index) => {
            const currentTenant = Boolean(tenant === singleProperty?.tenant?._id);
            const hasEndDate = Boolean(endDate);

            return (
              <Box
                key={`${fullName}-tenant-${index}`}
                mt={3}
              >
                <Card
                  variant='outlined'
                >
                  <Grid container>
                    <Grid item md={3}>
                      <Typography variant='caption'>{t('tenant')}</Typography>
                      <Typography variant='subtitle2'>{fullName}</Typography>
                    </Grid>
                    <Grid item md={3}>
                      <Typography variant='caption'>{t('period')}</Typography>
                      {hasEndDate && (
                        <Typography variant='subtitle2'>
                          {`${formatDate(startDate, 'dd.MM.yyyy')}-${formatDate(endDate, 'dd.MM.yyyy')}`}
                        </Typography>
                      )}
                      {(currentTenant && !hasEndDate) && (
                        <Typography variant='subtitle2'>
                          {`${formatDate(startDate, 'dd.MM.yyyy')}- ${t('untilToday')}`}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item md={3}>
                      <Typography variant='caption'>{t('rent')}</Typography>
                      <Typography variant='subtitle2'>
                        {formatAmount(rent, i18n?.language)}
                      </Typography>
                    </Grid>
                    <Grid item md={3}>
                      <Typography variant='caption'>{t('deposit')}</Typography>
                      <Typography variant='subtitle2'>
                        {formatAmount(deposit, i18n?.language)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container mt={2}>
                    <Grid item md={3}>
                      <Typography variant='caption'>{t('phone')}</Typography>
                      <Typography variant='subtitle2'>{phoneNumber}</Typography>
                    </Grid>
                    <Grid item md={3}>
                      <Typography variant='caption'>{t('mail')}</Typography>
                      <Typography variant='subtitle2'>{email}</Typography>
                    </Grid>
                  </Grid>
                </Card>
              </Box>
            );
          })}
        </Box>
      </Paper>
    </DefaultLayout>
  );
};

export default TenantHistory;