import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Fab,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Stack,
  IconButton,
  Breadcrumbs,
  Link,
  Tooltip,
  Checkbox,
  FormGroup,
  FormControlLabel,
  CircularProgress,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import AddIcon from '@mui/icons-material/Add';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { formatDate } from 'utils';
import { MenuList, Dialog } from 'components';
import {
  FolderIcon,
  FileIcon,
  ActionsIcon,
  AddCalendarIcon,
  EditIcon,
  TrashIcon,
  ChangeFolderIcon,
  AddFilesIcon,
  AddFolderIcon,
  BellIcon
} from 'icons';
import {
  getFiles,
  createFile,
  setImpDate,
  createFolder,
  updateFileOrFolder,
  deleteFileOrFolder,
  removeFileItem,
} from 'stores/FilesSlice';
import FilePreview from './FilePreview';
import FoldersList from './FoldersList';

const PropertyFilesLibrary = ({ propertyId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const fileInputRef = useRef();
  const manageParamsDefault = {
    isOpen: false,
    action: '',
    isMove: false,
    impDateFormIsOpen: false,
  };
  const [manageParams, setManageParams] = useState(manageParamsDefault);
  const [currentFolderId, setCurrentFolderId] = useState('');
  const [selectedItem, setSelectedItem] = useState(undefined);
  const [previewModalIsOpen, setPreviewModalIsOpen] = useState(false);
  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [hasDeleteConfirm, setHasDeleteConfirm] = useState(true);

  const {
    filesState: { files, createFileIsLoading },
  } = useSelector(({ filesState }) => ({ filesState }));

  useEffect(() => {
    if (propertyId) {
      dispatch(getFiles({
        property: propertyId,
        ...(currentFolderId && { dir: currentFolderId }),
      }));
    }
  }, [propertyId, currentFolderId]);

  const resetFilesManageParams = () => {
    setManageParams(manageParamsDefault);
    setSelectedItem(undefined);
  };

  const onChooseFile = (ev) => {
    const file = ev.target.files[0];
    dispatch(createFile(file, propertyId, currentFolderId, () => {
      fileInputRef.current.value = '';
    }));
  };

  const onSubmitManageFiles = () => {
    const isEdit = manageParams?.action === 'edit';

    if (isEdit) {
      dispatch(updateFileOrFolder({
        name: selectedItem?.name,
      }, selectedItem?.id, () => {
        setBreadcrumbs((currentItem) => currentItem?.map((item) => {
          if (item?.id === selectedItem?.id) {
            return selectedItem;
          }
          return item;
        }));
        resetFilesManageParams();
      }));
    } else {
      dispatch(createFolder({
        propertyId,
        ...(currentFolderId && { parentDir: currentFolderId }),
        name: selectedItem?.name,
      }, () => resetFilesManageParams()));
    }
  };

  const onSubmitImpDate = () => {
    dispatch(setImpDate({
      impDate: selectedItem?.impDate,
      impMessage: selectedItem?.impMessage,
    }, selectedItem?.id, () => {
      setManageParams({
        ...manageParams,
        impDateFormIsOpen: false,
      });
    }));
  };

  const renderItemOptions = (item) => [
    {
      label: t('addImportantDay'),
      description: t('addDayEvent'),
      icon: AddCalendarIcon,
      onClick: () => {
        setSelectedItem(item);
        setManageParams({
          ...manageParams,
          impDateFormIsOpen: true,
        });
      },
    },
    {
      label: t('changeName'),
      description: t('changeNameInSystem'),
      icon: EditIcon,
      onClick: () => {
        setSelectedItem(item);
        setManageParams({
          ...manageParams,
          isOpen: true,
          action: 'edit',
        });
      }
    },
    {
      label: t('changeLocation'),
      description: t('changeFileLocation'),
      icon: ChangeFolderIcon,
      onClick: () => {
        setSelectedItem(item);
        setManageParams({
          ...manageParams,
          isMove: true,
        });
      },
    },
    {
      label: t('delete'),
      description: t('removeFromSystem'),
      icon: TrashIcon,
      onClick: () => {
        if (hasDeleteConfirm) {
          setSelectedItem(item);
          setConfirmModalIsOpen(true);
        } else {
          dispatch(deleteFileOrFolder(item?.id));
        }
      }
    },
  ];

  return (
    <div>
      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        {manageParams?.isMove ? (
          <Stack>
            <Typography variant='subtitle1'>{t('rearangeFiles')}</Typography>
            <Typography variant='subtitle2' textTransform='uppercase'>{selectedItem?.name}</Typography>
          </Stack>
        ) : (
          <Breadcrumbs separator={<ArrowRightAltIcon />}>
            <Link
              sx={{ cursor: 'pointer' }}
              underline='hover'
              color='inherit'
              onClick={() => {
                setBreadcrumbs([]);
                setCurrentFolderId('');
              }}
            >
              {t('allFiles')}
            </Link>
            {breadcrumbs?.map((item, index) => {
              const isLast = breadcrumbs?.length === index + 1;

              return (
                <Stack key={`${item}-${index}`} direction='row' spacing={1} alignItems='center'>
                  <Typography
                    sx={{ cursor: 'pointer' }}
                    onClick={() => {
                      setBreadcrumbs((currentBreadcrumbs) => currentBreadcrumbs.slice(0, index + 1));
                      setCurrentFolderId(item?.id);
                    }}
                    color='text.primary'
                  >
                    {item.name}
                  </Typography>

                  {isLast && (
                    <MenuList
                      trigger={({ handleOpen }) => (
                        <>
                          <Stack direction='row' alignItems='center' justifyContent='space-between'>
                            <IconButton onClick={handleOpen}>
                              <ActionsIcon style={{ width: 16, height: 16 }} />
                            </IconButton>
                          </Stack>
                        </>
                      )}
                      items={renderItemOptions(item)}
                    />
                  )}
                </Stack>
              );
            })}
          </Breadcrumbs>
        )}
      </Stack>
      {!manageParams?.isMove ? (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant='body1'>{t('name')}</Typography>
              </TableCell>
              <TableCell align='right'>
                <Typography variant='body1'>{t('lastChange')}</Typography>
              </TableCell>
              <TableCell align='right'>
                <Typography variant='body1'>{t('importantDay')}</Typography>
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {files?.map((item) => {
              const isFolder = item?.type === 'folder';
              const onItemClick = isFolder ? () => {
                setCurrentFolderId(item?.id);
                setBreadcrumbs([
                  ...breadcrumbs,
                  item,
                ]);
              } : () => {
                setSelectedItem(item);
                setPreviewModalIsOpen(true);
              };

              return (
                <TableRow
                  key={item?.id}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    ':hover': {
                      backgroundColor: '#EFF5FF',
                      cursor: 'pointer'
                    }
                  }}
                >
                  <TableCell onClick={onItemClick} style={{ width: '50%' }} component='th' scope='row'>
                    <Stack direction='row' alignItems='center' spacing={1}>
                      <IconButton>
                        {isFolder ? (
                          <FolderIcon style={{ width: 28, height: 28 }} />
                        ) : (
                          <FileIcon style={{ width: 28, height: 28 }} />
                        )}
                      </IconButton>
                      <Typography variant='body1' textTransform='uppercase'>{item?.name}</Typography>
                    </Stack>
                  </TableCell>
                  <TableCell onClick={onItemClick} style={{ width: '25%' }} align='right' >
                    {formatDate(item?.updatedAt)}
                  </TableCell>
                  <TableCell onClick={onItemClick} style={{ width: '25%' }} align='right'>
                    {item?.impDate ? (
                      <Tooltip title={item?.impMessage} placement='bottom'>
                        <div>
                          <Stack alignItems='center' direction='row' spacing={1} justifyContent='flex-end'>
                            <div>
                              <BellIcon />
                            </div>
                            <div>{formatDate(item?.impDate)}</div>
                          </Stack>
                        </div>
                      </Tooltip>
                    ) : '---'}
                  </TableCell>
                  <TableCell style={{ width: '15%' }} align='right' >
                    <MenuList
                      trigger={({ handleOpen }) => (
                        <IconButton onClick={handleOpen}>
                          <ActionsIcon style={{ width: 16, height: 16 }} />
                        </IconButton>
                      )}
                      items={renderItemOptions(item)}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      ) : (
        <FoldersList
          propertyId={propertyId}
          disabledFoldersIds={[selectedItem?.id, selectedItem?.parentDir]}
          typeOfItem={selectedItem?.type}
          parentDir={selectedItem?.parentDir}
          onChooseFolder={(folderId) => {
            dispatch(updateFileOrFolder({
              name: selectedItem?.name,
              parentDir: folderId,
            }, selectedItem?.id, () => {
              dispatch(removeFileItem(selectedItem?.id));
              resetFilesManageParams();
              setCurrentFolderId('');
            }));
          }}
          onCancel={() => resetFilesManageParams()}
        />
      )}

      <div style={{ position: 'fixed', bottom: '30px', right: '30px' }}>
        <MenuList
          trigger={({ handleOpen }) => (
            <Fab size='medium' color='primary' onClick={handleOpen} aria-label='add'>
              <AddIcon style={{ color: '#fff' }} />
            </Fab>
          )}
          items={[
            {
              label: t('addNewFile'),
              description: t('addNewFileInFolder'),
              icon: AddFilesIcon,
              onClick: () => fileInputRef?.current?.click(),
            },
            {
              label: t('newFolder'),
              description: t('createNewFolderHere'),
              icon: AddFolderIcon,
              onClick: () => {
                setManageParams({
                  ...manageParams,
                  isOpen: true,
                  action: 'create',
                });
              }
            }
          ]}
        />
      </div>

      <input
        ref={fileInputRef}
        type='file'
        onChange={onChooseFile}
        style={{ display: 'none' }}
      />

      <Dialog
        open={manageParams?.impDateFormIsOpen}
        onClose={() => {
          setManageParams({
            ...manageParams,
            impDateFormIsOpen: false,
          });
        }}
        title={t('addImportantDay')}
      >
        <Box mb={3}>
          <Typography variant='h6'>{t('remindImportantDay')}</Typography>
        </Box>
        <DesktopDatePicker
          margin='normal'
          label={t('contractBeginning')}
          value={selectedItem?.impDate ? new Date(selectedItem?.impDate) : new Date()}
          onChange={(date) => setSelectedItem({
            ...selectedItem,
            impDate: format(date, 'yyyy-MM-dd'),
          })}
          renderInput={(params) => <TextField {...params} />}
        />

        <TextField
          margin='normal'
          value={selectedItem?.impMessage ?? ''}
          onChange={({ target: { value } }) => setSelectedItem({
            ...selectedItem,
            impMessage: value,
          })}
          multiline
          rows={2}
          fullWidth
          label={t('description')}
          size='small'
        />

        <Box mt={3}>
          <Button
            size='large'
            variant='contained'
            fullWidth
            onClick={onSubmitImpDate}
            disabled={!Boolean(selectedItem?.impDate && selectedItem?.impMessage)}
          >
            {t('add')}
          </Button>
        </Box>
      </Dialog>

      <Dialog
        open={previewModalIsOpen}
        onClose={() => setPreviewModalIsOpen(false)}
        title={selectedItem?.name}
      >
        <FilePreview file={selectedItem} />
      </Dialog>

      <Dialog
        title={selectedItem?.type === 'folder' ? `${manageParams.action === 'create' ? t('create') : t('edit')} ${t('folder')}` : `${manageParams.action === 'create' ? t('create') : t('edit')}`}
        open={manageParams?.isOpen}
        onClose={() => {
          setManageParams({
            ...manageParams,
            isOpen: false,
          });
          setSelectedItem(undefined);
        }}
      >
        <TextField
          value={selectedItem?.name}
          onChange={({ target: { value } }) => setSelectedItem({
            ...selectedItem,
            name: value,
          })}
          fullWidth
          label={t('name')}
          size='small'
        />
        <Box mt={3}>
          <Button
            size='large'
            variant='contained'
            fullWidth
            onClick={onSubmitManageFiles}
            disabled={!Boolean(selectedItem?.name)}
          >
            {manageParams.action === 'edit' ? t('edit') : t('add')} {selectedItem?.type === 'folder' ? t('folder') : ''}
          </Button>
        </Box>
      </Dialog>

      <Dialog
        title={t('deleteElement')}
        open={confirmModalIsOpen}
        onClose={() => {
          setConfirmModalIsOpen(false);
        }}
      >
        <Typography variant='h6'>
          {t('areYouSureYouWantToDelete')}
        </Typography>
        <Box mt={3}>
          <Typography variant='h6'>
            {t('confirmDeleteBatches')}
          </Typography>
        </Box>
        <Box mt={3}>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox
                checked={!hasDeleteConfirm}
                onChange={() => setHasDeleteConfirm(!hasDeleteConfirm)}
                color='secondary'
              />}
              label={t('doNotShowMore')}
            />
          </FormGroup>
        </Box>
        <Box mt={3}>
          <Button
            size='large'
            variant='contained'
            fullWidth
            onClick={() => {
              dispatch(deleteFileOrFolder(selectedItem?.id));
              setConfirmModalIsOpen(false);
            }}
            color='error'
          >
            {t('delete')}
          </Button>
        </Box>
        <Box mt={3}>
          <Button
            size='large'
            variant='contained'
            fullWidth
            onClick={() => {
              setConfirmModalIsOpen(false);
            }}
            color='warning'
          >
            {t('decline')}
          </Button>
        </Box>
      </Dialog>

      <Dialog
        title={t('uploadFile')}
        open={createFileIsLoading}
      >
        <Typography variant='h6'>
          {t('pleaseWaitUploadFile')}
        </Typography>
        <Stack mt={3} direction='row' justifyContent='center'>
          <CircularProgress size={80} />
        </Stack>
      </Dialog>

    </div>
  );
};

export default PropertyFilesLibrary;