
const Logo = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 170.075 44.26'
    {...props}
  >
    <g data-name='Group 1967' transform='translate(47.67 14.523)'>
      <path
        fill='#00d297'
        d='M5.49-31.781h1.1v15.117h-1.1zm20.667 15.117L26.136-29.6l-6.328 10.928h-.54l-6.327-10.884v12.893h-1.08v-15.118h.929L19.549-20.1l6.76-11.683h.907l.022 15.117zm20.646 0L46.781-29.6l-6.327 10.928h-.54l-6.328-10.884v12.893h-1.08v-15.118h.929l6.76 11.681 6.759-11.683h.907l.022 15.117zm12.763.108a8.076 8.076 0 01-4.017-1 7.4 7.4 0 01-2.818-2.753 7.583 7.583 0 01-1.026-3.909 7.583 7.583 0 011.026-3.909 7.4 7.4 0 012.818-2.753 8.076 8.076 0 014.017-1 8.145 8.145 0 014.017.993 7.318 7.318 0 012.817 2.734 7.624 7.624 0 011.026 3.93 7.624 7.624 0 01-1.026 3.93 7.318 7.318 0 01-2.818 2.743 8.145 8.145 0 01-4.016.994zm0-1.015A6.873 6.873 0 0063-18.435a6.363 6.363 0 002.419-2.386 6.641 6.641 0 00.885-3.4 6.641 6.641 0 00-.885-3.4A6.363 6.363 0 0063-30.01a6.873 6.873 0 00-3.434-.864 6.933 6.933 0 00-3.445.864 6.342 6.342 0 00-2.43 2.386 6.641 6.641 0 00-.885 3.4 6.641 6.641 0 00.885 3.4 6.342 6.342 0 002.43 2.386 6.933 6.933 0 003.445.867zM73.538-28.93H68.7v-2.851h13.174v2.851h-4.837v12.266h-3.5zm21.92 9.459v2.807h-11.7v-15.117h11.42v2.807H87.23v3.283h7.019v2.721H87.23v3.5zm9.977 3.067a8.683 8.683 0 01-4.179-1 7.494 7.494 0 01-2.937-2.786 7.675 7.675 0 01-1.069-4.028 7.675 7.675 0 011.07-4.032 7.494 7.494 0 012.937-2.786 8.728 8.728 0 014.2-1 8.81 8.81 0 013.553.691 7.115 7.115 0 012.667 1.987l-2.246 2.073a4.829 4.829 0 00-3.8-1.771 5.047 5.047 0 00-2.505.615 4.375 4.375 0 00-1.717 1.717 5.047 5.047 0 00-.615 2.505 5.047 5.047 0 00.615 2.505A4.375 4.375 0 00103.125-20a5.047 5.047 0 002.505.615 4.806 4.806 0 003.8-1.792l2.246 2.073A7 7 0 01109-17.1a8.888 8.888 0 01-3.564.7zM127.9-31.781v15.117h-3.5v-6.2h-6.867v6.2h-3.5v-15.117h3.5v5.96h6.867v-5.96z'
        data-name='Path 51432'
        transform='translate(-5.49 32.04)'
      ></path>
    </g>
    <g fill='#00d297' data-name='Group 1966'>
      <path
        d='M5.011 83.255H.73a.73.73 0 01-.73-.73V60.42a.73.73 0 01.73-.73h4.281a.73.73 0 01.73.73v22.107a.73.73 0 01-.73.728z'
        data-name='Path 1294'
        transform='translate(0 -46.561)'
      ></path>
      <path
        d='M139.2 83.247h-5.011a.364.364 0 01-.365-.365V60.045a.364.364 0 01.365-.365h5.011a.364.364 0 01.365.365v22.839a.364.364 0 01-.365.363z'
        data-name='Path 1295'
        transform='translate(-104.619 -46.553)'
      ></path>
      <path
        d='M72.041 100.238H67.76a.729.729 0 01-.73-.73V72.57a.729.729 0 01.73-.73h4.281a.729.729 0 01.73.73v26.936a.732.732 0 01-.73.732z'
        data-name='Path 1296'
        transform='translate(-52.403 -55.978)'
      ></path>
      <path
        d='M26.121.12l-6.788 7.533a.368.368 0 01-.545 0L12 .12a.368.368 0 00-.545 0L2.092 10.512a.365.365 0 00.273.608h4.719a.363.363 0 00.272-.12l4.052-4.5a.368.368 0 01.545 0l4.163 4.62h5.633a.363.363 0 00.272-.12l4.052-4.5a.368.368 0 01.545 0L30.67 11a.371.371 0 00.273.122h4.813a.365.365 0 00.273-.608L26.666.12a.365.365 0 00-.545 0z'
        data-name='Path 1297'
        transform='translate(-1.564 .002)'
      ></path>
    </g>
  </svg>
);

export default Logo;
