import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  IconButton,
  Stack,
  Typography,
  Box,
  Paper,
  Grid,
  Button,
  TextField,
  InputAdornment,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DefaultLayout } from 'layouts';
import { ArrowBackIcon } from 'icons';
import { Dialog } from 'components';
import { getSingleUser, deleteSingleUser, updateSingleUser } from 'stores/OrganizationSlice';

const PersonalSettingsEdit = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userId } = useParams();
  const [confirmDeleteModalIsOpen, setConfirmDeleteModalIsOpen] = useState(false);

  const { singleUser } = useSelector(({ organizationState }) => organizationState);

  const validations = Yup.object().shape({
    firstName: Yup.string().required(t('firstNameRequired')),
    lastName: Yup.string().required(t('lastNameRequired')),
    phoneNumber: Yup.string().min(9).max(9),
    email: Yup.string().email().label('Email')
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({
    resolver: yupResolver(validations),
  });

  useEffect(() => {
    if (userId) {
      dispatch(getSingleUser(userId));
    }
  }, []);

  useEffect(() => {
    reset({
      firstName: singleUser?.firstName,
      lastName: singleUser?.lastName,
      phoneNumber: singleUser?.phoneNumber?.substring(4),
      email: singleUser?.email
    });
  }, [singleUser]);

  return (
    <DefaultLayout>
      <form onSubmit={handleSubmit((data) => {
        dispatch(updateSingleUser({
          ...data,
          _id: userId,
          phoneNumber: `+359${data?.phoneNumber}`
        }, () => navigate('/personal-settings')));
      })}>
        <Paper>
          <Stack direction='row' alignItems='center' justifyContent='space-between'>
            <Stack direction='row' alignItems='center' spacing={1}>
              <IconButton onClick={() => navigate('/personal-settings')}>
                <ArrowBackIcon style={{ width: 16, height: 16 }} />
              </IconButton>
              <Typography variant='h5'>{t('editPersonalData')}</Typography>
            </Stack>
            <Stack direction='row' spacing={1}>
              <Button
                variant='contained'
                color='error'
                onClick={() => setConfirmDeleteModalIsOpen(true)}
              >
                {t('delete')}
              </Button>
              <Button
                variant='contained'
                color='primary'
                type='submit'
              >
                {t('edit')}
              </Button>
            </Stack>
          </Stack>
        </Paper>
        <Box mt={2}>
          <Paper elevation={4}>
            <Typography variant='subtitle1'>{t('userData')}</Typography>
            <Grid mt={1} container>
              <Grid item md={3}>
                <TextField
                  label={`${t('name')}*`}
                  size='small'
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  {...register('firstName')}
                  error={Boolean(errors?.firstName?.message)}
                  helperText={errors?.firstName?.message}
                />
              </Grid>
              <Grid item md={3}>
                <TextField
                  label={`${t('lastName')}*`}
                  size='small'
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  {...register('lastName')}
                  error={Boolean(errors?.lastName?.message)}
                  helperText={errors?.lastName?.message}
                />
              </Grid>
              <Grid item md={3}>
                <TextField
                  label={`${t('phone')}*`}
                  InputProps={{
                    startAdornment: <InputAdornment position='start'>
                      +359
                    </InputAdornment>,
                  }}
                  size='small'
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  {...register('phoneNumber')}
                  error={Boolean(errors?.phoneNumber?.message)}
                  helperText={errors?.phoneNumber?.message}
                />
              </Grid>
              <Grid item md={3}>
                <TextField
                  label={t('mail')}
                  size='small'
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  {...register('email')}
                  error={Boolean(errors?.email?.message)}
                  helperText={errors?.email?.message}
                />
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </form>
      <Dialog
        open={confirmDeleteModalIsOpen}
        onClose={() => setConfirmDeleteModalIsOpen(false)}
        title={t('deleteUser')}
      >
        <Typography variant='h6' mb={3}>{t('deleteIncomeOutcomeConfirmation')}</Typography>
        <Button
          variant='contained'
          color='error'
          fullWidth
          onClick={() => {
            dispatch(deleteSingleUser(userId, () => navigate('/personal-settings')));
          }}
        >
          {t('delete')}
        </Button>
        <Box mt={3}>
          <Button
            variant='contained'
            color='warning'
            fullWidth
            onClick={() => setConfirmDeleteModalIsOpen(false)}
          >
            {t('decline')}
          </Button>
        </Box>
      </Dialog>
    </DefaultLayout>
  );
};

export default PersonalSettingsEdit;