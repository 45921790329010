import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  Stack,
  Typography,
  IconButton,
  Button,
  Box,
  Grid
} from '@mui/material';
import { ArrowIcon, EllipseIcon, EmptyStateIcon } from 'icons';
import { getCasesList } from 'stores/CasesSlice';
import { formatDate } from 'utils';
import { OverflowContent } from 'components';
import { useTranslation } from 'react-i18next';

const CasesList = ({ propertyId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { casesList, getCasesListIsLoading } = useSelector(({ casesState }) => casesState);
  const copiedCasesList = [...casesList];
  const reversedCasesList = copiedCasesList?.reverse();

  useEffect(() => {
    if (propertyId) {
      dispatch(getCasesList(propertyId));
    }
  }, [propertyId]);

  return (
    <OverflowContent head={(
      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <Typography variant='subtitle1'>{t('cases')}</Typography>
        <Stack direction='row' alignItems='center' spacing={1}>
          {/* <IconButton>
            <FilterIcon style={{ width: 12, height: 12 }} />
          </IconButton> */}
          <Button
            variant='contained'
            color='secondary'
            onClick={() => navigate(`/properties/${propertyId}/case`)}
          >
            {t('create')}
          </Button>
        </Stack>
      </Stack>
    )}>
      {getCasesListIsLoading && (
        <OverflowContent.Loader />
      )}
      {(!getCasesListIsLoading && !casesList.length) ? (
        <Box mt={10} textAlign='center'>
          <EmptyStateIcon />
          <Typography variant='h6'>{t('welcomeToCases')}</Typography>
          <Stack direction='row' spacing={1} mt={2} justifyContent='center' alignItems='center'>
            <EllipseIcon fill='#2F3191' style={{ width: 12, height: 12 }} />
            <Typography variant='h6'>{t('welcomeToCases1')}</Typography>
          </Stack>
          <Stack direction='row' spacing={1} mt={2} justifyContent='center' alignItems='center'>
            <EllipseIcon fill='#2F3191' style={{ width: 12, height: 12 }} />
            <Typography variant='h6'>{t('welcomeToCases2')}</Typography>
          </Stack>
        </Box>
      ) : (
        <>
          {reversedCasesList?.map(({ id, author, createdAt, files, isActive, title, notes }) => (
            <Box
              key={id}
              p={1}
              mt={2}
              sx={{
                backgroundColor: isActive ? '#FFFCFD' : '#FFF',
                border: isActive ? '1px solid #FBEAEF' : ' 1px solid #C5D7F2',
                borderRadius: 2,
                cursor: 'pointer'
              }}
              onClick={() => navigate(`/properties/${propertyId}/case-detail/${id}`)}
            >
              <Stack direction='row' alignItems='center' justifyContent='space-between'>
                <Typography variant='subtitle2' textTransform='uppercase'>{title}</Typography>
                <Typography variant='caption' color={isActive ? '#E988B1' : 'primary'}>
                  {isActive ? t('active') : t('allowed')}
                </Typography>
              </Stack>
              <Grid container mt={1} alignItems='center'>
                <Grid item md={3}>
                  <Stack>
                    <Typography variant='caption'>{t('createdAt')}</Typography>
                    <Typography variant='subtitle2'>{createdAt ? formatDate(createdAt, 'yyyy-MM-dd HH:mm') : ''}</Typography>
                  </Stack>
                </Grid>
                <Grid item md={3}>
                  <Stack>
                    <Typography variant='caption'>{t('createdFrom')}</Typography>
                    <Typography variant='subtitle2'>{author.fullName ?? ''}</Typography>
                  </Stack>
                </Grid>
                <Grid item md={3}>
                  <Stack>
                    <Typography variant='caption'>{t('notes')}</Typography>
                    <Typography variant='subtitle2'>{notes?.length > 1 ? `${notes?.length} ${t('notes')}` : t('without')}</Typography>
                  </Stack>
                </Grid>
                <Grid item md={2}>
                  <Stack>
                    <Typography variant='caption'>{t('files')}</Typography>
                    <Typography variant='subtitle2'>{files.length ?? ''}</Typography>
                  </Stack>
                </Grid>
                <Grid item md={1}>
                  <IconButton onClick={() => navigate(`/properties/${propertyId}/case-detail/${id}`)}>
                    <ArrowIcon style={{ width: 16, height: 16 }} />
                  </IconButton>
                </Grid>
              </Grid>
            </Box>
          ))}
        </>
      )}
    </OverflowContent>
  );
};

export default CasesList;