import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  IconButton,
  Menu,
  Stack,
  Typography,
  Box,
  Avatar,
  Button,
  MenuItem
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { CrossIcon, ProfileSettingsIcon, LogoutIcon, UsersIcon } from 'icons';
import { logout } from '../stores/AuthenticationSlice';


const BoxMenuItem = styled(MenuItem)(({ theme }) => `
  border: 1px solid ${theme.palette.info.main};
  border-radius: 5px;
  margin: 15px 0;
`);

const PersonalSettingsDropdown = ({ trigger }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { myUserData, organization } = useSelector(({ organizationState }) => organizationState);
  const currentLangCode = i18n?.language;

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <div>
      {trigger({ handleOpen })}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <Box p={1} sx={{ width: 350 }}>
          <Stack mb={1} direction='row' justifyContent='space-between' alignItems='center'>
            <Typography variant='subtitle2' textTransform='none'>{t('profileSettings')}</Typography>
            <IconButton onClick={handleClose}>
              <CrossIcon fill='#0E1833' />
            </IconButton>
          </Stack>
          <Stack direction='row' alignItems='center' justifyContent='flex-start' spacing={1}>
            <Avatar src={myUserData?.avatar} />
            <Stack>
              <Typography variant='subtitle2' textTransform='none'>{myUserData?.fullName}</Typography>
              <Typography variant='caption' sx={{ textTransform: 'none' }}>{organization?.fullName}</Typography>
            </Stack>
          </Stack>
          <Box p={2}>
            <Stack direction='row' alignItems='center' justifyContent='center'>
              <Button
                variant={currentLangCode === 'bg' ? 'contained' : 'outlined'}
                color='secondary'
                fullWidth
                onClick={() => i18n.changeLanguage('bg')}
              >
                {t('Bulgarian')}
              </Button>
              <Button
                variant={currentLangCode === 'en' ? 'contained' : 'outlined'}
                color='secondary'
                fullWidth
                onClick={() => i18n.changeLanguage('en')}
              >
                {t('English')}
              </Button>
            </Stack>
          </Box>
          <BoxMenuItem onClick={() => {
            navigate('/personal-settings');
            handleClose();
          }}>
            <Stack direction='row' alignItems='center' sx={{ width: '100%', justifyContent: 'space-between' }}>
              <Typography variant='subtitle2'>{t('profileSettings')}</Typography>
              <UsersIcon />
            </Stack>
          </BoxMenuItem>
          <BoxMenuItem onClick={() => navigate('global-settings')}>
            <Stack direction='row' alignItems='center' sx={{ width: '100%', justifyContent: 'space-between' }}>
              <Typography variant='subtitle2'>{t('globalSettings')}</Typography>
              <ProfileSettingsIcon />
            </Stack>
          </BoxMenuItem>
          <BoxMenuItem onClick={() => {
            dispatch(logout());
            handleClose();
          }} sx={{ borderColor: '#F5CCDB' }}>
            <Stack direction='row' alignItems='center' sx={{ width: '100%', justifyContent: 'space-between' }}>
              <Typography variant='subtitle2'>{t('exit')}</Typography>
              <LogoutIcon />
            </Stack>
          </BoxMenuItem>
        </Box>
      </Menu>
    </div >
  );
};

export default PersonalSettingsDropdown;