
const EditIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20.5'
    height='20.506'
    viewBox='0 0 20.5 20.506'
    {...props}
  >
    <path
      fill='#2f3191'
      stroke='#fff'
      strokeWidth='0.5'
      d='M3.25 16.256h4.24a1 1 0 00.71-.29l6.92-6.93h0l2.84-2.78a1 1 0 000-1.42L13.72.546a1 1 0 00-1.42 0l-2.82 2.83h0l-6.94 6.93a1 1 0 00-.29.71v4.24a1 1 0 001 1zm9.76-13.59l2.83 2.83-1.42 1.42-2.83-2.83zm-8.76 8.76l5.93-5.93 2.83 2.83-5.93 5.93H4.25zm15 6.83h-18a1 1 0 000 2h18a1 1 0 000-2z'
    ></path>
  </svg>
);

export default EditIcon;
