import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import {
  IconButton,
  Grid,
  Stack,
  Divider,
  Typography,
  Paper,
  Box
} from '@mui/material';
import { SettingsIcon, ArrowIcon } from 'icons';
import { formatAmount } from 'utils';
import { MonthRangePicker } from 'components';
import ContentBox, { contentBoxBottomGutter } from 'components/ContentBox';
import {
  getRentStatistics,
  getPropertiesStatistics
} from 'stores/DashboardSlice';

const PropertyStatistic = ({ cost, description, subDescription, style }) => {
  const { i18n } = useTranslation();
  return (
    <Paper variant='outlined' style={style}>
      <Box p={0.5}>
        <Stack direction='row' alignItems='center' spacing={2} justifyContent='space-around'>
          <Grid container>
            <Grid item md={10}>
              <Stack alignItems='center' direction='row' spacing={2}>
                <Typography variant='h6'>
                  {formatAmount(cost, i18n?.language)}
                </Typography>
                <div>
                  <Typography variant='body1' fontWeight={600}>
                    {description}
                  </Typography>
                  <Typography variant='body1'>
                    {subDescription}
                  </Typography>
                </div>
              </Stack>
            </Grid>
            {/* {onClick && (
              <Grid item md={2}>
                <div>
                  <IconButton onClick={onClick}>
                    <ArrowIcon style={{ width: 16, height: 16 }} />
                  </IconButton>
                </div>
              </Grid>
            )} */}
          </Grid>
        </Stack>
      </Box>
    </Paper>
  );
};

const EmploymentStatistics = ({ count, description, color }) => (
  <Stack alignItems='center' direction='row' spacing={1} mt={1}>
    <Divider orientation='vertical' variant='middle' flexItem sx={{ background: color, height: 24, width: 3, borderRadius: 5 }} />
    <Typography variant='h5'>{count}</Typography>
    <Typography variant='caption'>{description}</Typography>
  </Stack>
);

const BusynessStatistics = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [date, setDate] = useState(undefined);

  const { dashboardState: { rentStatistics, propertiesStatistics } } = useSelector(({ dashboardState }) => ({ dashboardState }));

  useEffect(() => {
    const formatDate = (newDate) => newDate ? format(newDate, 'MM-yyyy') : '';
    const params = date ? {
      from: formatDate(date?.from),
      to: formatDate(date?.to),
    } : {};
    dispatch(getRentStatistics(params));
    dispatch(getPropertiesStatistics(params));
  }, [date]);

  const propertiesOptions = {
    colors: ['#2F3191', '#00D297', '#C5D7F2'],
    plotOptions: {
      radialBar: {
        size: undefined,
        inverseOrder: false,
        startAngle: -180,
        endAngle: 180,
        offsetX: -30,
        offsetY: -15,
        track: {
          show: true,
          startAngle: undefined,
          endAngle: undefined,
          background: '#f2f2f2',
          strokeWidth: '75%',
          opacity: 1,
          margin: 6,
          dropShadow: {
            enabled: false,
            top: 0,
            left: 0,
            blur: 3,
            opacity: 0.5
          }
        },
        dataLabels: {
          show: true,
          name: {
            show: false,
            fontSize: '22px',
            fontFamily: undefined,
            offsetY: -55
          },
          value: {
            show: true,
            fontSize: '26px',
            fontFamily: undefined,
            color: '#00D297',
            offsetY: 6,
            formatter: function (val) {
              return val + '%';
            }
          },
          total: {
            show: false,
            label: '',
            color: '#373d3f',
            formatter: function (w) {
              return (
                w.globals.seriesTotals.reduce((a, b) => a + b, 0) /
                w.globals.series.length +
                '%'
              );
            }
          }
        }
      }
    },
  };

  const series = [((propertiesStatistics?.total * 100) / propertiesStatistics?.total).toFixed(2), ((propertiesStatistics?.rented * 100) / propertiesStatistics?.total).toFixed(2), ((propertiesStatistics?.free * 100) / propertiesStatistics?.total).toFixed(2)];

  return (
    <Grid container>
      <Grid item sm={6}>
        <ContentBox
          title={t('busyness')}
          caption={date ? `${t(format(date?.from, 'LLLL.yy'))} - ${format(date?.to, 'LLLL.yy')}` : ''}
          iconButton={
            <MonthRangePicker
              onSelectDate={(filterDate) => setDate(filterDate)}
              trigger={() => (
                <IconButton>
                  <SettingsIcon style={{ width: 12, height: 12 }} />
                </IconButton>
              )}
            />
          }
        >
          <Grid container>
            <Grid item sm={6}>
              <div style={{ width: '100%' }}>
                <Chart options={propertiesOptions} series={series} type='radialBar' width='200' height='200' />
              </div>
            </Grid>
            <Grid item sm={6}>
              <EmploymentStatistics
                count={propertiesStatistics?.total}
                description={t('addedProperties')}
                color='#711CD2'
              />
              <EmploymentStatistics
                count={propertiesStatistics?.rented}
                description={t('rentedProperties')}
                color='#00D297'
              />
              <EmploymentStatistics
                count={propertiesStatistics?.free}
                description={t('availableProperties')}
                color='#C5D7F2'
              />
            </Grid>
          </Grid>
        </ContentBox>
      </Grid>
      <Grid item sm={6}>
        <div>
          <PropertyStatistic
            cost={rentStatistics?.avarage}
            description={t('averageAmount')}
            style={{ marginBottom: contentBoxBottomGutter, height: '50%', paddingBottom: 16 }}
          />
          <PropertyStatistic
            cost={rentStatistics?.highest?.rent}
            description={t('highestAmount')}
            subDescription={rentStatistics?.highest?.nicknames?.landlord}
            onClick={() => { }}
            style={{ height: `calc(50% - ${contentBoxBottomGutter}px)`, marginBottom: contentBoxBottomGutter }}
          />
          <PropertyStatistic
            cost={rentStatistics?.lowest?.rent}
            description={t('lowestAmount')}
            subDescription={rentStatistics?.lowest?.nicknames?.landlord}
            onClick={() => { }}
            style={{ height: `calc(50% - ${contentBoxBottomGutter}px)`, marginBottom: contentBoxBottomGutter }}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default BusynessStatistics;