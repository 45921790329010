import { styled, Box, CircularProgress } from '@mui/material';

const Wrapper = styled('div')`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Content = styled('div')`
  height: 100%;
  overflow: auto;
`;

const OverflowContent = ({ head, children }) => (
  <Wrapper>
    {Boolean(head) && (
      <Box mb={2}>
        {head}
      </Box>
    )}
    <Content>
      {children}
    </Content>
  </Wrapper>
);

OverflowContent.Loader = () => (
  <Box height='100%' display='flex' width='100%' justifyContent='center' pt={5}>
    <CircularProgress size={100} />
  </Box>
);

export default OverflowContent;