import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactEcharts from 'echarts-for-react';
import { IconButton, Grid, Stack, Typography, Box } from '@mui/material';
import { SettingsIcon, PropertiesIcon, FinanceIcon } from 'icons';
import { formatAmount } from 'utils';
import { format } from 'date-fns';
import { MonthRangePicker } from 'components';
import ContentBox from 'components/ContentBox';
import { useTranslation } from 'react-i18next';
import {
  getIncomeByCities,
  getPropertiesByCities,
} from 'stores/DashboardSlice';

const PropertiesIncomesByCities = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [date, setDate] = useState(undefined);
  const [incomesLayout, setIncomesLayout] = useState('incomesByCities');

  const { dashboardState: {
    propertiesByCities,
    incomeByCities,
    getIncomesByCityIsLoading,
    getPropertiesByCitiesIsLoading
  } } = useSelector(({ dashboardState }) => ({ dashboardState }));

  const incomeByCitiesArray = Object.entries(incomeByCities);
  const propertiesByCitiesArray = Object.entries(propertiesByCities);
  const hasProperties = Boolean(propertiesByCitiesArray?.length);
  const hasCities = Boolean(incomeByCitiesArray?.length);
  const emptyChartData = Boolean((incomesLayout === 'incomesByCities' && !hasCities) || (incomesLayout === 'propertiesByCities' && !hasProperties));

  useEffect(() => {
    const formatDate = (newDate) => newDate ? format(newDate, 'MM-yyyy') : '';
    const params = date ? {
      from: formatDate(date?.from),
      to: formatDate(date?.to),
    } : {};
    dispatch(getPropertiesByCities(params));
    dispatch(getIncomeByCities(params));
  }, [date]);

  const incomesByCities = {
    legend: {
      top: '0',
      left: '30%',
      orient: 'horizontal',
      selectedMode: false,
    },
    series: [
      {
        type: 'pie',
        center: ['15%', '20%'],
        radius: ['20%', '30%'],
        label: {
          show: false,
        },
        labelLine: {
          show: false,
        },
        data: incomeByCitiesArray?.map((item) => (
          { value: item[1], name: `${item[0]} - ${formatAmount(item[1], i18n.language)}` }
        ))
      }
    ]
  };

  const propertieByCities = {
    legend: {
      top: '0',
      left: '30%',
      orient: 'horizontal',
      selectedMode: false
    },
    series: [
      {
        type: 'pie',
        center: ['15%', '20%'],
        radius: ['20%', '30%'],
        label: {
          show: false,
        },
        labelLine: {
          show: false,
        },
        data: propertiesByCitiesArray?.map((item) => (
          { value: item[1], name: `${item[0]} - ${item[1]}` }
        ))
      }
    ]
  };

  const emptyIncomesData = !Boolean(incomeByCities) && !getIncomesByCityIsLoading;
  const emptyPropertiesData = !Boolean(propertiesByCities) && !getPropertiesByCitiesIsLoading;

  return (
    <ContentBox
      title={incomesLayout === 'incomesByCities' ? t('incomesCities') : t('propertiesByCities')}
      caption={date ? `${t(format(date?.from, 'LLLL.yy'))} - ${format(date?.to, 'LLLL.yy')}` : ''}
      iconButton={
        <MonthRangePicker
          onSelectDate={(filterDate) => setDate(filterDate)}
          trigger={() => (
            <IconButton>
              <SettingsIcon style={{ width: 12, height: 12 }} />
            </IconButton>
          )}
        />
      }
      contentHeight={180}
    >
      <Grid container>
        <Grid
          item
          sm={emptyChartData ? 5 : 11}
        >
          {incomesLayout === 'incomesByCities' ? (
            <div style={{ width: '100%', marginTop: 20, filter: `grayscale(${emptyIncomesData ? 1 : 0})` }}>
              <ReactEcharts option={incomesByCities} />
            </div>
          ) : (
            <div style={{ width: '100%', marginTop: 20, filter: `grayscale(${emptyPropertiesData ? 1 : 0})` }}>
              <ReactEcharts option={propertieByCities} />
            </div>
          )}
        </Grid>
        <Grid
          item
          sm={emptyChartData ? 7 : 1}
        >
          {(incomesLayout === 'propertiesByCities' && !hasProperties) && (
            <Box mt={3} sx={{ alignItems: 'center' }}>
              <Typography variant='h6'>{t('noPropertiesForCurrentPeriod')}</Typography>
            </Box>
          )}
          {(incomesLayout === 'incomesByCities' && !hasCities) && (
            <Box mt={3} sx={{ alignItems: 'center' }}>
              <Typography variant='h6'>{t('noPropertiesForCurrentPeriod')}</Typography>
            </Box>
          )}
          <Stack mt={10} direction='row' spacing={1} justifyContent='flex-end'>
            <IconButton color='primary' onClick={() => setIncomesLayout('incomesByCities')}>
              <FinanceIcon style={{ width: 16, height: 16, filter: `grayscale(${incomesLayout === 'propertiesByCities' ? 1 : 0})` }} />
            </IconButton>
            <IconButton color='primary' onClick={() => setIncomesLayout('propertiesByCities')}>
              <PropertiesIcon style={{ width: 16, height: 16, filter: `grayscale(${incomesLayout === 'incomesByCities' ? 1 : 0})` }} />
            </IconButton>
          </Stack>
        </Grid>
      </Grid>
    </ContentBox>
  );
};

export default PropertiesIncomesByCities;