import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { PaymentBillsForm } from 'components/forms';
import { getUtilityBills } from 'stores/UtilityBillsSlice';


const EditPaymentBills = () => {
  const dispatch = useDispatch();
  const { propertyId } = useParams();
  const { utilityBillsList } = useSelector(({ utilityBillsState }) => utilityBillsState);

  console.log('utilityBillsList', utilityBillsList);

  useEffect(() => {
    if (propertyId) {
      dispatch(getUtilityBills(propertyId));
    }
  }, [propertyId]);

  return (
    <PaymentBillsForm
      isEdit
      defaultValues={utilityBillsList}
    />
  );
};

export default EditPaymentBills;