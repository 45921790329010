
const SearchIcon = ({ props }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='18.506'
    height='18.506'
    viewBox='0 0 18.506 18.506'
    {...props}
  >
    <path
      fill='#2f3191'
      stroke='#fff'
      strokeWidth='0.5'
      d='M17.96 16.54l-3.4-3.39a7.92 7.92 0 001.69-4.9 8 8 0 10-8 8 7.92 7.92 0 004.9-1.69l3.39 3.4a1.004 1.004 0 101.42-1.42zM2.25 8.25a6 6 0 116 6 6 6 0 01-6-6z'
      data-name='Path 55645'
    ></path>
  </svg>
);

export default SearchIcon;
