import { formatDistanceToNow as dateFnsFormatDistance } from 'date-fns';
import { bg, enGB } from 'date-fns/locale';

const formatDistanceToNow = (dateString, lang = 'bg') => {
  const langs = {
    bg,
    en: enGB,
  };

  return dateFnsFormatDistance(new Date(dateString), {
    addSuffix: true,
    locale: langs[lang],
  });
};

export default formatDistanceToNow;
