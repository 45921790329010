import { Paper } from '@mui/material';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { PropertyFilesLibrary } from 'components';
import { PropertyFormLayout } from 'layouts';

const PropertyFiles = () => {
  const { propertyId } = useParams();

  const {
    propertiesState: { singleProperty },
  } = useSelector(({ propertiesState, filesState }) => ({ propertiesState, filesState }));

  return (
    <PropertyFormLayout hasLandlord={Boolean(singleProperty?.tenant)}>
      {() => (
        <Paper elevation={4}>
          <PropertyFilesLibrary propertyId={propertyId} />
        </Paper>
      )}
    </PropertyFormLayout >
  );
};

export default PropertyFiles;