
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Stack,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';

const StyledDialog = styled(Dialog)(({ placement = 'center' }) => `
  & .MuiDialog-container {
    justify-content: ${placement};
  };
`);

const DialogComponent = ({ open, onClose, title, children, placement, dialogWidth = '500px' }) => (
  <StyledDialog
    onClose={onClose}
    open={open}
    placement={placement}
  >
    <div style={{ width: dialogWidth }}>
      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <DialogTitle sx={{ width: '80%' }}>
          <Typography sx={{ wordWrap: 'break-word' }} variant='h5'>{title}</Typography>
        </DialogTitle>
        {Boolean(onClose) && (
          <IconButton onClick={onClose} >
            <CloseIcon />
          </IconButton>
        )}
      </Stack>
      <DialogContent>
        {children}
      </DialogContent>
    </div>
  </StyledDialog>
);

export default DialogComponent;