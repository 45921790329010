
const CompletePaymentIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='28'
    height='28'
    viewBox='0 0 28 28'
    {...props}
  >
    <g data-name='Group 33575' transform='translate(-11239.558 -7443.558)'>
      <path
        fill='none'
        d='M0 0H28V28H0z'
        data-name='Rectangle 14464'
        transform='translate(11239.558 7443.558)'
      ></path>
      <path
        fill='#2f3191'
        d='M24.882 18.449l-.392-1.329a5.924 5.924 0 00-10.1-2.279 5.76 5.76 0 00-1.329 2.467l-.163.639H2.695A1.7 1.7 0 011 16.252V2.695A1.7 1.7 0 012.695 1h22.031a1.7 1.7 0 011.695 1.695v13.557a1.7 1.7 0 01-.535 1.241zM2.695 2.695v13.557h8.912a7.446 7.446 0 011.517-2.542 7.6 7.6 0 0111.345 0c.086.094.169.192.25.291L24.726 2.7zm22.031 0z'
        data-name='Path 55919'
        transform='translate(11239.848 7443.847)'
      ></path>
      <path
        fill='#2f3191'
        d='M10.084 11.779H6.695A1.7 1.7 0 015 10.084V6.695A1.7 1.7 0 016.695 5h3.389a1.7 1.7 0 011.695 1.695v3.389a1.7 1.7 0 01-1.695 1.695zM6.695 6.695v3.389h3.391V6.695zm10.168 2.542h-2.542a.847.847 0 010-1.695h2.542a.847.847 0 010 1.695zm5.931 0h-2.542a.847.847 0 010-1.695h2.542a.847.847 0 010 1.695zM19.4 27.031a7.6 7.6 0 01-7.181-5.085 7.347 7.347 0 01-.445-2.541 7.635 7.635 0 011.956-5.084 7.614 7.614 0 0113.3 5.084 7.45 7.45 0 01-.3 2.073 7.62 7.62 0 01-7.33 5.553zm0-13.558a5.909 5.909 0 00-4.4 1.979 5.945 5.945 0 00-1.526 3.948 5.686 5.686 0 00.346 1.969 5.92 5.92 0 0011.286-.357 5.742 5.742 0 00.231-1.613 6.011 6.011 0 00-.237-1.677 5.991 5.991 0 00-5.7-4.248z'
        data-name='Path 55920'
        transform='translate(11239.236 7443.237)'
      ></path>
      <circle
        cx='8'
        cy='8'
        r='8'
        fill='#2f3191'
        data-name='Ellipse 9322'
        transform='translate(11250.558 7454.558)'
      ></circle>
      <g
        fill='#fff'
        data-name='Group 33574'
        transform='translate(11253.88 7458.066)'
      >
        <path
          d='M22.578 43.011h-2.2a.549.549 0 01-.549-.549v-2.2a.549.549 0 111.1 0v1.647h1.647a.549.549 0 010 1.1z'
          data-name='Path 55921'
          transform='translate(-19.833 -39.18)'
        ></path>
        <path
          d='M24.783 37.152a4.546 4.546 0 01-3.236-1.34.549.549 0 01.777-.777 3.478 3.478 0 100-4.918l-1.553 1.553a.549.549 0 01-.777-.777l1.553-1.553a4.576 4.576 0 113.236 7.811z'
          data-name='Path 55922'
          transform='translate(-19.833 -28)'
        ></path>
      </g>
    </g>
  </svg>
);

export default CompletePaymentIcon;
