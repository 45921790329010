import {
  IconButton,
  Stack,
  Typography,
  Box,
  Paper,
  Grid,
  Card,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ActionsIcon, EditIcon } from 'icons';
import { DefaultLayout } from 'layouts';
import { formatAmount } from 'utils';
import { MenuList } from 'components';

const PersonalSettingsSubscription = () => {
  const { t } = useTranslation();
  return (
    <DefaultLayout>
      <Paper elevation={4} sx={{ width: 850, margin: '0 auto' }}>
        <Box p={3}>
          <Stack direction='row' justifyContent='space-between' alignItems='center'>
            <Typography variant='h5'>{t('subsPlan')}</Typography>
            <MenuList
              trigger={({ handleOpen }) => (
                <IconButton onClick={handleOpen}>
                  <ActionsIcon style={{ width: 16, height: 16 }} />
                </IconButton>
              )}
              items={[
                {
                  label: t('editPayment'),
                  description: t('editPaymentText'),
                  icon: EditIcon,
                },
                {
                  label: t('editSubs'),
                  description: t('editSubsText'),
                  icon: EditIcon,
                },
              ]}
            />
          </Stack>
          <Box mt={2}>
            <Card variant='outlined' sx={{ backgroundColor: '#F5FFFC' }}>
              <Grid container>
                <Grid item md={3}>
                  <Typography variant='caption'>{t('status')}</Typography>
                  <Typography variant='subtitle2'>Активен</Typography>
                </Grid>
                <Grid item md={3}>
                  <Typography variant='caption'>{t('paymentAmount')}</Typography>
                  <Typography variant='subtitle2'>{formatAmount(474)} ({formatAmount(79)}/имот)</Typography>
                </Grid>
                <Grid item md={3}>
                  <Typography variant='caption'>{t('managementOn')}</Typography>
                  <Typography variant='subtitle2'>6 имота</Typography>
                </Grid>
                <Grid item md={3}>
                  <Typography variant='caption'>{t('status')}</Typography>
                  <Typography variant='subtitle2'>Активен</Typography>
                </Grid>
              </Grid>
              <Grid container mt={1}>
                <Grid item md={3}>
                  <Typography variant='caption'>{t('paymentWay')}</Typography>
                  <Typography variant='subtitle2'>Банкова карта</Typography>
                </Grid>
                <Grid item md={3}>
                  <Typography variant='caption'>{t('cardHolder')}</Typography>
                  <Typography variant='subtitle2'>Мартин Александров</Typography>
                </Grid>
                <Grid item md={3}>
                  <Typography variant='caption'>{t('paymentWithCard')}</Typography>
                  <Typography variant='subtitle2'>**** **** **** **** 4444</Typography>
                </Grid>
              </Grid>
            </Card>
          </Box>
        </Box>
      </Paper>
      <Box mt={2}>
        <Paper elevation={4} sx={{ width: 850, margin: '0 auto', height: '100%' }}>
          <Box p={3}>
            <Stack direction='row' alignItems='center' justifyContent='space-between'>
              <Typography variant='h5'>{t('paymentsHistory')}</Typography>
              <IconButton>
                <ActionsIcon style={{ width: 16, height: 16 }} />
              </IconButton>
            </Stack>
            <Box mt={2}>
              <Card variant='outlined'>
                <Grid container>
                  <Grid item md={6}>
                    <Typography variant='subtitle2' textTransform='uppercase'>
                      22 февруари ’22 - 11 декември ‘22
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container mt={1}>
                  <Grid item md={3}>
                    <Typography variant='caption'>{t('paidOn')}</Typography>
                    <Typography variant='subtitle2'>22.02.2022 | 14:20</Typography>
                  </Grid>
                  <Grid item md={3}>
                    <Typography variant='caption'>{t('paidAmount')}</Typography>
                    <Typography variant='subtitle2'>72.80</Typography>
                  </Grid>
                  <Grid item md={3}>
                    <Typography variant='caption'>{t('paymentWay')}</Typography>
                    <Typography variant='subtitle2'>Банков път</Typography>
                  </Grid>
                  <Grid item md={3}>
                    <Typography variant='caption'>{t('paidFor')}</Typography>
                    <Typography variant='subtitle2'>5 имота</Typography>
                  </Grid>
                </Grid>
              </Card>
            </Box>
            <Box mt={2}>
              <Card variant='outlined'>
                <Grid container>
                  <Grid item md={6}>
                    <Typography variant='subtitle2' textTransform='uppercase'>
                      22 февруари ’22 - 11 декември ‘22
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container mt={1}>
                  <Grid item md={3}>
                    <Typography variant='caption'>{t('paidOn')}</Typography>
                    <Typography variant='subtitle2'>22.02.2022 | 14:20</Typography>
                  </Grid>
                  <Grid item md={3}>
                    <Typography variant='caption'>{t('paidAmount')}</Typography>
                    <Typography variant='subtitle2'>72.80</Typography>
                  </Grid>
                  <Grid item md={3}>
                    <Typography variant='caption'>{t('paymentWay')}</Typography>
                    <Typography variant='subtitle2'>Банков път</Typography>
                  </Grid>
                  <Grid item md={3}>
                    <Typography variant='caption'>{t('paidFor')}</Typography>
                    <Typography variant='subtitle2'>5 имота</Typography>
                  </Grid>
                </Grid>
              </Card>
            </Box>
          </Box>
        </Paper>
      </Box>
    </DefaultLayout >
  );
};

export default PersonalSettingsSubscription;