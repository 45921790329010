import { createSlice } from '@reduxjs/toolkit';
import queryString from 'query-string';
import S3FileUpload from 'react-s3';
import httpClient from '../api/httpClient';
window.Buffer = window.Buffer || require('buffer').Buffer;

const s3Config = {
  bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
  dirName: 'immotech-web-app',
  region: 'eu-central-1',
  accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_S3_ACCESS_KEY,
};

const initialState = {
  files: [],
  getFilesIsLoading: false,
  createFileIsLoading: false,
  createFolderIsLoading: false,
  updateFileIsLoading: false,
  setImpDataIsLoading: false,
  deleteFileOrFolderIsLoading: false,
};

export const FilesSlice = createSlice({
  name: 'Files',
  initialState,
  reducers: {
    getFilesRequesting: (state) => {
      state.getFilesIsLoading = true;
    },
    getFilesSuccess: (state, { payload }) => {
      state.getFilesIsLoading = false;
      state.files = payload;
    },
    getFilesError: (state) => {
      state.getFilesIsLoading = false;
    },
    createFileRequesting: (state) => {
      state.createFileIsLoading = true;
    },
    createFileSuccess: (state, { payload }) => {
      state.createFileIsLoading = false;
      if (payload?.success) {
        const file = payload?.data;
        state.files = [
          ...state.files,
          file,
        ];
      }
    },
    createFileError: (state) => {
      state.createFileIsLoading = false;
    },
    createFolderRequesting: (state) => {
      state.createFolderIsLoading = true;
    },
    createFolderSuccess: (state, { payload }) => {
      state.createFolderIsLoading = false;
      if (payload?.data?.success) {
        const folder = payload?.data?.data;
        state.files = [
          ...state.files,
          folder,
        ];
      }
    },
    createFolderError: (state) => {
      state.createFolderIsLoading = false;
    },
    updateFileOrFolderRequesting: (state) => {
      state.updateFileIsLoading = true;
    },
    updateFileOrFolderSuccess: (state, { payload }) => {
      state.updateFileIsLoading = false;
      if (payload?.data?.success) {
        const updatedItem = payload?.data?.data;
        state.files = state.files.map((item) => {
          if (item.id === updatedItem.id) {
            return updatedItem;
          }
          return item;
        });
      }
    },
    updateFileOrFolderError: (state) => {
      state.updateFileIsLoading = false;
    },
    setImpDateRequesting: (state) => {
      state.setImpDataIsLoading = true;
    },
    setImpDateSuccess: (state, { payload }) => {
      state.setImpDataIsLoading = false;
      if (payload?.data?.success) {
        const updatedItem = payload?.data?.data;
        state.files = state.files.map((item) => {
          if (item.id === updatedItem.id) {
            return updatedItem;
          }
          return item;
        });
      };
    },
    setImpDateError: (state) => {
      state.setImpDataIsLoading = false;
    },
    deleteFileOrFolderRequesting: (state) => {
      state.deleteFileOrFolderIsLoading = true;
    },
    deleteFileOrFolderSuccess: (state, { payload }) => {
      state.deleteFileOrFolderIsLoading = false;
      const deleteId = payload?.data?.data?._id;
      state.files = state.files.filter(({ id }) => id !== deleteId);
    },
    deleteFileOrFolderError: (state) => {
      state.deleteFileOrFolderIsLoading = false;
    },
    removeFileItem: (state, { payload: id }) => {
      state.files = state.files.filter((item) => item.id !== id);
    },
  }
});

export const {
  getFilesRequesting,
  getFilesSuccess,
  getFilesError,
  createFileRequesting,
  createFileSuccess,
  createFileError,
  createFolderRequesting,
  createFolderSuccess,
  createFolderError,
  updateFileOrFolderRequesting,
  updateFileOrFolderSuccess,
  updateFileOrFolderError,
  setImpDateRequesting,
  setImpDateSuccess,
  setImpDateError,
  deleteFileOrFolderRequesting,
  deleteFileOrFolderSuccess,
  deleteFileOrFolderError,
  removeFileItem,
} = FilesSlice.actions;

export const getFiles = (data, onSuccess, isSetToState = true) => async (dispatch) => {
  if (isSetToState) {
    dispatch(getFilesRequesting());
  }
  httpClient.get(`/files?&${queryString.stringify(data)}`).then(
    (response) => {
      if (isSetToState) {
        dispatch(getFilesSuccess(response?.data?.data));
      }
      if (onSuccess) {
        onSuccess(response);
      }
    },
    (error) => {
      if (isSetToState) {
        dispatch(getFilesError(error));
      }
    },
  );
};

export const createFile = (file, propertyId, parentDir, onSuccess) => async (dispatch) => {
  dispatch(createFileRequesting());
  S3FileUpload.uploadFile(file, s3Config)
    .then(data => {
      httpClient.post('/files', {
        propertyId,
        name: file?.name,
        url: data?.location,
        ...(parentDir && { parentDir: parentDir }),
        type: 'file',
      }).then(
        (response) => {
          dispatch(createFileSuccess(response?.data));
          if (onSuccess) {
            onSuccess(response);
          }
        },
        (error) => dispatch(createFileError(error)),
      );
    })
    .catch((error) => dispatch(createFileError(error)));
};

export const createFolder = (data, onSuccess) => async (dispatch) => {
  dispatch(createFolderRequesting());
  httpClient.post('/files', {
    ...data,
    type: 'folder',
  }).then(
    (response) => {
      dispatch(createFolderSuccess(response));
      if (onSuccess) {
        onSuccess(response);
      }
    },
    (error) => dispatch(createFolderError(error)),
  );
};

export const updateFileOrFolder = (data, id, onSuccess) => async (dispatch) => {
  dispatch(updateFileOrFolderRequesting());
  httpClient.put(`/files/${id}`, data).then(
    (response) => {
      dispatch(updateFileOrFolderSuccess(response));
      if (onSuccess) {
        onSuccess(response);
      }
    },
    (error) => dispatch(updateFileOrFolderError(error)),
  );
};

export const setImpDate = (data, id, onSuccess) => async (dispatch) => {
  dispatch(setImpDateRequesting());
  httpClient.post(`/files/imp-date/${id}`, data).then(
    (response) => {
      dispatch(setImpDateSuccess(response));
      if (onSuccess) {
        onSuccess(response);
      }
    },
    (error) => dispatch(setImpDateError(error)),
  );
};

export const deleteFileOrFolder = (id) => async (dispatch) => {
  dispatch(deleteFileOrFolderRequesting());
  httpClient.delete(`/files/${id}`).then(
    (response) => {
      dispatch(deleteFileOrFolderSuccess(response));
    },
    (error) => dispatch(deleteFileOrFolderError(error)),
  );
};

export const s3Upload = (file, onSuccess) => async () => {
  S3FileUpload.uploadFile(file, s3Config)
    .then(data => {
      if (onSuccess) {
        onSuccess(data);
      }
    });
};

export default FilesSlice.reducer;
