import { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Paper,
  Typography,
  Box,
  Stack,
  Tabs,
  Tab,
  IconButton,
  CircularProgress,
  Button
} from '@mui/material';
import { DefaultLayout } from 'layouts';
import {
  ArrowBackIcon,
} from 'icons';
import {
  resetSingleProperty,
  getSingleProperty,
  editProperty
} from 'stores/PropertiesSlice';
import { prepareFirstRent } from 'stores/RentSlice';
import { handleBeforeReload } from 'utils';

const PropertyFormLayout = ({ hasLandlord, children }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { propertyId } = useParams();
  const location = useLocation();
  const currentPath = location?.pathname;
  const dispatch = useDispatch();
  const isCreate = !Boolean(propertyId);
  const { singleProperty, getSinglePropertyIsLoading } = useSelector(({ propertiesState }) => propertiesState);
  const hasBaseParamsData = Boolean(singleProperty?.id);
  const hasTenantData = Boolean(singleProperty?.tenant?._id);
  const hasPaymentBillsData = Boolean(singleProperty?.utilityBills?.length);
  const [disabledButton, setDisabledButton] = useState(false);

  const TABS = [
    {
      route: `/properties${isCreate ? '/create' : `/${propertyId}/base-params`}`,
      label: t('baseParams'),
      infoText: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s',
    },
    (hasLandlord && {
      route: `/properties/${propertyId}/tenant`,
      label: t('tenantData'),
      infoText: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s',
    }),
    {
      route: `/properties/${propertyId}/payment-bills`,
      label: t('bills'),
      infoText: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s',
    },
    {
      route: `/properties/${propertyId}/files`,
      label: t('files'),
      infoText: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s',
    },
  ];

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeReload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeReload);
    };
  }, []);

  // Cleanup single property state
  useEffect(() => () => {
    dispatch(resetSingleProperty());
  }, []);

  useEffect(() => {
    if (propertyId) {
      dispatch(getSingleProperty(propertyId));
    }
  }, [propertyId]);

  useEffect(() => {
    if (!isCreate) {
      if (hasBaseParamsData && currentPath === `/properties/${propertyId}/base-params`) {
        navigate('/properties');
      }

      if (hasTenantData && currentPath === `/properties/${propertyId}/tenant`) {
        navigate('/properties');
      }

      if (hasPaymentBillsData && currentPath === `/properties/${propertyId}/payment-bills`) {
        navigate('/properties');
      }
    }
  }, [
    hasBaseParamsData,
    hasTenantData,
    hasPaymentBillsData,
    currentPath,
    isCreate,
  ]);

  return (
    <DefaultLayout>
      <Paper elevation={4}>
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <Stack direction='row' spacing={1}>
            <IconButton onClick={() => {
              // eslint-disable-next-line no-restricted-globals
              if (confirm('Are you sure you want to leave? All provided data will be lost.') === true) {
                navigate('/properties');
              }
            }}>
              <ArrowBackIcon
                style={{ width: 16, height: 16 }}
              />
            </IconButton>
            <Typography gutterBottom={false} variant='h5'>{t('addProperty2')}</Typography>
          </Stack>
          {currentPath?.includes('files') && (
            <Button
              variant='contained'
              disabled={disabledButton}
              onClick={() => {
                dispatch(editProperty({ completed: true }, propertyId, (response) => {
                  dispatch(prepareFirstRent({
                    _id: response?.data?.property?.id
                  }, () => navigate('/properties')));
                  setDisabledButton(true);
                }));
              }}>
              {t('save')}
            </Button>
          )}
        </Stack>
      </Paper>
      <Box mt={3}>
        <Tabs variant='fullWidth' value={currentPath}>
          {TABS.map((item, index) => {
            const { route, label } = item ?? {};
            return item ? (
              <Tab
                key={`tab-${label}-item-${index}-${item}`}
                value={route}
                onClick={() => navigate(route)}
                disabled={route !== currentPath}
                {...{ label }}
              />
            ) : <div></div>;
          })}
        </Tabs>
      </Box>
      <Box mt={3}>
        {getSinglePropertyIsLoading ? (
          <Box mt={5} mb={5} textAlign='center'>
            <CircularProgress size={130} />
          </Box>
        ) : (
          <>
            {children({ hasBaseParamsData, propertyId, hasTenantData, hasPaymentBillsData })}
          </>
        )}
      </Box>
    </DefaultLayout >
  );
};

export default PropertyFormLayout;
