import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Stack,
  Box,
  Typography,
  useTheme,
  Paper,
  ButtonBase,
  Switch,
  FormGroup,
  FormControlLabel,
  Checkbox,
  IconButton
} from '@mui/material';
import { formatAmount, formatDate } from 'utils';
import { FinancesLayout } from 'layouts';
import { ProfileSettingsIcon } from 'icons';
import { Table, Dialog, SelectBankAccount } from 'components';
import {
  updateTransactions,
  getTransactions
} from 'stores/FinancesSlice';
import { useTranslation } from 'react-i18next';

const arrayFieldName = 'banks';

const validations = Yup.object().shape({
  banks: Yup.array().of(
    Yup.object().shape({
      _id: Yup.string(),
      isHidden: Yup.boolean()
    })
  )
});

const FinancesBank = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();

  const [addBankAccountIsOpen, setAddBankAccountIsOpen] = useState(false);
  const [controlBankDialogIsOpen, setControlBankDialogIsOpen] = useState(false);
  const [hideTransactionDialogIsOpen, setHideTransactionsDialogIsOpen] = useState(false);
  const [selectedTransactionIndex, setSelectedTransactionIndex] = useState('');
  const [disableTransactionConfirm, setDisableTransactionConfirm] = useState(false);


  const bankColumns = [
    {
      Header: t('type'),
      accessor: 'type',
      Cell: ({ value }) => {
        if (value === 'income') {
          return <div>{t('income')}</div>;
        } else {
          return <div>{t('outcome')}</div>;
        }
      }
    },
    {
      Header: t('amount'),
      accessor: 'amount',
      Cell: ({ value, row }) => {
        const isOutcome = Boolean(row?.original?.type === 'outcome');
        if (isOutcome && !value.includes('-')) {
          return <div>{`-${formatAmount(value, i18n?.language)}`}</div>;
        } else {
          return <div>{formatAmount(value, i18n?.language)}</div>;
        }
      }
    },
    {
      Header: t('payer'),
      accessor: 'debtorName',
      Cell: ({ value, row }) => {
        const isIncome = row?.original?.type === 'income';
        const debtorName = row?.original?.debtorName;
        const debtorIban = row?.original?.debtorIban;
        const hasDebtorName = Boolean(debtorName);
        const hasDebtorIban = Boolean(debtorIban);
        const creditorName = row?.original?.creditorName;
        const creditorIban = row?.original?.creditorIban;
        const hasCreditorName = Boolean(creditorName);
        const hasCreditorIban = Boolean(creditorIban);
        if (isIncome) {
          if (hasDebtorName) {
            return <div>{debtorName}</div>;
          } else if ((!hasDebtorName && hasDebtorIban)) {
            return <div>{debtorIban}</div>;
          } else if ((!hasDebtorIban && !hasDebtorIban)) {
            return <div>--</div>;
          }
        } else {
          if (hasCreditorName) {
            return <div>{creditorName}</div>;
          } else if ((!hasCreditorName && creditorIban)) {
            return <div>{creditorIban}</div>;
          } else if ((!hasCreditorName && !hasCreditorIban)) {
            return <div>--</div>;
          }
        }
      }
    },
    {
      Header: t('date'),
      accessor: 'bookingDate',
      Cell: ({ value }) => (
        <div>{formatDate(value, 'dd.MM.yyyy')}</div>
      )
    },
    {
      Header: t('iban'),
      accessor: 'iban'
    },
    {
      Header: t('basis'),
      accessor: 'remittanceInfo',
      Cell: ({ value }) => (
        <div>{value}</div>
      )
    },
    {
      Header: t('property'),
      accessor: 'property',
      Cell: ({ value }) => {
        const propertyId = value?._id;
        const landlordNickname = value?.nicknames?.landlord;
        const ownerNickname = value?.nicknames?.owner;
        const tenantNickname = value?.nicknames?.tenant;
        if (landlordNickname) {
          return <div style={{ color: theme.palette.secondary.main, textDecoration: 'underline' }} onClick={() => navigate(`/properties/${propertyId}`)}>
            {landlordNickname}
          </div>;
        } else if (ownerNickname) {
          return <div style={{ color: theme.palette.secondary.main, textDecoration: 'underline' }} onClick={() => navigate(`/properties/${propertyId}`)}>
            {ownerNickname}
          </div>;
        } else if (tenantNickname) {
          return <div style={{ color: theme.palette.secondary.main, textDecoration: 'underline' }} onClick={() => navigate(`/properties/${propertyId}`)}>
            {tenantNickname}
          </div>;
        } else {
          return <div>--</div>;
        }
      }
    },
    {
      Header: t('category'),
      accessor: 'categoryTitle',
      Cell: ({ value }) => {
        if (value) {
          return <div>{value}</div>;
        } else {
          return <div>--</div>;
        }
      }
    },
    {
      Header: t('paidFor'),
      accessor: 'paymentFor',
      Cell: ({ value, row }) => {
        const paymentId = row?.original?._id;
        if (value) {
          return <div>{value}</div>;
        } else {
          return (
            <Stack direction='row' alignItems='center' justifyContent='space-between' sx={{ width: '100%' }}>
              <div>--</div>
              <Stack direction='row'>
                {/* <Button variant='outlined' color='error' size='small'>{t('ignore')}</Button> */}
                <Button variant='contained' color='info' size='small' onClick={() => navigate(`/finances/${paymentId}/payment-localization-create`)}>
                  {t('locate')}
                </Button>
              </Stack>
            </Stack>
          );
        }
      },
    },
  ];

  const {
    financesState: {
      transactions,
      bankDetails,
      getTransactionsListIsLoading,
    },
    organizationState: {
      getBankDetailsIsLoading,
      updateTransactionsIsLoading
    }
  } = useSelector(({ financesState, propertiesState, organizationState }) => ({ financesState, propertiesState, organizationState }));

  const hasBanksTransactions = Boolean(bankDetails?.length);

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(validations),
    defaultValues: {
      [arrayFieldName]: []
    }
  });

  const { fields } = useFieldArray({
    control,
    name: arrayFieldName,
  });

  useEffect(() => {
    dispatch(getTransactions({ org: true, page: 1, limit: 20 }));
  }, []);

  useEffect(() => {
    reset({
      [arrayFieldName]: bankDetails?.map(({ _id, isHidden, bank }) => (
        {
          _id,
          bank,
          isHidden
        }
      )),
    });
  }, [bankDetails, controlBankDialogIsOpen]);

  const [lastCheck] = bankDetails?.map(({ transactionsLastCheck }) => transactionsLastCheck);
  const [nextCheck] = bankDetails?.map(({ nextCheck }) => nextCheck);

  return (
    <FinancesLayout
      rightAdornment={
        <>
          <Stack direction='row' spacing={1} alignItems='center'>
            <Stack>
              <Typography>
                {lastCheck ? (
                  `${t('lastActualization')}: ${formatDate(lastCheck, 'dd.MM | HH:mm')}`
                ) : (
                  `${t('lastActualization')}: --`
                )}
              </Typography>
              {nextCheck ? (
                <Typography>
                  {`${t('nextActualizationAfter')}: ${formatDate(nextCheck, 'dd.MM | HH:mm')}`}
                </Typography>
              ) : (
                `${t('nextActualizationAfter')}: --`
              )}
            </Stack>
            {/* <IconButton>
              <ReloadIcon style={{ width: 20, height: 20 }} />
            </IconButton> */}
            {/* <IconButton>
              <FilterIcon />
            </IconButton> */}
            <IconButton onClick={() => setControlBankDialogIsOpen(true)}>
              <ProfileSettingsIcon />
            </IconButton>
          </Stack>
        </>
      }
    >
      {({ layoutHeight }) => (
        <>
          <Box sx={{
            height: 'calc(100% - 60px)'
          }}>
            {Boolean(layoutHeight) && (
              <Table
                height={layoutHeight - 40}
                data={transactions?.docs ?? []}
                columns={bankColumns}
                isLoading={(getTransactionsListIsLoading)}
                hasNextPage={transactions?.hasNextPage}
                onNextPage={() => {
                  dispatch(getTransactions({
                    org: true,
                    page: transactions?.nextPage,
                    limit: 20,
                    loadMore: true
                  }));
                }}
                emptyStateProps={{
                  title: t('welcomeToBank'),
                  subtitle: t('inThisModuleYouCanOperate'),
                  buttonProps: {
                    children: t('toConnection'),
                    onClick: () => setAddBankAccountIsOpen(true),
                  }
                }}
              >
              </Table>
            )}
          </Box>
          {hasBanksTransactions && (
            <Box sx={{
              borderRadius: 2,
              width: '100%',
              position: 'absolute',
              bottom: 0,
              left: 0,
              backgroundColor: '#fff',
            }}
            >
              <Stack direction='row' alignItems='center' spacing={3}>
                {bankDetails?.map(({ balance, bank, nickname, iban }, index) => (
                  <Box
                    key={`balance-bank-${balance}-${index}`}
                    sx={{ borderRadius: 1, backgroundColor: theme.palette.info.main }}
                    p={1}
                  >
                    <Stack
                      direction='row'
                      spacing={2}
                      alignItems='center'
                    >
                      <Stack sx={{ borderRight: `1px solid ${theme.palette.secondary.main}`, paddingRight: '10px' }}>
                        <Stack direction='row' spacing={1} alignItems='center'>
                          {nickname ? (
                            <Typography variant='subtitle2'>
                              {`${bank} ${nickname}`}
                            </Typography>
                          ) : (<Typography variant='subtitle2'>
                            {bank}
                          </Typography>)}
                        </Stack>
                        <Typography variant='caption'>
                          {iban}
                        </Typography>
                      </Stack>
                      <Typography variant='caption'>
                        {formatAmount(balance, i18n?.language)}
                      </Typography>
                    </Stack>
                  </Box>
                ))}
              </Stack>
            </Box>
          )}
          <Dialog
            open={controlBankDialogIsOpen}
            title={t('controlIntegratedAccounts')}
            dialogWidth='480px'
            onClose={() => setControlBankDialogIsOpen(false)}
          >
            {t('turnOffAccountOrAddNew')}
            <form onSubmit={handleSubmit((data) => {
              dispatch(updateTransactions(data?.[arrayFieldName], () => {
                // dispatch(getBankDetails(() => setControlBankDialogIsOpen(false)));
                setControlBankDialogIsOpen(false);
                dispatch(getTransactions({
                  org: true,
                  page: 1,
                  limit: 20,
                  loadMore: false,
                }));
              }));
            })}>
              <Box mt={3}>
                {fields?.map(({ _id, bank }, index) => {
                  const isHiddenFieldName = `${arrayFieldName}.${index}.isHidden`;
                  const isHidden = watch(isHiddenFieldName);
                  return (
                    <div key={`${_id}-item-${index}`}>
                      <Box mb={2}>
                        <Paper>
                          <ButtonBase sx={{ width: '100%' }}>
                            <Stack direction='row' alignItems='center' justifyContent='space-between' sx={{ width: '100%' }}>
                              <Typography variant='subtitle2'>
                                {bank}
                              </Typography>
                              <Switch
                                checked={!isHidden}
                                onChange={() => {
                                  if (isHidden || disableTransactionConfirm) {
                                    setValue(isHiddenFieldName, !isHidden);
                                  } else {
                                    setHideTransactionsDialogIsOpen(true);
                                    setSelectedTransactionIndex(index);
                                  }
                                }}
                              />
                            </Stack>
                          </ButtonBase>
                        </Paper>
                      </Box>
                    </div>
                  );
                })}
              </Box>
              <Box mt={2}>
                <Button
                  variant='outlined'
                  fullWidth
                  color='primary'
                  onClick={() => {
                    setControlBankDialogIsOpen(false);
                    setAddBankAccountIsOpen(true);
                  }}
                >
                  {t('addNew')}
                </Button>
              </Box>
              <Box mt={2}>
                <Button
                  variant='contained'
                  fullWidth
                  color='primary'
                  type='submit'
                  disabled={getBankDetailsIsLoading || updateTransactionsIsLoading}
                >
                  {t('save')}
                </Button>
              </Box>
            </form>
          </Dialog>
          <Dialog
            open={hideTransactionDialogIsOpen}
            title={t('turnOffBank')}
            dialogWidth='680px'
            onClose={() => setHideTransactionsDialogIsOpen(false)}
          >
            <Typography variant='h6'>
              {t('youChooseToTurnOffBankIntegration')}
            </Typography>
            <Typography variant='h6'>
              {t('pleaseConfirm')}
            </Typography>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox
                  checked={disableTransactionConfirm}
                  onChange={() => setDisableTransactionConfirm(!disableTransactionConfirm)}
                  color='secondary'
                />}
                label={<Typography sx={{ fontWeight: 400 }}
                >
                  {t('doNotShowMore')}</Typography>}
              />
            </FormGroup>
            <Box mt={3} mb={3}>
              <Button
                variant='contained'
                color='error'
                fullWidth
                onClick={() => {
                  const isHiddenFieldName = `${arrayFieldName}.${selectedTransactionIndex}.isHidden`;
                  setValue(isHiddenFieldName, !watch(isHiddenFieldName));
                  setHideTransactionsDialogIsOpen(false);
                }}
              >
                {t('turnOff')}
              </Button>
            </Box>
            <Button
              variant='contained'
              color='warning'
              fullWidth
              onClick={() => setHideTransactionsDialogIsOpen(false)}
            >
              {t('cancel')}
            </Button>
          </Dialog>
          <SelectBankAccount
            isOpen={addBankAccountIsOpen}
            onClose={() => setAddBankAccountIsOpen(false)}
          />
        </>
      )}
    </FinancesLayout >
  );
};

export default FinancesBank;