import { useState, useEffect } from 'react';
import { MonthPicker as MuiMonthPicker } from '@mui/x-date-pickers';
import { IconButton, Chip, Stack, styled } from '@mui/material';

import { ArrowIcon } from 'icons';

const formatDate = (month, year) => new Date(`${month}-01-${year}`);

const generateArrayOfYears = () => {
  var max = new Date().getFullYear();
  var min = max - 9;
  var years = [];

  for (var i = max; i >= min; i--) {
    years.push(i);
  }
  return years;
};

export const minTwoDigits = (n) => (n < 10 ? '0' : '') + n;

const SMuiMonthPicker = styled(MuiMonthPicker)(({ theme, hasDate }) => `
  width: 100%;

  & .PrivatePickersMonth-root {
    padding: 0;
    font-size: 12px;
    height: 22px;
    line-height: 1;

    ${hasDate ? `
      &.Mui-selected {
        background: ${theme.palette.secondary.main};
        color: #ffffff;
      }
    ` : `
      &.Mui-selected {
        background: transparent;
        color: #000;
      }
    `}
  }
`);

const MonthPicker = ({ date, minDate, maxDate, disabled, onChange }) => {
  const years = generateArrayOfYears();
  const yearsLength = years?.length;
  const [activeYearIndex, setActiveYearIndex] = useState(0);
  const selectedYear = years[activeYearIndex];
  const canPrevYear = activeYearIndex + 1 === yearsLength;
  const canNextYear = activeYearIndex === 0;

  useEffect(() => {
    if (date) {
      setActiveYearIndex(years.findIndex((year) => year === date?.getFullYear()));
    }
  }, [date]);

  const updateYearByIndex = (yearIndex) => {
    setActiveYearIndex(yearIndex);
    const currentDate = date ?? new Date();
    const newDate = formatDate(currentDate?.getMonth() + 1, years[yearIndex]);
    onChange(newDate);
  };

  const onPrevYear = () => {
    updateYearByIndex(activeYearIndex + 1);
  };

  const onNextYear = () => {
    updateYearByIndex(activeYearIndex - 1);
  };

  return (
    <div>
      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <IconButton disabled={canPrevYear || disabled} onClick={onPrevYear}>
          <ArrowIcon style={{ width: 16, height: 16, transform: 'rotate(180deg)' }} />
        </IconButton>
        <Chip size='small' label={selectedYear} />
        <IconButton disabled={canNextYear || disabled} onClick={onNextYear}>
          <ArrowIcon style={{ width: 16, height: 16 }} />
        </IconButton>
      </Stack>
      <SMuiMonthPicker
        style={{ width: '100%' }}
        onChange={(currentDate) => {
          const month = minTwoDigits(currentDate?.getMonth() + 1);
          const selectedDate = formatDate(month, selectedYear);
          onChange(selectedDate);
        }}
        date={date ? date : null}
        hasDate={Boolean(date)}
        disabled={disabled}
        minDate={minDate}
        maxDate={maxDate}
      />
    </div>
  );
};

export default MonthPicker;