import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Paper,
  Typography,
  Box,
  Stack,
  Grid,
  IconButton,
  TextField,
  Button,
  InputAdornment,
  FormHelperText,
  Autocomplete,
  styled
} from '@mui/material';
import GoogleAutoComplete from 'react-google-autocomplete';
import { DefaultLayout } from 'layouts';
import { ArrowBackIcon } from 'icons';
import { editProperty, getSingleProperty } from 'stores/PropertiesSlice';
import { searchCity } from 'stores/AddressSlice';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const StyledAddress = styled('div')(({ error }) => `
  input {
    width: 100%;
    height: 40px;
    border: 1px solid  ${error ? '#d32f2f' : 'rgb(196 196 196)'};
    font-family: CorsaGrotesk;
    padding: 0 14px;
    border-radius: 3px;
    font-size: 16px;
  }
`);

const EditTenant = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { propertyId } = useParams();
  const [latLng, setLatLng] = useState(undefined);

  const {
    propertiesState: {
      singleProperty,
      getSinglePropertyIsLoading
    },
    addressState: { citiesList }
  } = useSelector(({ propertiesState, addressState }) => ({ propertiesState, addressState }));

  useEffect(() => {
    dispatch(getSingleProperty(propertyId));
  }, []);

  const tenantIsFirm = Boolean(singleProperty?.tenant?.companyName);

  const validations = Yup.object().shape({
    ...(!tenantIsFirm ? {
      invoiceDetails: Yup.object().shape({
        fullName: Yup.string().required(t('firstNameRequired')),
        city: Yup.string().required(t('cityRequired')),
        address: Yup.string().required(t('addressRequired')),
        egn: Yup.string().required(t('pinRequired')),
        phoneNumber: Yup.string().required(t('phoneNumberRequired')),
        email: Yup.string().email().required(t('emailRequired')),
      })
    } : {
      invoiceDetails: Yup.object().shape({
        fullName: Yup.string().required(t('firstNameRequired')),
        city: Yup.string().required(t('cityRequired')),
        address: Yup.string().required(t('addressRequired')),
        eic: Yup.string().required(t('eicRequired')),
        phoneNumber: Yup.string().required(t('phoneNumberRequired')),
        email: Yup.string().email().required(t('emailRequired')),
        manager: Yup.string().required(t('managerRequired'))
      })
    })
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    reset,
    watch
  } = useForm({
    resolver: yupResolver(validations),
  });

  useEffect(() => {
    if (singleProperty) {
      let cityName = singleProperty?.invoiceDetails?.city;
      if (cityName?.includes('гр. ')) {
        cityName = cityName?.replace('гр. ', '');
      } else {
        cityName = cityName?.replace('с. ', '');
      }
      dispatch(searchCity(cityName, false, (response) => {
        const cities = response?.data?.sites;
        const selectedCity = cities?.find(({ name }) => name === cityName);
        if (!tenantIsFirm) {
          const defaultValues = {
            invoiceDetails: {
              city: singleProperty?.invoiceDetails?.city,
              address: singleProperty?.invoiceDetails?.address,
              egn: singleProperty?.invoiceDetails?.egn,
              phoneNumber: singleProperty?.invoiceDetails?.phoneNumber?.slice(4),
              email: singleProperty?.invoiceDetails?.email,
              fullName: singleProperty?.invoiceDetails?.fullName
            }
          };
          reset(defaultValues);
        } else {
          const defaultValues = {
            invoiceDetails: {
              city: singleProperty?.invoiceDetails?.city,
              address: singleProperty?.invoiceDetails?.address,
              eic: singleProperty?.invoiceDetails?.eic,
              phoneNumber: singleProperty?.invoiceDetails?.phoneNumber?.slice(4),
              email: singleProperty?.invoiceDetails?.email,
              fullName: singleProperty?.invoiceDetails?.fullName,
              manager: singleProperty?.invoiceDetails?.manager
            }
          };
          reset(defaultValues);
        }

        if (selectedCity) {
          setValue('invoiceDetails.city', singleProperty?.invoiceDetails?.city, { shouldValidate: true });
          setValue('invoiceDetails.address', singleProperty?.invoiceDetails?.address);
          setValue('municipality', selectedCity?.municipality);
          setValue('region', selectedCity?.region);
          setValue('postCode', selectedCity?.postCode);
          setLatLng({
            lat: selectedCity?.y,
            lng: selectedCity?.x,
          });
        }
      }));
    }
  }, [getSinglePropertyIsLoading]);

  return (
    <DefaultLayout>
      <form onSubmit={handleSubmit((data) => {
        if (tenantIsFirm) {
          dispatch(editProperty({
            invoiceDetails: {
              ...data?.invoiceDetails,
              phoneNumber: `+359${data?.invoiceDetails?.phoneNumber}`,
            }
          }, propertyId, () => toast.success(t('editTenantSuccess'))));
        } else {
          dispatch(editProperty({
            invoiceDetails: {
              ...data?.invoiceDetails,
              phoneNumber: `+359${data?.invoiceDetails?.phoneNumber}`,
            }
          }, propertyId, () => toast.success(t('editTenantSuccess'))));
        }
      })}>
        <Box mb={3}>
          <Paper elevation={4}>
            <Stack direction='row' justifyContent='space-between' alignItems='center'>
              <Stack direction='row' alignItems='center' spacing={1}>
                <IconButton onClick={() => navigate(`/properties/${propertyId}`)}>
                  <ArrowBackIcon style={{ width: 16, height: 16 }} />
                </IconButton>
                <Typography variant='h5'>
                  {t('editTenant')}
                </Typography>
              </Stack>
              <Button
                variant='contained'
                color='primary'
                type='submit'
              >
                {t('save')}
              </Button>
            </Stack>
          </Paper>
        </Box>
        <Box>
          <Paper elevation={4}>
            <Typography variant='subtitle1'>
              {t('tenantData')}
            </Typography>
            {!tenantIsFirm ? (
              <Grid container mt={1}>
                <Grid item md={3}>
                  <Stack>
                    <Typography variant='h6'>{`${t('clientType')}*`}</Typography>
                    <Typography variant='subtitle2'>{t('individual')}</Typography>
                  </Stack>
                </Grid>
                <Grid item md={3}>
                  <TextField
                    label={`${t('name')}*`}
                    size='small'
                    fullWidth
                    {...register('invoiceDetails.fullName')}
                    error={Boolean(errors?.invoiceDetails?.fullName?.message)}
                    helperText={errors?.invoiceDetails?.fullName?.message}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item md={3}>
                  <TextField
                    label={`${t('egn')}*`}
                    type='number'
                    size='small'
                    fullWidth
                    {...register('invoiceDetails.egn')}
                    error={Boolean(errors?.invoiceDetails?.egn?.message)}
                    helperText={errors?.invoiceDetails?.egn?.message}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
              </Grid>
            ) : (
              <Grid container mt={1}>
                <Grid item md={3}>
                  <Stack>
                    <Typography variant='h6'>{`${t('clientType')}*`}</Typography>
                    <Typography variant='subtitle2'>{t('legalType')}</Typography>
                  </Stack>
                </Grid>
                <Grid item md={3}>
                  <TextField
                    label={`${t('companyName')}*`}
                    size='small'
                    fullWidth
                    {...register('invoiceDetails.fullName')}
                    error={Boolean(errors?.invoiceDetails?.fullName?.message)}
                    helperText={errors?.invoiceDetails?.fullName?.message}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item md={3}>
                  <TextField
                    label={`${t('eik')}*`}
                    type='number'
                    size='small'
                    fullWidth
                    {...register('invoiceDetails.eic')}
                    error={Boolean(errors?.invoiceDetails?.eic?.message)}
                    helperText={errors?.invoiceDetails?.eic?.message}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item md={3}>
                  <TextField
                    label={`${t('materiallyResponsiblePerson')}*`}
                    size='small'
                    fullWidth
                    {...register('invoiceDetails.manager')}
                    error={Boolean(errors?.invoiceDetails?.manager?.message)}
                    helperText={errors?.invoiceDetails?.manager?.message}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
              </Grid>
            )}
            <Grid container mt={3}>
              <Grid item md={3}>
                <TextField
                  label={t('phoneNumber')}
                  size='small'
                  fullWidth
                  InputProps={{
                    startAdornment: <InputAdornment position='start'>+359</InputAdornment>,
                  }}
                  {...register('invoiceDetails.phoneNumber')}
                  error={Boolean(errors?.invoiceDetails?.phoneNumber?.message)}
                  helperText={errors?.invoiceDetails?.phoneNumber?.message}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item md={3}>
                {singleProperty?.invoiceDetails?.city && (
                  <Autocomplete
                    options={citiesList?.map((city, cityIndex) => ({
                      label: `${city?.type} ${city?.name} (обл. ${city?.region})`,
                      value: city,
                    }))}
                    defaultValue={{
                      value: singleProperty?.invoiceDetails?.city,
                      label: singleProperty?.invoiceDetails?.city,
                    }}
                    onChange={(_, { value }) => {
                      setValue('invoiceDetails.city', value?.name, { shouldValidate: true });
                      setValue('municipality', value?.municipality);
                      setValue('region', value?.region);
                      setValue('postCode', value?.postCode);
                      setValue('address', '');
                      setLatLng({
                        lat: value?.y,
                        lng: value?.x,
                      });
                    }}
                    filterOptions={(options) => options}
                    onInputChange={(_, value) => dispatch(searchCity(value))}
                    renderInput={(params) => <TextField {...params} label={`${t('city')}*`} size='small' InputLabelProps={{ shrink: true }} {...register('city')} error={Boolean(errors?.city?.message)} />}
                    noOptionsText={t('addCity')}
                  />
                )}
                {Boolean(errors?.invoiceDetails?.city?.message) && (
                  <FormHelperText error>
                    {errors?.invoiceDetails?.city?.message}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item md={3}>
                {Boolean(latLng) && (
                  <StyledAddress error={Boolean(errors?.invoiceDetails?.address?.message)}>
                    <GoogleAutoComplete
                      key={`${latLng.lat}${latLng.lng}`}
                      apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                      defaultValue={watch('invoiceDetails.address') ?? ''}
                      onPlaceSelected={(place) => {
                        setValue('invoiceDetails.address', place?.formatted_address, { shouldValidate: true });
                      }}
                      placeholder={t('enterAddress')}
                      onChange={(ev) => {
                        if (!ev?.target?.value) {
                          setValue('invoiceDetails.address', '', { shouldValidate: true });
                        }
                      }}
                      language='bg'
                      options={{
                        bounds: {
                          north: latLng.lat + 0.1,
                          south: latLng.lat - 0.1,
                          east: latLng.lng + 0.1,
                          west: latLng.lng - 0.1,
                        },
                        strictBounds: true,
                        types: [],
                        language: 'bulgarian',
                        componentRestrictions: { country: 'bg' }
                      }}
                    />
                    {Boolean(errors?.invoiceDetails?.address?.message) && (
                      <FormHelperText error>
                        {errors?.invoiceDetails?.address?.message}
                      </FormHelperText>
                    )}
                  </StyledAddress>
                )}
              </Grid>
              <Grid item md={3}>
                <TextField
                  label={t('mail')}
                  size='small'
                  fullWidth
                  {...register('invoiceDetails.email')}
                  error={Boolean(errors?.invoiceDetails?.email?.message)}
                  helperText={errors?.invoiceDetails?.email?.message}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </form>
    </DefaultLayout >
  );
};

export default EditTenant;