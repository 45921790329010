
import { useSelector } from 'react-redux';
import { ArrivalsAndExpensesForm } from 'components/forms';

const FinancesArrivalExpensesCreate = () => {
  const {
    financesState: { createArrivalOrExpenseIsLoading }
  } = useSelector(({ financesState }) => ({ financesState }));
  return (
    <ArrivalsAndExpensesForm
      isLoading={createArrivalOrExpenseIsLoading}
    />
  );
};
export default FinancesArrivalExpensesCreate;