
const BellIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='11.437'
    height='14.699'
    viewBox='0 0 11.437 14.699'
    {...props}
  >
    <path
      fill='#2f3191'
      d='M15.627 13.649l-.972-.972v-3.77a4.593 4.593 0 00-3.392-4.764v-.512a1.131 1.131 0 10-2.263 0v.513a4.58 4.58 0 00-3.391 4.763v3.769l-.972.972a.754.754 0 00.528 1.289h9.928a.754.754 0 00.535-1.289zm-2.48-.219h-6.03V8.907a3.091 3.091 0 013.015-3.392 3.091 3.091 0 013.015 3.392zm-3.015 3.77a1.512 1.512 0 001.508-1.508H8.625a1.507 1.507 0 001.507 1.508z'
      data-name='Path 55907'
      transform='translate(-4.414 -2.5)'
    ></path>
  </svg>
);

export default BellIcon;
