
const InvoiceIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20.5'
    height='20.517'
    viewBox='0 0 20.5 20.517'
    {...props}
  >
    <path
      fill='#00d297'
      stroke='#fff'
      strokeWidth='0.5'
      d='M7.25 10.267h-2a1 1 0 000 2h2a1 1 0 000-2zm-1-2h4a1 1 0 000-2h-4a1 1 0 000 2zm1 6h-2a1 1 0 000 2h2a1 1 0 000-2zm12-4h-3v-9a1 1 0 00-1.5-.87l-3 1.72-3-1.72a1 1 0 00-1 0l-3 1.72-3-1.72a1 1 0 00-1.5.87v16a3 3 0 003 3h14a3 3 0 003-3v-6a1 1 0 00-1-1zm-16 8a1 1 0 01-1-1V2.997l2 1.14a1.08 1.08 0 001 0l3-1.72 3 1.72a1.08 1.08 0 001 0l2-1.14v14.27a3 3 0 00.18 1zm15-1a1 1 0 01-2 0v-5h2zm-6.44-2.83a.76.76 0 00-.18-.09.6.6 0 00-.19-.06 1 1 0 00-.9.27 1.05 1.05 0 00-.29.71 1 1 0 00.07.38 1.19 1.19 0 00.22.33 1.15 1.15 0 00.33.21.94.94 0 00.76 0 1.15 1.15 0 00.33-.21 1 1 0 00.29-.71 1.05 1.05 0 00-.29-.71 1.58 1.58 0 00-.15-.12zm.14-3.88a1.038 1.038 0 10.22.33.91.91 0 00-.22-.33z'
    ></path>
  </svg>
);

export default InvoiceIcon;
