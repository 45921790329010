
const PropertiesIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    x='0'
    y='0'
    enableBackground='new 0 0 20 20'
    version='1.1'
    viewBox='0 0 20 20'
    xmlSpace='preserve'
    fill='currentColor'
    {...props}
  >
    <path
      d='M3.2 11.8h1.4v.7H3.2v-.7zm5.4.7H10v-.7H8.6v.7zm0-1.4H10v-.7H8.6v.7zm0-1.5H10v-.7H8.6v.7zm0-1.4H10v-.7H8.6v.7zm0-1.4h3.2v-.7H8.6v.7zm0-1.4h3.2v-.8H8.6v.8zm0-1.5h3.2v-.7H8.6v.7zm0-1.4h3.2v-.7H8.6v.7zM3.2 13.9h1.4v-.7H3.2v.7zm5.4 0H10v-.7H8.6v.7zm-5.4 1.5h1.4v-.7H3.2v.7zm5.4 0H10v-.7H8.6v.7zm-5.4 1.4h1.4v-.7H3.2v.7zm5.4 0H10v-.7H8.6v.7zM5 12.5h1.4v-.7H5v.7zm5.4 0h1.4v-.7h-1.4v.7zm0-1.4h1.4v-.7h-1.4v.7zm0-1.5h1.4v-.7h-1.4v.7zm0-1.4h1.4v-.7h-1.4v.7zM5 13.9h1.4v-.7H5v.7zm5.4 0h1.4v-.7h-1.4v.7zM5 15.4h1.4v-.7H5v.7zm5.4 0h1.4v-.7h-1.4v.7zM5 16.8h1.4v-.7H5v.7zm5.4 0h1.4v-.7h-1.4v.7zm7.8-8.6v11.4c0 .2-.1.3-.3.4H2.1c-.2 0-.3-.1-.4-.3v-9.3c0-.2.1-.3.3-.4h5V.4c.1-.2.3-.4.5-.4h5.4c.2 0 .3.1.4.3v7.5h4.6c.1 0 .3.2.3.4zM2.5 19.3h.7v-1.4c0-.2.1-.3.3-.4H6c.2 0 .3.1.4.3v1.4h.7v-8.6H2.5v8.7zm3.2 0v-1.1H3.9v1.1h1.8zm2.2 0h.7v-1.4c0-.2.1-.3.3-.4h2.5c.2 0 .3.1.4.3v1.4h.7V.7H7.9v18.6zm3.2 0v-1.1H9.3v1.1h1.8zm5 0v-.7h-1.4v.7h1.4zm1.4-10.7h-4.3v10.7h.7v-1.1c0-.2.1-.3.3-.4h2.1c.2 0 .3.1.4.3v1.1h.7l.1-10.6zm-3.6 2.8h.7V9.3h-.7v2.1zm0 2.9h.7v-2.1h-.7v2.1zm0 2.8h.7V15h-.7v2.1zm2.2-5.7h.7V9.3h-.7v2.1zm0 2.9h.7v-2.1h-.7v2.1zm0 2.8h.7V15h-.7v2.1zM15 11.4h.7V9.3H15v2.1zm0 2.9h.7v-2.1H15v2.1zm0 2.8h.7V15H15v2.1z'
      className='st0'
    ></path>
  </svg>
);

export default PropertiesIcon;
