
const CrossIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='18'
    height='18'
    viewBox='0 0 18 18'
    {...props}
  >
    <path
      fill={props.fill}
      d='M28 11.819L26.181 10 19 17.181 11.819 10 10 11.819 17.181 19 10 26.181 11.819 28 19 20.819 26.181 28 28 26.181 20.819 19z'
      data-name='Path 55781'
      transform='translate(-10 -10)'
    ></path>
  </svg>
);


export default CrossIcon;
