
import { NavLink, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  AppBar, Stack, Toolbar, Button, Avatar, IconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Logo as LogoSvg, ChatIcon } from 'icons';
import { PersonalSettingsDropdown } from 'components';

const NavItem = styled(NavLink)(({ theme }) => `
  position: relative;
  color: inherit;
  text-decoration: none;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 0;
    width: 0;
    height: 6px;
    background-color: ${theme.palette.primary.main};
    transition: all 0.3s ease;
  }

  &.active {
    &:after {
      width: 100%;
    }
  }
`);

const NavButton = styled(Button)`
  font-weight: 400;
  font-size: 14px;
  height: 60px;
  cursor: 'pointer';
  padding: 20px 0;
  border-radius: 0;
  text-transform: uppercase;
`;

const Logo = styled(LogoSvg)({
  width: 140,
});

const Header = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { myUserData } = useSelector(({ organizationState }) => organizationState);

  const NAV_ITEMS = [
    {
      label: t('managment'),
      route: '/',
    },
    {
      label: t('properties'),
      route: '/properties',
    },
    {
      label: t('finances'),
      route: '/finances/bank',
    }
  ];

  return (
    <AppBar color='default' sx={{ p: 0 }}>
      <Toolbar>
        <Logo />
        <Stack direction='row' width='100%' justifyContent='center' spacing={6}>
          {NAV_ITEMS.map(({ label, route }) => (
            <NavItem to={route} key={`${label}-${route}`}>
              <NavButton variant='text' color='inherit'>
                {label}
              </NavButton>
            </NavItem>
          ))}
        </Stack>
        <Stack direction='row' alignItems='center' spacing={1}>
          <IconButton onClick={() => navigate('/personal-settings/help')}>
            <ChatIcon style={{ width: 24, height: 24 }} />
          </IconButton>
          <PersonalSettingsDropdown
            trigger={({ handleOpen }) => (
              <Avatar
                onClick={handleOpen} style={{ cursor: 'pointer' }}
                src={myUserData?.avatar}
              />
            )}
          />
        </Stack>
      </Toolbar>
    </AppBar >
  );
};
export default Header;