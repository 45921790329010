import animationData from 'assets/loader-animation.json';
import { Player } from '@lottiefiles/react-lottie-player';
import { styled } from '@mui/material';

const LoaderWrapper = styled('div')`
  height: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const Loader = () => (
  <LoaderWrapper>
    <Player autoplay loop src={animationData} style={{ width: 300, height: 300 }} />
  </LoaderWrapper>
);

export default Loader;