import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactEcharts from 'echarts-for-react';
import { useTranslation } from 'react-i18next';
import { IconButton, CircularProgress } from '@mui/material';
import { format } from 'date-fns';
import { SettingsIcon } from 'icons';
import { MonthRangePicker } from 'components';
import { formatAmount } from 'utils';
import ContentBox from 'components/ContentBox';
import { getOutcomeStatistics } from 'stores/DashboardSlice';

const OutcomeStatistics = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [date, setDate] = useState(undefined);

  const {
    dashboardState: {
      outcomeStatistics,
      getOutcomeStatisticsIsLoading,
    }
  } = useSelector(({ dashboardState }) => ({ dashboardState }));

  useEffect(() => {
    const formatDate = (newDate) => newDate ? format(newDate, 'MM-yyyy') : '';
    const params = date ? {
      from: formatDate(date?.from),
      to: formatDate(date?.to),
    } : {};
    dispatch(getOutcomeStatistics(params));
  }, [date]);

  const realizedValue = outcomeStatistics?.realized;
  const unrealizedValue = outcomeStatistics?.unrealized;
  const outcomeDataIsReady = !getOutcomeStatisticsIsLoading && outcomeStatistics;
  const hasRealizedValue = Boolean(realizedValue);
  const hasUnrealizedValue = Boolean(unrealizedValue);
  const emptyData = !getOutcomeStatisticsIsLoading && !hasRealizedValue && !hasUnrealizedValue;

  const outcomeOptions = {
    title: {
      text: !emptyData ? `${formatAmount(realizedValue + unrealizedValue, i18n?.language)}` : `0 ${t('lv')}`,
      top: 'center',
      left: 'center',
      show: true,
      textStyle: {
        fontWeight: 'normal'
      }
    },
    color: [
      '#2F3191',
      '#C5D7F2',
    ],
    legend: {
      orient: 'horizontal',
      left: 'right',
      bottom: '0',
      selectedMode: false
    },
    series: [
      {
        type: 'pie',
        radius: ['55%', '70%'],
        label: {
          show: true,
          color: '#000',
          formatter: (params) => formatAmount(params.value, i18n?.language),
        },
        labelLine: {
          show: false,
        },
        data: [
          { value: realizedValue, name: t('realized') },
          { value: unrealizedValue, name: t('unrealized') },
        ]
      }
    ]
  };

  return (
    <ContentBox
      title={t('outcomes')}
      caption={date ? `${t(format(date?.from, 'LLLL.yy'))} - ${format(date?.to, 'LLLL.yy')}` : ''}
      iconButton={
        <MonthRangePicker
          onSelectDate={(filterDate) => setDate(filterDate)}
          trigger={() => (
            <IconButton>
              <SettingsIcon style={{ width: 12, height: 12 }} />
            </IconButton>
          )}
        />
      }
    >
      {outcomeDataIsReady ? (
        <div style={{ width: '100%', filter: `grayscale(${emptyData ? 1 : 0})` }}>
          <ReactEcharts option={outcomeOptions} />
        </div>
      ) : (
        <div style={{ width: '100%', textAlign: 'center' }}>
          <CircularProgress size={200} />
        </div>
      )}
    </ContentBox>
  );
};

export default OutcomeStatistics;