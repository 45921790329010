const InfoIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='17'
    height='17'
    viewBox='0 0 17 17'
    {...props}
  >
    <g transform='translate(-252.822 -318.822)'>
      <path
        fill='currentColor'
        stroke='rgba(0,0,0,0)'
        strokeWidth='1'
        d='M261.316 324.605a1.012 1.012 0 001.032-1.017 1 1 0 00-1.019-1.019 1.012 1.012 0 00-1.032 1.019 1.017 1.017 0 001.019 1.017zm.774 7.47v-6.386h-1.56v6.386z'
        data-name='Path 55306'
      ></path>
      <g
        fill='none'
        stroke='currentColor'
        strokeWidth='0.5'
        data-name='Ellipse 8733'
        transform='translate(252.822 318.822)'
      >
        <circle cx='8.5' cy='8.5' r='8.5' stroke='none'></circle>
        <circle cx='8.5' cy='8.5' r='8.25'></circle>
      </g>
    </g>
  </svg>
);

export default InfoIcon;