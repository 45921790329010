
const RemoveTenantIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='20'
    viewBox='0 0 20 20'
  >
    <g
      fill='#d31163'
      data-name='Group 33863'
      transform='translate(15417 4325)'
    >
      <path
        d='M15 5a10 10 0 1010 10A10 10 0 0015 5zm0 18.182A8.182 8.182 0 1123.182 15 8.182 8.182 0 0115 23.182z'
        data-name='Path 59463'
        transform='translate(-15422 -4330)'
      ></path>
      <path
        d='M7.381 8.281L4.233 5.133 1.085 8.281a.636.636 0 01-.9-.9l3.149-3.147L.186 1.086a.636.636 0 01.9-.9l3.148 3.148L7.381.186a.636.636 0 11.9.9L5.133 4.234l3.148 3.147a.636.636 0 01-.9.9z'
        data-name='Union 2'
        transform='translate(-15411.233 -4319.234)'
      ></path>
    </g>
  </svg>
);

export default RemoveTenantIcon;
