
const AddCalendarIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='19.528'
    height='21.031'
    viewBox='0 0 19.528 21.031'
    {...props}
  >
    <path
      fill='#00d297'
      d='M18.026 3a1.444 1.444 0 011.056.446 1.444 1.444 0 01.446 1.056v15.026a1.523 1.523 0 01-1.5 1.5H1.5a1.444 1.444 0 01-1.056-.446A1.444 1.444 0 010 19.528V4.507A1.444 1.444 0 01.446 3.45 1.444 1.444 0 011.5 3H3V1.878A1.808 1.808 0 013.556.552 1.808 1.808 0 014.882 0h.751a1.808 1.808 0 011.326.552 1.808 1.808 0 01.552 1.326V3h4.507V1.878a1.808 1.808 0 01.551-1.326A1.808 1.808 0 0113.9 0h.751a1.808 1.808 0 011.326.552 1.808 1.808 0 01.552 1.326V3zM13.52 1.878v3.38a.362.362 0 00.376.376h.751a.362.362 0 00.376-.376v-3.38a.362.362 0 00-.376-.376H13.9a.362.362 0 00-.376.376zm-9.013 0v3.38a.362.362 0 00.376.376h.751a.362.362 0 00.376-.376v-3.38a.362.362 0 00-.377-.378h-.751a.362.362 0 00-.376.376zm13.52 17.651V7.511H1.5v12.017zm-7.511-6.76h2.629a.362.362 0 01.376.376v.755a.362.362 0 01-.376.376h-2.63V16.9a.362.362 0 01-.376.376h-.75a.362.362 0 01-.376-.376v-2.629H6.384a.362.362 0 01-.376-.376v-.751a.362.362 0 01.376-.376h2.629V10.14a.362.362 0 01.376-.376h.751a.362.362 0 01.376.376z'
    ></path>
  </svg>
);

export default AddCalendarIcon;
