import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  IconButton,
  Stack,
  Typography,
  Box,
  Paper,
  Grid,
  Button,
  TextField,
  InputAdornment,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  FormHelperText
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DefaultLayout } from 'layouts';
import { ArrowBackIcon, CrossIcon } from 'icons';
import { addUser } from 'stores/OrganizationSlice';

const arrayFieldName = 'users';

const userDefaultValue = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  role: ''
};

const PersonalSettingsAddUser = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const validations = Yup.object().shape({
    users: Yup.array().of(
      Yup.object().shape({
        firstName: Yup.string().required(t('firstNameRequired')),
        lastName: Yup.string().required(t('lastNameRequired')),
        phoneNumber: Yup.string().min(9).max(9).required(t('phoneNumberRequired')),
        email: Yup.string().email().label('Email'),
        // role: Yup.string().label('Role')
      })
    )
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    control,
    setValue
  } = useForm({
    resolver: yupResolver(validations),
    defaultValues: {
      [arrayFieldName]: [userDefaultValue]
    }
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: arrayFieldName,
  });

  const fieldsLength = fields?.length;

  return (
    <DefaultLayout>
      <form onSubmit={handleSubmit((data) => {
        const usersData = data?.users?.map((user) => ({
          ...user,
          phoneNumber: `+359${user?.phoneNumber}`,
          role: 'admin'
        }));
        dispatch(addUser(usersData, () => navigate('/personal-settings')));
      })}>
        <Paper elevation={4}>
          <Stack direction='row' alignItems='center' justifyContent='space-between'>
            <Stack direction='row' alignItems='center' spacing={1}>
              <IconButton onClick={() => navigate('/personal-settings')}>
                <ArrowBackIcon style={{ width: 16, height: 16 }} />
              </IconButton>
              <Typography variant='h5'>{t('addUsers')}</Typography>
            </Stack>
            <Button variant='contained' color='primary' type='submit'>
              {t('add')}
            </Button>
          </Stack>
        </Paper>
        {fields?.map((_, index) => {
          const isLastFormItem = fieldsLength === index + 1;
          const canRemoveFormItem = fieldsLength > 1;
          const fieldPrefix = `${arrayFieldName}.${index}`;
          const fieldError = errors?.[arrayFieldName]?.[index];
          const roleValue = watch(`${fieldPrefix}.role`);
          return (
            <div key={`field-value-${index}`}>
              <Box mt={2}>
                <Paper elevation={4}>
                  <Box p={1}>
                    <Stack direction='row' alignItems='center' justifyContent='space-between'>
                      <Typography variant='subtitle1'>
                        {`${t('user')} ${index + 1}`}
                      </Typography>
                      {isLastFormItem && (
                        <>
                          {canRemoveFormItem && (
                            <IconButton onClick={() => remove(index)}>
                              <CrossIcon
                                fill='#d31163'
                                style={{ width: 16, height: 16 }} />
                            </IconButton>
                          )}
                        </>
                      )}
                    </Stack>
                    <Grid mt={1} container>
                      <Grid item md={3}>
                        <TextField
                          label={`${t('firstName')}*`}
                          size='small'
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          {...register(`${fieldPrefix}.firstName`)}
                          error={Boolean(fieldError?.firstName?.message)}
                          helperText={fieldError?.firstName?.message}
                        />
                      </Grid>
                      <Grid item md={3}>
                        <TextField
                          label={`${t('lastName')}*`}
                          size='small'
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          {...register(`${fieldPrefix}.lastName`)}
                          error={Boolean(fieldError?.lastName?.message)}
                          helperText={fieldError?.lastName?.message}
                        />
                      </Grid>
                      {/* <Grid item md={3}>
                        <FormControl error={Boolean(errors?.payForMonth?.message)}>
                          <FormLabel disabled>{t('addOn')}</FormLabel>
                          <RadioGroup row>
                            <FormControlLabel
                              value='admin'
                              control={
                                <Radio
                                  checked={watch(`${fieldPrefix}.role`) === 'admin'}
                                  onChange={() => setValue(`${fieldPrefix}.role`, 'admin', { shouldValidate: true })}
                                />
                              }
                              label={t('admin')}
                            />
                            <FormControlLabel
                              value='екип'
                              control={
                                <Radio
                                  checked={watch(`${fieldPrefix}.role`) === 'team'}
                                  onChange={() => setValue(`${fieldPrefix}.role`, 'team', { shouldValidate: true })}
                                />
                              }
                              label={t('team')}
                            />
                          </RadioGroup>
                          <FormHelperText error>
                            {fieldError?.role?.message}
                          </FormHelperText >
                        </FormControl>
                      </Grid> */}
                      <Grid item md={3}>
                        <TextField
                          label={`${t('phone')}*`}
                          InputProps={{
                            startAdornment: <InputAdornment position='start'>
                              +359
                            </InputAdornment>,
                          }}
                          size='small'
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          {...register(`${fieldPrefix}.phoneNumber`)}
                          error={Boolean(fieldError?.phoneNumber?.message)}
                          helperText={fieldError?.phoneNumber?.message}
                        />
                      </Grid>
                      <Grid item md={3}>
                        <TextField
                          label={t('mail')}
                          size='small'
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          {...register(`${fieldPrefix}.email`)}
                          error={Boolean(fieldError?.email?.message)}
                          helperText={fieldError?.email?.message}
                        />
                      </Grid>
                    </Grid>
                    {(roleValue === 'admin' || roleValue === 'team') && (
                      <Box mt={1} p={1} sx={{ backgroundColor: '#F5F9FC' }}>
                        <Typography variant='caption' textTransform='none'>
                          {t('userRights')}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Paper>
              </Box>
              {isLastFormItem && (
                <Box mt={1}>
                  <Button
                    variant='outlined'
                    color='primary'
                    onClick={() => append(userDefaultValue)}
                  >
                    {t('addOneMore')}
                  </Button>
                </Box>
              )}
            </div>
          );
        })}
      </form>
    </DefaultLayout >
  );
};
export default PersonalSettingsAddUser;