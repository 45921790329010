import { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  Stack,
  Typography,
  IconButton,
  Button,
  Box,
  Grid,
  TextField,
  useTheme
} from '@mui/material';
import io from 'socket.io-client';
import { _ } from 'lodash';
import { ArrowIcon, FilterIcon, EmptyStateIcon, EllipseIcon } from 'icons';
import { Dialog, OverflowContent } from 'components';
import { formatDate } from 'utils';
import { useTranslation } from 'react-i18next';
import { getMyUserData, getOrganization } from 'stores/OrganizationSlice';

const SOCKET_LISTENER_MESSAGE = 'socket-message';
const SOCKET_EMITTER_MESSAGE = 'client-message';

const sortRoomsByOpen = (rooms) => {
  const openedRooms = rooms?.filter(({ status }) => status === 'open');
  const otherRooms = rooms?.filter(({ status }) => status !== 'open');

  return [
    ...openedRooms,
    ...otherRooms,
  ];
};

const PropertiesCommunicatorList = ({ propertyId }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setIsOpen] = useState(false);
  const [socket, setSocket] = useState(null);
  const [rooms, setRooms] = useState([]);
  const [createRoomName, setCreateRoomName] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const {
    authenticationState: { token, organizationUsers },
    organizationState: { myUserData }
  } = useSelector(({ authenticationState, organizationState }) => ({ authenticationState, organizationState }));

  const currentUserId = myUserData?.organization;

  const socketInit = useCallback(() => {
    const newSocket = io(process.env.REACT_APP_API_URL, {
      query: `token=${token}`,
      transports: ['websocket'],
    });

    if (newSocket && propertyId) {
      newSocket.on(SOCKET_LISTENER_MESSAGE, ({ action, payload }) => {
        // TODO: Filter this on backend
        const getCurrentPropertyRooms = (items) => items?.filter((item) => item?.property === propertyId);

        if (action === 'socket/INIT') {
          setRooms(getCurrentPropertyRooms(payload?.rooms ?? []));
          setIsLoading(false);
        }

        if (action === 'socket/NEW_ROOM') {
          navigate(`/properties/${propertyId}/communicator/${payload?.id}`);
          setIsOpen(false);
        }

        if (action === 'socket/NEW_MESSAGE') {
          setRooms((currentRooms) => currentRooms?.map((room) => {
            if (room?.id === payload?.room) {
              return {
                ...room,
                messages: [
                  payload,
                  ...room?.messages,
                ]
              };
            }

            return room;
          }));
        }

      });

      setSocket(() => newSocket);
    }
  }, [token, propertyId]);

  useEffect(() => {
    if (!socket) {
      setIsLoading(true);
      socketInit();
    };

    //cleanup
    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  }, [socket, socketInit]);

  useEffect(() => {
    dispatch(getMyUserData());
  }, []);

  useEffect(() => {
    if (currentUserId) {
      dispatch(getOrganization(currentUserId));
    }
  }, [currentUserId]);

  return (
    <OverflowContent head={(
      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        <Typography variant='subtitle1'>{t('communicator')}</Typography>
        <Stack direction='row' spacing={1} alignItems='center'>
          {/* <IconButton>
            <FilterIcon style={{ width: 12, height: 12 }} />
          </IconButton> */}
          <Button
            variant='contained'
            color='primary'
            onClick={() => setIsOpen(true)}
          >
            {t('openChat')}
          </Button>
        </Stack>
      </Stack>
    )}>
      {sortRoomsByOpen(rooms)?.map((room) => {
        const isOpen = room?.status === 'open';
        const lastMessage = room?.messages?.[0];
        const seenByIds = lastMessage?.seenBy;
        const organizationUsersIds = organizationUsers?.map(({ _id }) => _id);
        const hasUnread = !organizationUsersIds?.some(ai => seenByIds?.includes(ai)) && Boolean(lastMessage);

        return (
          <Box key={room?.id} mt={1} p={1} sx={{
            border: `1px solid ${theme.palette.info.main} `,
            borderRadius: 1,
            backgroundColor: isOpen ? '#F5F9FC' : 'transparent',
          }}>
            <Stack direction='row' alignItems='center' justifyContent='space-between'>
              <Typography variant='subtitle2' textTransform='uppercase'>{room?.title}</Typography>
              <Typography variant='caption' color={isOpen ? '#00D297' : '#000'}>{isOpen ? t('opened') : t('closed')}</Typography>
            </Stack>
            <Stack direction='row' alignItems='center' justifyContent='space-between'>
              <div style={{ width: '100%' }}>
                {isOpen && (
                  <Grid container mt={1} alignItems='center'>
                    <Grid item md={3}>
                      <Typography variant='caption'>{t('createdAt')}</Typography>
                      <Typography variant='subtitle2'>{formatDate(room?.createdAt)}</Typography>
                    </Grid>
                    <Grid item md={3}>
                      <Typography variant='caption'>{t('createdFrom')}</Typography>
                      <Typography variant='subtitle2'>{room?.author?.fullName}</Typography>
                    </Grid>
                    <Grid item md={5}>
                      <Typography variant='caption'>{t('status')}</Typography>
                      <Typography variant='subtitle2' color={hasUnread ? '#2F3191' : '#000'}>{hasUnread ? t('newMessages') : t('noNewMessages')}</Typography>
                    </Grid>
                  </Grid>
                )}
              </div>
              <div>
                <IconButton onClick={() => navigate(`/properties/${propertyId}/communicator/${room?.id}`)}>
                  <ArrowIcon style={{ width: 16, height: 16 }} />
                </IconButton>
              </div>
            </Stack>
          </Box>
        );
      })}

      {
        isLoading && (
          <OverflowContent.Loader />
        )
      }

      {
        (!isLoading && !rooms?.length) && (
          <Box mt={10} textAlign='center'>
            <div>
              <EmptyStateIcon />
            </div>
            <Typography variant='h6'>{t('welcomeToCommunicator')}</Typography>
            <Stack direction='row' spacing={1} mt={2} justifyContent='center' alignItems='center'>
              <EllipseIcon fill='#2F3191' style={{ width: 12, height: 12 }} />
              <Typography variant='h6'>
                {t('welcomeToCommunicatorDesc')}
              </Typography>
            </Stack>
          </Box>
        )
      }
      <Dialog
        open={open}
        onClose={() => setIsOpen(false)}
        title={t('openNewCase')}
        dialogWidth='350px'
      >
        <TextField
          label={t('caseTitle')}
          required
          size='small'
          fullWidth
          value={createRoomName}
          onChange={({ currentTarget: { value } }) => setCreateRoomName(value)}
        />

        <Box mt={2}>
          <Button
            variant='contained'
            color='primary'
            fullWidth
            disabled={!Boolean(createRoomName)}
            onClick={() => {
              socket.emit(SOCKET_EMITTER_MESSAGE, {
                action: 'createRoom',
                payload: {
                  title: createRoomName,
                  property: propertyId,
                  isOrg: true
                },
              });
            }}
          >
            {t('openCase')}
          </Button>
        </Box>
      </Dialog>
    </OverflowContent >
  );
};

export default PropertiesCommunicatorList;