import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import CssBaseline from '@mui/material/CssBaseline';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { loadStripe } from '@stripe/stripe-js';
import {
  Elements as StripeElements,
} from '@stripe/react-stripe-js';
import { store } from './stores/index';
import App from './App';
import reportWebVitals from './reportWebVitals';
import theme from './styles/theme';
import './index.css';
import translationBG from './translations/bg.json';
import translationEN from './translations/en.json';

const stripePromise = loadStripe('pk_test_vTevFv2juBKxcJvTrFBpt1Pc004LbwOwxa');

toast.configure({ autoClose: 4000 });

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: translationEN },
      bg: { translation: translationBG }
    },
    fallbackLng: 'bg',
  });

window.i18n = i18n;

ReactDOM.render(
  <LocalizationProvider dateAdapter={AdapterDateFns}>
    <ThemeProvider theme={theme}>
      <ReduxProvider store={store}>
        <React.StrictMode>
          <CssBaseline />
          <BrowserRouter>
            <StripeElements stripe={stripePromise}>
              <App />
            </StripeElements>
          </BrowserRouter>
        </React.StrictMode>
      </ReduxProvider>
    </ThemeProvider>
  </LocalizationProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
