
const FinanceIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='15.145'
    height='15.145'
    viewBox='0 0 15.145 15.145'
    fill='currentColor'
    {...props}
  >
    <path
      d='M9.461 0c-2.82 0-5.675.813-5.675 2.366v2.568a5.2 5.2 0 104.479 9.207c.4.034.8.056 1.2.056 2.822 0 5.679-.813 5.679-2.366V2.366C15.145.813 12.286 0 9.461 0zM.947 9.939A4.259 4.259 0 115.206 14.2 4.264 4.264 0 01.947 9.939zM14.2 11.832c0 .565-1.888 1.42-4.733 1.42-.083 0-.159-.006-.24-.008a5.208 5.208 0 00.831-1.428 9.137 9.137 0 004.142-.989v1.005zm0-2.366c0 .506-1.516 1.241-3.872 1.391a5.2 5.2 0 00.086-.918c0-.172-.009-.341-.026-.509a8.755 8.755 0 003.812-.97zm0-2.366c0 .514-1.568 1.266-3.994 1.4a5.179 5.179 0 00-.642-1.4 9.676 9.676 0 004.636-1zm0-2.366c0 .565-1.891 1.42-4.737 1.42-.247 0-.485-.009-.717-.021a5.181 5.181 0 00-3.539-1.4c-.158 0-.314.01-.468.024V3.733a9.78 9.78 0 004.729 1 9.8 9.8 0 004.737-1zm-4.739-.948c-2.843 0-4.729-.855-4.729-1.42S6.619.947 9.461.947 14.2 1.8 14.2 2.366s-1.891 1.42-4.739 1.42z'
    ></path>
    <path
      d='M5.188 6.858a3.1 3.1 0 103.1 3.1 3.1 3.1 0 00-3.1-3.1zm0 5.313a2.214 2.214 0 112.214-2.214 2.216 2.216 0 01-2.214 2.214z'
    ></path>
  </svg>
);

export default FinanceIcon;