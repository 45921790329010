
const ProfileSettingsIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='20'
    viewBox='0 0 20 20'
    {...props}
  >
    <path
      fill='#112462'
      fillRule='evenodd'
      d='M10.287.265a2.731 2.731 0 00-2.46 1.51l-.43.86q-.254.094-.5.206l-.911-.3a2.724 2.724 0 00-3.448 3.445l.3.909a8.881 8.881 0 00-.211.5l-.856.428a2.723 2.723 0 000 4.875l.861.433q.095.254.206.5l-.3.909a2.724 2.724 0 003.447 3.447l.909-.3c.166.076.334.145.5.21l.428.854a2.724 2.724 0 004.877 0l.428-.856q.257-.1.508-.208l.906.3a2.724 2.724 0 003.447-3.449l-.3-.909q.113-.248.21-.5l.856-.43a2.723 2.723 0 000-4.875L17.9 7.4q-.095-.256-.206-.5l.3-.908a2.724 2.724 0 00-3.447-3.447l-.911.3q-.248-.113-.5-.21l-.436-.86a2.729 2.729 0 00-2.413-1.51zm-.014 1.819a.905.905 0 01.8.5l.577 1.154a.909.909 0 00.536.46 6.347 6.347 0 01.995.412.909.909 0 00.705.057l1.229-.408a.9.9 0 011.152 1.151l-.407 1.226a.909.909 0 00.053.7 6.367 6.367 0 01.407 1 .909.909 0 00.462.542l1.162.581a.9.9 0 010 1.621l-1.154.579a.909.909 0 00-.458.536 6.361 6.361 0 01-.414.995.909.909 0 00-.057.707l.408 1.227a.9.9 0 01-1.146 1.147L13.9 15.86a.909.909 0 00-.7.053 6.342 6.342 0 01-1 .408.909.909 0 00-.542.462l-.579 1.158a.905.905 0 01-1.623 0l-.577-1.153a.909.909 0 00-.536-.46 6.365 6.365 0 01-1-.414.909.909 0 00-.707-.057l-1.227.408a.9.9 0 01-1.144-1.145l.407-1.224a.909.909 0 00-.055-.7 6.36 6.36 0 01-.408-.995.909.909 0 00-.458-.54l-1.16-.583a.9.9 0 010-1.621l1.154-.577a.909.909 0 00.46-.536 6.361 6.361 0 01.414-.995.909.909 0 00.057-.705l-.411-1.232A.9.9 0 015.41 4.263l1.225.408a.909.909 0 00.7-.053 6.349 6.349 0 01.995-.407.909.909 0 00.542-.462l.581-1.162a.9.9 0 01.82-.503zm0 3.635a4.547 4.547 0 104.543 4.545 4.563 4.563 0 00-4.547-4.545zm0 1.819a2.729 2.729 0 11-2.733 2.726 2.71 2.71 0 012.729-2.726z'
      transform='translate(-.266 -.266)'
    ></path>
  </svg>
);

export default ProfileSettingsIcon;
