
const VisaIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='62.068'
    height='19.107'
    viewBox='0 0 62.068 19.107'
    {...props}
  >
    <g data-name='Group 7192' transform='translate(21.698 .318)'>
      <g data-name='Group 7191'>
        <path
          d='M19.876 16.8l-3.154 18.51h5.045l3.157-18.51z'
          data-name='Path 28400'
          transform='translate(-16.722 -16.799)'
        ></path>
      </g>
    </g>
    <g data-name='Group 7194' transform='translate(5.011 .335)'>
      <g data-name='Group 7193'>
        <path
          d='M16.319 16.816L11.378 29.44l-.527-1.906a19.806 19.806 0 00-6.989-7.674L8.38 35.3l5.338-.009 7.945-18.476z'
          data-name='Path 28401'
          transform='translate(-3.862 -16.812)'
        ></path>
      </g>
    </g>
    <g data-name='Group 7196' transform='translate(0 .16)'>
      <g data-name='Group 7195'>
        <path
          d='M10.085 18.181a2.03 2.03 0 00-2.2-1.5H.065L0 17.046c6.087 1.477 10.115 5.035 11.786 9.313z'
          data-name='Path 28402'
          transform='translate(0 -16.677)'
        ></path>
      </g>
    </g>
    <g data-name='Group 7198' transform='translate(29.9)'>
      <g data-name='Group 7197'>
        <path
          d='M33.268 20.421a9.314 9.314 0 013.776.708l.455.214.683-4.012a13.022 13.022 0 00-4.519-.777c-4.985 0-8.5 2.511-8.526 6.109-.032 2.659 2.5 4.143 4.418 5.029 1.966.908 2.625 1.486 2.616 2.3-.016 1.236-1.571 1.808-3.018 1.808a10.59 10.59 0 01-4.75-.969l-.65-.3-.71 4.143a15.978 15.978 0 005.621.986c5.3 0 8.75-2.481 8.786-6.326.022-2.1-1.324-3.708-4.239-5.026-1.765-.859-2.844-1.43-2.834-2.3 0-.765.916-1.587 2.891-1.587z'
          data-name='Path 28403'
          transform='translate(-23.043 -16.554)'
        ></path>
      </g>
    </g>
    <g data-name='Group 7200' transform='translate(43.954 .339)'>
      <g data-name='Group 7199'>
        <path
          d='M47.906 16.815h-3.9a2.51 2.51 0 00-2.643 1.536l-7.489 16.965h5.3s.864-2.281 1.06-2.781l6.461.008c.151.646.615 2.773.615 2.773h4.68zm-6.222 11.931c.415-1.063 2.01-5.173 2.01-5.173-.027.051.411-1.071.672-1.767l.34 1.6 1.169 5.345z'
          data-name='Path 28404'
          transform='translate(-33.874 -16.815)'
        ></path>
      </g>
    </g>
  </svg>
);

export default VisaIcon;
