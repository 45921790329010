import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Grid,
  Typography,
  Divider,
  Stack,
  Avatar,
  List,
  Card,
  ListItem,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  Box
} from '@mui/material';
import * as moment from 'moment';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FullScreenLayout } from 'layouts';
import { Table } from 'components';
import { EmptySettingIcon } from 'icons';
import {
  formatAmount, formatDate, printActivity,
} from 'utils';
import ContentBox from 'components/ContentBox';
import { getCases } from 'stores/CasesSlice';
import { getActivity } from 'stores/DashboardSlice';
import {
  IncomeStatistic,
  OutcomeStatistics,
  BusynessStatistics,
  PropertiesIncomesByCities,
} from 'components/Dashboard';

const ActivityElement = ({ name, location, event, time, isLast, avatar }) => (
  <>
    <ListItem disablePadding>
      <ListItemButton>
        <Grid container>
          <Grid item md={10}>
            <Stack direction='row' spacing={1} alignItems='center'>
              <ListItemAvatar>
                <Avatar src={avatar} />
              </ListItemAvatar>
              <ListItemText secondary={(
                <Typography fontWeight={400}>
                  <span style={{ fontWeight: 600 }}>{name} </span>
                  <span>{printActivity(event)}, </span>
                  <span style={{ fontWeight: 600 }}>{location}</span>
                </Typography>
              )} />
            </Stack>
          </Grid>
          <Grid item md={2}>
            <ListItemText secondary={time ? formatDate(time, 'HH:mm') : ''} />
          </Grid>
        </Grid>
      </ListItemButton>
    </ListItem>
    {!isLast && (
      <Divider />
    )}
  </>
);

const Management = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    casesState: { allCases },
    dashboardState: { activity }
  } = useSelector(({ casesState, dashboardState }) => ({ casesState, dashboardState }));

  const columns = [
    {
      Header: t('property'),
      accessor: 'property.nicknames.landlord',
    },
    {
      Header: t('rent'),
      accessor: 'property.rent',
      Cell: ({ value }) => <div>{formatAmount(value, i18n?.language)}</div>
    },
    {
      Header: t('case'),
      accessor: 'title',
    },
  ];

  useEffect(() => {
    dispatch(getActivity({ page: 1, loadMore: false }));
    dispatch(getCases({ page: 1, loadMore: false }));
  }, []);

  const hasActivity = Boolean(activity?.docs?.length);

  return (
    <FullScreenLayout>
      {({ layoutHeight }) => (
        <Card elevation={3}>
          <Grid container>
            <Grid item sm={6}>
              <BusynessStatistics />
              <Grid container>
                <Grid item sm={6}>
                  <IncomeStatistic />
                </Grid>
                <Grid item sm={6}>
                  <OutcomeStatistics />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item sm={12}>
                  <PropertiesIncomesByCities />
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={6}>
              <ContentBox title={t('cases')} scrollable>
                <Table
                  height={165}
                  data={allCases?.docs ?? []}
                  columns={columns}
                  hasNextPage={allCases?.hasNextPage}
                  onRowClick={(row) => {
                    const propertyId = row?.original?.property?._id;
                    const caseId = row?.original?.id;
                    navigate(`/properties/${propertyId}/case-detail/${caseId}`);
                  }}
                  onNextPage={() => {
                    dispatch(getCases({ page: allCases?.nextPage, loadMore: true }));
                  }}
                  emptyStateProps={{
                    isCaseTable: true,
                  }}
                />
              </ContentBox>
              <ContentBox title={t('activity')} scrollable>
                <InfiniteScroll
                  height={550}
                  dataLength={activity.docs?.length}
                  next={() => {
                    dispatch(getActivity({
                      page: activity?.nextPage,
                      loadMore: true
                    }));
                  }}
                  hasMore={activity?.hasNextPage}
                  loader={<h4>Loading...</h4>}
                >
                  {hasActivity ? (
                    <List disablePadding>
                      {activity?.docs?.map(({ createdAt, property, user, action }, index) => (
                        <div key={`${property}-activity-${index}-${createdAt}`}>
                          {(!moment(createdAt).isSame(moment(activity?.docs?.[index - 1]?.createdAt), 'day') || index === 0) && (
                            <Divider>
                              {moment(createdAt).isSame(moment(), 'day')
                                ? t('today')
                                : moment(createdAt).isSame(moment().subtract(1, 'd'), 'day')
                                  ? t('yesterday')
                                  : formatDate(createdAt, 'dd LLLL', i18n?.language)}
                            </Divider>
                          )}
                          <ActivityElement
                            avatar={user?.avatar}
                            name={user?.fullName}
                            event={action}
                            location={property?.nicknames?.landlord}
                            time={createdAt}
                          />
                        </div>
                      ))}
                    </List>
                  ) : (
                    <Box textAlign='center' mt={3}>
                      <EmptySettingIcon />
                      <Typography variant='h6'>
                        {t('noActivityList')}
                      </Typography>
                    </Box>
                  )}
                </InfiniteScroll>
              </ContentBox>
            </Grid>
          </Grid>
        </Card>
      )
      }
    </FullScreenLayout >
  );
};

export default Management;