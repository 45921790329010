
const MasterCardIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fillRule='evenodd'
      d='M15.504 17.5a4.998 4.998 0 004.996-5 4.998 4.998 0 00-7.716-4.195 5.982 5.982 0 011.708 4.195 5.99 5.99 0 01-2.173 4.623.5.5 0 01-.638 0A5.99 5.99 0 019.508 12.5c0-1.633.651-3.113 1.708-4.195A4.998 4.998 0 003.5 12.5a4.998 4.998 0 006.674 4.711.5.5 0 01.335.942A5.998 5.998 0 012.5 12.5a5.998 5.998 0 019.501-4.87 5.998 5.998 0 019.5 4.87 5.998 5.998 0 01-8.01 5.653.5.5 0 01.336-.942 4.983 4.983 0 001.678.289zM12 8.936a4.987 4.987 0 00-1.492 3.564c0 1.395.57 2.657 1.492 3.564a4.986 4.986 0 001.492-3.564A4.987 4.987 0 0012 8.936z'
      clipRule='evenodd'
    ></path>
  </svg>
);

export default MasterCardIcon;
