import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  styled,
  Paper,
  Typography,
  Button,
  Box,
  Stack,
  TextField,
  Grid,
  InputAdornment,
  FormControlLabel,
  FormControl,
  Radio,
  RadioGroup,
  FormHelperText,
  FormLabel,
  Autocomplete
} from '@mui/material';
import GoogleAutoComplete from 'react-google-autocomplete';
import { useTranslation } from 'react-i18next';
import { PropertyFormLayout } from 'layouts';
import { Dialog } from 'components';
import { searchCity } from 'stores/AddressSlice';
import { createTenant } from 'stores/PropertiesSlice';

const StyledAddress = styled('div')(({ error }) => `
  input {
    width: 100%;
    height: 40px;
    border: 1px solid  ${error ? '#d32f2f' : 'rgb(196 196 196)'};
    font-family: CorsaGrotesk;
    padding: 0 14px;
    border-radius: 3px;
    font-size: 16px;
  }
`);

const PropertyTenant = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
  const [isIndividual, setIsIndividual] = useState(true);
  const [createdUser, setCreatedUser] = useState(false);
  const [foundUser, setFoundUser] = useState(false);
  const [canContinue, setCanContinue] = useState(false);
  const [previewOnly, setPreviewOnly] = useState(false);
  const [latLng, setLatLng] = useState(undefined);

  const {
    addressState: { citiesList },
  } = useSelector(({ propertiesState, addressState }) => ({ propertiesState, addressState }));

  const validations = Yup.object().shape({
    ...(isIndividual ? {
      firstName: Yup.string().required(t('firstNameRequired')),
      lastName: Yup.string().required(t('lastNameRequired')),
      phoneNumber: Yup.string().required(t('phoneNumberRequired')),
      email: Yup.string().email().required(t('emailRequired')),
      invoiceDetails: Yup.object().shape({
        city: Yup.string().required(t('cityRequired')),
        address: Yup.string().required(t('addressRequired')),
        egn: Yup.string().required(t('pinRequired')),
      })
    } : {
      companyName: Yup.string().required(t('companyNameRequired')),
      phoneNumber: Yup.string().required(t('phoneNumberRequired')),
      email: Yup.string().email().required(t('emailRequired')),
      invoiceDetails: Yup.object().shape({
        city: Yup.string().required(t('cityRequired')),
        address: Yup.string().required(t('addressRequired')),
        eic: Yup.string().label('EIK').required(t('eicRequired')),
        manager: Yup.string().required(t('managerRequired'))
      })
    })
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue
  } = useForm({
    resolver: yupResolver(validations),
  });

  return (
    <PropertyFormLayout hasLandlord>
      {({ hasTenantData, propertyId }) => {
        const fieldsProps = {
          disabled: hasTenantData || previewOnly,
        };
        return (
          <>
            <form onSubmit={handleSubmit((data) => {
              {
                isIndividual ? (
                  dispatch(createTenant({
                    ...data,
                    propertyId,
                    phoneNumber: `+359${data?.phoneNumber}`,
                    invoiceDetails: {
                      ...data?.invoiceDetails,
                      fullName: `${data?.firstName} ${data?.lastName}`,
                      phoneNumber: `+359${data?.phoneNumber}`,
                      email: data?.email
                    }
                  }, (response) => {
                    if (response?.data?.status === 'created') {
                      setCreatedUser(true);
                      setCanContinue(true);
                      setPreviewOnly(true);
                    } else if (response?.data?.status === 'found') {
                      setFoundUser(true);
                      setCanContinue(true);
                      setPreviewOnly(true);
                    }
                  }))
                ) : (
                  dispatch(createTenant({
                    ...data,
                    propertyId,
                    isBusinessTenant: true,
                    phoneNumber: `+359${data?.phoneNumber}`,
                    invoiceDetails: {
                      ...data?.invoiceDetails,
                      fullName: data?.companyName,
                      phoneNumber: `+359${data?.phoneNumber}`,
                      email: data?.email
                    }
                  }, (response) => {
                    if (response?.data?.status === 'created') {
                      setCreatedUser(true);
                      setCanContinue(true);
                      setPreviewOnly(true);
                    } else if (response?.data?.status === 'found') {
                      setFoundUser(true);
                      setCanContinue(true);
                      setPreviewOnly(true);
                    }
                  }))
                );
              }
            })}>
              <Box mb={3}>
                <Paper elevation={4}>
                  <Typography variant='subtitle1'>
                    {t('profile')}
                  </Typography>
                  <Typography variant='caption' textTransform='none'>
                    {t('profileText')}
                  </Typography>
                  <Grid container mt={3}>
                    <Grid item md={3}>
                      <FormControl error={Boolean(errors?.payForMonth?.message)}>
                        <FormLabel disabled>{t('clientType')}*</FormLabel>
                        <RadioGroup row>
                          <FormControlLabel
                            control={
                              <Radio
                                checked={isIndividual === true}
                                onChange={() => setIsIndividual(true)}
                              />
                            }
                            label={t('Individual')}
                            {...fieldsProps}
                          />
                          <FormControlLabel
                            control={
                              <Radio
                                checked={isIndividual === false}
                                onChange={() => setIsIndividual(false)}
                              />
                            }
                            label={t('legal')}
                            {...fieldsProps}
                          />
                        </RadioGroup>
                        <FormHelperText error>
                          {errors?.type?.message}
                        </FormHelperText >
                      </FormControl>
                    </Grid>
                    {isIndividual ? (
                      <>
                        <Grid item md={3}>
                          <TextField
                            label={`${t('name')}*`}
                            size='small'
                            fullWidth
                            {...register('firstName')}
                            error={Boolean(errors?.firstName?.message)}
                            helperText={errors?.firstName?.message}
                            InputLabelProps={{ shrink: true }}
                            {...fieldsProps}
                          />
                        </Grid>
                        <Grid item md={3}>
                          <TextField
                            label={`${t('lastName')}*`}
                            size='small'
                            fullWidth
                            {...register('lastName')}
                            error={Boolean(errors?.lastName?.message)}
                            helperText={errors?.lastName?.message}
                            InputLabelProps={{ shrink: true }}
                            {...fieldsProps}
                          />
                        </Grid>
                        <Grid item md={3}>
                          <TextField
                            label={`${t('egn')}*`}
                            type='number'
                            size='small'
                            fullWidth
                            {...register('invoiceDetails.egn')}
                            error={Boolean(errors?.invoiceDetails?.egn?.message)}
                            helperText={errors?.invoiceDetails?.egn?.message}
                            InputLabelProps={{ shrink: true }}
                            {...fieldsProps}
                          />
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid item md={3}>
                          <TextField
                            label={`${t('companyName')}*`}
                            size='small'
                            fullWidth
                            {...register('companyName')}
                            error={Boolean(errors?.companyName?.message)}
                            helperText={errors?.companyName?.message}
                            InputLabelProps={{ shrink: true }}
                            {...fieldsProps}
                          />
                        </Grid>
                        <Grid item md={3}>
                          <TextField
                            label={`${t('eik')}*`}
                            type='number'
                            size='small'
                            fullWidth
                            {...register('invoiceDetails.eic')}
                            error={Boolean(errors?.invoiceDetails?.eic?.message)}
                            helperText={errors?.invoiceDetails?.eic?.message}
                            InputLabelProps={{ shrink: true }}
                            {...fieldsProps}
                          />
                        </Grid>
                        <Grid item md={3}>
                          <TextField
                            label={`${t('materiallyResponsiblePerson')}*`}
                            size='small'
                            fullWidth
                            {...register('invoiceDetails.manager')}
                            error={Boolean(errors?.invoiceDetails?.manager?.message)}
                            helperText={errors?.invoiceDetails?.manager?.message}
                            InputLabelProps={{ shrink: true }}
                            {...fieldsProps}
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                  <Grid container mt={3}>
                    <Grid item md={3}>
                      <Autocomplete
                        options={citiesList?.map((city, cityIndex) => ({
                          label: `${city?.type} ${city?.name} (обл. ${city?.region})`,
                          value: city,
                        }))}
                        onChange={(_, { value }) => {
                          setValue('invoiceDetails.city', value?.name, { shouldValidate: true });
                          setLatLng({
                            lat: value?.y,
                            lng: value?.x,
                          });
                        }}
                        filterOptions={(options) => options}
                        onInputChange={(_, value) => dispatch(searchCity(value))}
                        renderInput={(params) => <TextField {...params} label={`${t('city')}*`} size='small' InputLabelProps={{ shrink: true }} error={Boolean(errors?.invoiceDetails?.city?.message)} />}
                        noOptionsText={t('addCity')}
                        {...fieldsProps}
                      />
                      {Boolean(errors?.invoiceDetails?.city?.message) && (
                        <FormHelperText error>
                          {errors?.invoiceDetails?.city?.message}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item md={4}>
                      {Boolean(latLng) && (
                        <StyledAddress error={Boolean(errors?.address?.message)}>
                          <GoogleAutoComplete
                            key={`${latLng.lat}${latLng.lng}`}
                            apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                            onPlaceSelected={(place) => {
                              setValue('invoiceDetails.address', place?.formatted_address, { shouldValidate: true });
                            }}
                            placeholder={t('enterAddress')}
                            language='bg'
                            options={{
                              bounds: {
                                north: latLng.lat + 0.1,
                                south: latLng.lat - 0.1,
                                east: latLng.lng + 0.1,
                                west: latLng.lng - 0.1,
                              },
                              strictBounds: true,
                              types: [],
                              language: 'bulgarian',
                              componentRestrictions: { country: 'bg' }
                            }}
                            {...fieldsProps}
                          />
                          {Boolean(errors?.invoiceDetails?.address?.message) && (
                            <FormHelperText error>
                              {errors?.invoiceDetails?.address?.message}
                            </FormHelperText>
                          )}
                        </StyledAddress>
                      )}
                    </Grid>
                    <Grid item md={3}>
                      <TextField
                        label={t('email')}
                        size='small'
                        fullWidth
                        {...register('email')}
                        error={Boolean(errors?.email?.message)}
                        helperText={errors?.email?.message}
                        InputLabelProps={{ shrink: true }}
                        {...fieldsProps}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Box>
              <Box mt={3}>
                <Paper elevation={4}>
                  <Typography variant='subtitle1'>
                    {t('mobileInfo')}
                  </Typography>
                  <Typography variant='caption' textTransform='none'>
                    {t('mobileDescription')}
                  </Typography>
                  <Grid container mt={2} alignItems='flex-end'>
                    <Grid item md={3}>
                      <TextField
                        label={t('phoneNumber')}
                        size='small'
                        fullWidth
                        InputProps={{
                          startAdornment: <InputAdornment position='start'>+359</InputAdornment>,
                        }}
                        {...register('phoneNumber')}
                        error={Boolean(errors?.phoneNumber?.message)}
                        helperText={errors?.phoneNumber?.message}
                        InputLabelProps={{ shrink: true }}
                        {...fieldsProps}
                      />
                    </Grid>
                    <Grid item md={3}>
                      <Button
                        variant='contained'
                        color='info'
                        type='submit'
                        disabled={canContinue}
                      >
                        {t('insert')}
                      </Button>
                    </Grid>
                  </Grid>
                  {createdUser && (
                    <Box p={1} mt={3} sx={{ backgroundColor: '#F5F9FC' }}>
                      <Stack direction='row' spacing={3} alignItems='center'>
                        <Typography variant='caption' textTransform='none'>
                          {`${t('result')}:`}
                        </Typography>
                        <Typography variant='subtitle1' color='secondary'>
                          {t('createdUser')}
                        </Typography>
                      </Stack>
                      <Typography variant='caption' textTransform='none'>
                        {t('createdUserText')}
                      </Typography>
                    </Box>
                  )}
                  {foundUser && (
                    <Box p={1} mt={3} sx={{ backgroundColor: '#F5F9FC' }}>
                      <Stack direction='row' spacing={3} alignItems='center'>
                        <Typography variant='caption' textTransform='none'>
                          {`${t('result')}:`}
                        </Typography>
                        <Typography variant='subtitle1' color='primary'>
                          {t('activeUser')}
                        </Typography>
                      </Stack>
                      <Typography variant='caption' textTransform='none'>
                        {t('foundedUserText')}
                      </Typography>
                    </Box>
                  )}
                </Paper>
              </Box>
              <Stack mt={3} mb={3} direction='row' justifyContent='flex-end'>
                <Button
                  variant='contained'
                  color='secondary'
                  disabled={!canContinue}
                  onClick={() => setConfirmModalIsOpen(true)}
                >
                  {t('continue')}
                </Button>
              </Stack>
            </form>
            <Dialog
              open={confirmModalIsOpen}
              onClose={() => setConfirmModalIsOpen(false)}
              title={t('confirmInfo')}
            >
              <Typography variant='h6'>{t('propertyError')}</Typography>
              <Box mt={4}>
                <Stack direction='row' alignItems='center' justifyContent='center' spacing={2}>
                  <Button
                    variant='contained'
                    color='warning'
                    size='large'
                    fullWidth
                    onClick={() => setConfirmModalIsOpen(false)}
                  >
                    {t('back')}
                  </Button>
                  <Button
                    variant='contained'
                    color='primary'
                    size='large'
                    fullWidth
                    onClick={() => {
                      navigate(`/properties/${propertyId}/payment-bills`);
                      setConfirmModalIsOpen(false);
                    }}
                  >
                    {t('confirm')}
                  </Button>
                </Stack>
              </Box>
            </Dialog>
          </>
        );
      }}
    </PropertyFormLayout >
  );
};

export default PropertyTenant;
