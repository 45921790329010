
const ChatIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 22.189 23.471'
    {...props}
  >
    <path
      fill='#0e1833'
      d='M22495.893-22977.727h-10.213a.9.9 0 01-.812-.549.883.883 0 01.184-.957l2.438-2.4-.221-.277a11.039 11.039 0 01-2.35-5.668 11.081 11.081 0 011.016-6.045 11.062 11.062 0 014.076-4.582 11.057 11.057 0 015.889-1.721.876.876 0 01.871.877.879.879 0 01-.877.877 9.326 9.326 0 00-8.607 5.773 9.321 9.321 0 002.016 10.16.871.871 0 010 1.234l-1.514 1.514h8.111a9.35 9.35 0 009.271-8.186.874.874 0 01.865-.762.722.722 0 01.115.006.876.876 0 01.584.334.937.937 0 01.172.658 11.094 11.094 0 01-3.67 6.934 11.116 11.116 0 01-7.344 2.78zm7.666-12.775h-.006a.875.875 0 01-.623-.26.933.933 0 01-.191-.275.834.834 0 01-.057-.336v-.029a.7.7 0 01.063-.316l.012-.023a.836.836 0 01.174-.266l.139-.121.156-.08.172-.059h.035a.959.959 0 01.1-.008 1.026 1.026 0 01.215.027l.133.059a.975.975 0 01.484.492l.006.006a.611.611 0 01.063.3v.012a.879.879 0 01-.875.879zm0-3.832a.879.879 0 01-.877-.877.88.88 0 01.877-.879 1.678 1.678 0 001.68-1.674 1.674 1.674 0 00-1.246-1.633 1.571 1.571 0 00-.434-.059 1.674 1.674 0 00-1.455.85.879.879 0 01-.762.438.883.883 0 01-.434-.115.87.87 0 01-.414-.531.879.879 0 01.092-.67 3.436 3.436 0 012.973-1.713 3.413 3.413 0 011.172.207 3.42 3.42 0 012.205 3.816 3.428 3.428 0 01-3.377 2.84z'
      data-name='Subtraction 10'
      transform='translate(-22484.799 23001.197)'
    ></path>
  </svg>
);

export default ChatIcon;
