import React from 'react';

const ChevronIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='18.521'
    height='31.641'
    viewBox='0 0 18.521 31.641'
    {...props}
  >
    <path
      fill='#00d297'
      d='M14.72 31L.451 16.894a1.5 1.5 0 010-2.148L14.72.644a2.247 2.247 0 013.15 0 2.185 2.185 0 010 3.112L5.663 15.821l5 4.945 7.2 7.117a2.187 2.187 0 010 3.113 2.247 2.247 0 01-3.15 0'
    ></path>
  </svg>
);

export default ChevronIcon;
