import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Paper,
  Stack,
  Typography,
  IconButton,
  Box,
  Button,
  CircularProgress,
  styled
} from '@mui/material';
import { formatAmount, formatDate } from 'utils';
import {
  ArrowBackIcon,
  ActionsIcon,
  DownloadFileIcon,
  SendMailIcon
} from 'icons';
import { MenuList, Dialog } from 'components';
import { DefaultLayout } from 'layouts';
import { getSingleProperty } from 'stores/PropertiesSlice';
import { sendInvoiceManually } from 'stores/RentSlice';


const InvoiceTemplate = styled('div')`
   & .container {
      width: 100%;
      border: 1px solid #ccc;
      margin: 0 auto;
    }

    & .invoice-top-bar {
      display: -webkit-flex;
      /* -webkit-align-items: center;
      -webkit-justify-content: space-between; */
      padding: 20px;
    }

    & .invoice-top-bar-title {
      font-weight: bold;
      width: 60%;
    }

    & .invoice-info {
      display: -webkit-flex;
      -webkit-justify-content: space-between;
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      padding: 15px;
    }

    & .invoice-info-col {
      display: -webkit-flex;
    }

    & .invoice-info-col div:first-of-type {
      margin-right: 5px;
    }

    & .invoice-info-col div:last-child {
      font-weight: 500;
    }

    & .invoice-sender-info {
      display: -webkit-flex;
      padding: 0;
      margin-top: 20px;
    }

    & .invoice-sender-info-col {
      width: 50%;
      padding: 0 15px;
    }

    & .invoice-headline {
      font-weight: 600;
      margin-bottom: 10px;
    }

    & .invoice-sender-info-item {
      display: -webkit-flex;
    }

    & .invoice-sender-info-item:not(:last-child) {
      margin-bottom: 5px;
    }

    & .invoice-sender-info-item div:first-of-type {
      margin-right: 6px;
    }

    & .invoice-sender-info-item div:last-child {
      font-weight: bold;
    }

    & .invoice-sender-info-item-address {
      margin-bottom: 5px;
    }

    & .invoice-sender-info-item-address:first-of-type {
      margin-right: 6px;
    }

    & .invoice-sender-info-item-address span {
      font-weight: bold;
    }

    & .service-info {
      margin-top: 15px;
      padding: 10px
    }

    & table {
      width: 100%;
      border-collapse: collapse;
      border-color: #C5D7F2;
      margin-bottom: 20px;
    }

    & table thead tr {
      background-color: #112462;
      color: #fff
    }

    & table.light-head thead tr {
      background-color: #C5D7F2;
      color: #0E1833;
    }

    & table th,
    & table td {
      border: 1px solid #C5D7F2;
      text-align: left;
      padding: 8px;
    }

    & table.light-head tr th {
      border: 2px solid #fff;
    }

    & .payment {
      margin-top: 10px;
      padding: 10px;
    }

    & .payment-info {
      width: 100%;
      display: flex;
      /* -webkit-justify-content: space-between; */
      background-color: #C5D7F2;
      margin-bottom: 5px;
    }

    & .total-payment-info {
      width: 100%;
      display: flex;
      /* -webkit-justify-content: space-between; */
      background-color: #112462;
      margin-top: 5px
    }

    & .payment-data {
      margin-top: 10px;
      padding: 10px
    }

    & .payment-data table {
      width: 100%;
      border-collapse: collapse;
      border-color: #C5D7F2;
    }

    & .payment-data table tr {
      background-color: #C5D7F2;
      color: #000
    }

    & .invoice-service {
      margin-top: 30px;
      padding: 15px;
    }f

    & .invoice-service table {
      border-spacing: 0;
      border-collapse: collapse;
      width: 100%;
      text-align: left;
    }

    & .invoice-service table thead {
      background-color: #112462;
      color: #fff;
      font-weight: 400;
    }

    & .invoice-service table tr td,
    & .invoice-service table tr th {
      border: 1px solid #ccc;
      padding: 10px;
      font-weight: 400;
    }

    & .amount-row {
      padding: 10px;
      width: 100%;
      display: -webkit-flex;
      -webkit-justify-content: space-between;
    }

    & .amount-row.light {
      background-color: #C5D7F2;
      color: #0E1833;
    }

    & .amount-row.light:not(:first-of-type) {
      margin-top: 3px;
    }

    & .amount-row.dark {
      background-color: #112462;
      color: #fff;
      margin-top: 3px;
      margin-bottom:  40px;
      font-weight: 500;
    }

    & .invoice-localization {
      border-top: 1px solid #E785AE;
      border-bottom: 1px solid #E785AE;
      color: #D62671;
      background-color: #F5CCDB;
      margin-bottom: 20px;
      margin-top: 20px;
      text-align: center;
      padding: 8px;
    }
`;

const Invoice = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { propertyId } = useParams();
  const [sendInvoiceDialogIsOpen, setSendInvoiceDialogIsOpen] = useState(false);
  const [successDialogIsOpen, setSuccessDialogIsOpen] = useState(false);

  const {
    propertiesState: { singleProperty },
    rentState: { sendInvoiceManuallyIsLoading }
  } = useSelector(({ propertiesState, rentState }) => ({ propertiesState, rentState }));


  useEffect(() => {
    dispatch(getSingleProperty(propertyId));
  }, []);

  let invoice = {};

  singleProperty?.rentPayments?.map((item) => {
    if (item?.invoice) {
      invoice = item?.invoice;
    }
  });

  return (
    <DefaultLayout>
      <>
        <Paper elevation={4} sx={{ width: 850, margin: '0 auto' }}>
          <Box p={1}>
            <Stack direction='row' justifyContent='space-between' alignItems='center'>
              <Stack direction='row' spacing={1} alignItems='center'>
                <IconButton onClick={() => navigate(`/properties/${propertyId}`)}>
                  <ArrowBackIcon style={{ width: 16, height: 16 }} />
                </IconButton>
                <Typography variant='h5'>
                  {t('viewInvoice')}
                </Typography>
              </Stack>
              <MenuList
                trigger={({ handleOpen }) => (
                  <IconButton onClick={handleOpen}>
                    <ActionsIcon
                      style={{ width: 16, height: 16 }}
                    />
                  </IconButton>
                )}
                items={[
                  {
                    label: t('downloadInvoice'),
                    description: t('saveLocalInvоice'),
                    icon: DownloadFileIcon,
                    onClick: () => window.open(invoice?.downloadLink, '_blank')
                  },
                  {
                    label: t('sendInvoice'),
                    description: t('sendInvoiceThrowMail'),
                    icon: SendMailIcon,
                    onClick: () => setSendInvoiceDialogIsOpen(true)
                  }
                ]}
              />
            </Stack>
            <Box mt={2}>
              <InvoiceTemplate>
                <div className='container'>
                  <div className='invoice-top-bar'>
                    <div className='invoice-top-bar-title'>
                      {t('invoiceOriginal')}
                    </div>
                    <div className='invoice-top-bar-logo'>
                      <img width='180px' alt='logo' src='https://dev-fidweb.s3.amazonaws.com/immotech-web-app/Group 24600.png' />
                    </div>
                  </div>
                  <div className='invoice-info'>
                    <div className='invoice-info-col'>
                      <div>{`${t('dateOfIssue')}:`} </div>
                      <div>
                        {invoice?.issueDate ? formatDate(invoice?.issueDate, 'dd.MM.yyyy') : ''}
                      </div>
                    </div>
                    <div className='invoice-info-col' style={{ textAlign: 'center' }}>
                      <div>№ </div>
                      <div>
                        {invoice?.invoiceNumber}
                      </div>
                    </div>
                    <div className='invoice-info-col' style={{ textAlign: 'center' }}>
                      <div>{`${t('dateOfDue')}:`} </div>
                      <div>
                        {invoice?.dueDate ? formatDate(invoice?.dueDate, 'dd.MM.yyyy') : ''}
                      </div>
                    </div>
                  </div>
                  <div className='invoice-sender-info'>
                    <div className='invoice-sender-info-col'>
                      <div className='invoice-headline'>
                        {t('sender')}
                      </div>
                      <div className='invoice-sender-info-item'>
                        <div>{`${t('name')}:`}</div>
                        <div>
                          {invoice?.provider?.fullName}
                        </div>
                      </div>
                      <div className='invoice-sender-info-item'>
                        <div>{`${t('city')}:`}</div>
                        <div>
                          {invoice?.provider?.city}
                        </div>
                      </div>
                      <div className='invoice-sender-info-item-address'>
                        {`${t('address')}:`}
                        <span>
                          {invoice?.receiver?.address}
                        </span>
                      </div>
                      <div className='invoice-sender-info-item'>
                        <div>
                          {/* {{ providerIdType }}:  */}
                        </div>
                        <div>
                          {/* {{ providerId }} */}
                        </div>
                      </div>
                    </div>
                    <div className='invoice-sender-info-col'>
                      <div className='invoice-headline'>
                        {t('receiver')}
                      </div>
                      <div className='invoice-sender-info-item'>
                        <div>{`${t('name')}:`}</div>
                        <div>
                          {invoice?.receiver?.fullName}
                        </div>
                      </div>
                      <div className='invoice-sender-info-item'>
                        <div>{`${t('city')}:`}</div>
                        <div>
                          {invoice?.receiver?.city}
                        </div>
                      </div>
                      <div className='invoice-sender-info-item-address'>
                        <div>{`${t('address')}:`}</div>
                        <span>
                          {invoice?.receiver?.address}
                        </span>
                      </div>
                      <div className='invoice-sender-info-item'>
                        <div>
                          {/* {{ receiverIdType }}:  */}
                        </div>
                        <div>
                          {/* {{ receiverId }} */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='invoice-service'>
                    <table>
                      <thead>
                        <tr>
                          <th>{t('serviceName')}</th>
                          <th>{t('quantity')}</th>
                          <th>{t('unitPrice')}</th>
                          <th>{t('value')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {`${t('monthlyRent')} - `}
                            {invoice?.month}
                          </td>
                          <td>
                            {`1 - ${t('rent')}`}
                          </td>
                          <td>
                            {formatAmount(invoice?.amountWithoutVAT, i18n?.language)}
                          </td>
                          <td>
                            {formatAmount(invoice?.amountWithoutVAT, i18n?.language)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div>
                      <div className='amount-row light'>
                        <p>{t('amountWithoutVat')}</p>
                        <p>
                          {formatAmount(invoice?.amountWithoutVAT, i18n?.language)}
                        </p>
                      </div>
                      <div className='amount-row light'>
                        <p>
                          {`${(invoice?.VAT)} % ${t('VAT')}`}
                        </p>
                        <p>
                          {formatAmount(invoice?.vatAmount, i18n?.language)}
                        </p>
                      </div>
                      <div className='amount-row dark'>
                        <p>{t('amountWithVat')}</p>
                        <p>
                          {formatAmount(invoice?.amount, i18n?.language)}
                        </p>
                      </div>
                    </div>
                    <div className='invoice-headline'>{t('paymentData')}</div>
                    <table className='light-head'>
                      <thead>
                        <tr>
                          <th>{t('taxPaymentData')}</th>
                          <th>{t('basis')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {invoice?.dueDate ? formatDate(invoice?.dueDate, 'dd.MM.yyyy') : ''}
                          </td>
                          <td>
                            {invoice?.subject}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table className='light-head'>
                      <thead>
                        <tr>
                          <th>{t('bank')}</th>
                          <th>IBAN</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {invoice?.bankDetails?.bank}
                          </td>
                          <td>
                            {invoice?.bankDetails?.iban}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className='invoice-localization'>
                    {`${t('provideLocalizationCode')} ${invoice?.localizationCode}`}
                  </div>
                </div>
              </InvoiceTemplate>
            </Box>
          </Box>
        </Paper >
        <Dialog
          open={sendInvoiceDialogIsOpen}
          onClose={() => setSendInvoiceDialogIsOpen(false)}
          title={t('sendingInvoice')}
        >
          <Box>
            {sendInvoiceManuallyIsLoading ? (
              <Box sx={{ textAlign: 'center' }}>
                <CircularProgress size={100} />
              </Box>
            ) : (
              <>
                <Box>
                  <Typography variant='h6' mt={1} mb={3}>
                    {t('sendInvoiceManually')}
                    <Typography variant='body2'>mail@mailexample.com</Typography>
                  </Typography>
                  <Typography variant='h6'>
                    {t('pleaseConfirm')}
                  </Typography>
                </Box>
                <Stack mt={3} direction='row' alignItems='center' spacing={3}>
                  <Button
                    variant='contained'
                    color='warning'
                    fullWidth
                    onClick={() => setSendInvoiceDialogIsOpen(false)}
                  >
                    {t('back')}
                  </Button>
                  <Button
                    variant='contained'
                    color='primary'
                    fullWidth
                    onClick={() => {
                      dispatch(sendInvoiceManually(invoice?._id, (response) => {
                        if (response?.data?.success) {
                          setSendInvoiceDialogIsOpen(false);
                          setSuccessDialogIsOpen(true);
                        }
                      }));
                    }}
                  >
                    {t('send')}
                  </Button>
                </Stack>
              </>
            )}
          </Box>
        </Dialog>
        <Dialog
          open={successDialogIsOpen}
          onClose={() => setSuccessDialogIsOpen(false)}
          title={t('sendingInvoiceSuccess')}
        >
          <Typography variant='h6' mt={1} mb={3}>
            {t('tenantWillReceiveInvoice')}
          </Typography>
          <Button
            variant='contained'
            color='primary'
            fullWidth
            onClick={() => navigate(`/properties/${propertyId}`)}
          >
            {t('manageProperty')}
          </Button>
        </Dialog>
      </>
    </DefaultLayout >
  );
};

export default Invoice;