
const MapIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='21.033'
    height='21.03'
    viewBox='0 0 21.033 21.03'
    {...props}
  >
    <g fill='#c7c7c7' transform='translate(-1.996 -2)'>
      <path
        d='M16.27 2a6.732 6.732 0 00-6.76 6.76 8.138 8.138 0 001.052 3.755h-1.8a.452.452 0 00-.3.075c-.075.075-.15.075-.225.15l-6.013 6.009a.686.686 0 00-.15.826.722.722 0 00.676.451h4.957l2.779 2.774a.682.682 0 00.526.225h5.258a.452.452 0 00.3-.075c.075-.075.15-.075.225-.15l6.005-6c.075-.075.15-.15.15-.225a.823.823 0 000-.6.905.905 0 00-.376-.376.452.452 0 00-.3-.075H19.8c1.652-1.878 3.23-4.281 3.23-6.76A6.732 6.732 0 0016.27 2zm0 1.5a5.225 5.225 0 015.257 5.26c0 2.7-2.554 5.558-4.056 6.985a11.862 11.862 0 01-1.2 1.052 19.679 19.679 0 01-3.68-3.981 7.32 7.32 0 01-1.579-4.056A5.225 5.225 0 0116.27 3.5zM8.533 18.749a.682.682 0 00-.526-.225H4.553l4.506-4.506h2.479a21.634 21.634 0 004.281 4.356 1.068 1.068 0 00.451.15 1.068 1.068 0 00.451-.15s.676-.526 1.577-1.352h2.178l-4.506 4.506h-4.658z'
        data-name='Path 55294'
      ></path>
      <path
        d='M19.755 13.311A3.755 3.755 0 1016 9.555a3.719 3.719 0 003.755 3.756zm0-6.009A2.253 2.253 0 1117.5 9.555 2.213 2.213 0 0119.755 7.3z'
        data-name='Path 55295'
        transform='translate(-3.486 -.946)'
      ></path>
    </g>
  </svg>
);

export default MapIcon;
