import React from 'react';

const LoginLogoIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='90.394'
    height='114.499'
    data-name='Group 1966'
    viewBox='0 0 90.394 114.499'
    {...props}
  >
    <path
      fill='#00d297'
      d='M12.964 120.65H1.888A1.889 1.889 0 010 118.762V61.578a1.889 1.889 0 011.888-1.888h11.076a1.889 1.889 0 011.888 1.888v57.19a1.887 1.887 0 01-1.888 1.882z'
      data-name='Path 1294'
      transform='translate(0 -25.725)'
    ></path>
    <path
      fill='#00d297'
      d='M147.728 120.646h-12.964a.943.943 0 01-.944-.944V60.624a.943.943 0 01.944-.944h12.964a.943.943 0 01.944.944v59.083a.941.941 0 01-.944.939z'
      data-name='Path 1295'
      transform='translate(-58.279 -25.721)'
    ></path>
    <path
      fill='#00d297'
      d='M79.994 145.3H68.918a1.885 1.885 0 01-1.888-1.888V73.728a1.885 1.885 0 011.888-1.888h11.076a1.885 1.885 0 011.888 1.888v69.682a1.893 1.893 0 01-1.888 1.89z'
      data-name='Path 1296'
      transform='translate(-29.192 -30.805)'
    ></path>
    <path
      fill='#00d297'
      d='M64.4.314L46.842 19.8a.953.953 0 01-1.41 0L27.876.314a.953.953 0 00-1.41 0L2.242 27.2a.943.943 0 00.705 1.573h12.208a.939.939 0 00.705-.315l10.481-11.64a.953.953 0 011.41 0l10.77 11.953h14.571a.939.939 0 00.705-.315l10.482-11.638a.953.953 0 011.41 0l10.482 11.638a.959.959 0 00.705.315h12.45a.943.943 0 00.705-1.573L65.814.314a.945.945 0 00-1.414 0z'
      data-name='Path 1297'
      transform='translate(-.875 .002)'
    ></path>
  </svg>
);

export default LoginLogoIcon;
