
import { useEffect, useMemo } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Header, RequireAuth } from 'components';
import { Login, ConfirmCode } from 'containers/Login';
import {
  Management,
  AddPaymentBills,
  EditPaymentBills,
  ConfirmPayments,
  SubscriptionPayment,
} from 'containers';
import {
  PropertyCaseCreation,
  PropertiesList,
  PropertiesDetailView,
  PropertiesRent,
  PropertiesRentEdit,
  PropertiesSemiPaidRentEdit,
  PropertiesCaseEdit,
  PropertiesCaseDetails,
  PropertiesCommunicatorRoom,
  PropertyTenant,
  PropertyBaseParams,
  PropertyPaymentBills,
  PropertyFiles,
  EditProperty,
  EditRent,
  TenantInfo,
  EditTenant,
  RentContract,
  TenantData,
  TenantHistory
} from 'containers/Properties';
import {
  FinancesOther,
  FinancesBank,
  FinancesPaymentLocalizationCreate,
  FinancesPaymentLocalizationEdit,
  FinancesArrivalExpensesCreate,
  FinancesArrivalExpensesEdit,
} from 'containers/Finances';
import {
  PersonalSettings,
  PersonalSettingsEdit,
  PersonalSettingsAddUser,
  PersonalSettingsSubscription,
  PersonalSettingsHelp
} from 'containers/Settings';
import {
  AddOrganizationBank,
  EditOrganization,
  EditOrganizationBank,
  EditOrganizationPersonalData
} from 'containers/Organization';
import {
  GlobalSettings,
  Invoice
} from 'containers/GlobalSettings';
import { getMyUserData } from 'stores/OrganizationSlice';
import { updateRequisition } from 'stores/FinancesSlice';

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const App = () => {
  const dispatch = useDispatch();
  let query = useQuery();
  const refQuery = query.get('ref');
  const { isAuthenticated } = useSelector(({ authenticationState }) => authenticationState);

  useEffect(() => {
    if (refQuery) {
      dispatch(updateRequisition(refQuery));
    }
  }, [refQuery]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getMyUserData());
    }
  }, [isAuthenticated]);

  return (
    <>
      {isAuthenticated && (
        <Header />
      )}
      <Routes>
        <Route path='/' element={
          <RequireAuth>
            <Management />
          </RequireAuth>
        } />
        <Route path='/login' element={<Login />} />
        <Route path='/login/:phoneNumber' element={<ConfirmCode />} />
        <Route path='/global-settings' element={
          <RequireAuth>
            <GlobalSettings />
          </RequireAuth>
        } />
        <Route path='/invoice/:propertyId' element={
          <RequireAuth>
            <Invoice />
          </RequireAuth>
        } />
        <Route path='/personal-settings' element={
          <RequireAuth>
            < PersonalSettings />
          </RequireAuth>}
        />
        <Route path='/personal-settings/:userId/edit' element={
          <RequireAuth>
            <PersonalSettingsEdit />
          </RequireAuth>}
        />
        <Route path='/personal-settings/add-user' element={
          <RequireAuth>
            <PersonalSettingsAddUser />
          </RequireAuth>
        } />
        <Route path='/organization/:organizationId/edit' element={
          <RequireAuth>
            <EditOrganization />
          </RequireAuth>
        } />
        <Route path='/personal-settings/personal-data/:organizationId/edit' element={
          <RequireAuth>
            <EditOrganizationPersonalData />
          </RequireAuth>
        } />
        <Route path='/organization/:organizationId/edit-banks' element={
          <RequireAuth>
            <EditOrganizationBank />
          </RequireAuth>
        } />
        <Route path='/personal-settings/subscription' element={
          <RequireAuth>
            <PersonalSettingsSubscription />
          </RequireAuth>
        } />
        <Route path='/personal-settings/help' element={
          <RequireAuth>
            <PersonalSettingsHelp />
          </RequireAuth>
        } />
        <Route path='/finances/other' element={
          <RequireAuth>
            <FinancesOther />
          </RequireAuth>
        } />
        <Route path='/finances/bank' element={
          <RequireAuth>
            <FinancesBank />
          </RequireAuth>
        } />
        <Route path='/finances/other/add-arrival-and-expenses' element={
          <RequireAuth>
            <FinancesArrivalExpensesCreate />
          </RequireAuth>
        } />
        <Route path='/finances/other/edit-arrival-and-expenses' element={
          <RequireAuth>
            <FinancesArrivalExpensesEdit />
          </RequireAuth>
        } />
        <Route path='/finances/:financeId/payment-localization-create' element={
          <RequireAuth>
            <FinancesPaymentLocalizationCreate />
          </RequireAuth>
        } />
        <Route path='/finances/:financeId/payment-localization-edit' element={
          <RequireAuth>
            <FinancesPaymentLocalizationEdit />
          </RequireAuth>
        } />
        <Route path='/properties' element={
          <RequireAuth>
            <PropertiesList />
          </RequireAuth>
        } />
        <Route path='/properties/:propertyId/edit' element={
          <RequireAuth>
            <EditProperty />
          </RequireAuth>
        } />
        <Route path='/properties/:propertyId/edit-rent' element={
          <RequireAuth>
            <EditRent />
          </RequireAuth>
        } />
        <Route path='/properties/:propertyId/edit-tenant' element={
          <RequireAuth>
            <EditTenant />
          </RequireAuth>
        } />
        <Route path='/properties/:propertyId/add-tenant/rent-contract' element={
          <RequireAuth>
            <RentContract />
          </RequireAuth>
        } />
        <Route path='/properties/:propertyId/add-tenant/data-tenant' element={
          <RequireAuth>
            <TenantData />
          </RequireAuth>
        } />
        <Route path='/properties/:propertyId/tenant-history' element={
          <RequireAuth>
            <TenantHistory />
          </RequireAuth>
        } />
        <Route path='/properties/create' element={
          <RequireAuth>
            <PropertyBaseParams />
          </RequireAuth>
        } />
        <Route path='/properties/:propertyId/base-params' element={
          <RequireAuth>
            <PropertyBaseParams />
          </RequireAuth>
        } />
        <Route path='/properties/:propertyId/tenant' element={
          <RequireAuth>
            <PropertyTenant />
          </RequireAuth>
        } />
        <Route path='/properties/:propertyId/payment-bills' element={
          <RequireAuth>
            <PropertyPaymentBills />
          </RequireAuth>
        } />
        <Route path='/properties/:propertyId/files' element={
          <RequireAuth>
            <PropertyFiles />
          </RequireAuth>
        } />
        <Route path='/properties/subscription-payment' element={
          <RequireAuth>
            <SubscriptionPayment />
          </RequireAuth>
        } />
        <Route path='/properties/:propertyId' element={
          <RequireAuth>
            <PropertiesDetailView />
          </RequireAuth>
        } />
        <Route path='/properties/:propertyId/tenant-data' element={
          <RequireAuth>
            <TenantInfo />
          </RequireAuth>
        } />
        <Route path='/properties/:propertyId/rent/:rentId' element={
          <RequireAuth>
            <PropertiesRent />
          </RequireAuth>
        } />
        <Route path='/properties/:propertyId/edit-rent/:rentId' element={
          <RequireAuth>
            <PropertiesRentEdit />
          </RequireAuth>
        } />
        <Route path='/properties/:propertyId/edit-rent/:rentId/:paymentId' element={
          <RequireAuth>
            <PropertiesSemiPaidRentEdit />
          </RequireAuth>
        } />
        <Route path='/properties/:propertyId/add-payment-bills' element={
          <RequireAuth>
            <AddPaymentBills />
          </RequireAuth>
        } />
        <Route path='/properties/:propertyId/edit-payment-bills' element={
          <RequireAuth>
            <EditPaymentBills />
          </RequireAuth>
        } />
        <Route path='/properties/:propertyId/confirm-payments' element={
          <RequireAuth>
            <ConfirmPayments />
          </RequireAuth>
        } />
        <Route path='/properties/:propertyId/case' element={
          <RequireAuth>
            <PropertyCaseCreation />
          </RequireAuth>
        } />
        <Route path='/properties/:propertyId/case/:caseId' element={
          <RequireAuth>
            <PropertiesCaseEdit />
          </RequireAuth>
        } />
        <Route path='/properties/:propertyId/case-detail/:caseId' element={
          <RequireAuth>
            <PropertiesCaseDetails />
          </RequireAuth>
        } />
        <Route path='/properties/:propertyId/communicator/:roomId' element={
          <RequireAuth>
            <PropertiesCommunicatorRoom />
          </RequireAuth>
        } />
        <Route path='/organization/add-bank' element={
          <RequireAuth>
            <AddOrganizationBank />
          </RequireAuth>
        } />
      </Routes>
    </>
  );
};

export default App;
