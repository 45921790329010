import { Select, FormControl, MenuItem, InputLabel, FormHelperText } from '@mui/material';

const SelectComponent = ({ options, fullWidth = true, label, error, helperText, ...restProps }) => (
  <FormControl fullWidth {...{ error }}>
    <InputLabel shrink={true} id='demo-controlled-open-select-label'>{label}</InputLabel>
    <Select
      notched={true}
      labelId='demo-controlled-open-select-label'
      id='demo-controlled-open-select'
      label={label}
      fullWidth={fullWidth}
      error={error}
      inputProps={{ size: 'medium' }}
      {...restProps}
    >
      {options?.map(({ label, value }, index) => (
        <MenuItem
          key={`${index}-label`}
          value={value}
        >
          {label}
        </MenuItem>
      ))}
    </Select>
    {Boolean(helperText) && (
      <FormHelperText>{helperText}</FormHelperText>
    )}
  </FormControl>
);

export default SelectComponent;