
const EllipseIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='30'
    height='30'
    viewBox='0 0 30 30'
    {...props}
  >
    <circle
      cx='15'
      cy='15'
      r='15'
      fill={props.fill}
      data-name='Ellipse 9323'
    ></circle>
  </svg>
);

export default EllipseIcon;
