
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import {
  Button,
  Stack,
  Typography,
  Card,
  IconButton,
  Grid,
  Paper,
  Box
} from '@mui/material';
import { FullScreenLayout } from 'layouts';
import { formatAmount } from 'utils';
import { Table, Dialog, ContentBox } from 'components';
import { ListIcon, MapIcon, SmallEllipseIcon } from 'icons';
import { getPropertiesList } from 'stores/PropertiesSlice';
import PropertyImage from 'assets/property.png';
import { useTranslation } from 'react-i18next';

const PropertyProfile = ({ image, rent, size, location, type, construction }) => {
  const { t } = useTranslation();
  return (
    <Box mb={1}>
      <Paper sx={{ p: 0 }} variant='outlined'>
        <Grid container spacing={1} alignItems='center'>
          <Grid item sm={4}>
            <img src={image} alt='Property' style={{ display: 'block', width: '100%' }} />
          </Grid>
          <Grid item sm={8}>
            <Grid container>
              <Grid item sm={8}>
                <Typography sx={{ fontSize: 10 }} variant='caption'>{t('area')}</Typography>
                <Typography variant='body1'>{location}</Typography>
              </Grid>
              <Grid item sm={4}>
                <Typography sx={{ fontSize: 10 }} variant='caption'>{t('rent')}</Typography>
                <Typography variant='body1'>{rent}</Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item sm={4}>
                <Typography sx={{ fontSize: 10 }} variant='caption'>{t('size')}</Typography>
                <Typography variant='body1'>{size}</Typography>
              </Grid>
              <Grid item sm={4}>
                <Typography sx={{ fontSize: 10 }} variant='caption'>{t('typeProp')}</Typography>
                <Typography variant='body1'>{type}</Typography>
              </Grid>
              <Grid item sm={4}>
                <Typography sx={{ fontSize: 10 }} variant='caption'>{t('construction')}</Typography>
                <Typography variant='body1'>{construction}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

const PropertiesList = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [propertyStatisticIsOpen, setPropertyStatisticIsOpen] = useState(false);
  const {
    propertiesState: {
      propertiesList, getPropertiesListIsLoading,
    },
  } = useSelector(({ propertiesState, authenticationState }) => ({ propertiesState, authenticationState }));

  useEffect(() => {
    dispatch(getPropertiesList());
  }, []);

  const columns = [
    {
      Header: t('propertyName'),
      accessor: 'nicknames',
      Cell: ({ value, row }) => {
        const propertyId = row?.original?._id;
        return <div style={{ width: '100%' }} onClick={() => navigate(`/properties/${propertyId}`)}>{value.landlord}</div>;
      }
    },
    {
      Header: t('city'),
      accessor: 'city',
      Cell: ({ value, row }) => {
        const propertyId = row?.original?._id;
        return <div style={{ width: '100%' }} onClick={() => navigate(`/properties/${propertyId}`)}>{value}</div>;
      }
    },
    {
      Header: t('propertyType'),
      accessor: 'propertyType',
      Cell: ({ value, row }) => {
        const propertyId = row?.original?._id;
        if (value.type === 'office') {
          return <div style={{ width: '100%' }} onClick={() => navigate(`/properties/${propertyId}`)}>{t('office')}</div>;
        } else if (value.type === 'warehouse') {
          return <div style={{ width: '100%' }} onClick={() => navigate(`/properties/${propertyId}`)}>{t('warehouse')}</div>;
        } else if (value.type === 'house') {
          return <div style={{ width: '100%' }} onClick={() => navigate(`/properties/${propertyId}`)}>{t('house')}</div>;
        } else if (value.type === 'apartment') {
          return <div style={{ width: '100%' }} onClick={() => navigate(`/properties/${propertyId}`)}>{t('apartment')}</div>;
        } else if (value.type === 'room') {
          return <div style={{ width: '100%' }} onClick={() => navigate(`/properties/${propertyId}`)}>{t('room')}</div>;
        } else {
          return <div style={{ width: '100%' }} onClick={() => navigate(`/properties/${propertyId}`)}>{t('houseFloor')}</div>;
        }
      },
    },
    {
      Header: t('onRent'),
      Cell: ({ row }) => {
        const propertyId = row?.original?._id;
        return <div style={{ width: '100%' }} onClick={() => navigate(`/properties/${propertyId}`)}>{Boolean(row?.original?.rent) ? t('yes') : t('no')} </div>;
      }
    },
    {
      Header: t('rent'),
      accessor: 'rent',
      Cell: ({ value, row }) => {
        const propertyId = row?.original?._id;
        return <div style={{ width: '100%' }} onClick={() => navigate(`/properties/${propertyId}`)}>{formatAmount(value, i18n?.language)}</div>;
      }
    },
    {
      Header: t('tenant'),
      accessor: 'tenant',
      Cell: ({ value, row }) => {
        const propertyId = row?.original?._id;
        return <div style={{ width: '100%' }} onClick={() => navigate(`/properties/${propertyId}`)}>{value?.fullName ? value?.fullName : '--'}</div>;
      }
    },
    {
      Header: t('startContract'),
      accessor: 'startDate',
      Cell: ({ value, row }) => {
        const propertyId = row?.original?._id;
        return <div style={{ width: '100%' }} onClick={() => navigate(`/properties/${propertyId}`)}>{value ? `${format(new Date(value), 'dd.MM.yyyy')}` : '--'}</div>;
      }
    },
    {
      Header: t('endContract'),
      accessor: 'endDate',
      Cell: ({ value, row }) => {
        const propertyId = row?.original?._id;
        if (value === null) {
          return <div style={{ width: '100%' }} onClick={() => navigate(`/properties/${propertyId}`)}>{t('unlimited')}</div>;
        } else {
          return <div style={{ width: '100%' }} onClick={() => navigate(`/properties/${propertyId}`)}>{format(new Date(value), 'dd.MM.yyyy')}</div>;
        }
      }
    },
    {
      Header: t('obligationsRent'),
      accessor: 'unpaidRentsAmount',
      Cell: ({ value, row }) => {
        const propertyId = row?.original?._id;
        if (value) {
          return <div style={{ width: '100%' }} onClick={() => navigate(`/properties/${propertyId}?activeTab=paymentBills`)}>{formatAmount(value, i18n?.language)}</div>;
        } else {
          return <div>--</div>;
        }
      }
    },
    {
      Header: t('obligationsBills'),
      accessor: 'unpaidBillsAmount',
      Cell: ({ value, row }) => {
        const propertyId = row?.original?._id;
        if (value) {
          return <div style={{ width: '100%' }} onClick={() => navigate(`/properties/${propertyId}?activeTab=paymentBills`)}>
            {formatAmount(value, i18n?.language)}
          </div>;
        } else {
          return <div>--</div>;
        }
      }
    },
    {
      Header: t('otherCases'),
      accessor: 'openCasesCount',
      Cell: ({ value, row }) => {
        const propertyId = row?.original?._id;
        if (value) {
          return <div style={{ width: '100%' }} onClick={() => navigate(`/properties/${propertyId}?activeTab=cases`)}>{value}</div>;
        } else {
          return <div>--</div>;
        }
      }
    },
  ];

  return (
    <FullScreenLayout>
      {({ layoutHeight }) => (
        <>
          <Card elevation={1} style={{ height: layoutHeight }}>
            <Stack direction='row' alignItems='center' justifyContent='space-between'>
              <Typography variant='h5'>{t('addedProperties')}</Typography>
              <Stack direction='row' spacing={1}>
                <IconButton onClick={() => { }}>
                  <ListIcon />
                </IconButton>
                <IconButton>
                  <MapIcon />
                </IconButton>
                <Button
                  variant='contained'
                  onClick={() => navigate('/properties/create')}
                >
                  {t('addProperty')}
                </Button>
              </Stack>
            </Stack>
            <>
              <Box sx={{
                height: 'calc(100% - 40px)',
              }}>
                {Boolean(layoutHeight) && (
                  <Table
                    height={layoutHeight - 50}
                    isLoading={getPropertiesListIsLoading}
                    data={propertiesList ?? []}
                    columns={columns}
                  />
                )}
              </Box>
            </>
          </Card>
          <Dialog
            open={propertyStatisticIsOpen}
            onClose={() => setPropertyStatisticIsOpen(false)}
            title={t('payStatistics')}
            placement='flex-end'
          >
            <Stack direction='row' spacing={1} alignItems='center'>
              <SmallEllipseIcon />
              <Typography variant='caption'>Наемът на този имот е по-нисък от средния на пазара с 10%</Typography>
            </Stack>
            <Grid container spacing={2}>
              <Grid item sm={6}>
                <Paper variant='outlined'>
                  <Stack direction='row' alignItems='center' spacing={1}>
                    <Typography variant='overline'>880лв</Typography>
                    <Typography variant='caption'>Най-висок за района</Typography>
                  </Stack>
                </Paper>
              </Grid>
              <Grid item sm={6}>
                <Paper variant='outlined'>
                  <Stack direction='row' alignItems='center' spacing={1}>
                    <Typography variant='overline'>880лв</Typography>
                    <Typography variant='caption'>Най-нисък за района</Typography>
                  </Stack>
                </Paper>
              </Grid>
              <Grid item sm={12}>
                <ContentBox scrollable={false} title='Профил на имот' >
                  <Grid container>
                    <Grid item md={6}>
                      <Typography variant='caption'>Град</Typography>
                      <Typography variant='subtitle2'>Велико Търново</Typography>
                    </Grid>
                    <Grid item md={6}>
                      <Typography variant='caption'>Район</Typography>
                      <Typography variant='subtitle2'>кв. Дружба</Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item md={4}>
                      <Typography variant='caption'>Вид имот</Typography>
                      <Typography variant='subtitle2'>Жилище</Typography>
                    </Grid>
                    <Grid item md={4}>
                      <Typography variant='caption'>Размер</Typography>
                      <Typography variant='subtitle2'>109кв.м</Typography>
                    </Grid>
                    <Grid item md={4}>
                      <Typography variant='caption'>Тип</Typography>
                      <Typography variant='subtitle2'>Тристаен</Typography>
                    </Grid>
                  </Grid>
                </ContentBox>
              </Grid>
              <Grid item sm={12}>
                <ContentBox
                  contentHeight='100px'
                  title='Наеми със сходен профил'
                  scrollable
                >
                  <PropertyProfile
                    image={PropertyImage}
                    rent='820лв'
                    type='Тристаен'
                    size='99лв.'
                    location='кв.Дружба | 7мин до Център'
                    construction='Ново-тухла'
                  />
                  <PropertyProfile
                    image={PropertyImage}
                    rent='820лв'
                    type='Тристаен'
                    size='99лв.'
                    location='кв.Дружба | 7мин до Център'
                    construction='Ново-тухла'
                  />
                  <PropertyProfile
                    image={PropertyImage}
                    rent='820лв'
                    type='Тристаен'
                    size='99лв.'
                    location='кв.Дружба | 7мин до Център'
                    construction='Ново-тухла'
                  />
                  <PropertyProfile
                    image={PropertyImage}
                    rent='820лв'
                    type='Тристаен'
                    size='99лв.'
                    location='кв.Дружба | 7мин до Център'
                    construction='Ново-тухла'
                  />
                </ContentBox>
              </Grid>
            </Grid>
          </Dialog>
        </>
      )}
    </FullScreenLayout>
  );
};

export default PropertiesList;