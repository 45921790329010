
const LoginLogoSubIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='189.08'
    height='24.152'
    viewBox='0 0 189.08 24.152'
    {...props}
  >
    <path
      fill='#00d297'
      d='M5.49-31.64h1.7v23.352h-1.7zM37.415-8.288l-.034-19.982-9.774 16.88h-.834L17-28.2v19.912h-1.669V-31.64h1.434l10.442 18.048L37.648-31.64h1.4l.033 23.351zm31.891 0l-.033-19.982L59.5-11.391h-.834L48.89-28.2v19.912h-1.668V-31.64h1.434L59.1-13.592 69.54-31.64h1.4l.033 23.351zm19.715.167a12.474 12.474 0 01-6.2-1.551 11.426 11.426 0 01-4.353-4.253 11.713 11.713 0 01-1.585-6.038A11.713 11.713 0 0178.463-26a11.426 11.426 0 014.353-4.253 12.474 12.474 0 016.2-1.551 12.582 12.582 0 016.2 1.535 11.3 11.3 0 014.353 4.237 11.777 11.777 0 011.585 6.071 11.777 11.777 0 01-1.585 6.071 11.3 11.3 0 01-4.353 4.237 12.582 12.582 0 01-6.195 1.531zm0-1.568a10.617 10.617 0 005.3-1.334 9.83 9.83 0 003.736-3.686 10.259 10.259 0 001.368-5.254 10.259 10.259 0 00-1.368-5.254 9.83 9.83 0 00-3.732-3.683 10.617 10.617 0 00-5.3-1.334A10.71 10.71 0 0083.7-28.9a9.8 9.8 0 00-3.753 3.686 10.259 10.259 0 00-1.368 5.254 10.259 10.259 0 001.368 5.254 9.8 9.8 0 003.753 3.682 10.71 10.71 0 005.321 1.335zM110.6-27.236h-7.472v-4.4h20.349v4.4h-7.472v18.948h-5.4zm33.86 14.611v4.337h-18.077V-31.64h17.647v4.34h-12.276v5.071H142.6v4.2h-10.846v5.4zm15.412 4.737a13.413 13.413 0 01-6.455-1.551 11.577 11.577 0 01-4.537-4.3 11.856 11.856 0 01-1.651-6.221 11.856 11.856 0 011.651-6.221 11.577 11.577 0 014.537-4.3 13.483 13.483 0 016.488-1.551 13.61 13.61 0 015.488 1.067 10.991 10.991 0 014.12 3.069l-3.469 3.2a7.459 7.459 0 00-5.871-2.735 7.8 7.8 0 00-3.87.951 6.759 6.759 0 00-2.652 2.652 7.8 7.8 0 00-.951 3.87 7.8 7.8 0 00.951 3.87 6.759 6.759 0 002.652 2.652 7.8 7.8 0 003.87.951 7.423 7.423 0 005.871-2.769l3.469 3.2a10.814 10.814 0 01-4.137 3.1 13.729 13.729 0 01-5.5 1.066zM194.57-31.64v23.352h-5.4v-9.574h-10.613v9.574h-5.4V-31.64h5.4v9.207h10.608v-9.207z'
      data-name='Path 51432'
      transform='translate(-5.49 32.04)'
    ></path>
  </svg>
);

export default LoginLogoSubIcon;
