import { Box, Typography, Button } from '@mui/material';
import { EmptyFinancesIcon, EmptySettingIcon } from 'icons';

const EmptyTableState = ({
  title, subtitle, buttonProps, isCaseTable
}) => (
  <Box mt={isCaseTable ? 1 : 10} textAlign='center'>
    {isCaseTable ? (
      <Box textAlign='center'>
        <EmptySettingIcon />
        <Typography variant='h6'>
          В момента няма активни казуси
        </Typography>
      </Box>
    ) : (
      <>
        <EmptyFinancesIcon style={{ height: 280 }} />
        <Typography variant='h6' mt={2}>
          {title}
        </Typography>
        {subtitle && (
          <Typography variant='h6' mt={1}>
            {subtitle}
          </Typography>
        )}
        <Box mt={2}>
          {buttonProps && (
            <Button
              variant='contained'
              color='primary'
              {...buttonProps}
            />
          )}
        </Box>
      </>
    )}
  </Box>
);

export default EmptyTableState;