import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import * as moment from 'moment';
import { en, bg } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';
import {
  Stack,
  Typography,
  Grid,
  ListItem,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Divider,
  Box,
} from '@mui/material';
import {
  printActivity,
  formatDate,
} from 'utils';
import { OverflowContent } from 'components';
import { getPropertyActivity } from 'stores/DashboardSlice';

const PropertiesActivitiesList = ({ leftColHeight }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { propertyId } = useParams();

  const { singlePropertyActivity } = useSelector(({ dashboardState }) => dashboardState);

  const PER_PAGE = 20;

  useEffect(() => {
    dispatch(getPropertyActivity(propertyId, { page: 1, limit: PER_PAGE }, false));
  }, []);

  return (
    <OverflowContent>
      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <Typography variant='subtitle1'>
          {t('propertyActivity')}
        </Typography>
      </Stack>
      <Box mt={3}>
        <InfiniteScroll
          dataLength={singlePropertyActivity?.docs?.length}
          height={leftColHeight - 100}
          next={() => {
            dispatch(getPropertyActivity(propertyId, {
              page: singlePropertyActivity?.nextPage,
              limit: PER_PAGE,
            }));
          }}
          hasMore={singlePropertyActivity?.hasNextPage}
          loader={<h4>Loading...</h4>}
        >
          {singlePropertyActivity?.docs?.map(({ action, createdAt, property, user }, index) => (
            <div key={`${property}-activity-${index}-${createdAt}`}>
              {(!moment(createdAt).isSame(moment(singlePropertyActivity?.docs?.[index - 1]?.createdAt), 'day') || index === 0) && (
                <Divider>
                  {moment(createdAt).isSame(moment(), 'day')
                    ? t('today')
                    : moment(createdAt).isSame(moment().subtract(1, 'd'), 'day')
                      ? t('yesterday')
                      : formatDate(createdAt, 'dd LLLL', i18n?.language)}
                </Divider>
              )}
              <ListItem disablePadding>
                <ListItemButton>
                  <Grid container>
                    <Grid item md={11}>
                      <Stack direction='row' spacing={1} alignItems='center'>
                        <ListItemAvatar>
                          <Avatar src={user?.avatar} />
                        </ListItemAvatar>
                        <ListItemText secondary={(
                          <Typography fontWeight={400}>
                            <span style={{ fontWeight: 600 }}>{user?.fullName}</span>
                            <span>{printActivity(action)}, </span>
                            <span style={{ fontWeight: 600 }}>{property?.nicknames?.landlord}</span>
                          </Typography>
                        )} />
                      </Stack>
                    </Grid>
                    <Grid item md={1}>
                      <ListItemText secondary={createdAt ? formatDate(createdAt, 'HH:mm') : ''} />
                    </Grid>
                  </Grid>
                </ListItemButton>
              </ListItem>
            </div>
          ))}
        </InfiniteScroll>
      </Box>
    </OverflowContent>
  );
};

export default PropertiesActivitiesList;