
const ActionsIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='4'
    height='16'
    viewBox='0 0 4 16'
    {...props}
  >
    <g
      fill='#0e1833'
      data-name='Group 33487'
      transform='translate(-439 -308)'
    >
      <circle
        cx='2'
        cy='2'
        r='2'
        data-name='Ellipse 8831'
        transform='translate(439 308)'
      ></circle>
      <circle
        cx='2'
        cy='2'
        r='2'
        data-name='Ellipse 8832'
        transform='translate(439 314)'
      ></circle>
      <circle
        cx='2'
        cy='2'
        r='2'
        data-name='Ellipse 8833'
        transform='translate(439 320)'
      ></circle>
    </g>
  </svg>
);

export default ActionsIcon;
