
const TenantIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='20'
    viewBox='0 0 20 20'
    {...props}
  >
    <path
      fill='#2f3191'
      fillRule='evenodd'
      d='M100.563 2017H87.438a1 1 0 01-.961-1.338 8.4 8.4 0 0115.047 0 1 1 0 01-.961 1.338m-10.646-12a4.084 4.084 0 114.083 4 4.047 4.047 0 01-4.083-4m14.039 11.636a9.518 9.518 0 00-6.119-6.963 5.933 5.933 0 002.216-5.6 6.136 6.136 0 00-12.178.93 5.937 5.937 0 002.289 4.673 9.517 9.517 0 00-6.12 6.963 2 2 0 002.01 2.364h15.892a2 2 0 002.01-2.364'
      data-name='profile (2)'
      transform='translate(-84 -1999)'
    ></path>
  </svg>
);

export default TenantIcon;
