import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  IconButton,
  Stack,
  Typography,
  Box,
  Card,
  Paper,
  Grid,
  Avatar,
  Button,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DefaultLayout } from 'layouts';
import { ActionsIcon, EmptySettingIcon, EditIcon, AddFilesIcon } from 'icons';
import { Table, MenuList, SelectBankAccount } from 'components';
import { getMyUserData, getOrganization } from 'stores/OrganizationSlice';

const PersonalSettings = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [addBankAccountIsOpen, setAddBankAccountIsOpen] = useState(false);
  const {
    organizationState: { organization, myUserData },
  } = useSelector(({ organizationState, financesState }) => ({ organizationState, financesState }));

  const columns = [
    {
      Header: t('names'),
      accessor: 'fullName',
    },
    {
      Header: t('rights'),
      accessor: 'organizationRole',
      Cell: ({ value }) => {
        if (value === 'admin') {
          return <div>{t('admin')}</div>;
        } else {
          return <div>{t('team')}</div>;
        }
      }
    },
    {
      Header: t('phone'),
      accessor: 'phoneNumber',
    },
    {
      Header: t('mail'),
      accessor: 'email',
      Cell: ({ value, row }) => {
        const userId = row?.original?._id;
        return (
          <Stack direction='row' alignItems='center' justifyContent='space-between' sx={{ width: '100%' }}>
            <div>{value}</div>
            <MenuList
              trigger={({ handleOpen }) => (
                <IconButton onClick={handleOpen}>
                  <ActionsIcon
                    style={{ width: 16, height: 16 }}
                  />
                </IconButton>
              )}
              items={[
                {
                  label: t('edit'),
                  description: t('editCurrentInfo'),
                  icon: EditIcon,
                  onClick: () => navigate(`/personal-settings/${userId}/edit`)
                }
              ]}
            />
          </Stack>
        );
      },
    },
  ];

  const organizationId = myUserData?.organization;
  const hasBankAccounts = Boolean(organization?.bankAccounts?.length > 0);

  useEffect(() => {
    if (organizationId) {
      dispatch(getOrganization(organizationId));
    }
  }, [organizationId]);

  useEffect(() => {
    dispatch(getMyUserData());
  }, []);

  return (
    <DefaultLayout>
      <Grid container>
        <Grid item md={5}>
          <Paper>
            <Stack direction='row' alignItems='center' justifyContent='space-between'>
              <Typography variant='h5'>
                {t('personalData')}
              </Typography>
              <MenuList
                trigger={({ handleOpen }) => (
                  <IconButton onClick={handleOpen}>
                    <ActionsIcon
                      style={{ width: 16, height: 16 }}
                    />
                  </IconButton>
                )}
                items={[
                  {
                    label: t('edit'),
                    description: t('editCurrentInfo'),
                    onClick: () => navigate(`/personal-settings/personal-data/${organizationId}/edit`),
                    icon: EditIcon,
                  },
                ]}
              />
            </Stack>
            <Box mt={1}>
              <Card variant='outlined'>
                <Grid container>
                  <Grid item md={6}>
                    <Stack direction='row' alignItems='center' spacing={2}>
                      <Avatar src={myUserData?.avatar} />
                      <Stack>
                        <Typography variant='caption'>{t('names')}</Typography>
                        <Typography variant='subtitle2'>{myUserData?.fullName}</Typography>
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid item md={6}>
                    <Stack>
                      <Typography variant='caption'>{t('rights')}</Typography>
                      <Typography variant='subtitle2'>{myUserData?.organizationRole}</Typography>
                    </Stack>
                  </Grid>
                </Grid>
                <Grid mt={1} container>
                  <Grid item md={5}>
                    <Stack>
                      <Typography variant='caption'>{t('phone')}</Typography>
                      <Typography variant='subtitle2'>{myUserData?.phoneNumber}</Typography>
                    </Stack>
                  </Grid>
                  <Grid item md={7}>
                    <Stack>
                      <Typography variant='caption'>{t('mail')}</Typography>
                      <Typography variant='subtitle2' color='error'>{myUserData?.email}</Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </Card>
            </Box>
          </Paper>
          <Box mt={2}>
            <Paper>
              <Stack direction='row' alignItems='center' justifyContent='space-between'>
                <Typography variant='h5'>{t('organisation')}</Typography>
                <MenuList
                  trigger={({ handleOpen }) => (
                    <IconButton onClick={handleOpen}>
                      <ActionsIcon
                        style={{ width: 16, height: 16 }}
                      />
                    </IconButton>
                  )}
                  items={[
                    {
                      label: t('edit'),
                      description: t('editCurrentInfo'),
                      icon: EditIcon,
                      onClick: () => navigate(`/organization/${organizationId}/edit`),
                    }
                  ]}
                />
              </Stack>
              <Box mt={1}>
                <Card variant='outlined'>
                  <Grid container>
                    <Grid item md={6}>
                      <Typography variant='caption'>{t('name')}</Typography>
                      <Typography variant='subtitle2'>{organization?.fullName}</Typography>
                    </Grid>
                    <Grid item md={6}>
                      <Typography variant='caption'>{t('eik')}</Typography>
                      <Typography variant='subtitle2'>{organization?.eic}</Typography>
                    </Grid>
                  </Grid>
                  <Grid container mt={1}>
                    <Grid item md={6}>
                      <Typography variant='caption'>{t('materiallyResponsiblePerson')}</Typography>
                      <Typography variant='subtitle2'>{organization?.manager}</Typography>
                    </Grid>
                    <Grid item md={6}>
                      <Typography variant='caption'>{t('phone')}</Typography>
                      <Typography variant='subtitle2'>{organization?.phoneNumber}</Typography>
                    </Grid>
                  </Grid>
                  <Grid container mt={1}>
                    <Grid item md={6}>
                      <Typography variant='caption'>{t('mail')}</Typography>
                      <Typography variant='subtitle2'>{organization?.email}</Typography>
                    </Grid>
                    <Grid item md={6}>
                      <Typography variant='caption'>{t('contactPerson')}</Typography>
                      <Typography variant='subtitle2'>{organization?.contactPerson}</Typography>
                    </Grid>
                  </Grid>
                  <Grid container mt={1}>
                    <Grid item md={6}>
                      <Typography variant='caption'>{t('address')}</Typography>
                      <Typography variant='subtitle2'>{organization?.address}</Typography>
                    </Grid>
                  </Grid>
                </Card>
              </Box>
            </Paper>
          </Box>
          <Box mt={2}>
            <Paper>
              <Stack direction='row' alignItems='center' justifyContent='space-between'>
                <Typography variant='h5'>{t('bankAccs')}</Typography>
                <MenuList
                  trigger={({ handleOpen }) => (
                    <IconButton onClick={handleOpen}>
                      <ActionsIcon
                        style={{ width: 16, height: 16 }}
                      />
                    </IconButton>
                  )}
                  items={[
                    {
                      label: t('addNew'),
                      description: t('addNewBankAcc'),
                      icon: AddFilesIcon,
                      onClick: () => setAddBankAccountIsOpen(true),
                    },
                    {
                      label: t('edit'),
                      description: t('editBanks'),
                      icon: EditIcon,
                      onClick: () => navigate(`/organization/${organizationId}/edit-banks`),
                    }
                  ]}
                />
              </Stack>
              <Box mt={1}>
                {hasBankAccounts ? (
                  <>
                    {organization?.bankAccounts?.map(({ bank, iban, holder, nickname }, index) => (
                      <Box
                        key={`${iban}-${index}-item`}
                        mt={1}
                      >
                        <Card variant='outlined'>
                          <Grid key={`${holder}-${iban}-${index}`} container >
                            <Grid item md={6}>
                              <Typography variant='caption'>{t('bank')}</Typography>
                              <Typography variant='subtitle2'>{bank}</Typography>
                            </Grid>
                            <Grid item md={6}>
                              <Typography variant='caption'>{t('iban')}</Typography>
                              <Typography variant='subtitle2'>{iban}</Typography>
                            </Grid>
                            <Grid item md={6} mb={1}>
                              <Typography variant='caption'>{t('holder')}</Typography>
                              <Typography variant='subtitle2'>{holder}</Typography>
                            </Grid>
                            <Grid item md={6} mb={1}>
                              <Typography variant='caption'>{t('nickname')}</Typography>
                              <Typography variant='subtitle2'>{nickname}</Typography>
                            </Grid>
                          </Grid>
                        </Card>
                      </Box>
                    ))}
                  </>
                ) : (
                  <Box textAlign='center' mt={3}>
                    <EmptySettingIcon />
                    <Typography variant='h6'>
                      {t('addBankAccForInvoice')}
                    </Typography>
                    <Box mt={1}>
                      <Button
                        variant='outlined'
                        color='primary'
                        onClick={() => setAddBankAccountIsOpen(true)}
                      >
                        {t('insert')}
                      </Button>
                    </Box>
                  </Box>
                )}
              </Box>
            </Paper>
          </Box>
        </Grid>
        <Grid item md={7}>
          <Paper elevation={4} sx={{ height: '100%' }}>
            <Box p={1}>
              <Stack direction='row' alignItems='center' justifyContent='space-between'>
                <Typography variant='h5'>{t('users')}</Typography>
                <Button variant='contained' color='primary' onClick={() => navigate('/personal-settings/add-user')}>{t('add')}</Button>
              </Stack>
              <Table data={organization?.users ?? []} columns={columns} />
            </Box>
          </Paper>
        </Grid>
      </Grid >
      <SelectBankAccount
        isOpen={addBankAccountIsOpen}
        onClose={() => setAddBankAccountIsOpen(false)}
      />
    </DefaultLayout >
  );
};

export default PersonalSettings;