import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import NumberFormat from 'react-number-format';
import {
  Paper,
  Typography,
  Box,
  Stack,
  Grid,
  TextField,
  Button,
  InputAdornment,
  FormHelperText,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Fade,
} from '@mui/material';
import { Dialog } from 'components';
import { TenantFormLayout } from 'layouts';
import { getSingleProperty, editProperty } from 'stores/PropertiesSlice';

const RentContract = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { propertyId } = useParams();
  const [hasEndDate, setHasEndDate] = useState(true);
  const [confirmDialogIsOpen, setConfirmDialogIsOpen] = useState(false);
  const [submitData, setSubmitData] = useState({});

  const {
    propertiesState: {
      singleProperty
    },
  } = useSelector(({ propertiesState }) => ({ propertiesState }));

  useEffect(() => {
    dispatch(getSingleProperty(propertyId));
  }, [propertyId]);

  const validations = Yup.object().shape({
    rent: Yup.number().required(t('rentRequired')),
    payBefore: Yup.number().required(t('payBeforeRequired')).typeError(t(t('payBeforeRequired'))),
    payForMonth: Yup.string().required(t('payForMonthRequired')),
    deposit: Yup.number().label('Deposit').typeError(t('depositRequired')),
    startDate: Yup.date().required(t('startDateRequired')).typeError(t('enterValidDate')),
    ...(hasEndDate && {
      endDate: Yup.date().required(t('endDateRequired')).typeError(t('enterValidDate')),
    })
  });

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(validations)
  });

  useEffect(() => {
    if (singleProperty) {
      reset({
        rent: singleProperty?.rent,
        payBefore: singleProperty?.payBefore,
        payForMonth: singleProperty?.payForMonth,
        deposit: singleProperty?.deposit,
        startDate: singleProperty?.startDate,
        endDate: singleProperty?.endDate
      });
    }
  }, []);

  return (
    <TenantFormLayout>
      <form onSubmit={handleSubmit((data) => {
        setConfirmDialogIsOpen(true);
        setSubmitData(data);
      })}>
        <Box mb={3}>
          <Paper elevation={4}>
            <Typography variant='subtitle1' mb={3}>{t('rentContract')}</Typography>
            <Grid container>
              <Grid item md={3}>
                <NumberFormat
                  customInput={TextField}
                  thousandSeparator
                  InputLabelProps={{ shrink: true }}
                  label={`${t('monthlyRentAmount')}*`}
                  InputProps={{
                    endAdornment: <InputAdornment position='start'>
                      {t('lv')}
                    </InputAdornment>,
                  }}
                  size='small'
                  fullWidth
                  value={watch('rent')}
                  onValueChange={({ floatValue }) => {
                    setValue('rent', floatValue, { shouldValidate: true });
                  }}
                  error={Boolean(errors?.rent?.message)}
                  helperText={errors?.rent?.message}
                />
              </Grid>
              <Grid item md={3}>
                <TextField
                  label={`${t('dueDate')}*`}
                  InputProps={{
                    endAdornment: <InputAdornment position='start'>
                      {t('numberMonth')}
                    </InputAdornment>,
                  }}
                  size='small'
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  {...register('payBefore')}
                  error={Boolean(errors?.payBefore?.message)}
                  helperText={errors?.payBefore?.message}
                />
              </Grid>
              <Grid item md={3}>
                <FormControl
                  error={Boolean(errors?.payForMonth?.message)}
                >
                  <FormLabel disabled>{t('payOn')}*</FormLabel>
                  <RadioGroup row>
                    <FormControlLabel
                      value='предходен месец'
                      control={
                        <Radio
                          checked={watch('payForMonth') === 'previous'}
                          onChange={() => setValue('payForMonth', 'previous', { shouldValidate: true })}
                        />
                      }
                      label={t('previousMonth')}
                    />
                    <FormControlLabel
                      value='текущ месец'
                      control={
                        <Radio
                          checked={watch('payForMonth') === 'current'}
                          onChange={() => setValue('payForMonth', 'current', { shouldValidate: true })}
                        />
                      }
                      label={t('nextMonth')}
                    />
                  </RadioGroup>
                  <FormHelperText error>
                    {errors?.payForMonth?.message}
                  </FormHelperText >
                </FormControl>
              </Grid>
              <Grid item md={3}>
                <NumberFormat
                  customInput={TextField}
                  thousandSeparator
                  InputLabelProps={{ shrink: true }}
                  label={t('deposit')}
                  InputProps={{
                    endAdornment: <InputAdornment position='start'>
                      {t('lv')}
                    </InputAdornment>,
                  }}
                  size='small'
                  fullWidth
                  value={watch('deposit')}
                  onValueChange={({ floatValue }) => {
                    setValue('deposit', floatValue, { shouldValidate: true });
                  }}
                  error={Boolean(errors?.deposit?.message)}
                  helperText={errors?.deposit?.message}
                />
              </Grid>
            </Grid>
            <Grid container mt={3}>
              <Grid item md={4}>
                <FormControl>
                  <FormLabel disabled>{t('contractType')}</FormLabel>
                  <RadioGroup row>
                    <FormControlLabel
                      control={
                        <Radio
                          onChange={() => setHasEndDate(true)}
                          checked={hasEndDate}
                        />
                      }
                      label={t('limited')}
                    />
                    <FormControlLabel
                      control={
                        <Radio
                          onChange={() => setHasEndDate(false)}
                          checked={!hasEndDate}
                        />
                      }
                      label={t('unlimited')}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item md={3}>
                <DesktopDatePicker
                  label={`${t('startContract')} *`}
                  value={watch('startDate') ? watch('startDate') : null}
                  onChange={(date) => setValue('startDate', date, { shouldValidate: true })}
                  maxDate={watch('endDate') ? watch('endDate') : undefined}
                  TransitionComponent={Fade}
                  renderInput={(params) => <TextField
                    {...params}
                    size='small'
                    error={Boolean(errors?.startDate?.message)}
                    helperText={errors?.startDate?.message}
                    InputLabelProps={{ shrink: true }}
                  />}
                />
              </Grid>
              {hasEndDate && (
                <Grid item md={3}>
                  <DesktopDatePicker
                    label={`${t('endContract')}`}
                    value={watch('endDate') ? watch('endDate') : null}
                    onChange={(date) => setValue('endDate', date, { shouldValidate: true })}
                    minDate={watch('startDate') ? watch('startDate') : undefined}
                    renderInput={(params) => <TextField
                      {...params}
                      size='small'
                      InputLabelProps={{ shrink: true }}
                      error={Boolean(errors?.endDate?.message)}
                      helperText={errors?.endDate?.message}
                    />}
                    TransitionComponent={Fade}
                  />
                </Grid>
              )}
            </Grid>
          </Paper>
        </Box>
        <Stack direction='row' justifyContent='flex-end'>
          <Button
            variant='contained'
            color='secondary'
            type='submit'
          >
            {t('continue')}
          </Button>
        </Stack>
      </form>
      <Dialog
        open={confirmDialogIsOpen}
        onClose={() => setConfirmDialogIsOpen(false)}
        title={t('confirmInfo')}
      >
        <Typography variant='h6'>
          {t('propertyError')}
        </Typography>
        <Box mt={4}>
          <Stack direction='row' alignItems='center' spacing={2} justifyContent='center'>
            <Button
              variant='contained'
              color='warning'
              size='large'
              fullWidth
              onClick={() => setConfirmDialogIsOpen(false)}
            >
              {t('back')}
            </Button>
            <Button
              variant='contained'
              color='primary'
              size='large'
              fullWidth
              onClick={() => {
                dispatch(editProperty(submitData, propertyId, () => {
                  setConfirmDialogIsOpen(false);
                  navigate(`/properties/${propertyId}/add-tenant/data-tenant`);
                }));
              }}
            >
              {t('confirm')}
            </Button>
          </Stack>
        </Box>
      </Dialog>
    </TenantFormLayout>
  );
};

export default RentContract;