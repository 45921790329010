import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Paper,
  Typography,
  Button,
  Box,
  Stack,
  IconButton,
  Grid,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { DefaultLayout } from 'layouts';
import { formatAmount } from 'utils';
import { ArrowBackIcon, VisaIcon } from 'icons';
import { useTranslation } from 'react-i18next';

const SubscriptionPayment = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedValue, setSelectedValue] = useState('card');

  return (
    <DefaultLayout>
      <Paper elevation={4} sx={{ width: 850, margin: '0 auto' }}>
        <Box p={2}>
          <Stack direction='row' spacing={1} alignItems='center'>
            <IconButton onClick={() => navigate('/properties/create')}>
              <ArrowBackIcon style={{ width: 16, height: 16 }} />
            </IconButton>
            <Typography variant='h5'>{t('payingSubsPlan')}</Typography>
          </Stack>
          <Typography variant='caption' textTransform='none'>
            {t('payingSubsPlanText')}
          </Typography>
          <Box mt={5}>
            <Typography mt={4} variant='subtitle1' textTransform='uppercase'>{t('Subscription')}</Typography>
            <Stack mt={2} direction='row' justifyContent='space-between' alignItems='center'>
              <Box
                p={1}
                sx={{ backgroundColor: '#F5F9FC', border: '1px solid #C5D7F2', borderRadius: 2, width: '100%', padding: '15px' }}
              >
                <Typography variant='subtitle2' textTransform='none'>{t('AnnualSubs')}</Typography>
              </Box>
              <Box p={1} sx={{ backgroundColor: '#2F3191', borderRadius: 2 }}>
                <Typography variant='subtitle2' textTransform='none' color='#fff'>73.10лв/без ДДС</Typography>
              </Box>
            </Stack>
            <Stack direction='row' alignItems='center' spacing={1}>
              <PriorityHighIcon style={{ width: 16, height: 16, fill: 'red' }} />
              <Typography variant='caption' textTransform='none'>Стойността на годишен абонамент е {formatAmount(79)}/имот. При добавяне на имот </Typography>
            </Stack>
          </Box>
          <Box mt={5}>
            <Typography variant='subtitle1'>{t('payingForPeriod')}</Typography>
            <Box
              mt={2}
              p={1}
              sx={{ backgroundColor: '#F5F9FC', border: '1px solid #C5D7F2', borderRadius: 2, width: '100%', padding: '15px' }}
            >
              <Typography variant='subtitle2'>10 февруари ’22 - 11 декември ‘22</Typography>
            </Box>
          </Box>
          <Box mt={5} pb={10}>
            <FormControl>
              <Typography variant='subtitle1'>{t('paymentWay')}</Typography>
              <RadioGroup
                row
              >
                <FormControlLabel value='card' control={<Radio />} label='С карта' onChange={() => setSelectedValue('card')} />
                <FormControlLabel value='bank' control={<Radio />} label='По банков път' onChange={() => setSelectedValue('bank')} />
              </RadioGroup>
            </FormControl>
            {selectedValue === 'card' ? (
              <Grid container mt={1}>
                <Grid item md={6}>
                  <Stack p={1} direction='row' alignItems='center' justifyContent='space-between'
                    sx={{ backgroundColor: '#00D297', borderRadius: 2 }}
                  >
                    <Stack sx={{ color: '#fff' }}>
                      <Typography variant='body1'>{t('number')}</Typography>
                      <Typography variant='body1'>**** 271</Typography>
                    </Stack>
                    <Stack sx={{ color: '#fff' }}>
                      <Typography variant='body1'>{t('validity')}</Typography>
                      <Typography variant='body1'>04/23</Typography>
                    </Stack>
                    <Stack>
                      <VisaIcon style={{ fill: '#000' }} />
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item md={6}>
                  <Button variant='outlined' color='primary'>
                    <AddIcon style={{ width: 25, height: 40 }} />
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <>
                <Stack>
                  <Typography variant='caption' textTransform='none'>{t('payWithBankWay')}</Typography>
                </Stack>
                <Box p={1} mt={2} sx={{ backgroundColor: '#EFF5FF', border: '1px solid #C5D7F2', borderRadius: 2 }}>
                  <Grid container>
                    <Grid item md={4}>
                      <Typography variant='caption'>{t('firmName')}</Typography>
                      <Typography variant='subtitle2'>Имотех АД</Typography>
                    </Grid>
                    <Grid item md={4}>
                      <Typography variant='caption'>{t('accNumber')}</Typography>
                      <Typography variant='subtitle2'>BG58STSA930000801233</Typography>
                    </Grid>
                    <Grid item md={4}>
                      <Typography variant='caption'>{t('basis')}</Typography>
                      <Typography variant='subtitle2'>Заплащане абонамент - 342515dS</Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Typography variant='caption' textTransform='none'>{t('afterPaymentText')}</Typography>
              </>
            )}
          </Box>
        </Box>
      </Paper>
      <Box
        p={1}
        mt={5}
        sx={{ backgroundColor: '#EFF5FF', border: '1px solid #C5D7F2', borderRadius: 2, width: 850, margin: '0 auto' }}>
        <Stack direction='row' alignItems='center' justifyContent='space-between'>
          <Typography variant='subtitle1'>{t('totalAmount')}</Typography>
          <Stack direction='row' spacing={1} alignItems='center'>
            <Typography variant='overline'>313.27лв</Typography>
            <Button
              variant='contained'
              color='primary'
              sx={{ textTransform: 'uppercase' }}
            >
              Потвърди
            </Button>
          </Stack>
        </Stack>
      </Box>
    </DefaultLayout>
  );
};

export default SubscriptionPayment;