const printActivity = (action) => {
  if (action === 'add-property') {
    return window?.i18n?.t('addPropertyAct');
  } else if (action === 'remove-property') {
    return window?.i18n?.t('removePropertyAct');
  } else if (action === 'add-tenant') {
    return window?.i18n?.t('addTenantAct');
  } else if (action === 'remove-tenant') {
    return window?.i18n?.t('removeTenantAct');
  } else if (action === 'pay-rent') {
    return window?.i18n?.t('payRent');
  } else if (action === 'partial-pay-rent') {
    return window?.i18n?.t('partialPayRent');
  } else if (action === 'create-case') {
    return window?.i18n?.t('createCaseAct');
  } else if (action === 'close-case') {
    return window?.i18n?.t('closeCaseAct');
  } else if (action === 'pay-bills') {
    return window?.i18n?.t('payBillsAct');
  } else if (action === 'edit-property-details') {
    return window?.i18n?.t('editPropertyAct');
  } else if (action === 'edit-tenant-details') {
    return window?.i18n?.t('editTenantAct');
  }
};

export default printActivity;