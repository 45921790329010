
const SendIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 36 36'
    {...props}
  >
    <defs>
      <clipPath id='clip-path'>
        <path
          d='M0 0H36V36H0z'
          data-name='Rectangle 14426'
          transform='translate(-.488 -.488)'
        ></path>
      </clipPath>
    </defs>
    <g
      clipPath='url(#clip-path)'
      opacity='0.5'
      transform='translate(.488 .488)'
    >
      <path
        fill='#00d297'
        d='M5.368 21.788l10.115-2.023c.9-.18.9-.474 0-.655L5.368 17.087a1.805 1.805 0 01-1.31-1.31L2.035 5.662c-.181-.9.352-1.324 1.189-.937l30.867 14.246c.558.257.558.676 0 .933L3.224 34.15c-.837.386-1.37-.034-1.189-.937L4.058 23.1a1.805 1.805 0 011.31-1.312z'
        data-name='Path 55896'
        transform='translate(-.742 -1.925)'
      ></path>
    </g>
  </svg>
);

export default SendIcon;
