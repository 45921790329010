import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Typography,
  Link,
  Stack,
  FormHelperText
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ReactCodeInput from 'react-code-input';
import { AuthLayout } from 'layouts';
import { useTranslation } from 'react-i18next';
import { confirmCode, getSMSCode } from 'stores/AuthenticationSlice';

const validations = Yup.object().shape({
  code: Yup.string().min(4).required().label('Code')
});

const CodeInput = styled(ReactCodeInput)`
  border: 2px solid #000;
  border-radius: 3px;

  input {
    border: 0;
    width: 50px;
    height: 50px;
    text-align: center;
    font-size: 22px;
    border-right: 1px solid #e7e7e7;

    &:last-child {
      border-right: 0;
    }

    &:focus {
      outline: none;
    }
  }
`;

const ConfirmCode = () => {
  const { t } = useTranslation();
  let { phoneNumber } = useParams();
  const {
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validations)
  });


  const dispatch = useDispatch();
  const navigate = useNavigate();

  const hasError = Boolean(errors?.code?.message);
  const codeValue = watch('code');
  const canSubmit = codeValue?.length === 4;

  return (
    <AuthLayout
      title={t('verificationOnPhone')}
      subtitle={t('fillCode')}
      isConfirmCode
    >
      <form onSubmit={handleSubmit(({ code }) => dispatch(confirmCode({
        phoneNumber,
        code,
      }, navigate)))}>
        <Typography mt={2} textAlign='center' variant='h4'>
          {phoneNumber}
        </Typography>
        <Box mt={3}>
          <Stack direction='row' textAlign='center' justifyContent='center'>
            <div>
              <CodeInput
                type='number'
                fields={4}
                onChange={(value) => {
                  setValue('code', value);
                }}
                value={codeValue}
                isValid={!hasError}
              />
              {hasError && (
                <Stack direction='row' textAlign='center' justifyContent='center'>
                  <FormHelperText error>
                    {errors?.code?.message}
                  </FormHelperText>
                </Stack>
              )}
            </div>
          </Stack>
        </Box>
        <Box mt={1}>
          <Stack direction='row' justifyContent='center'>
            <Link
              href='#'
              color='secondary'
              variant='body1'
              fontSize={13}
              onClick={() => dispatch(getSMSCode({
                phoneNumber,
              }))}
            >
              {t('sendCodeAgain')}
            </Link>
          </Stack>
        </Box>
        <Box mt={3}>
          <Button
            variant='contained'
            color='primary'
            fullWidth
            type='submit'
            disabled={!canSubmit}
          >
            {t('login')}
          </Button>
          <Box mt={2}>
            <Stack direction='row' alignItems='center' justifyContent='center' spacing={1}>
              <Typography variant='body1' fontSize={13}> {t('wrongNumber')} </Typography>
              <Link href='/login' color='secondary' variant='body1' fontSize={13}> {t('fillNewNumber')} </Link>
            </Stack>
          </Box>
        </Box>
      </form>
    </AuthLayout >
  );
};

export default ConfirmCode;