/* eslint-disable indent */
import { createSlice } from '@reduxjs/toolkit';
import httpClient from '../api/httpClient';

const initialState = {
  incomeStatistics: undefined,
  getIncomeStatisticsIsLoading: false,
  outcomeStatistics: {},
  getOutcomeStatisticsIsLoading: false,
  rentStatistics: {},
  getRentStatisticsIsLoading: false,
  incomeByCities: {},
  getIncomesByCityIsLoading: false,
  propertiesByCities: {},
  getPropertiesByCitiesIsLoading: false,
  propertiesStatistics: {},
  getPropertyStatisticsIsLoading: false,
  activity: {
    docs: []
  },
  getActivityIsLoading: false,
  singlePropertyActivity: {
    docs: []
  },
  getSinglePropertyActivity: false
};

export const DashboardSlice = createSlice({
  name: 'Dashboard',
  initialState,
  reducers: {
    getIncomeStatisticsRequesting: (state) => {
      state.getIncomeStatisticsIsLoading = true;
    },
    getIncomeStatisticsSuccess: (state, { payload }) => {
      state.getIncomeStatisticsIsLoading = false;
      state.incomeStatistics = payload;
    },
    getIncomeStatisticsError: (state) => {
      state.getIncomeStatisticsIsLoading = false;
    },
    getOutcomeStatisticsRequesting: (state, { payload }) => {
      state.getOutcomeStatisticsIsLoading = true;
    },
    getOutcomeStatisticsSuccess: (state, { payload }) => {
      state.getOutcomeStatisticsIsLoading = false;
      state.outcomeStatistics = payload;
    },
    getOutcomeStatisticsError: (state) => {
      state.getOutcomeStatisticsIsLoading = false;
    },
    getRentStatisticsRequesting: (state) => {
      state.getRentStatisticsIsLoading = true;
    },
    getRentStatisticsSuccess: (state, { payload }) => {
      state.getRentStatisticsIsLoading = false;
      state.rentStatistics = payload;
    },
    getRentStatisticsError: (state) => {
      state.getRentStatisticsIsLoading = false;
    },
    getIncomeByCitiesRequesting: (state) => {
      state.getIncomesByCityIsLoading = true;
    },
    getIncomeByCitiesSuccess: (state, { payload }) => {
      state.getIncomesByCityIsLoading = false;
      state.incomeByCities = payload;
    },
    getIncomeByCitiesError: (state) => {
      state.getIncomesByCityIsLoading = false;
    },
    getPropertiesByCitiesRequesting: (state) => {
      state.getPropertiesByCitiesIsLoading = true;
    },
    getPropertiesByCitiesSuccess: (state, { payload }) => {
      state.getPropertiesByCitiesIsLoading = false;
      state.propertiesByCities = payload;
    },
    getPropertiesByCitiesError: (state) => {
      state.getPropertiesByCitiesIsLoading = false;
    },
    getPropertiesStatisticsRequesting: (state) => {
      state.getPropertyStatisticsIsLoading = true;
    },
    getPropertiesStatisticsSuccess: (state, { payload }) => {
      state.getPropertyStatisticsIsLoading = false;
      state.propertiesStatistics = payload;
    },
    getPropertiesStatisticsError: (state) => {
      state.getPropertyStatisticsIsLoading = false;
    },
    getActivityRequesting: (state) => {
      state.getActivityIsLoading = true;
    },
    getActivitySuccess: (state, { payload }) => {
      state.getActivityIsLoading = false;
      if (payload?.loadMore) {
        state.activity = {
          ...payload,
          docs: [
            ...state.activity?.docs,
            ...payload?.docs,
          ]
        };
      } else {
        state.activity = payload;
      }
    },
    getActivityError: (state) => {
      state.getActivityIsLoading = false;
    },
    getSinglePropertyActivityRequesting: (state) => {
      state.getSinglePropertyActivity = true;
    },
    getSinglePropertyActivitySuccess: (state, { payload }) => {
      state.getSinglePropertyActivity = false;
      if (payload?.loadMore) {
        state.singlePropertyActivity = {
          ...payload,
          docs: [
            ...state.singlePropertyActivity?.docs,
            ...payload?.docs,
          ]
        };
      } else {
        state.singlePropertyActivity = payload;
      }
    },
    getSinglePropertyActivityError: (state) => {
      state.getSinglePropertyActivity = false;
    },
  }
});

export const {
  getIncomeStatisticsRequesting,
  getIncomeStatisticsSuccess,
  getIncomeStatisticsError,
  getOutcomeStatisticsRequesting,
  getOutcomeStatisticsSuccess,
  getOutcomeStatisticsError,
  getRentStatisticsRequesting,
  getRentStatisticsSuccess,
  getRentStatisticsError,
  getIncomeByCitiesRequesting,
  getIncomeByCitiesSuccess,
  getIncomeByCitiesError,
  getPropertiesByCitiesRequesting,
  getPropertiesByCitiesSuccess,
  getPropertiesByCitiesError,
  getPropertiesStatisticsRequesting,
  getPropertiesStatisticsSuccess,
  getPropertiesStatisticsError,
  getActivityRequesting,
  getActivitySuccess,
  getActivityError,
  getSinglePropertyActivityRequesting,
  getSinglePropertyActivitySuccess,
  getSinglePropertyActivityError
} = DashboardSlice.actions;

export const getIncomeStatistics = (params) => async (dispatch) => {
  dispatch(getIncomeStatisticsRequesting());
  httpClient.get('/dashboard/stats/income', { params }).then(
    (response) => {
      dispatch(getIncomeStatisticsSuccess(response?.data?.income));
    },
    (error) => dispatch(getIncomeStatisticsError(error)),
  );
};

export const getOutcomeStatistics = (params) => async (dispatch) => {
  dispatch(getOutcomeStatisticsRequesting());
  httpClient.get('dashboard/stats/outcome', { params }).then(
    (response) => {
      dispatch(getOutcomeStatisticsSuccess(response?.data?.outcome));
    },
    (error) => dispatch(getOutcomeStatisticsError(error)),
  );
};

export const getRentStatistics = (params) => async (dispatch) => {
  dispatch(getRentStatisticsRequesting());
  httpClient.get('dashboard/stats/rent', { params }).then(
    (response) => {
      dispatch(getRentStatisticsSuccess(response?.data));
    },
    (error) => dispatch(getRentStatisticsError(error)),
  );
};

export const getIncomeByCities = (params) => async (dispatch) => {
  dispatch(getIncomeByCitiesRequesting());
  httpClient.get('/dashboard/stats/income-by-city', { params }).then(
    (response) => {
      dispatch(getIncomeByCitiesSuccess(response?.data?.data));
    },
    (error) => dispatch(getIncomeByCitiesError(error)),
  );
};

export const getPropertiesByCities = (params) => async (dispatch) => {
  dispatch(getPropertiesByCitiesRequesting());
  httpClient.get('/dashboard/stats/properties-by-city', { params }).then(
    (response) => {
      dispatch(getPropertiesByCitiesSuccess(response?.data?.data));
    },
    (error) => dispatch(getPropertiesByCitiesError(error)),
  );
};

export const getPropertiesStatistics = (params) => async (dispatch) => {
  dispatch(getPropertiesStatisticsRequesting());
  httpClient.get('/dashboard/stats/properties', { params }).then(
    (response) => {
      dispatch(getPropertiesStatisticsSuccess(response?.data));
    },
    (error) => dispatch(getPropertiesStatisticsError(error)),
  );
};

export const getActivity = ({ page, limit = 20, loadMore }) => async (dispatch) => {
  dispatch(getActivityRequesting());
  httpClient.get('/dashboard/activity',
    {
      params:
      {
        page,
        limit,
        loadMore
      }
    }).then(
      (response) => {
        dispatch(getActivitySuccess({
          ...response?.data?.activity,
          loadMore
        }));
      },
      (error) => dispatch(getActivityError(error)),
    );
};

export const getPropertyActivity = (propertyId, data, loadMore = true) => async (dispatch) => {
  dispatch(getSinglePropertyActivityRequesting());
  httpClient.get(`/properties/org/activity/${propertyId}`,
    {
      params: data
    }).then(
      (response) => {
        dispatch(getSinglePropertyActivitySuccess({
          ...response?.data?.activity,
          loadMore
        }));
      },
      (error) => dispatch(getSinglePropertyActivityError(error)),
    );
};


export default DashboardSlice.reducer;
