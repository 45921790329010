import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Paper,
  Stack,
  Typography,
  IconButton,
  Tooltip,
  Box,
  Grid,
  Button,
  CircularProgress,
} from '@mui/material';
import { isBefore } from 'date-fns';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import {
  formatAmount,
  printMonth,
  printPaymentMethod,
  rentFormatDate,
  formatDate,
  getDaysBetweenDates
} from 'utils';
import { useTranslation } from 'react-i18next';
import { MenuList, Dialog } from 'components';
import {
  ActionsIcon,
  AddFolderIcon,
  EditIcon,
  AddFilesIcon,
  InvoiceIcon,
  SendMailIcon,
  DownloadFileIcon,
  ClockIcon
} from 'icons';
import { sendInvoiceManually } from 'stores/RentSlice';

const RentInfo = ({
  id,
  title,
  month,
  paymentDate,
  dueDate,
  paid,
  propertyId,
  note,
  amountPaid,
  paymentMethod,
  onNoteClick,
  attachments,
  rent,
  invoice,
  hasInvoice,
  invoiceId,
  paymentHistory
}) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const attachmentHasOneFile = Boolean(attachments?.length === 1);
  const [attachmentsDialogIsOpen, setAttachmentsDialogIsOpen] = useState(false);
  const [sendInvoiceDialogIsOpen, setSendInvoiceDialogIsOpen] = useState(false);
  const [successDialogIsOpen, setSuccessDialogIsOpen] = useState(false);
  const [semiPaidHistoryDialogIsOpen, setSemiPaidHistoryDialogIsOpen] = useState(false);
  const hasPaidAmount = Boolean(amountPaid);
  const hasLowerPayment = hasPaidAmount ? amountPaid < rent : false;
  const isFullPaid = paid && !hasLowerPayment;
  const isOverdue = !isBefore(new Date(), new Date(dueDate)) && !isFullPaid;
  const partlyPaidAndNotOverdue = Boolean(hasLowerPayment && (isBefore(new Date(), new Date(dueDate))));
  const dueDateDifference = getDaysBetweenDates(new Date(), new Date(dueDate));
  const dueDateIsToday = Boolean(dueDateDifference === 1);
  const invoiceMinDate = Boolean(dueDateDifference === 6);

  const {
    rentState: { sendInvoiceManuallyIsLoading },
    propertiesState: { singleProperty }
  } = useSelector(({ rentState, propertiesState }) => ({ rentState, propertiesState }));

  return (
    <>
      <Paper
        variant='outlined'
        sx={{
          backgroundColor: !isFullPaid ? `${hasLowerPayment ? '#FFF8FA' : '#F5F9FC'}` : 'transparent',
          p: 2,
          border: `1px solid ${!isFullPaid ? `${hasLowerPayment ? '#ffacc3' : '#bad6ea'}` : '#bad6ea'}`
        }}>
        <Stack direction='row' alignItems='center' justifyContent='space-between'>
          <Stack direction='row' spacing={1}>
            <Typography variant='subtitle2' textTransform='uppercase'>{month ? `${t('rentOnMonth')} ${printMonth(month)}` : `${title}`}</Typography>
            {(hasLowerPayment) && (
              <Tooltip title={t('lowerAmount')} placement='right'>
                <PriorityHighIcon style={{ width: 16, height: 16, fill: 'red' }} />
              </Tooltip>
            )}
          </Stack>
          {isFullPaid && (
            <MenuList
              trigger={({ handleOpen }) => (
                <IconButton onClick={handleOpen}>
                  <ActionsIcon
                    style={{ width: 16, height: 16 }}
                  />
                </IconButton>
              )}
              items={[
                {
                  label: t('note'),
                  description: t('noteText'),
                  icon: AddFolderIcon,
                  onClick: onNoteClick,
                },
                {
                  label: t('history'),
                  description: t('semiPaidHistoryDescAndEdit'),
                  icon: ClockIcon,
                  onClick: () => setSemiPaidHistoryDialogIsOpen(true),
                },
              ]}
            />
          )}
        </Stack>
        <Box mt={3}>
          <Grid container alignItems='center'>
            <Grid item md={3}>
              <Typography variant='caption'>
                {!isFullPaid ? t('payingOn') : t('payedOn')}
              </Typography>
              {!isFullPaid ? (
                <Typography variant='subtitle2'>
                  {dueDateIsToday ? (
                    <Typography color='secondary'>
                      {t('paymentHasToBeToday')}
                    </Typography>
                  ) : (
                    <Typography color='secondary'>
                      {isOverdue ? <Typography component='span' variant='subtitle2' color='error'>{`${t('overdueWith')} ${dueDateDifference}  ${t('days')}`}</Typography> : `- ${t('after')} ${dueDateDifference} ${t('days')}`}
                    </Typography>
                  )}
                </Typography>
              ) : (
                <Typography variant='subtitle2'>
                  {rentFormatDate(paymentDate)}
                </Typography>
              )}
            </Grid>
            <Grid item md={3}>
              <Stack direction='row' alignItems='center' spacing={1}>
                <Typography variant='caption'>
                  {isFullPaid ? t('paidSum') : t('dueAmount')}
                </Typography>
                {(partlyPaidAndNotOverdue) && (
                  <IconButton onClick={() => setSemiPaidHistoryDialogIsOpen(true)}>
                    <AccessTimeIcon style={{ width: 16, height: 16, fill: '#ccc' }} />
                  </IconButton>
                )}
              </Stack>
              <Typography variant='subtitle2'
              >
                {hasPaidAmount ? (
                  <>
                    {hasLowerPayment ? (
                      <>
                        <Typography component='span' variant='subtitle2' color='error'>{amountPaid}</Typography> / {formatAmount(rent, i18n?.language)}
                      </>
                    ) : formatAmount(amountPaid, i18n?.language)}

                  </>
                ) : (
                  <>
                    {formatAmount(rent, i18n?.language)}
                  </>
                )}

              </Typography>
            </Grid>
            <Grid item md={3}>
              <Stack direction='row' alignItems='center' spacing={1}>
                <Typography variant='caption'>
                  {t('invoice')}
                </Typography>
                <Tooltip
                  title={t('automaticGenerateInvoice')}
                  placement='bottom'
                >
                  <ErrorOutlineIcon style={{ width: 16, height: 16, fill: '#ccc', cursor: 'pointer' }} />
                </Tooltip>
              </Stack>
              <Typography variant='subtitle2'>
                {invoice?.invoiceNumber ? `invoice-${invoice?.invoiceNumber}` : '--'}
              </Typography>
            </Grid>
            <Grid item md={3}>
              <Stack direction='row' alignItems='center' spacing={1}>
                <Typography variant='caption'>
                  {t('sendedInvoice')}
                </Typography>
                <Tooltip title={t('automaticSendingInvoice')} placement='bottom'>
                  <ErrorOutlineIcon style={{ width: 16, height: 16, fill: '#ccc', cursor: 'pointer' }} />
                </Tooltip>
              </Stack>
              {invoice?.sent ? (<>
                <Typography variant='subtitle2'>
                  {`${t('yes')} - ${formatDate(invoice?.sentAt, 'dd.MM.yyyy | HH:mm')}`}
                </Typography>
              </>) : (<>
                {invoiceMinDate ? (
                  <Typography variant='subtitle2'>
                    {`${t('no')} (${t('sendingAfter')} ${dueDateDifference - 5} ${t('days')}) `}
                  </Typography>
                ) : (
                  <Typography variant='subtitle2'>
                    {t('no')}
                  </Typography>
                )}
              </>)}
            </Grid>
            {note ? (
              <Grid item md={3}>
                <Typography variant='caption'>{t('note')}</Typography>
                <Typography variant='subtitle2'>{t('yes')}</Typography>
              </Grid>
            ) : (
              <Grid item md={3}>
                <Typography variant='caption'>{t('note')}</Typography>
                <Typography variant='subtitle2'>--</Typography>
              </Grid>
            )}
            <Grid item md={3}>
              <Typography variant='caption'>{t('uploadFiles')}</Typography>
              <Typography
                sx={{ cursor: 'pointer', textDecoration: 'underline' }}
                variant='subtitle2'
                onClick={() => attachments?.length > 0 ? setAttachmentsDialogIsOpen(true) : {}}
              >
                {attachmentHasOneFile ? `1 ${t('attachedFile')}` : `${attachments?.length} ${t('attachedFiles')}`}
              </Typography>
            </Grid>
            {!isFullPaid && (
              <Grid item md={12}>
                <Box alignItems='flex-end' justifyContent='flex-end' display='flex'>
                  {hasInvoice ? (
                    <MenuList
                      trigger={({ handleOpen }) => (
                        <Button
                          variant='contained'
                          color='secondary'
                          onClick={handleOpen}
                        >
                          {t('manage')}
                        </Button>
                      )}
                      items={[
                        {
                          label: t('markAsPaid'),
                          description: t('markAsPaidText'),
                          icon: AddFolderIcon,
                          onClick: () => navigate(`/properties/${propertyId}/rent/${id}`)
                        },
                        {
                          label: t('viewInvoice'),
                          description: t('viewSendInvoice'),
                          icon: InvoiceIcon,
                          onClick: () => navigate(`/invoice/${propertyId}`)
                        },
                        {
                          label: t('sendInvoice'),
                          description: t('sendInvoiceThrowMail'),
                          icon: SendMailIcon,
                          onClick: () => setSendInvoiceDialogIsOpen(true)
                        },
                        {
                          label: t('note'),
                          description: t('noteText'),
                          icon: AddFilesIcon,
                          onClick: onNoteClick,
                        },
                        {
                          value: 'hasPayment',
                          label: t('history'),
                          description: t('semiPaidHistoryDescAndEdit'),
                          icon: ClockIcon,
                          onClick: () => setSemiPaidHistoryDialogIsOpen(true),
                        },
                      ].filter(({ value }) => value !== 'hasPayment' || hasLowerPayment)}
                    />
                  ) : (
                    <MenuList
                      trigger={({ handleOpen }) => (
                        <Button
                          variant='contained'
                          color='secondary'
                          onClick={handleOpen}
                        >
                          {t('manage')}
                        </Button>
                      )}
                      items={[
                        {
                          label: t('markAsPaid'),
                          description: t('markAsPaidText'),
                          icon: AddFolderIcon,
                          onClick: () => navigate(`/properties/${propertyId}/rent/${id}`)
                        },
                        {
                          label: t('note'),
                          description: t('noteText'),
                          icon: AddFilesIcon,
                          onClick: onNoteClick,
                        },
                        {
                          value: 'hasPayment',
                          label: t('history'),
                          description: t('semiPaidHistoryDescAndEdit'),
                          icon: ClockIcon,
                          onClick: () => setSemiPaidHistoryDialogIsOpen(true),
                        },
                      ].filter(({ value }) => value !== 'hasPayment' || hasLowerPayment)}
                    />
                  )}
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
      </Paper >
      <Dialog
        open={attachmentsDialogIsOpen}
        onClose={() => setAttachmentsDialogIsOpen(false)}
        title={t('attachedFiles')}
        dialogWidth='650px'
      >
        <Typography variant='h6' mb={3}>
          {t('viewDownloadFile')}
        </Typography>
        {attachments?.map(({ name, createdAt, url }, index) => (
          <Box key={`${url}-${createdAt}-${index}`} mt={3}>
            <Stack>
              <Typography variant='caption'>
                {t('file')}
              </Typography>
              <Stack direction='row' alignItems='center' spacing={1}>
                <Typography variant='subtitle2'>
                  {name}
                </Typography>
                <a href={url} download target='_blank' rel='noreferrer noopener' style={{ color: '#000', cursor: 'pointer' }}>
                  <IconButton
                    onClick={() => url}
                  >
                    <DownloadFileIcon />
                  </IconButton>
                </a>
              </Stack>
            </Stack>
          </Box>
        ))}
      </Dialog>
      <Dialog
        open={sendInvoiceDialogIsOpen}
        onClose={() => setSendInvoiceDialogIsOpen(false)}
        title={t('sendingInvoice')}
      >
        <Box>
          {sendInvoiceManuallyIsLoading ? (
            <Box sx={{ textAlign: 'center' }}>
              <CircularProgress size={100} />
            </Box>
          ) : (
            <>
              <Box>
                <Typography variant='h6' mt={1} mb={3}>
                  {t('sendInvoiceManually')}
                  <Typography variant='body2'>{singleProperty?.tenant?.email}</Typography>
                </Typography>
                <Typography variant='h6'>
                  {t('pleaseConfirm')}
                </Typography>
              </Box>
              <Stack mt={3} direction='row' alignItems='center' spacing={3}>
                <Button
                  variant='contained'
                  color='warning'
                  fullWidth
                  onClick={() => setSendInvoiceDialogIsOpen(false)}
                >
                  {t('back')}
                </Button>
                <Button
                  variant='contained'
                  color='primary'
                  fullWidth
                  onClick={() => {
                    dispatch(sendInvoiceManually(invoiceId, (response) => {
                      if (response?.success) {
                        setSendInvoiceDialogIsOpen(false);
                        setSuccessDialogIsOpen(true);
                      }
                    }));
                  }}
                >
                  {t('send')}
                </Button>
              </Stack>
            </>
          )}
        </Box>
      </Dialog>
      <Dialog
        open={successDialogIsOpen}
        onClose={() => setSuccessDialogIsOpen(false)}
        title={t('sendingInvoiceSuccess')}
      >
        <Typography variant='h6' mt={1} mb={3}>
          {t('tenantWillReceiveInvoice')}
        </Typography>
        <Button
          variant='contained'
          color='primary'
          fullWidth
          onClick={() => setSuccessDialogIsOpen(false)}
        >
          {t('manageProperty')}
        </Button>
      </Dialog>
      <Dialog
        open={semiPaidHistoryDialogIsOpen}
        onClose={() => setSemiPaidHistoryDialogIsOpen(false)}
        title={t('semiPaidHistory')}
        dialogWidth='700px'
      >
        <Typography variant='h6'>
          {t('allSemiPayments')}
        </Typography>
        {paymentHistory?.map(({ _id, amount, paymentDate, markedBy, method }, index) => {
          const canEditPayment = Boolean((method === 'other') || (method === 'bank-transaction' && markedBy === 'landlord'));
          return (
            <Grid key={`item-${amount}-${paymentDate}-${index}`} container mt={1}>
              <Grid item md={3}>
                <Typography variant='caption'>{t('payDate')}</Typography>
                {paymentDate && (
                  <Typography variant='subtitle2'>{formatDate(paymentDate, 'dd.MM.yyyy')}</Typography>
                )}
              </Grid>
              <Grid item md={3}>
                <Typography variant='caption'>{t('paidSum')}</Typography>
                <Typography variant='subtitle2'>{formatAmount(amount, i18n?.language)}</Typography>
              </Grid>
              <Grid item md={2}>
                <Typography variant='caption'>{t('addedFrom')}</Typography>
                <Typography variant='subtitle2'>{t(markedBy)}</Typography>
              </Grid>
              <Grid item md={3}>
                <Typography variant='caption'>{t('paymentMethod')}</Typography>
                <Typography variant='subtitle2'>{t(method)}</Typography>
              </Grid>
              {canEditPayment && (
                <Grid item md={1}>
                  <IconButton onClick={() => navigate(`/properties/${propertyId}/edit-rent/${id}/${_id}`)}>
                    <EditIcon />
                  </IconButton>
                </Grid>
              )}
            </Grid>
          );
        })}
      </Dialog>
    </>
  );
};

export default RentInfo;