import { SearchIcon } from 'icons';
import { useTranslation } from 'react-i18next';
import { InputBase } from '@mui/material';
import { styled } from '@mui/material/styles';

const SearchInput = styled(InputBase)`
 width: 100% ;
 border: 1px solid #ccc ;
 border-radius: 5px;
 padding: 0 10px;
`;

const SearchField = () => {
  const { t } = useTranslation();

  return (
    <div>
      <SearchInput placeholder={t('search')} endAdornment={<SearchIcon />} />
    </div>
  );
};

export default SearchField;