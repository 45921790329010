const formatPayDate = (payDate) => {
  if (window?.i18n?.language === 'bg') {
    if (payDate === 1 || payDate === 2) {
      return `${payDate}-во число`;
    } else if (payDate === 7 || payDate === 8) {
      return `${payDate}-мо число`;
    } else if (payDate === 21 || payDate === 22) {
      return `${payDate}-ро число`;
    } else if (payDate === 27 || payDate === 28) {
      return `${payDate}-мо число`;
    } else if (payDate === 31) {
      return `${payDate}-во число`;
    } else if (payDate === null || payDate === undefined) {
      return window.i18n.t('notIndicated');
    } else {
      return `${payDate}-то число`;
    }
  } else {
    if (payDate === 1) {
      return `${payDate}st number`;
    } else if (payDate === 2) {
      return `${payDate}nd number`;
    } else {
      return `${payDate}th number`;
    }
  }

};

export default formatPayDate;