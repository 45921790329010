import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import httpClient from '../api/httpClient';

const initialState = {
  utilityBillsList: [],
  paymentHistory: [],
  totalUnpaidAmount: 0,
  getUtilityBillsIsLoading: false,
  deleteUtilityBillsIsLoading: false,
  checkUtilityBillsForPaymentIsLoading: false,
  room: null,
  editUtilityBillIsLoading: false,
  payBillIsLoading: false
};

export const UtilityBillsSlice = createSlice({
  name: 'UtilityBills',
  initialState,
  reducers: {
    getUtilityBillsListRequesting: (state) => {
      state.getUtilityBillsIsLoading = true;
    },
    getUtilityBillsListSuccess: (state, { payload }) => {
      state.getUtilityBillsIsLoading = false;
      state.utilityBillsList = payload?.utilityBills;
      state.totalUnpaidAmount = payload?.totalUnpaidAmount;
    },
    getUtilityBillsListError: (state) => {
      state.getUtilityBillsIsLoading = false;
    },
    deleteUtilityBillRequesting: (state) => {
      state.deleteUtilityBillsIsLoading = true;
    },
    deleteUtilityBillSuccess: (state, { payload }) => {
      state.deleteUtilityBillsIsLoading = false;
      const deletedBillId = payload?._id;
      state.utilityBillsList = state.utilityBillsList?.filter((bill) => bill?._id !== deletedBillId);
    },
    deleteUtilityBillError: (state) => {
      state.deleteUtilityBillsIsLoading = false;
    },
    checkUtilityBillsForPaymentRequesting: (state) => {
      state.checkUtilityBillsForPaymentIsLoading = true;
    },
    checkUtilityBillsForPaymentSuccess: (state, { payload }) => {
      state.checkUtilityBillsForPaymentIsLoading = false;
      state.room = payload;
    },
    checkUtilityBillsForPaymentError: (state) => {
      state.checkUtilityBillsForPaymentIsLoading = false;
    },
    editUtilityBillRequesting: (state) => {
      state.editUtilityBillIsLoading = true;
    },
    editUtilityBillSuccess: (state, { payload }) => {
      state.editUtilityBillIsLoading = false;
    },
    editUtilityBillError: (state) => {
      state.editUtilityBillIsLoading = false;
    },
    payBillRequesting: (state) => {
      state.payBillIsLoading = true;
    },
    payBillSuccess: (state, { payload }) => {
      state.payBillIsLoading = false;
    },
    payBillError: (state) => {
      state.payBillIsLoading = false;
    },
  }
});

export const {
  getUtilityBillsListRequesting,
  getUtilityBillsListSuccess,
  getUtilityBillsListError,
  deleteUtilityBillRequesting,
  deleteUtilityBillSuccess,
  deleteUtilityBillError,
  checkUtilityBillsForPaymentRequesting,
  checkUtilityBillsForPaymentSuccess,
  checkUtilityBillsForPaymentError,
  editUtilityBillRequesting,
  editUtilityBillSuccess,
  editUtilityBillError,
  payBillRequesting,
  payBillSuccess,
  payBillError
} = UtilityBillsSlice.actions;

export const getUtilityBills = (id, onSuccess) => async (dispatch) => {
  dispatch(getUtilityBillsListRequesting());
  httpClient.get(`/utility-bills/property/${id}`).then(
    (response) => {
      dispatch(getUtilityBillsListSuccess(response?.data));
      if (onSuccess) {
        onSuccess(response);
      }
    },
    (error) => dispatch(getUtilityBillsListError(error)),
  );
};

export const deleteUtilityBill = (data, onSuccess) => async (dispatch) => {
  dispatch(deleteUtilityBillRequesting());
  httpClient.delete('/utility-bills', { data }).then(
    (response) => {
      dispatch(deleteUtilityBillSuccess(response?.data));
      if (onSuccess) {
        onSuccess();
      }
    },
    (error) => dispatch(deleteUtilityBillError(error)),
  );
};

export const checkUtilityBillsForPayment = (data, onSuccess) => async (dispatch) => {
  dispatch(checkUtilityBillsForPaymentRequesting());
  httpClient.post('/utility-bills/check', data).then(
    (response) => {
      dispatch(checkUtilityBillsForPaymentSuccess(response?.data?.room));
      if (onSuccess) {
        onSuccess(response);
      }
    },

    (error) => dispatch(checkUtilityBillsForPaymentError(error)),
  );
};

export const editUtilityBill = (data, onSuccess) => async (dispatch) => {
  dispatch(editUtilityBillRequesting());
  httpClient.put('/utility-bills', data).then(
    (response) => {
      dispatch(editUtilityBillSuccess(response));
      toast.success(window.i18n.t('batchUpdateSuccess'));
      if (onSuccess) {
        onSuccess();
      }
    },

    (error) => dispatch(editUtilityBillError(error)),
  );
};

export const payBill = (data) => async (dispatch) => {
  dispatch(payBillRequesting());
  httpClient.delete('/utility-bills', { data }).then(
    (response) => {
      dispatch(payBillSuccess(response));
    },
    (error) => dispatch(payBillError(error)),
  );
};


export default UtilityBillsSlice.reducer;
