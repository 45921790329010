import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  IconButton,
  Stack,
  Typography,
  Box,
  Paper,
  Grid,
  Button,
  TextField,
  InputAdornment
} from '@mui/material';
import { ArrowBackIcon } from 'icons';
import { DefaultLayout } from 'layouts';
import { getOrganization, updateOrganization } from 'stores/OrganizationSlice';
import { useTranslation } from 'react-i18next';

const EditOrganization = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { organizationId } = useParams();
  const { organization } = useSelector(({ organizationState }) => organizationState);

  const validations = Yup.object().shape({
    fullName: Yup.string().required(t('fullNameRequired')),
    eic: Yup.string().required(t('eicRequired')),
    manager: Yup.string().required(t('managerRequired')),
    phoneNumber: Yup.string().required(t('phoneNumberRequired')),
    email: Yup.string().email().required(t('emailRequired'))
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(validations),
  });

  useEffect(() => {
    if (organizationId) {
      dispatch(getOrganization(organizationId));
    }
  }, [organizationId]);

  useEffect(() => {
    reset({
      fullName: organization?.fullName,
      eic: organization?.eic,
      manager: organization?.manager,
      phoneNumber: organization?.phoneNumber?.slice(4),
      email: organization?.email,
    });
  }, [organization]);

  return (
    <DefaultLayout>
      <form onSubmit={handleSubmit((data) => {
        dispatch(updateOrganization(organizationId, {
          ...data,
          phoneNumber: `+359${data?.phoneNumber}`
        }, () => navigate('/personal-settings')));
      })}>
        <Paper elevation={4}>
          <Stack direction='row' alignItems='center' justifyContent='space-between'>
            <Stack direction='row' alignItems='center' spacing={1}>
              <IconButton onClick={() => navigate('/personal-settings')}>
                <ArrowBackIcon style={{ width: 16, height: 16 }} />
              </IconButton>
              <Typography variant='h5'>{t('organizationData')}</Typography>
            </Stack>
            <Button
              variant='contained'
              color='primary'
              type='submit'
            >
              {t('save')}
            </Button>
          </Stack>
        </Paper>
        <Box mt={3}>
          <Paper elevation={4}>
            <Typography variant='subtitle1'>
              {t('mainInfo')}
            </Typography>
            <Grid container mt={3}>
              <Grid item md={3}>
                <TextField
                  label={`${t('name')}*`}
                  size='small'
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  {...register('fullName')}
                  error={Boolean(errors?.fullName?.message)}
                  helperText={errors?.fullName?.message}
                />
              </Grid>
              <Grid item md={3}>
                <TextField
                  label={`${t('eik')}*`}
                  size='small'
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  {...register('eic')}
                  error={Boolean(errors?.eic?.message)}
                  helperText={errors?.eic?.message}
                />
              </Grid>
              <Grid item md={3}>
                <TextField
                  label={`${t('materiallyResponsiblePerson')}*`}
                  size='small'
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  {...register('manager')}
                  error={Boolean(errors?.manager?.message)}
                  helperText={errors?.manager?.message}
                />
              </Grid>
            </Grid>
          </Paper>
        </Box>
        <Box mt={3}>
          <Paper elevation={4}>
            <Typography variant='subtitle1'>
              {t('contactInfo')}
            </Typography>
            <Grid container mt={3}>
              <Grid item md={3}>
                <TextField
                  label={`${t('phone')}*`}
                  InputProps={{
                    startAdornment: <InputAdornment position='start'>
                      +359
                    </InputAdornment>,
                  }}
                  size='small'
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  {...register('phoneNumber')}
                  error={Boolean(errors?.phoneNumber?.message)}
                  helperText={errors?.phoneNumber?.message}
                />
              </Grid>
              <Grid item md={3}>
                <TextField
                  label={`${t('mail')}*`}
                  size='small'
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  {...register('email')}
                  error={Boolean(errors?.email?.message)}
                  helperText={errors?.email?.message}
                />
              </Grid>
              {/* <Grid item md={3}>
              <TextField
                label={`${t('contactPerson')}*`}
                size='small'
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            </Grid> */}
            </Grid>
          </Paper>
        </Box>
      </form>
    </DefaultLayout>
  );
};

export default EditOrganization;