import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sendInvoiceManuallyIsLoading: false,
};

export const InvoiceSlice = createSlice({
  name: 'InvoiceSlice',
  initialState,
  reducers: {

  }
});

export const {

} = InvoiceSlice.actions;


export default InvoiceSlice.reducer;
