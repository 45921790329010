import { useNavigate, useLocation } from 'react-router-dom';
import {
  Button,
  Stack,
  Box,
  Card,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FullScreenLayout } from 'layouts';

const FinancesLayout = ({ children, leftAdornment, rightAdornment }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location?.pathname;

  return (
    <FullScreenLayout>
      {({ layoutHeight }) => (
        <>
          <Card style={{ height: layoutHeight, position: 'relative' }} elevation={1}>
            <Stack direction='row' justifyContent='space-between' alignItems='center'>
              <Stack direction='row' alignItems='center' spacing={2} >
                <Button
                  color='secondary'
                  variant={currentPath === '/finances/bank' ? 'contained' : 'outlined'}
                  onClick={() => navigate('/finances/bank')}
                >
                  {t('bank')}
                </Button>
                <Button
                  color='secondary'
                  variant={currentPath === '/finances/other' ? 'contained' : 'outlined'}
                  onClick={() => navigate('/finances/other')}
                >
                  {t('others')}
                </Button>
                {leftAdornment}
              </Stack>
              {Boolean(rightAdornment) && (
                <Stack direction='row' alignItems='center' spacing={1}>
                  {rightAdornment}
                </Stack>
              )}
            </Stack>
            <Box mt={2} sx={{ height: 'calc(100% - 36px)' }}>
              {children({ layoutHeight })}
            </Box>
          </Card>
        </>
      )}
    </FullScreenLayout>
  );
};


export default FinancesLayout;