import { useState } from 'react';
import { CircularProgress, Fade, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import DownloadIcon from '@mui/icons-material/Download';

const Preview = styled('div')`
  width: 100%;
  height: 400px;
  background: #ebebeb;
  border-radius: 6px;
  display: flex;
  align-content: center;
  justify-content: center;
  position: relative;
  align-items: center;

  a {
    text-decoration: none;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    display: block;
    border-radius: 6px;
  }
`;

const Loader = styled('div')`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PREVIEW_EXTENSIONS = ['JPG', 'JPEG', 'PNG', 'GIF'];

const FilePreview = ({ file }) => {
  const [isLoading, setIsLoading] = useState(true);
  const ext = file?.url?.substring(file?.url?.lastIndexOf('.') + 1);
  const canPreview = PREVIEW_EXTENSIONS.includes(ext?.toUpperCase());

  return (
    <Preview>
      {canPreview ? (
        <>
          <Fade in={isLoading}>
            <Loader>
              <CircularProgress size={100} />
            </Loader>
          </Fade>
          <img
            onLoad={() => setIsLoading(false)}
            onError={() => setIsLoading(false)}
            src={file?.url} alt={file?.name}
          />
        </>
      ) : (
        <div>
          <a href={file?.url} target='_blank' rel='noreferrer noopener' download>
            <Button
              size='large'
              variant='contained'
              onClick={() => file?.url}
              startIcon={<DownloadIcon />}
            >
              Download
            </Button>
          </a>
        </div>
      )}
    </Preview>
  );
};

export default FilePreview;