import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { CaseForm } from 'components/forms';
import { getSingleCase } from 'stores/CasesSlice';

const PropertiesCaseEdit = () => {
  const dispatch = useDispatch();
  const { singleCase, getSingleCaseIsLoading } = useSelector(({ casesState }) => casesState);
  const { caseId } = useParams();

  useEffect(() => {
    if (caseId) {
      dispatch(getSingleCase(caseId));
    }
  }, [caseId]);

  const defaultValues = {
    title: singleCase?.title,
    description: singleCase?.description,
    files: singleCase?.files?.map(({ name, url, _id }) => ({
      name,
      url,
      _id
    })),
  };

  return (
    <CaseForm
      isEdit
      isLoading={getSingleCaseIsLoading}
      defaultValues={defaultValues}
    />
  );
};

export default PropertiesCaseEdit;