
import { styled } from '@mui/material/styles';
import { Container } from '@mui/material';

const Default = styled('div')({
  paddingTop: '80px',
});

const DefaultLayout = ({ children }) => (
  <Default>
    <Container>
      {children}
    </Container>
  </Default>
);

export default DefaultLayout;