/* eslint-disable indent */
import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import httpClient from '../api/httpClient';

const initialState = {
  transactions: {
    docs: [],
  },
  getTransactionsListIsLoading: false,
  banks: [],
  bankDetails: [],
  getBankListIsLoading: false,
  requisitions: [],
  getRequisitionsListIsLoading: false,
  createRequisitionIsLoading: false,
  updateRequisitionIsLoading: false,
  arrivalsAndExpensesList: {
    docs: []
  },
  singleArrivalOrExpense: undefined,
  getArrivalAndExpensesListIsLoading: true,
  createArrivalOrExpenseIsLoading: false,
  editArrivalOrExpenseIsLoading: false,
  deleteArrivalOrExpenseIsLoading: false,
  selectedPropertyId: 'all',
  updateTransactionsIsLoading: false,
  updatePrimaryBankIsLoading: false,
  paymentLocalizationDetails: [],
  getPaymentLocalizationDetails: false,
  createPaymentLocalizationIsLoading: false
};

export const FinancesSlice = createSlice({
  name: 'Finance',
  initialState,
  reducers: {
    getTransactionsRequesting: (state, { payload }) => {
      if (payload?.loadMore) {
        state.getTransactionsListIsLoading = false;
      } else {
        state.getTransactionsListIsLoading = true;
      }
    },
    getTransactionsSuccess: (state, { payload }) => {
      state.getTransactionsListIsLoading = false;
      if (payload?.loadMore) {
        state.transactions = {
          ...payload?.transactions,
          docs: [
            ...state.transactions?.docs,
            ...payload?.transactions?.docs,
          ]
        };
      } else {
        state.transactions = payload?.transactions;
      }
      state.bankDetails = payload?.bankAccounts;
    },
    getTransactionsError: (state) => {
      state.getTransactionsListIsLoading = false;
    },
    getBanksRequesting: (state) => {
      state.getBankListIsLoading = true;
    },
    getBanksSuccess: (state, { payload }) => {
      state.getBankListIsLoading = false;
      if (payload?.status === 200) {
        state.banks = payload?.data?.banks;
      }
    },
    getBanksError: (state) => {
      state.getBankListIsLoading = false;
    },
    getRequisitionsRequesting: (state) => {
      state.getRequisitionsListIsLoading = true;
    },
    getRequisitionsSuccess: (state) => {
      state.getRequisitionsListIsLoading = false;

    },
    getRequisitionsError: (state) => {
      state.getRequisitionsListIsLoading = false;
    },
    createRequisitionRequesting: (state) => {
      state.createRequisitionIsLoading = true;
    },
    createRequisitionSuccess: (state) => {
      state.createRequisitionIsLoading = false;
    },
    createRequisitionError: (state) => {
      state.createRequisitionIsLoading = false;
    },
    updateRequisitionRequesting: (state) => {
      state.updateRequisitionIsLoading = true;
    },
    updateRequisitionSuccess: (state) => {
      state.updateRequisitionIsLoading = false;
    },
    updateRequisitionError: (state) => {
      state.updateRequisitionIsLoading = false;
    },
    getArrivalsAndExpensesRequesting: (state, { payload }) => {
      if (payload?.loadMore) {
        state.getArrivalAndExpensesListIsLoading = false;
      } else {
        state.getArrivalAndExpensesListIsLoading = true;
      }
    },
    getArrivalsAndExpensesSuccess: (state, { payload }) => {
      if (payload?.loadMore) {
        state.arrivalsAndExpensesList = {
          ...payload?.finances,
          docs: [
            ...state?.arrivalsAndExpensesList?.docs,
            ...payload?.finances?.docs,
          ]
        };
      } else {
        state.arrivalsAndExpensesList = payload?.finances;
      }
      state.getArrivalAndExpensesListIsLoading = false;
    },
    getArrivalsAndExpensesError: (state) => {
      state.getArrivalAndExpensesListIsLoading = false;
    },
    createArrivalOrExpenseRequesting: (state) => {
      state.createArrivalOrExpenseIsLoading = true;
    },
    createArrivalOrExpenseSuccess: (state) => {
      state.createArrivalOrExpenseIsLoading = false;
    },
    createArrivalOrExpenseError: (state) => {
      state.createArrivalOrExpenseIsLoading = false;
    },
    editArrivalOrExpenseRequesting: (state) => {
      state.editArrivalOrExpenseIsLoading = true;
    },
    editArrivalOrExpenseSuccess: (state) => {
      state.editArrivalOrExpenseIsLoading = false;
    },
    editArrivalOrExpenseError: (state) => {
      state.editArrivalOrExpenseIsLoading = false;
    },
    deleteArrivalOrExpenseRequesting: (state) => {
      state.deleteArrivalOrExpenseIsLoading = true;
    },
    deleteArrivalOrExpenseSuccess: (state) => {
      state.deleteArrivalOrExpenseIsLoading = false;
    },
    deleteArrivalOrExpenseError: (state) => {
      state.deleteArrivalOrExpenseIsLoading = false;
    },
    setSingleArrivalOrExpense: (state, { payload }) => {
      state.singleArrivalOrExpense = payload;
    },
    setSelectedPropertyId: (state, { payload }) => {
      state.selectedPropertyId = payload;
    },
    clearBanks: (state) => {
      state.banks = [];
    },
    updateTransactionsRequesting: (state) => {
      state.updateTransactionsIsLoading = true;
    },
    updateTransactionsSuccess: (state) => {
      state.updateTransactionsIsLoading = false;
    },
    updateTransactionsError: (state) => {
      state.updateTransactionsIsLoading = false;
    },
    updatePrimaryBankRequesting: (state) => {
      state.updatePrimaryBankIsLoading = true;
    },
    updatePrimaryBankSuccess: (state) => {
      state.updatePrimaryBankIsLoading = false;
    },
    updatePrimaryBankError: (state) => {
      state.updatePrimaryBankIsLoading = false;
    },
    getPaymentLocalizationDetailsRequesting: (state) => {
      state.getPaymentLocalizationDetails = true;
    },
    getPaymentLocalizationDetailsSuccess: (state, { payload }) => {
      state.getPaymentLocalizationDetails = false;
      state.paymentLocalizationDetails = payload;
    },
    getPaymentLocalizationDetailsError: (state) => {
      state.getPaymentLocalizationDetails = false;
    },
    createPaymentLocalizationRequesting: (state) => {
      state.createPaymentLocalizationIsLoading = true;
    },
    createPaymentLocalizationSuccess: (state, { payload }) => {
      state.createPaymentLocalizationIsLoading = false;
    },
    createPaymentLocalizationError: (state) => {
      state.createPaymentLocalizationIsLoading = false;
    },
  },
}
);

export const {
  getTransactionsRequesting,
  getTransactionsSuccess,
  getTransactionsError,
  getBanksRequesting,
  getBanksSuccess,
  getBanksError,
  getRequisitionsRequesting,
  getRequisitionsSuccess,
  getRequisitionsError,
  createRequisitionRequesting,
  createRequisitionSuccess,
  createRequisitionError,
  updateRequisitionRequesting,
  updateRequisitionSuccess,
  updateRequisitionError,
  getArrivalsAndExpensesRequesting,
  getArrivalsAndExpensesSuccess,
  getArrivalsAndExpensesError,
  createArrivalOrExpenseRequesting,
  createArrivalOrExpenseSuccess,
  createArrivalOrExpenseError,
  editArrivalOrExpenseRequesting,
  editArrivalOrExpenseSuccess,
  editArrivalOrExpenseError,
  deleteArrivalOrExpenseRequesting,
  deleteArrivalOrExpenseSuccess,
  deleteArrivalOrExpenseError,
  setSingleArrivalOrExpense,
  setSelectedPropertyId,
  clearBanks,
  updateTransactionsRequesting,
  updateTransactionsSuccess,
  updateTransactionsError,
  updatePrimaryBankRequesting,
  updatePrimaryBankSuccess,
  updatePrimaryBankError,
  getPaymentLocalizationDetailsRequesting,
  getPaymentLocalizationDetailsSuccess,
  getPaymentLocalizationDetailsError,
  createPaymentLocalizationRequesting,
  createPaymentLocalizationSuccess,
  createPaymentLocalizationError
} = FinancesSlice.actions;

export const getTransactions = ({ page, limit = 10, org, loadMore }) => async (dispatch) => {
  dispatch(getTransactionsRequesting({ loadMore }));
  httpClient.get('/nordigen/transactions',
    {
      params: {
        page,
        limit,
        org,
      }
    }).then(
      (response) => {
        dispatch(getTransactionsSuccess({
          ...response?.data,
          loadMore,
        }));
      },
      (error) => dispatch(getTransactionsError(error)),
    );
};

export const getBanks = (onSuccess) => async (dispatch) => {
  dispatch(getBanksRequesting());
  httpClient.get('/nordigen/banks').then(
    (response) => {
      dispatch(getBanksSuccess(response));
      if (onSuccess) {
        onSuccess(response);
      }
    },
    (error) => dispatch(getBanksError(error)),
  );
};

export const getRequisitions = () => async (dispatch) => {
  dispatch(getRequisitionsRequesting());
  httpClient.get('/nordigen/requisitions').then(
    (response) => {
      dispatch(getRequisitionsSuccess(response));
    },
    (error) => dispatch(getRequisitionsError(error)),
  );
};

export const createRequisition = (data, onSuccess) => async (dispatch) => {
  dispatch(createRequisitionRequesting());
  httpClient.post('/nordigen/requisitions?org=true', data).then(
    (response) => {
      dispatch(createRequisitionSuccess(response));
      if (onSuccess) {
        onSuccess(response);
      }
    },
    (error) => dispatch(createRequisitionError(error)),
  );
};

export const updateRequisition = (id) => async (dispatch) => {
  dispatch(updateRequisitionRequesting());
  httpClient.put(`/nordigen/requisitions/${id}?org=true`).then(
    (response) => {
      dispatch(updateRequisitionSuccess(response));
    },
    (error) => dispatch(updateRequisitionError(error)),
  );
};

export const getArrivalsAndExpenses = ({ page, limit = 20, filters, loadMore }) => async (dispatch) => {
  dispatch(getArrivalsAndExpensesRequesting({ loadMore }));
  httpClient.post(`/finances/user?page=${page}&limit=${limit}&org=true`, { filters }).then(
    (response) => {
      dispatch(getArrivalsAndExpensesSuccess({
        ...response?.data,
        loadMore,
      }));
    },
    (error) => dispatch(getArrivalsAndExpensesError(error)),
  );
};

export const createArrivalOrExpense = (data, onSuccess) => async (dispatch) => {
  dispatch(createArrivalOrExpenseRequesting());
  httpClient.post('/finances?org=true', data).then(
    (response) => {
      dispatch(createArrivalOrExpenseSuccess(response?.data));
      toast.success(window.i18n.t('arrivalExpenseAddSuccess'));
      if (onSuccess) {
        onSuccess();
      }
    },
    (error) => dispatch(createArrivalOrExpenseError(error)),
  );
};

export const editArrivalOrExpense = (data, onSuccess) => async (dispatch) => {
  dispatch(editArrivalOrExpenseRequesting());
  httpClient.put('/finances', data).then(
    (response) => {
      dispatch(editArrivalOrExpenseSuccess(response?.data));
      toast.success(window.i18n.t('arrivalExpenseEditSuccess'));
      if (onSuccess) {
        onSuccess();
      }
    },
    (error) => dispatch(editArrivalOrExpenseError(error)),
  );
};

export const deleteArrivalOrExpense = (data, onSuccess) => async (dispatch) => {
  dispatch(deleteArrivalOrExpenseRequesting());
  httpClient.delete('/finances', { data }).then(
    (response) => {
      dispatch(deleteArrivalOrExpenseSuccess(response));
      toast.success(window.i18n.t('arrivalExpenseDeleteSuccess'));
      if (onSuccess) {
        onSuccess();
      }
    },
    (error) => dispatch(deleteArrivalOrExpenseError(error)),
  );
};

export const updateTransactions = (banks, onSuccess) => async (dispatch) => {
  dispatch(updateTransactionsRequesting());
  Promise.all(banks?.map(async ({ _id, isHidden }) => httpClient.put(`/bank-details/${_id}`, { isHidden })))
    .then((response) => {
      dispatch(updateTransactionsSuccess(response));
      if (onSuccess) {
        onSuccess();
      }
    }, (error) => dispatch(updateTransactionsError(error)));
};

export const updatePrimaryBank = (bankId, data, onSuccess) => async (dispatch) => {
  dispatch(updatePrimaryBankRequesting());
  httpClient.put(`/bank-details/${bankId}`, data).then(
    (response) => {
      dispatch(updatePrimaryBankSuccess(response));
      if (onSuccess) {
        onSuccess();
      }
    },
    (error) => dispatch(updatePrimaryBankError(error)),
  );
};

export const getPaymentLocalizationDetails = () => async (dispatch) => {
  dispatch(getPaymentLocalizationDetailsRequesting());
  httpClient.get('/nordigen/transactions/localize?org=true').then(
    (response) => {
      dispatch(getPaymentLocalizationDetailsSuccess(response?.data?.properties));
    },
    (error) => dispatch(getPaymentLocalizationDetailsError(error)),
  );
};

export const createPaymentLocalization = (financeId, data, onSuccess) => async (dispatch) => {
  dispatch(createPaymentLocalizationRequesting());
  httpClient.post(`/nordigen/transactions/localize/${financeId}?org=true`, data).then(
    (response) => {
      dispatch(createPaymentLocalizationSuccess(response));
      if (onSuccess) {
        onSuccess();
      }
    },
    (error) => dispatch(createPaymentLocalizationError(error)),
  );
};

export default FinancesSlice.reducer;
