import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  IconButton,
  Stack,
  Typography,
  Box,
  Paper,
  Grid,
  Button,
  TextField,
  Avatar,
  InputAdornment
} from '@mui/material';
import { ArrowBackIcon, CrossIcon } from 'icons';
import { DefaultLayout } from 'layouts';
import { getMyUserData, updateUser } from 'stores/OrganizationSlice';
import { s3Upload } from 'stores/FilesSlice';
import { useTranslation } from 'react-i18next';

const EditOrganizationPersonalData = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fileInputRef = useRef();
  const { myUserData } = useSelector(({ organizationState }) => organizationState);

  const validations = Yup.object().shape({
    firstName: Yup.string().required(t('firstNameRequired')),
    lastName: Yup.string().required(t('lastNameRequired')),
    phoneNumber: Yup.string().required(t('phoneNumberRequired')),
    email: Yup.string().email().required(t('emailRequired')),
    avatar: Yup.string().label('Avatar')
  });

  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields },
    reset,
    watch,
    setValue
  } = useForm({
    resolver: yupResolver(validations),
  });

  useEffect(() => {
    dispatch(getMyUserData());
  }, []);

  useEffect(() => {
    reset({
      firstName: myUserData?.firstName,
      lastName: myUserData?.lastName,
      phoneNumber: myUserData?.phoneNumber?.slice(4),
      email: myUserData?.email,
      avatar: myUserData?.avatar,
    });
  }, [myUserData]);

  const onChooseFile = (ev) => {
    const file = ev.currentTarget.files[0];
    dispatch(s3Upload(file, (data) => {
      setValue('avatar', data?.location);
    }));
    fileInputRef.current.value = '';
  };

  const avatarValue = watch('avatar');

  return (
    <DefaultLayout>
      <form onSubmit={handleSubmit(({ phoneNumber, ...data }) => {
        dispatch(updateUser({
          ...data,
          ...(dirtyFields?.phoneNumber && { phoneNumber: `+359${phoneNumber}` }),
        }, () => navigate('/personal-settings')));
      })}>
        <Paper elevation={4}>
          <Stack direction='row' alignItems='center' justifyContent='space-between'>
            <Stack direction='row' alignItems='center' spacing={1}>
              <IconButton onClick={() => navigate('/personal-settings')}>
                <ArrowBackIcon style={{ width: 16, height: 16 }} />
              </IconButton>
              <Typography variant='h5'>{t('editOrganizationPersonalData')}</Typography>
            </Stack>
            <Button
              variant='contained'
              color='primary'
              type='submit'
            >
              {t('save')}
            </Button>
          </Stack>
        </Paper>
        <Box mt={3}>
          <Paper elevation={4}>
            <Stack direction='row' alignItems='center' justifyContent='space-between'>
              <Typography variant='subtitle1'>
                {t('data')}
              </Typography>
            </Stack>
            <Grid container mt={3}>
              <Grid item md={3}>
                <TextField
                  label={`${t('name')}*`}
                  size='small'
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  {...register('firstName')}
                  error={Boolean(errors?.firstName?.message)}
                  helperText={errors?.firstName?.message}
                />
              </Grid>
              <Grid item md={3}>
                <TextField
                  label={`${t('lastName')}*`}
                  size='small'
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  {...register('lastName')}
                  error={Boolean(errors?.lastName?.message)}
                  helperText={errors?.lastName?.message}
                />
              </Grid>
              <Grid item md={3}>
                <TextField
                  label={`${t('phone')}*`}
                  size='small'
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    startAdornment: <InputAdornment position='start'>
                      +359
                    </InputAdornment>,
                  }}
                  {...register('phoneNumber')}
                  error={Boolean(errors?.phoneNumber?.message)}
                  helperText={errors?.phoneNumber?.message}
                />
              </Grid>
              <Grid item md={3}>
                <TextField
                  label={`${t('mail')}`}
                  size='small'
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  {...register('email')}
                  error={Boolean(errors?.email?.message)}
                  helperText={errors?.email?.message}
                />
              </Grid>
              <Grid item md={3}>
                <Typography variant='h6' textTransform='none'>
                  {t('profilePicture')}
                </Typography>
                <input
                  ref={fileInputRef}
                  type='file'
                  onChange={onChooseFile}
                  multiple
                  style={{ display: 'none' }}
                />
                <Stack direction='row' spacing={1} alignItems='center'>
                  <Avatar
                    sx={{ cursor: 'pointer', width: 50, height: 50 }}
                    src={watch('avatar')}
                    onClick={() => fileInputRef?.current?.click()}
                  />
                  {Boolean(avatarValue) && (
                    <IconButton onClick={() => setValue('avatar', '')}>
                      <CrossIcon
                        style={{ width: 12, height: 12 }}
                        fill='#d31163'
                      />
                    </IconButton>
                  )}
                </Stack>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </form>
    </DefaultLayout>
  );
};

export default EditOrganizationPersonalData;