import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Paper,
  Typography,
  Box,
  Stack,
  Grid,
  IconButton,
  Avatar,
  Button
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DefaultLayout } from 'layouts';
import { ArrowBackIcon } from 'icons';
import { getSingleProperty } from 'stores/PropertiesSlice';

const TenantInfo = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { propertyId } = useParams();

  const {
    propertiesState: {
      singleProperty
    },
  } = useSelector(({ propertiesState }) => ({ propertiesState }));

  useEffect(() => {
    dispatch(getSingleProperty(propertyId));
  }, []);

  const tenantIsFirm = Boolean(singleProperty?.tenant?.companyName);

  return (
    <DefaultLayout>
      <Paper elevation={4} sx={{ width: 850, margin: '0 auto' }}>
        <Box p={1}>
          <Stack direction='row' justifyContent='space-between' alignItems='center'>
            <Stack direction='row' alignItems='center' spacing={1}>
              <IconButton onClick={() => navigate(`/properties/${propertyId}`)}>
                <ArrowBackIcon style={{ width: 16, height: 16 }} />
              </IconButton>
              <Typography variant='h5'>
                {t('tenantData')}
              </Typography>
            </Stack>
            <Button
              variant='contained'
              color='info'
              onClick={() => navigate(`/properties/${propertyId}/edit-tenant`)}
            >
              {t('edit')}
            </Button>
          </Stack>
          <Typography variant='subtitle1' mt={3}>
            {t('basicInfo')}
          </Typography>
          {tenantIsFirm ? (
            <>
              <Grid container mt={1}>
                <Grid item md={4}>
                  <Stack>
                    <Typography variant='caption'>
                      {t('tenant')}
                    </Typography>
                    <Typography variant='subtitle2'>
                      {singleProperty?.invoiceDetails?.fullName}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item md={4}>
                  <Stack>
                    <Typography variant='caption'>
                      {t('eik')}
                    </Typography>
                    <Typography variant='subtitle2'>
                      {singleProperty?.invoiceDetails?.eic}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item md={4}>
                  <Stack>
                    <Typography variant='caption'>
                      {t('materiallyResponsiblePerson')}
                    </Typography>
                    <Typography variant='subtitle2'>
                      {singleProperty?.invoiceDetails?.manager}
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
              <Grid container mt={1}>
                <Grid item md={12}>
                  <Stack>
                    <Typography variant='caption'>
                      {t('address')}
                    </Typography>
                    <Typography variant='subtitle2'>
                      {singleProperty?.invoiceDetails?.address}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item md={12}>
                  <Stack>
                    <Typography variant='caption'>
                      {t('mail')}
                    </Typography>
                    <Typography variant='subtitle2'>
                      {singleProperty?.invoiceDetails?.email}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item md={12}>
                  <Stack>
                    <Typography variant='caption'>
                      {t('phone')}
                    </Typography>
                    <Typography variant='subtitle2'>
                      {singleProperty?.invoiceDetails?.phoneNumber}
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Grid container mt={1}>
                <Grid item md={6}>
                  <Stack direction='row' alignItems='center' spacing={1}>
                    <Avatar src={singleProperty?.tenant?.avatar} />
                    <Stack>
                      <Typography variant='caption'>
                        {t('tenant')}
                      </Typography>
                      <Typography variant='subtitle2'>
                        {singleProperty?.invoiceDetails?.fullName}
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item md={6}>
                  <Stack>
                    <Typography variant='caption'>
                      {t('egn')}
                    </Typography>
                    <Typography variant='subtitle2'>
                      {singleProperty?.invoiceDetails?.egn}
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
              <Grid container mt={1}>
                <Grid item md={12}>
                  <Stack>
                    <Typography variant='caption'>
                      {t('address')}
                    </Typography>
                    <Typography variant='subtitle2'>
                      {singleProperty?.invoiceDetails?.address}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item md={12}>
                  <Stack>
                    <Typography variant='caption'>
                      {t('mail')}
                    </Typography>
                    <Typography variant='subtitle2'>
                      {singleProperty?.invoiceDetails?.email}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item md={12}>
                  <Stack>
                    <Typography variant='caption'>
                      {t('phone')}
                    </Typography>
                    <Typography variant='subtitle2'>
                      {singleProperty?.invoiceDetails?.phoneNumber}
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      </Paper>
    </DefaultLayout>
  );
};

export default TenantInfo;