
const DownloadFileIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='25'
    height='25'
    viewBox='0 0 25 25'
    {...props}
  >
    <g data-name='Group 33909' transform='translate(11406 9893)'>
      <g
        fill='#f5fffc'
        stroke='#aaf4de'
        strokeWidth='0.5'
        data-name='Rectangle 11591'
        transform='translate(-11406 -9893)'
      >
        <rect width='25' height='25' stroke='none' rx='5'></rect>
        <rect
          width='24.5'
          height='24.5'
          x='0.25'
          y='0.25'
          fill='none'
          rx='4.75'
        ></rect>
      </g>
      <g fill='#00d297' transform='translate(-11405.161 -9892.161)'>
        <path
          d='M11.79 15.748a.958.958 0 01-.68-.278l-3.83-3.83a.958.958 0 011.35-1.35l3.16 3.151 3.151-3.151a.958.958 0 011.35 1.35l-3.831 3.831a.958.958 0 01-.67.277z'
          data-name='Path 59477'
          transform='translate(-.129 -.257)'
        ></path>
        <path
          d='M11.661 15.492a.958.958 0 01-.958-.958V4.958a.958.958 0 011.915 0v9.576a.958.958 0 01-.957.958zm6.7 3.831H4.958a.958.958 0 010-1.915h13.407a.958.958 0 010 1.915z'
          data-name='Path 59478'
        ></path>
      </g>
    </g>
  </svg>
);

export default DownloadFileIcon;
