import { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  Paper,
  Stack,
  Typography,
  IconButton,
  Button,
  Box,
  Grid,
  Tabs,
  Tab,
  Divider,
  Link,
  useTheme,
  InputBase
} from '@mui/material';
import { FullScreenLayout } from 'layouts';
import { ArrowBackIcon, SendIcon } from 'icons';
import { Dialog } from 'components';
import { formatDate, formatDistanceToNow } from 'utils';
import {
  getSingleCase,
  updateCasesFile,
  updateSingleCaseFiles,
  updateCaseStatus,
  addNote
} from 'stores/CasesSlice';
import { s3Upload } from 'stores/FilesSlice';
import { groupBy } from 'lodash';
import { useTranslation } from 'react-i18next';

const Notes = ({ scrollHeight }) => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const boxRef = useRef(null);
  const { caseId } = useParams();
  const [note, setNote] = useState('');
  const { singleCase } = useSelector(({ casesState }) => casesState);
  const groupedData = groupBy(singleCase?.notes, (item) => item?.createdAt ? formatDistanceToNow(item?.createdAt, i18n?.language) : '');

  return (
    <>
      <Box ref={boxRef} p={1} sx={{ overflow: 'auto' }}>
        {Object.entries(groupedData)?.map(([timeDistanceString, notes], index) => (
          <Box key={`${timeDistanceString}-${index}-${notes}`} mt={2}>
            <Box mt={3}>
              <Divider>{timeDistanceString}</Divider>
            </Box>
            {notes?.length ? (
              <>
                {notes?.map(({ text, author, createdAt }, index) => (
                  <div key={`${createdAt}-${text}-${index}-item`}>
                    <Stack direction='row' justifyContent='flex-end'>
                      <Typography mb={1} variant='subtitle2'>
                        {author?.fullName}
                      </Typography>
                    </Stack>
                    <Stack direction='row' spacing={1} justifyContent='flex-end' alignItems='center'>
                      <Typography variant='caption'>
                        {createdAt ? formatDate(createdAt, 'HH:mm') : ''}
                      </Typography>
                      <Box p={1} sx={{ backgroundColor: '#EFF5FF', borderRadius: 2, textTransform: 'none' }}>
                        <Typography variant='caption' textTransform='none'>
                          {Boolean(text) ? text : ''}
                        </Typography>
                      </Box>
                    </Stack>
                  </div>
                ))}
              </>
            ) : (<></>)}
          </Box>
        ))}
      </Box>
      <Box
        p={1}
        mt={1}
        sx={{ backgroundColor: theme.palette.info.main, borderRadius: 2 }}
      >
        <InputBase
          sx={{ backgroundColor: '#fff' }}
          fullWidth
          endAdornment={(
            <div>
              <IconButton
                onClick={() => {
                  dispatch(addNote({
                    text: note,
                    caseId
                  }, () => {
                    setNote('');
                    boxRef?.current?.scrollTo({
                      top: 999999,
                      behavior: 'smooth'
                    });
                  }));
                }}
              >
                <SendIcon
                  style={{ width: 20, height: 20 }}
                />
              </IconButton>
            </div>
          )}
          value={note}
          onChange={({ currentTarget: { value } }) => setNote(value)}
        />
      </Box>
    </>
  );
};

const Files = ({ scrollHeight }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const fileInputRef = useRef();
  const { caseId } = useParams();
  const { singleCase } = useSelector(({ casesState }) => casesState);

  const onChooseFile = (ev) => {
    const file = ev.currentTarget.files[0];
    dispatch(s3Upload(file, (data) => {
      const newFile = {
        name: file?.name,
        url: data?.location
      };
      dispatch(updateCasesFile({
        ...newFile,
        caseId,
      }, (response) => {
        dispatch(updateSingleCaseFiles([
          ...singleCase?.files,
          response?.data?.data,
        ]));
      }));
    }));
  };

  return (
    <>
      <Box p={1} sx={{ overflow: 'auto' }}>
        <Stack direction='row' alignItems='center' justifyContent='space-between'>
          <Stack direction='row' spacing={1} alignItems='center'>
            <Typography variant='h6'>{t('files')}</Typography>
          </Stack>
          <Stack direction='row' spacing={1} alignItems='center'>
            <Typography variant='h6'>{t('addedOn')}</Typography>
          </Stack>
        </Stack>
        {singleCase?.files?.map(({ name, url, createdAt }) => (
          <Box key={url} mb={1} p={2} sx={{ borderRadius: 2, border: '1px solid #E3ECF8' }}>
            <Stack direction='row' justifyContent='space-between'>
              <Link href={url} target='_blank' color='secondary'>{name}</Link>
              <Stack direction='row' spacing={1}>
                <Divider orientation='vertical' flexItem />
                <Typography variant='body1'>{createdAt ? formatDate(createdAt, 'yyyy-MM-dd HH:mm') : ''}</Typography>
              </Stack>
            </Stack>
          </Box>
        ))}
        <input
          type='file'
          onChange={onChooseFile}
          ref={fileInputRef}
          style={{ display: 'none' }}
        />
      </Box>
      <Box
        p={1}
        mt={1}>
        <Button
          color='primary'
          fullWidth
          variant='outlined'
          onClick={() => fileInputRef.current.click()}
        >
          {t('addFiles')}
        </Button>
      </Box>
    </>
  );
};

const PropertiesCaseDetails = () => {
  const { t } = useTranslation();
  const TABS = [
    {
      id: 'tab1',
      label: t('notes'),
      component: Notes
    },
    {
      id: 'tab2',
      label: t('files'),
      component: Files
    },
  ];

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { propertyId } = useParams();
  const { caseId } = useParams();
  const { singleCase } = useSelector(({ casesState }) => casesState);
  const [selectedTabId, setSelectedTabId] = useState(TABS[0].id);
  const [open, setIsOpen] = useState(false);

  useEffect(() => {
    dispatch(getSingleCase(caseId));
  }, []);

  return (
    <FullScreenLayout>
      {({ layoutHeight }) => (
        <>
          <Box style={{ height: layoutHeight }}>
            <Box>
              <Paper elevation={4}>
                <Stack direction='row' justifyContent='space-between' alignItems='center'>
                  <Stack direction='row' spacing={1} alignItems='center'>
                    <IconButton onClick={() => navigate(`/properties/${propertyId}`)}>
                      <ArrowBackIcon style={{ width: 16, height: 16 }} />
                    </IconButton>
                    <Typography variant='h5'>{singleCase?.title}</Typography>
                  </Stack>
                  <Stack direction='row' spacing={5} alignItems='center'>
                    <Stack direction='row' spacing={1}>
                      <Typography variant='h5' textTransform='uppercase'>{t('status')}:</Typography>
                      <Typography variant='h5' textTransform='uppercase' color={singleCase?.isActive ? '#E46C9F' : 'primary'} >
                        {singleCase?.isActive ? t('active') : t('solved')}
                      </Typography>
                      {!singleCase?.isActive && (
                        <Typography variant='h5'>
                          {singleCase?.updatedAt ? formatDate(singleCase?.updatedAt, 'yyyy-MM-dd HH:mm') : ''}
                        </Typography>
                      )}
                    </Stack>
                    {singleCase?.isActive && (
                      <Stack direction='row' alignItems='center' spacing={1}>
                        <Button variant='contained' color='info' onClick={() => navigate(`/properties/${propertyId}/case/${caseId}`)}>{t('edit')}</Button>
                        <Button variant='contained' color='primary' onClick={() => setIsOpen(true)}>{t('allow')}</Button>
                      </Stack>
                    )}
                  </Stack>
                </Stack>
              </Paper>
            </Box>
            <Box mt={2} sx={{ height: 'calc(100% - 55px)' }}>
              <Grid container sx={{ height: '100%' }}>
                <Grid item md={7} sx={{ height: '100%' }}>
                  <Paper elevation={4} sx={{ height: '100%' }}>
                    <Box p={1} pb={12}>
                      <Typography variant='h5' mt={1} mb={2}>{t('description')}</Typography>
                      <Typography variant='h6'>
                        {singleCase?.description}
                      </Typography>
                    </Box>
                  </Paper>
                </Grid>
                <Grid item md={5}>
                  <Paper elevation={4} sx={{ display: 'flex', flexDirection: 'column', height: '100%', position: 'relative' }}>
                    <Box mt={3} p={1}>
                      <Tabs
                        value={selectedTabId}
                        onChange={(_, tabValue) => setSelectedTabId(tabValue)}
                        variant='fullWidth'
                      >
                        {TABS.map(({ id, label }) => (
                          <Tab
                            key={`${id}-tab`}
                            value={id}
                            {...{ label }}
                          />
                        ))}
                      </Tabs>
                    </Box>
                    <Box sx={{ height: '100%', display: 'flex', alignItems: 'flex-end' }} mt={2}>
                      {TABS.map(({ id, component: Component }) => {
                        const isActive = id === selectedTabId;
                        return (
                          <div
                            key={`${id}-tab-component`}
                            style={{ width: '100%', display: isActive ? 'block' : 'none' }}>
                            <Component />
                          </div>
                        );
                      })}
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            </Box>
            <Dialog
              open={open}
              onClose={() => setIsOpen(false)}
              title={t('allowCase')}
            >
              <Typography variant='h6' mb={3}> {t('pleaseAllow')}
              </Typography>
              <Button
                fullWidth
                variant='contained'
                color='primary'
                onClick={() => {
                  dispatch(updateCaseStatus(caseId));
                  setIsOpen(false);
                }}
              >
                {t('allow')}
              </Button>
            </Dialog>
          </Box>
        </>
      )}
    </FullScreenLayout >
  );
};

export default PropertiesCaseDetails;